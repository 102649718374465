import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { Card, Button } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { DeletePost } from '../../graphql/mutations';

const styles = {
  button__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
};

@injectSheet(styles)
@graphql(DeletePost)
export default class DeleteAction extends Component {
  state = {
    post: this.props.post,
    deleting: false,
  };

  static getDerivedStateFromProps(nextProps) {
    return { post: nextProps.post };
  }

  hide = () => {
    this.setState({ deleting: false });
    this.props.onHide();
  };

  delete = () => {
    const { refetchQueries, mutate } = this.props;
    this.setState({ deleting: true });
    mutate({
      variables: { id: this.state.post._id },
      refetchQueries,
    })
      .then(this.hide)
      .catch(this.hide);
  };

  render() {
    return (
      <Card>
        <h3>Delete Post</h3>
        <p>Are you sure you want to delete this post? The post will be removed from the Spoiler Alert marketplace.</p>
        <div className={this.props.classes.button__container}>
          <Button type="button" link onClick={this.hide} disabled={this.state.deleting}>
            Cancel
          </Button>
          <Button type="button" primary onClick={this.delete} loading={this.state.deleting} loadingText="Wait...">
            Delete
          </Button>
        </div>
      </Card>
    );
  }
}

DeleteAction.propTypes = {
  classes: PropTypes.object,
  post: PropTypes.object,
  onHide: PropTypes.func,
  mutate: PropTypes.func,
  refetchQueries: PropTypes.array,
};

DeleteAction.defaultProps = {
  onHide: () => {},
};
