import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GraphQLDataTable } from '@spoiler-alert/ui-library';
import { PendingUsersQuery } from '../../graphql/queries';
import { pendingUserColumns } from './pending-user-columns';

export default class PendingUsers extends Component {
  render() {
    return (
      <GraphQLDataTable
        query={PendingUsersQuery}
        queryName="currentUserQuery"
        queryField="site.invites"
        userId={this.props.user._id}
        search
        transition
        columns={pendingUserColumns}
        pagination
        perPage={15}
        onRowClick={this.props.handleDynamicRowClick}
        perPageIncrements={[15, 30, 50, 100]}
        onQueryChange={this.props.onQueryChange}
      />
    );
  }
}

PendingUsers.propTypes = {
  user: PropTypes.object,
  onQueryChange: PropTypes.func,
  handleDynamicRowClick: PropTypes.func,
};
