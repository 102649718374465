import SupportService from './support-service';
import AppSettings from '../app-settings';

class LoginService {
  constructor() {
    this.apiUrl = `${AppSettings.GRAPHQL_SERVER_URL}`;
    this.contentType = 'application/json';
  }

  async makeCall(url, method, body, headers) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        body,
        method,
        headers,
      }).then((res) => {
        if (!res.ok) res.text().then((err) => reject(err));
        else
          res.json().then((token) => {
            localStorage.setItem('token', token);
            if (this.checkForKustomerRedirect()) this.redirectToKustomer();
            resolve(token);
          });
      });
    });
  }

  checkForKustomerRedirect() {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    return params.get('kustomerRedirectUri');
  }

  async redirectToKustomer() {
    SupportService.supportSSOUrl()
      .then((response) => response.json())
      .then((data) => {
        window.location = data.url;
      })
      .catch((err) => AlertService.error(err));
  }

  get headers() {
    return {
      'Content-Type': this.contentType,
    };
  }

  login(username, password) {
    const body = JSON.stringify({ username, password });
    return this.makeCall(`${this.apiUrl}auth/login`, 'POST', body, this.headers);
  }

  getProviderURI(email, path) {
    return fetch(`${this.apiUrl}sso/redirect-uri`, { body: JSON.stringify({ email, path }), method: 'POST', headers: this.headers });
  }

  exchangeToken(code) {
    return this.makeCall(`${this.apiUrl}sso/exchange`, 'POST', JSON.stringify({ code }), this.headers);
  }
}

const loginService = new LoginService();
export default loginService;
