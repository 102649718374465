import LocalizedStrings from 'react-localization';

const GeneralStrings = new LocalizedStrings({
  en: {
    submit: 'Submit',
    loading: 'Loading...',
    delete: 'Delete',
    clear: 'Clear',
    undo: 'Undo',
    save: 'Save',
  },
});

export default GeneralStrings;
