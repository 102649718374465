import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { TitleService } from '../services';
import { Breadcrumbs } from '../store';
import featureFlags from '../enums/feature-flags';
import routePaths from '../route-paths';

const useStyles = createUseStyles({
  lookerDashboard: {
    minHeight: 'calc(100vh - 68px)',
    width: '100%',
  },
});

const LookerDashboard = ({ user, rootStore, history }) => {
  const classes = useStyles();
  useEffect(() => {
    TitleService.setTitles('Dashboard', 'Dashboard');
    Breadcrumbs.set([
      {
        url: '/',
        title: 'Dashboard',
      },
    ]);
  }, []);
  const { featureMap } = rootStore;
  const hasLegacyFeatureFlag = !!featureMap.get(featureFlags.legacyDashboard) === true;
  if (hasLegacyFeatureFlag) history.push(routePaths.enterpriseDashboard);
  return <iframe className={classes.lookerDashboard} src={user.lookerDashboardSsoUrl} frameBorder="0"></iframe>;
};

LookerDashboard.propTypes = {
  user: PropTypes.object,
  rootStore: PropTypes.object,
  history: PropTypes.object,
};

const ConnectedComponent = inject('rootStore')(observer(LookerDashboard));
export default ConnectedComponent;
