import LocalizedStrings from 'react-localization';

const TaxInfoStrings = new LocalizedStrings({
  en: {
    successAlertText: 'Your tax info has been updated correctly.',
    errorAlertText: 'There was an error updating your tax info. If this problem persists, please contact a Spoiler Alert Administrator to help you.',
    taxIdToolTip1:
      "We use a federal tax identification number, called an Employer Identification Number (EIN), to verify your nonprofit status. You can find your company's EIN in one of the following ways:",
    taxIdToolTip2: 'Look at your tax documents (e.g. W-2s or 1099s)',
    taxIdToolTip3: "Contact your company's Human Resources or Accounting representative",
    taxIdToolTip4: 'Contact your local Internal Revenue Service (IRS) office',
    signatureTitle: 'Signature',
    signHere: 'Sign Here',
    purposeTooltip:
      'This should be a statement of the mission of your nonprofit that includes information on the ultimate recipients of food received through a donation. This language will be used on tax receipts for charitable donations.',
    pageTitle: 'Taxes',
    signatureDescription:
      'The signature below is the signature that will be included on all tax documents for this site. Please insure that the appropriate party at your organization signs the box below.',
    clearSignature: 'Clear Signature',
  },
});

export default TaxInfoStrings;
