import LocalizedStrings from 'react-localization';

const SettingsStrings = new LocalizedStrings({
  en: {
    address: 'Address',
    radius: 'Radius (miles)',
    pickup: 'Pick-Up',
    dropoff: 'Drop-Off',
    foodHandlingPrefs: 'Food Handling Preferences',
    notificationsSubtitle: 'How do you want to receive your purchases?',
    notificationsTitle: 'Notifications',
    foodHandlingLabelMap: {
      'dry storage': 'Dry Storage',
      'keep cool 41 degrees': 'Cool (32-41 degrees)',
      'keep frozen 32 degrees': 'Frozen (<32 degrees)',
      'keep hot 135 degrees': 'Prepared (>135 degrees)',
      none: null,
    },
    mutationSuccess: 'Your notifications settings have been updated correctly.',
    mutationFailure:
      'There was an error updating your notifications settings. If this problem persists, please contact a Spoiler Alert Administrator to help you.',
    mile: 'mile',
    handlingCapabilities: 'Handling Capabilities',
    handlingText: 'Specify your food handling capabilities to receive relevant notifications.',
    productPreferences: 'Product Preferences',
    productConditions: 'Product Conditions',
    locationText: 'Set a distance radius to receive notifications for posts from within the defined radius.',
  },
});

export default SettingsStrings;
