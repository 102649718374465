import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { inject, observer } from 'mobx-react';
import { Button, Theme as theme } from '@spoiler-alert/ui-library';
import { RefreshService } from '../../services';
import { RefreshStrings } from '../../string-resources';

const styles = {
  refresher: {
    display: 'flex',
    padding: '10px 30px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: 30,
    left: 30,
    backgroundColor: '#292F34',
    color: '#ECF1F5',
    boxShadow: '0px 0px 17px 3px rgba(0, 0, 0, 0.4)',
    zIndex: 5000,
    borderRadius: theme.borderRadius,
    animation: 'fade-in 500ms forwards',
  },
};

class Refresher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      different: false,
    };

    this.checkVersion();
    this.checkFeatures();
    this.interval = window.setInterval(this.checkLatest, this.duration);
  }

  // 5 minute duration
  get duration() {
    return 5 * 60 * 1000;
  }

  checkVersion = async () => {
    const serverVersion = await RefreshService.checkServerVersion();
    if (!this.version) this.version = serverVersion.version;
    if (serverVersion.version !== this.version) {
      this.setState({ different: true });
      window.clearInterval(this.interval);
    }
  };

  checkLatest = () => {
    this.checkVersion();
    this.checkFeatures();
  };

  checkFeatures = async () => {
    const currentFeatures = await RefreshService.checkFeatures(this.props.buyer);
    this.props.rootStore.updateFeatures(currentFeatures);
    this.props.rootStore.setLoading(false);
  };

  refresh = (ev) => {
    ev.preventDefault();
    window.location.reload(true);
  };

  render() {
    const { classes } = this.props;
    const { different } = this.state;
    if (!different) return null;
    return (
      <div className={classes.refresher}>
        {RefreshStrings.refresherText}{' '}
        <Button type="button" link onClick={this.refresh}>
          {RefreshStrings.refreshButton}
        </Button>
      </div>
    );
  }
}

Refresher.propTypes = {
  classes: PropTypes.object,
  rootStore: PropTypes.object,
  buyer: PropTypes.bool,
};

const ConnectedComponent = inject('rootStore')(observer(Refresher));
const StyledComponent = injectSheet(styles)(ConnectedComponent);
export default StyledComponent;
