import { ActiveListingsQuery } from '../../graphql/queries';
/* eslint-disable @typescript-eslint/no-explicit-any */
const deleteCachedFieldsOnActiveListingsQuery = (cache) => {
    const userQueryRes = cache.readQuery({
        query: ActiveListingsQuery,
    });
    cache.evict({ id: cache.identify(userQueryRes === null || userQueryRes === void 0 ? void 0 : userQueryRes.currentUserQuery) });
    cache.gc();
};
export default deleteCachedFieldsOnActiveListingsQuery;
