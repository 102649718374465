import React, { Component } from 'react';
import { ApolloConsumer } from '@apollo/client';

export default function withApolloClient(WrappedComponent) {
  return class Wrapper extends Component {
    render() {
      return <ApolloConsumer>{(client) => <WrappedComponent apolloClient={client} {...this.props} />}</ApolloConsumer>;
    }
  };
}
