import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { graphql } from '@apollo/client/react/hoc';
import { Modal, ModalContent, ModalFooter, Button, TextInput, Select, SelectOption, AlertService, Theme as theme } from '@spoiler-alert/ui-library';
import { createInvite } from '../../graphql/mutations';
import { PendingUsersQuery } from '../../graphql/queries';
import { Envelope, Check } from '../envelope';

const styles = {
  invite__container: {
    overflow: 'hidden',
    position: 'relative',
  },
  form__container: {
    transitionDuration: '400ms',
  },
  'form__container--sent': {
    transform: 'translateY(-100%)',
  },
  sent__container: {
    transitionDuration: '400ms',
    transform: 'translateY(100%)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'sent__container--sent': {
    transform: 'translateY(0)',
  },
  modal__title: {
    fontSize: '1.5rem',
    padding: 0,
    marginTop: 0,
    color: '#292e34',
    fontWeight: 'normal',
  },
  modal__subtitle: {
    fontSize: '0.875rem',
    color: '#292e34',
  },
  invite__form: {
    width: '100%',
  },
  input__row: {
    padding: '6px 0',
    display: 'flex',
    flexDirection: 'row',
  },
  input__field: {
    width: '100%',
    '&:first-child': {
      marginRight: 24,
    },
  },
  sent__header: {
    fontSize: '1.5rem',
    color: theme.textColorPrimary,
    fontWeight: '500',
    marginBottom: 8,
    opacity: 0,
  },
  'sent__header--sent': {
    animation: 'move-in 200ms ease-out 900ms 1 forwards',
  },
  sent__text: {
    fontSize: '0.875rem',
    color: theme.textColorPrimary,
    fontWeight: '500',
    opacity: 0,
  },
  'sent__text--sent': {
    animation: 'move-in 200ms ease-out 1000ms 1 forwards',
  },
  envelope__svg: {
    height: 36,
    width: 48,
    animation: 'fly-away 750ms cubic-bezier(0.500, -0.800, 1.000, -0.065) 2000ms 1 forwards',
  },
  envelope: {
    height: 36,
    width: 48,
    opacity: 0,
  },
  checkmark: {
    position: 'absolute',
    top: -6,
    right: -4,
    animation: 'check-fade 150ms linear 2400ms 1 forwards',
  },
  'envelope--sent': {
    animation: 'move-in 200ms ease-out 850ms 1 forwards',
  },
  '@media (max-width: 840px)': {
    input__row: {
      flexDirection: 'column',
    },
    input__field: {
      '&:first-child': {
        marginRight: 0,
        marginBottom: 16,
      },
    },
  },
  '@keyframes move-in': {
    '0%': {
      transform: 'translateY(200%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  '@keyframes fly-away': {
    '0%': {
      transform: 'translateX(0)',
    },
    '100%': {
      transform: 'translateX(400px)',
    },
  },
  '@keyframes check-fade': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
};

@graphql(createInvite)
@injectSheet(styles)
export default class InviteModal extends Component {
  constructor(props) {
    super(props);

    const site = props.user.siteHierarchy.length === 1 ? props.user.siteHierarchy[0] : undefined;

    this.state = {
      open: false,
      firstName: '',
      lastName: '',
      email: '',
      title: '',
      siteId: site ? site._id : '',
      site: site ? { value: site, text: site.siteName } : undefined,
      siteName: site ? site.siteName : '',
      privClass: '',
      inviting: false,
      sent: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      open: nextProps.open,
    };
  }

  setFirstName = (firstName) => {
    this.setState({ firstName });
  };

  setLastName = (lastName) => {
    this.setState({ lastName });
  };

  setTitle = (title) => {
    this.setState({ title });
  };

  setEmail = (email) => {
    this.setState({ email });
  };

  cancel = () => {
    this.done();
  };

  get refetchQueries() {
    return [
      {
        query: PendingUsersQuery,
        variables: this.props.queryVariables,
      },
    ];
  }

  invite = (ev) => {
    ev.preventDefault();
    const { mutate } = this.props;
    this.setState({ inviting: true });
    const { firstName, lastName, email, privClass, title, siteId } = this.state;
    const variables = {
      firstName,
      lastName,
      email,
      privClass,
      title,
      siteId,
    };
    const { refetchQueries } = this;
    mutate({ variables, refetchQueries }).then(this.handleResponse).catch(this.handleResponse);
  };

  reset = () => {
    const site = this.props.user.siteHierarchy.length === 1 ? this.props.user.siteHierarchy[0] : undefined;
    this.setState({
      inviting: false,
      sent: false,
      firstName: '',
      lastName: '',
      email: '',
      title: '',
      privClass: '',
      userRole: undefined,
      site: undefined,
      siteId: site ? site._id : '',
      siteName: site ? site.siteName : '',
    });
  };

  done = () => {
    this.reset();
    this.props.onHide();
  };

  sent = () => {
    this.setState({ sent: true });
  };

  handleResponse = (response) => {
    if (!response.data) return this.failed('Something went wrong. Try again later.');
    const error = response.data.createInvite ? response.data.createInvite.error : response.data.error;
    if (error) return this.failed(error);
    return this.sent();
  };

  failed = (error) => {
    AlertService.alert({ type: 'warning', message: <span>{error}</span>, autoDismiss: true, dismissDelay: 3000 });
    this.setState({ inviting: false });
  };

  setSite = (_, site) => {
    this.setState({ site, siteId: site.value._id, siteName: site.value.siteName });
  };

  setRole = (_, userRole) => {
    const privClass = userRole.value;
    this.setState({ privClass, userRole });
  };

  get organizations() {
    const { user, classes } = this.props;
    return (
      <div className={classes.input__field}>
        <Select label="Organization" onChange={this.setSite} selectedItem={this.state.site}>
          {user.siteHierarchy.map((site) => (
            <SelectOption value={site} key={site._id}>
              {site.siteName}
            </SelectOption>
          ))}
        </Select>
      </div>
    );
  }

  get organization() {
    return (
      <TextInput className={this.props.classes.input__field} disabled labelText="Organization" type="text" required value={this.state.siteName} />
    );
  }

  get organizationInput() {
    return this.props.user.siteHierarchy.length > 1 ? this.organizations : this.organization;
  }

  render() {
    const { classes, user } = this.props;
    const formClass = `${classes.form__container}${this.state.sent ? ` ${classes['form__container--sent']}` : ''}`;
    const sentClass = `${classes.sent__container}${this.state.sent ? ` ${classes['sent__container--sent']}` : ''}`;
    const sentHeaderClass = `${classes.sent__header}${this.state.sent ? ` ${classes['sent__header--sent']}` : ''}`;
    const sentTextClass = `${classes.sent__text}${this.state.sent ? ` ${classes['sent__text--sent']}` : ''}`;
    const envelopeClass = `${classes.envelope}${this.state.sent ? ` ${classes['envelope--sent']}` : ''}`;
    return (
      <Modal onHide={this.props.onHide} open={this.state.open}>
        <form onSubmit={this.invite} className={classes.invite__form}>
          <ModalContent>
            <div className={classes.invite__container}>
              <div className={formClass}>
                <h2 className={classes.modal__title}>Invite Users to Spoiler Alert</h2>
                <p className={classes.modal__subtitle}>
                  Enter your team members information to send them a Spoiler Alert email invite. Learn more about{' '}
                  <a href="https://spoileralert.zendesk.com/hc/en-us/articles/115001313411" rel="noopener noreferrer" target="_blank">
                    Spoiler Alert User Roles
                  </a>
                  .
                </p>
                <div className={classes.input__row}>
                  <TextInput
                    className={classes.input__field}
                    onChange={this.setFirstName}
                    type="text"
                    labelText="First Name *"
                    value={this.state.firstName}
                    required
                  />
                  <TextInput
                    className={classes.input__field}
                    onChange={this.setLastName}
                    type="text"
                    labelText="Last Name *"
                    value={this.state.lastName}
                    required
                  />
                </div>
                <div className={classes.input__row}>
                  <TextInput
                    className={classes.input__field}
                    onChange={this.setEmail}
                    type="email"
                    labelText="Business Email *"
                    value={this.state.email}
                    required
                  />
                  <TextInput className={classes.input__field} onChange={this.setTitle} type="text" labelText="Title" value={this.state.title} />
                </div>
                <div className={classes.input__row}>
                  <div className={classes.input__field}>
                    <Select label="User Role *" onChange={this.setRole} required selectedItem={this.state.userRole}>
                      {user.site.privClasses.map((role) => (
                        <SelectOption value={role.name} key={role.displayName}>
                          {role.displayName}
                        </SelectOption>
                      ))}
                    </Select>
                  </div>
                  {this.organizationInput}
                </div>
              </div>
              {this.state.sent && (
                <div className={sentClass}>
                  <div className={envelopeClass}>
                    <Check className={classes.checkmark} checked />
                    <Envelope className={classes.envelope__svg} folded />
                  </div>
                  <h2 className={sentHeaderClass}>Your invitation has been sent</h2>
                  <p className={sentTextClass}>
                    You invited {this.state.firstName} {this.state.lastName} to {this.state.siteName}.
                  </p>
                </div>
              )}
            </div>
          </ModalContent>
          <div className={classes.invite__container}>
            <div className={formClass}>
              <ModalFooter>
                <Button type="button" onClick={this.cancel} link disabled={this.state.inviting}>
                  Cancel
                </Button>
                <Button type="submit" disabled={this.disabled} primary loading={this.state.inviting} loadingText="Wait...">
                  Send Invite
                </Button>
              </ModalFooter>
            </div>
            <div className={sentClass}>
              <ModalFooter>
                <Button type="button" onClick={this.reset} link>
                  Invite Another
                </Button>
                <Button type="button" primary onClick={this.done}>
                  Done
                </Button>
              </ModalFooter>
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

InviteModal.propTypes = {
  classes: PropTypes.object,
  onHide: PropTypes.func,
  open: PropTypes.bool,
  user: PropTypes.object,
  mutate: PropTypes.func,
  queryVariables: PropTypes.object,
};

InviteModal.defaultProps = {
  queryVariables: { paginate: { pageNumber: 1, limit: 30, filter: { sortBy: { asc: true, sortProperty: 'lastName' } } } },
};
