import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Overlay, LoadingLarge } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { inject, observer } from 'mobx-react';
import Sidebar from '../components/sidebar';
import MainContent from './main-content';
import Refresher from '../components/refresher';
import UserMenu from './user-menu';
import CreateDropdown from './create-dropdown';
import Breadcrumbs from './breadcrumbs';
import ProgressBars from './progress-bars/progress-bars';
import ScrollContext from './scroll-context';

const styles = {
  siteContainer: {
    width: '100%',
    height: '100%',
  },
  siteWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },
  'siteWrapper--overlay': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    filter: 'blur(5px)',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    paddingLeft: 202,
    backgroundColor: '#fff',
    position: 'relative',
    transitionDuration: '300ms',
    transitionProperty: 'padding-left',
  },
  'mainContent--collapsed': {
    paddingLeft: 70,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    position: 'relative',
    '&:has(#showScroll)': {
      overflowY: 'scroll',
    },
    '&:has(#hideScroll)': {
      overflowY: 'hidden !important',
    },
  },
  contentBody: {
    position: 'relative',
    padding: '0 16px',
  },
  '@media (max-width: 840px)': {
    mainContent: {
      paddingLeft: 0,
    },
  },
  siteBarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: '.7%',
    alignItems: 'baseline',
  },
};

class InternalLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      overlaid: false,
      collapsed: props.rootStore.sidebarStatus.collapsed,
    };
    this.isComponentMounted = true;
    this.scrollRef = createRef();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  onOverlayShow = () => {
    if (!this.state.overlaid) this.setState({ overlaid: true });
  };

  onOverlayHide = () => {
    if (this.state.overlaid) this.setState({ overlaid: false });
  };

  close = () => {
    this.props.rootStore.setSidebar({ collapsed: true });
  };

  open = () => {
    this.props.rootStore.setSidebar({ collapsed: false });
  };

  expand = () => {
    if (this.isComponentMounted) this.props.rootStore.setSidebar({ collapsed: false });
  };

  collapse = () => {
    if (this.isComponentMounted) this.props.rootStore.setSidebar({ collapsed: true });
  };

  userSiteIsNonProfit() {
    return this.props.user.site.isNonProfit;
  }

  get canViewDropdown() {
    return !this.userSiteIsNonProfit() && this.props.user.privileges.canViewDropdown;
  }

  render() {
    const { user, history, style, children, classes, rootStore, hideProgressBars } = this.props;
    const wrapperClass = this.state.overlaid ? classes['siteWrapper--overlay'] : classes.siteWrapper;
    const mainClass = `${classes.mainContent}${rootStore.sidebarStatus.collapsed ? ` ${classes['mainContent--collapsed']}` : ''}`;
    if (!user) return null;
    const isSellerSite = user.site.siteRole.find((role) => role === 'SELLER');
    const loading = rootStore.isLoading;
    return (
      <div className={classes.siteContainer} style={style}>
        {loading ? (
          <div className="loading__container">
            <LoadingLarge />
          </div>
        ) : (
          <div className={wrapperClass}>
            <Sidebar user={user} onClose={this.close} onOpen={this.open}>
              {this.canViewDropdown && <CreateDropdown user={user} />}
              <UserMenu user={user} history={history} />
            </Sidebar>
            <div className={mainClass}>
              <div className={classes.mainContainer} ref={this.scrollRef}>
                <div className={classes.siteBarWrapper}>
                  <Breadcrumbs className={classes.outerBreadCrumbWrapper} />
                  {isSellerSite && !hideProgressBars && <ProgressBars className={classes.progressBarWrapper} user={user} />}
                </div>
                <div className={classes.contentBody}>
                  <ScrollContext.Provider value={this.scrollRef}>
                    <MainContent user={user} history={history} content={children} />
                  </ScrollContext.Provider>
                </div>
              </div>
            </div>
          </div>
        )}
        <Refresher />
        <Overlay onShow={this.onOverlayShow} onHide={this.onOverlayHide} />
      </div>
    );
  }
}

InternalLayout.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  user: PropTypes.object,
  title: PropTypes.string,
  style: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  rootStore: PropTypes.object,
  hideProgressBars: PropTypes.bool,
};

const ConnectedComponent = inject('rootStore')(observer(InternalLayout));
const StyledComponent = injectSheet(styles)(ConnectedComponent);
export default StyledComponent;
