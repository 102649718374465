import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Loading, Theme as theme } from '@spoiler-alert/ui-library';
import { graphql } from '@apollo/client/react/hoc';
import { SiteSettingsQuery } from '../../graphql/queries';
import TaxInfo from './tax-info';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';

const styles = {
  loading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    fontSize: '16px',
    padding: '0 15px',
    position: 'relative',
  },
  settings__navigation: {
    position: 'fixed',
    display: 'flex',
    width: '184px',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: 20,
    zIndex: 10,
  },
  settings__navlist: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  settings__listitem: {
    display: 'block',
    margin: 0,
    padding: 0,
    boxShadow: `0 1px 0 0 ${theme.borderColor}`,
    '&:last-child': {
      boxShadow: 'none',
    },
  },
  settings__navlink: {
    display: 'block',
    fontSize: '1rem',
    fontWeight: 300,
    lineHeight: '1.1875rem',
    textDecoration: 'none',
    color: theme.textColorPrimary,
    padding: 10,
    marginTop: 1,
    '&:hover': {
      color: theme.textColorPrimary,
      backgroundColor: theme.menuItemHoverBg,
    },
    '&:active': {
      textDecoration: 'none',
    },
  },
  'settings__navlink--active': {
    fontWeight: 500,
    color: theme.primaryColor,
  },
  settings: {
    width: '100%',
    position: 'relative',
    zIndex: 5,
    paddingLeft: 258,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  settings__section: {
    marginBottom: 24,
    width: 578,
  },
  '@media (max-width: 840px)': {
    settings: {
      paddingLeft: 0,
    },
    settings__navigation: {
      display: 'none',
    },
  },
};

class SiteSettings extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('Site Settings');
    Breadcrumbs.set([
      {
        url: '/site/settings',
        title: 'Site Settings',
      },
    ]);
    this.references = new Map();

    const { data } = props;

    this.state = {
      data: data.currentUserQuery,
      loading: data.loading,
    };
  }

  get refetchQueries() {
    return [
      {
        query: SiteSettingsQuery,
      },
    ];
  }

  static getDerivedStateFromProps(nextProps) {
    const data = nextProps.data ? nextProps.data.currentUserQuery : null;
    return { data, loading: nextProps.data.loading };
  }

  handleScroll = (ev) => {
    ev.preventDefault();
    const ref = this.references.get(ev.target.dataset.target);
    ref.scrollIntoView({ inline: 'nearest', block: 'start', behavior: 'smooth' });
  };

  setReference = (ref) => {
    if (ref) this.references.set(ref.id, ref);
  };

  get isNonProfit() {
    const { loading, data } = this.state;
    return !loading && data && data.site && data.site.isNonProfit;
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    return (
      <Loading loading={loading}>
        <div className={classes.container}>
          {!loading && (
            <div className={classes.container}>
              <nav className={classes.settings__navigation}>
                <ul className={classes.settings__navlist}>
                  {this.isNonProfit && (
                    <li className={classes.settings__listitem}>
                      <a className={classes.settings__navlink} href="#tax-info" data-target="tax-info" onClick={this.handleScroll}>
                        Taxes
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
              <div className={classes.settings}>
                {this.isNonProfit && (
                  <div className={classes.settings__section} ref={this.setReference} id="tax-info">
                    <TaxInfo refetchQueries={this.refetchQueries} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Loading>
    );
  }
}

SiteSettings.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
};

const StyledComponent = injectSheet(styles)(SiteSettings);
const GqlComponent = graphql(SiteSettingsQuery)(StyledComponent);
export default GqlComponent;
