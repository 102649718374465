const createEmailMap = (seeds) => {
  const possibleEmailsPerBuyer = new Map();
  seeds.forEach((bsr) => {
    bsr.buyerUserEmails.forEach((email) => {
      if (possibleEmailsPerBuyer.get(bsr.buyerSiteId)) {
        possibleEmailsPerBuyer.get(bsr.buyerSiteId).push({
          _id: `${bsr.buyerSiteId}-${email}-buyer`,
          key: email,
          value: ['Offer Sheets', 'Award Sheets'],
          groupBy: 'Customer',
          buyerSiteId: bsr.buyerSiteId,
          sellerSiteId: bsr.sellerSiteId,
        });
      } else {
        possibleEmailsPerBuyer.set(bsr.buyerSiteId, [
          {
            _id: `${bsr.buyerSiteId}-${email}-buyer`,
            key: email,
            value: ['Offer Sheets', 'Award Sheets'],
            groupBy: 'Customer',
            buyerSiteId: bsr.buyerSiteId,
            sellerSiteId: bsr.sellerSiteId,
          },
        ]);
      }
    });
    bsr.sellerUserEmails.forEach((email) => {
      if (possibleEmailsPerBuyer.get(bsr.buyerSiteId)) {
        possibleEmailsPerBuyer.get(bsr.buyerSiteId).push({
          _id: `${bsr.buyerSiteId}-${email}-seller`,
          key: email,
          value: ['Offer Sheets', 'Award Sheets'],
          groupBy: 'Internal',
          buyerSiteId: bsr.buyerSiteId,
          sellerSiteId: bsr.sellerSiteId,
        });
      } else {
        possibleEmailsPerBuyer.set(bsr.buyerSiteId, [
          {
            _id: `${bsr.buyerSiteId}-${email}-seller`,
            key: email,
            value: ['Offer Sheets', 'Award Sheets'],
            groupBy: 'Internal',
            buyerSiteId: bsr.buyerSiteId,
            sellerSiteId: bsr.sellerSiteId,
          },
        ]);
      }
    });
  });
  return possibleEmailsPerBuyer;
};

export default createEmailMap;
