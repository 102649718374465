import React, { Component } from 'react';
import accounting from 'accounting';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { Modal, ModalContent, ModalFooter, Button, TextInput, DatePicker } from '@spoiler-alert/ui-library';
import { VerifyPost } from '../../graphql/mutations';

const styles = {
  'question-modal__title': {
    fontSize: '1.5rem',
    padding: 0,
    marginTop: 0,
    color: '#292e34',
    fontWeight: 'normal',
  },
  input__row: {
    padding: '6px 0',
  },
};

@graphql(VerifyPost)
@injectSheet(styles)
export default class VerifyModal extends Component {
  state = {
    claimDate: this.props.post && moment.utc(this.props.post?.claimDate || this.props.post?.createdAt),
    quantity: this.props.post?.quantity || 0,
    verifying: false,
    error: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.post && prevProps.post?._id !== this.props.post?._id) {
      const { post } = this.props;
      this.setState({
        claimDate: moment.utc(post.claimDate || post.createdAt),
        quantity: post.quantity || 0,
      });
    }
  }

  cancel = () => {
    this.props.close();
  };

  checkQuantity = (quantity) => {
    const error = quantity.trim() === '' || isNaN(quantity) || Number(quantity) < 1 || Number(quantity) > this.props.post.quantity;
    this.setState({ quantity: accounting.unformat(quantity), error });
  };

  setDate = (claimDate) => {
    this.setState({ claimDate: claimDate || moment.utc(this.props.post.claimDate) });
  };

  verify = (ev) => {
    ev.preventDefault();
    if (this.state.quantity > 0 && this.state.claimDate !== undefined) {
      const { refetchQueries, mutate } = this.props;
      this.setState({ verifying: true });
      const variables = { id: this.props.post._id, completedAt: this.state.claimDate.toDate(), quantity: this.state.quantity };
      mutate({ variables, refetchQueries }).then(this.hide).catch(this.error);
    }
  };

  get disabled() {
    return !this.props.post || this.state.quantity <= 0 || this.state.quantity > this.props.post.quantity || this.state.claimDate === undefined;
  }

  error = () => {
    this.setState({ verifying: false });
    this.props.onHide(undefined);
  };

  hide = (response) => {
    const error = response.data && response.data.error === undefined;
    this.props.onHide(error);
  };

  render() {
    const { classes, user, open, post } = this.props;
    const { quantity, claimDate, verifying } = this.state;
    return (
      <Modal onHide={this.props.close} open={open} closeOnEsc closeOnOutsideClick>
        <form onSubmit={this.verify}>
          <ModalContent>
            <h2 className={classes['question-modal__title']}>Verify the donation</h2>
            <div className={classes.input__row}>
              <DatePicker onChange={this.setDate} label="When did the exchange take place?" day={claimDate} />
            </div>
            <div className={classes.input__row}>
              <TextInput
                error={this.state.error}
                onChange={this.checkQuantity}
                type="number"
                labelText={`Verify the final ${post?.unitOfMeasure} donated`}
                value={quantity.toString()}
                min={1}
                max={post?.quantity || 1}
                required
              />
            </div>
            <p>By clicking “Complete”, you are confirming that the product has been received and accepted by {user.site.siteName}.</p>
          </ModalContent>
          <ModalFooter>
            <Button type="button" onClick={this.cancel} link disabled={verifying}>
              Cancel
            </Button>
            <Button type="submit" disabled={this.disabled} primary loading={verifying} loadingText="Wait...">
              Complete
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

VerifyModal.propTypes = {
  classes: PropTypes.object,
  onHide: PropTypes.func,
  close: PropTypes.func,
  open: PropTypes.bool,
  user: PropTypes.object,
  post: PropTypes.object,
  mutate: PropTypes.func,
  refetchQueries: PropTypes.array,
};
