import { gql } from '@apollo/client';

export const ItemDetailFragment = gql`
  fragment ItemDetails on Item {
    _id
    createdAt
    userName
    userId
    itemName
    siteName
    siteNumber
    itemDesc
    foodType
    foodSubtype
    handling
    brand
    unitOfMeasure
    packQty
    packSize
    packUOM
    grossWeightPerUOM
    netWeightPerUOM
    unitPrice
    unitCost
    activeReservePrice
    reserveAnchor
    uploadedReservePrice
    uploadedSuggestedUnitPrice
    managementGroup
    productType
    quality
    source
    upc
    GTINPallet
    GTINCase
    GTINProduct
    reason
    note
    unitOfSale
    pullTier
    casesPerPallet
    cubeAdjustedWeight
    productStatus
    shelfLife
    casesPerLayer
    layerPerPallet
    palletStackLimit
    caseHeight
    caseLength
    caseWidth
    caseStackLimit
    palletLayerHeight
    transitCondition
    branded
    labelsInCases
    productFormat
    bagsInCase
    prepackaged
    unitUPC
    maxStorage
    division
    caseCube
    doubleStack
    palletHi
    palletTi
    inventoryClassification
    materialType
    productSubtype
    dcItemNumber
    buyerGroup
    vendorName
    itemCategoryManagerName
    keheProductCategory
    productClass
    currencyType
    weightUnit
  }
`;
