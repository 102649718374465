import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GenericStaging from './generic-staging';
import { StagedListingSummaryQuery, StagedListingSummaryByCatalogQuery, ProgressBarsQuery } from '../../graphql/queries';
import { ClearStagedListings } from '../../graphql/mutations';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';

const Staging = ({ match, ...rest }) => {
  useEffect(() => {
    TitleService.setTitles('Staged Listings Overview');
    Breadcrumbs.set([
      {
        url: match.url,
        title: 'Staging',
      },
    ]);
  }, [match.url]);

  return (
    <GenericStaging
      key="Staging"
      dataTableProfileName="Staging"
      dataQuery={StagedListingSummaryQuery}
      progressBarsQuery={ProgressBarsQuery}
      dataGetter={(data) => data?.currentUserQuery?.stagedListingSummary || []}
      pollQuery={StagedListingSummaryByCatalogQuery}
      pollDataGetter={(data) => data?.currentUserQuery?.stagedListingSummaryByCatalog || []}
      refetchQueries={['StagedListingSummaryWithSiteQuery', 'StagedListingSummaryQuery', 'ProgressBarsQuery']}
      publishEndpoint="bid-sheet"
      clearListingsMutation={ClearStagedListings}
      clearListingsResponseGetter={(response) => response.data.clearStagedListings}
      detailPagePath={routePaths.staging}
      {...rest}
    />
  );
};

Staging.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Staging;
