import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Card, Button, TextInput, TypeAhead } from '@spoiler-alert/ui-library';
import formStyles from '../activity/forms/form-styles';
import { SiteNameTypeAheadQuery } from '../../graphql/queries';

const styles = {
  ...formStyles,
  button__prev: {
    marginRight: 'auto',
  },
  address: {
    display: 'flex',
    flexDirection: 'row',
  },
  city: {
    width: '50%',
    marginRight: 10,
  },
  state: {
    width: '20%',
    marginRight: 10,
  },
  zip: {
    width: '30%',
  },
};

@injectSheet(styles)
export default class SiteAdditional extends Component {
  static propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
    goToPreviousStep: PropTypes.func,
    goToNextStep: PropTypes.func,
    setReadyStep: PropTypes.func,
    serialize: PropTypes.func,
    siteType: PropTypes.object,
  };

  state = {
    itemImport: undefined,
    parentSite: undefined,
    rootSite: undefined,
    siteType: this.props.siteType,
  };

  static getDerivedStateFromProps({ siteType }) {
    return { siteType };
  }

  serialize() {
    this.props.serialize(this.state);
  }

  get ready() {
    const { parentSite, rootSite, itemImport } = this.state;
    return parentSite || rootSite || itemImport || this.isCustomer || this.isOrg;
  }

  setReady = () => {
    this.props.setReadyStep(this.ready);
    this.serialize();
  };

  updateFormItem = (key, value) => {
    const stateUpdate = {};
    stateUpdate[key] = value;
    this.setState(stateUpdate, this.setReady);
  };

  get disabled() {
    return !this.ready;
  }

  get itemImportInput() {
    const { classes } = this.props;
    return (
      <div id="item-import-input">
        <TextInput
          className={classes.input__field}
          onChange={this.updateFormItem.bind(this, 'itemImport')}
          type="text"
          labelText="Organization ID *"
          required
        />
      </div>
    );
  }

  get isRoot() {
    return ['SCS', 'SPO', 'SRO'].includes(this.state.siteType.value);
  }

  get isParent() {
    return this.state.siteType.value === 'SCS';
  }

  get isCustomer() {
    return this.state.siteType.value === 'CDS';
  }

  get isOrg() {
    return this.state.siteType.value === 'SRP';
  }

  get label() {
    if (this.isParent) return 'Business Unit name *';
    return 'Organization name *';
  }

  get field() {
    if (this.isParent) return 'parentSite';
    return 'rootSite';
  }

  updateTypeAheadItem = (key, value) => {
    const stateUpdate = {};
    stateUpdate[key] = value.result.siteName;
    this.setState(stateUpdate, this.setReady);
  };

  get parentSiteTypeahead() {
    return (
      <TypeAhead
        query={SiteNameTypeAheadQuery}
        queryName="siteNameTypeAheadQuery"
        dataFormat={[{ displayText: 'parentSite' }]}
        labelText={'Business Unit'}
        onChange={this.updateTypeAheadItem.bind(this, 'parentSite')}
        autoWidth
      />
    );
  }

  get rootSiteTypeahead() {
    return (
      <TypeAhead
        query={SiteNameTypeAheadQuery}
        queryName="siteNameTypeAheadQuery"
        dataFormat={[{ displayText: 'rootSite' }]}
        labelText={'Organization'}
        onChange={this.updateTypeAheadItem.bind(this, 'rootSite')}
        autoWidth
      />
    );
  }

  render() {
    const { classes, goToNextStep, goToPreviousStep } = this.props;
    return (
      <Card className={classes.form__container}>
        <h2 className={classes.form__header}>Additional Information</h2>
        {(this.isParent && this.rootSiteTypeahead) || (this.isRoot && this.rootSiteTypeahead && this.rootSiteTypeahead)}
        {this.isParent && this.parentSiteTypeahead}
        <div className={this.props.classes.button__container}>
          <Button type="button" className={classes.button__prev} link onClick={goToPreviousStep}>
            Prev
          </Button>
          <Button type="button" link onClick={goToNextStep} disabled={this.disabled}>
            Next
          </Button>
        </div>
      </Card>
    );
  }
}
