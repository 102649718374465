import moment from 'moment';
import { Column, Filter } from '@spoiler-alert/ui-library';
import accounting from 'accounting';

const statusMap = new Map([
  ['0', 'Manual'],
  ['1', 'Import'],
]);

export const historyColumns = [
  new Column({
    field: 'changedAt',
    displayName: 'Change Date',
    sortable: true,
    defaultSort: true,
    defaultSortDirection: 'desc',
    formatter(value) {
      return moment.utc(value).format('MM/DD/YYYY h:mm a');
    },
  }),
  new Column({ field: 'siteName', displayName: 'Site Name', sortable: true }),
  new Column({ field: 'itemName', displayName: 'Item Number', sortable: true }),
  new Column({
    field: 'unitPrice',
    displayName: 'Price/Unit',
    sortable: true,
    formatter(value) {
      return accounting.formatMoney(value);
    },
  }),
  new Column({
    field: 'unitCost',
    displayName: 'Cost/Unit',
    sortable: true,
    formatter(value) {
      return accounting.formatMoney(value);
    },
  }),
  new Column({ field: 'source', displayName: 'Source', sortable: true, formatter: (value, row, col) => col.enumMap.get(value), enumMap: statusMap }),
  new Column({ field: 'userName', displayName: 'Updated By', sortable: true }),
];

export function getFilters(user) {
  const locationFilter = new Filter({ displayName: 'Location', field: 'locations', queryField: 'sites', selectAll: true });
  return user.privileges.canUserManageMultipleSites ? [locationFilter] : [];
}
