import React from 'react';
import { ModalContent, CheckboxWithLabel, Button } from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import archiveInventoryModalStyles from './archive-inventory-modal-styles';

const useStyles = createUseStyles(archiveInventoryModalStyles);

const ReadyOrStagedArchiveInventoryModalContent = ({ toggleHideArchiveDisclaimer, hideArchiveDisclaimer, archiveLoading, cancel }) => {
  const classes = useStyles();
  const modalTitle = 'Archive Inventory';
  const modalBody =
    'Remove selected inventory from your Spoiler Alert Inventory. Archived inventory will be held in archived status and be removed from the workflow unless restored.';
  const modalFooter = "Don't show me this again";

  return (
    <div>
      <ModalContent>
        <div data-testid="modal-title" className={classes.modal__title}>
          {modalTitle}
        </div>
        <div data-testid="modal-body" className={classes.modal__body} style={{ marginBottom: 0 }}>
          {modalBody}
        </div>
      </ModalContent>
      <div className={classes.none_staged_footer__container}>
        <CheckboxWithLabel
          testId="disclaimer-button"
          onChecked={toggleHideArchiveDisclaimer}
          value="hideArchiveDisclaimer"
          checked={hideArchiveDisclaimer}
          disabled={archiveLoading}
          label={modalFooter}
        />
        <div className={classes.modal__submit}>
          <Button testId="cancel" className={classes.modal__cancel} type="button" onClick={cancel} secondary disabled={archiveLoading}>
            Cancel
          </Button>
          <Button testId="submit" type="submit" primary loadingText="Archiving Inventory" loading={archiveLoading}>
            Archive Inventory
          </Button>
        </div>
      </div>
    </div>
  );
};

ReadyOrStagedArchiveInventoryModalContent.propTypes = {
  toggleHideArchiveDisclaimer: PropTypes.func,
  hideArchiveDisclaimer: PropTypes.bool,
  archiveLoading: PropTypes.bool,
  cancel: PropTypes.func,
};

export default ReadyOrStagedArchiveInventoryModalContent;
