import React from 'react';
import injectSheet from 'react-jss';
import AppSettings from '../../app-settings';
const styles = {
    siteHeader: {
        padding: '18vh 0 6vh',
    },
    siteHeaderLogo: {
        width: 230,
        margin: 'auto',
        display: 'block',
    },
};
class SiteHeader extends React.Component {
    constructor() {
        super(...arguments);
        this.marketingWebsiteHomeUrl = AppSettings.MARKETING_URL;
    }
    render() {
        const { classes } = this.props;
        let img = React.createElement("img", { src: "/assets/spoiler-alert-logo.svg", alt: "Spoiler Alert", className: classes.siteHeaderLogo });
        if (this.props.showLink) {
            img = (React.createElement("a", { href: this.marketingWebsiteHomeUrl },
                React.createElement("img", { src: "/assets/spoiler-alert-logo.svg", alt: "Spoiler Alert", className: classes.siteHeaderLogo })));
        }
        return React.createElement("div", { className: classes.siteHeader }, img);
    }
}
export default injectSheet(styles)(SiteHeader);
