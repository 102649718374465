import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import capitalize from 'capitalize';
import injectSheet from 'react-jss';
import { Stepper, Steps, Step, StepContent, Button, Theme as theme, AlertService } from '@spoiler-alert/ui-library';
import { createOrUpdateTransaction } from '../../../graphql/mutations';
import ProductForm from './product-form';
import DestinationForm from './destination-form';
import { TitleService } from '../../../services';
import { Breadcrumbs } from '../../../store';

const styles = {
  stepper__container: {
    width: 'inherit',
    margin: '0 8px 36px 8px',
  },
  sticky__footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.primaryColor,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 8,
    left: 0,
    zIndex: 4,
  },
};

class LogTransaction extends Component {
  constructor(props) {
    super(props);
    this.gutterSize = 16;

    TitleService.setTitles('Log Transaction');
    Breadcrumbs.set([
      {
        url: '/log/transaction',
        title: 'Log Transaction',
      },
    ]);

    this.state = {
      isReady: false,
      reset: false,
      saving: false,
    };
  }

  handleReady = (isReady) => {
    this.setState({ isReady });
  };

  serializeFormData = (data) => {
    this.formData = { ...this.formData, ...data };
    this.setState({ reset: false, activeStep: undefined, saving: false, isDiscountedSales: data.isDiscountedSales });
  };

  success = () => {
    AlertService.alert({ type: 'success', message: <span>Success! Your transaction has been logged successfully.</span>, autoDismiss: true });
    this.setState({ reset: true && !this.props.post, activeStep: 'destination', saving: false });
  };

  error = () => {
    AlertService.alert({
      type: 'warning',
      message: (
        <span>
          There was a problem logging your transaction. Try again or contact <a href="mailto:support@spoileralert.com">support@spoileralert.com</a>{' '}
          for assistance.
        </span>
      ),
    });
    this.setState({ saving: false });
  };

  logTransaction = (ev) => {
    ev.preventDefault();
    const { item, quantity, quality, unitOfMeasure, isVendorReturn, destination, outlet, transactionDate, transactionPrice } = this.formData;
    this.setState({ saving: true });
    this.props
      .mutate({
        variables: {
          postId: this.getPostProperty('_id'),
          itemId: item._id,
          quantity: parseFloat(quantity),
          quality: quality.value,
          unitOfMeasure: unitOfMeasure.value,
          isVendorReturn,
          destination: destination.value,
          outlet: outlet ? outlet.text : undefined,
          outletSiteId: outlet && outlet.value ? outlet.value : undefined,
          transactionDate,
          transactionPrice: parseFloat(transactionPrice),
        },
      })
      .then(this.success)
      .catch(this.error);
  };

  getPostProperty(prop) {
    return this.props.post ? this.props.post[prop] : undefined;
  }

  get outlet() {
    const outletSite = this.getPostProperty('outletSite');
    const outletName = this.getPostProperty('outletName');
    if (outletSite && outletSite._id) {
      return { value: outletSite._id, text: capitalize(outletSite.siteName) };
    }
    if (outletName) {
      return { value: undefined, text: capitalize(outletName) };
    }
    return undefined;
  }

  render() {
    const { classes, user } = this.props;
    const { reset, isDiscountedSales, isReady, saving, activeStep } = this.state;
    return (
      <div id="log-activity">
        <form id="log-activity-form" className={classes.stepper__container}>
          <Stepper onReady={this.handleReady} activeStep={activeStep}>
            <Steps>
              <Step target="destination" step="1" active>
                Destination
              </Step>
              <Step target="product" step="2">
                Product
              </Step>
            </Steps>
            <StepContent id="destination" active>
              <DestinationForm
                user={user}
                serialize={this.serializeFormData}
                reset={reset}
                destination={this.getPostProperty('destination')}
                isNotWaste={this.getPostProperty('isDonation') || this.getPostProperty('isDiscountedSale')}
                transactionDate={this.getPostProperty('transactedAt')}
                outlet={this.outlet}
                postId={this.getPostProperty('_id')}
              />
            </StepContent>
            <StepContent id="product">
              <ProductForm
                user={user}
                serialize={this.serializeFormData}
                reset={reset}
                itemName={this.getPostProperty('itemName')}
                item={this.getPostProperty('item')}
                quality={this.getPostProperty('quality')}
                quantity={this.getPostProperty('quantity')}
                unitOfMeasure={this.getPostProperty('unitOfMeasure')}
                isVendorReturn={this.getPostProperty('isVendorReturn')}
                isDiscountedSales={isDiscountedSales}
              />
            </StepContent>
          </Stepper>
          <div className={classes.sticky__footer}>
            <Button type="submit" secondary onClick={this.logTransaction} disabled={!isReady} loading={saving} loadingText="Processing...">
              Log Activity
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

LogTransaction.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  mutate: PropTypes.func,
  post: PropTypes.object,
};

const StyledComponent = injectSheet(styles)(LogTransaction);
const GqlComponent = graphql(createOrUpdateTransaction)(StyledComponent);
export default GqlComponent;
