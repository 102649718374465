import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectOption } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import filterStyles from './filter-styles';

@injectSheet(filterStyles)
export default class LocationFilter extends React.Component {
  constructor(props) {
    super(props);
    this.sitesList = this.getSitesList();
    this.state = {
      values: props.selectedIds || this.sitesList.map((site) => ({ value: site.value, text: site.text, category: site.category })),
    };
  }

  getSitesList() {
    let sitesList = null;
    const { filterBasis, user } = this.props;

    if (!user) {
      return [];
    }
    switch (filterBasis) {
      case 'POST':
        sitesList = user.postFilterParameters.locations;
        break;
      case 'INVENTORY':
      default:
        sitesList = user.inventoryFilterParameters.sites;
        break;
    }
    return sitesList.map((l) => ({ value: l.id, text: l.name, category: l.category }));
  }

  onChange = (values) => {
    this.setState({ values });
    this.props.onChange(
      values.map((val) => val.value),
      values
    );
  };

  render() {
    const { classes, disabled } = this.props;
    const items = this.sitesList.map((itm, ix) => (
      <SelectOption
        key={`item-${ix}`}
        checkbox
        category={itm.category}
        selected={this.state.values.findIndex((item) => item.value === itm.value) > -1}
        value={itm.value}
      >
        {itm.text}
      </SelectOption>
    ));
    return (
      <div data-element="multi-site-locations-filter" className={classes.dashboard__filter}>
        <Select
          id="multi-site-locations-filter"
          label="Locations"
          selectedItems={this.state.values}
          multiple
          selectAll
          search
          categories
          autoWidth
          onChange={this.onChange}
          disabled={disabled}
        >
          {items}
        </Select>
      </div>
    );
  }
}

LocationFilter.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  onChange: PropTypes.func,
  selectedIds: PropTypes.array,
  filterBasis: PropTypes.oneOf(['POST', 'INVENTORY']),
  disabled: PropTypes.bool,
};
