import React from 'react';
import PropTypes from 'prop-types';
import { Theme, Button, CalculatorIcon, WandIcon } from '@spoiler-alert/ui-library';

import { createUseStyles } from 'react-jss';

const styles = {
  bannerContainer: {
    width: '-webkit-fill-available',
    bottom: '75px',
    display: 'flex',
    justifyContent: 'center',
  },
  banner: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    bottom: 75,
    backgroundColor: Theme.greenDark,
    borderRadius: '4000px',
    padding: '24px',
    width: '80%',
    maxWidth: '1400px',
    margin: 'auto',
    zIndex: '21',
    boxShadow: '0 7px 20px 0 rgba(0, 0, 0, 0.32)',
  },
  bannerInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  bannerIcon: {
    width: 36,
    height: 36,
    fill: Theme.white,
    marginRight: 12,
  },
  bannerText: {
    color: Theme.white,
    '& h1': {
      fontSize: 20,
      fontWeight: 'bold',
      margin: 0,
      lineHeight: '1.44',
    },
    '& h2': {
      fontSize: 18,
      fontWeight: 'normal',
      margin: 0,
      lineHeight: '1.44',
    },
  },
  button: {
    borderRadius: '4000px',
    color: Theme.textColorPrimary,
    fill: Theme.textColorPrimary,
    padding: 20,
    fontSize: 16,
    fontWeight: 600,
    '& svg': {
      height: 24,
      width: 24,
    },
  },
};
const useStyles = createUseStyles(styles);

const RecalculationBanner = ({ recalculateAction }) => {
  const classes = useStyles();

  return (
    <div className={classes.bannerContainer}>
      <div className={classes.banner}>
        <div className={classes.bannerInfo}>
          <WandIcon className={classes.bannerIcon} />
          <div className={classes.bannerText}>
            <h1>Update Smart Award Suggestions</h1>
            <h2>Recalculate to view the most up to date suggested awards</h2>
          </div>
        </div>
        <div>
          <Button secondary className={classes.button} icon={CalculatorIcon} onClick={recalculateAction}>
            Recalculate Suggested Awards
          </Button>
        </div>
      </div>
    </div>
  );
};

RecalculationBanner.propTypes = {
  recalculateAction: PropTypes.func,
};

export default RecalculationBanner;
