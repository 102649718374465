import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useMutation } from '@apollo/client';
import accounting from 'accounting';
import { Modal, ModalContent, ModalFooter, Button, Select, SelectOption, TextInput, AlertService } from '@spoiler-alert/ui-library';
import { ReservePriceStrings } from '../../string-resources';
import { SetReservePrice } from '../../graphql/mutations';
import ReservePriceStrategyAnchors, { ReservePriceStrategyAnchorsKeysObject } from './reserve-price-strategy-anchors';

const useStyles = createUseStyles({
  form__container: {
    width: '100%',
  },
  modal__field: {
    width: '48%',
  },
  modal__fields: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalButton: {
    width: '157px',
  },
  modalDescription: {
    fontSize: '16px',
    marginTop: '-16px',
    marginBottom: '24px',
  },
});

const ReservePriceModal = ({ onHide, open, refetchQueries, reserveAnchor, reserveNumber, user }) => {
  const classes = useStyles();

  const [selectedReserveAnchor, setAnchor] = useState();
  const [selectedReserveNumber, setNumber] = useState();
  const [loadingSet, setLoadingSet] = useState(false);
  const [loadingClear, setLoadingClear] = useState(false);

  const [setReservePrice, { loading }] = useMutation(SetReservePrice);

  const showSuccess = (message) => {
    AlertService.alert({
      type: 'success',
      message: <span>{message}</span>,
      autoDismiss: true,
      dismissDelay: 3000,
    });
  };

  const showError = (message) => {
    AlertService.alert({
      type: 'warning',
      message: <span>{message}</span>,
      autoDismiss: true,
      dismissDelay: 3000,
    });
  };

  const hide = (response) => {
    if (response.data?.SetReservePrice?.errors && response.data.SetReservePrice.errors.length > 0) {
      showError(response.data.SetReservePrice.errors[0].message);
    }
    if (response.data?.SetReservePrice?.modifiedCount !== null) {
      const anchor = response.data.SetReservePrice.reserveAnchor;
      const message =
        anchor === 'FlatRate'
          ? `The reserve price has been set to ${accounting.formatNumber(response.data.SetReservePrice.reserveNumber, 2)}/Case for all items.`
          : `The reserve price has been set to ${accounting.formatNumber(
              response.data.SetReservePrice.reserveNumber,
              2
            )}% of ${ReservePriceStrategyAnchors.get(anchor)} for all items.`;
      showSuccess(message);
      setAnchor(undefined);
      setNumber(undefined);
    }
    onHide();
  };

  const setReservePriceFromAnchor = (ev) => {
    setLoadingSet(true);
    ev.preventDefault();
    const reserveVariables = {
      reserveAnchor: selectedReserveAnchor ? selectedReserveAnchor[0].value : reserveAnchor,
      reserveNumber: selectedReserveNumber || reserveNumber,
    };
    setReservePrice({
      variables: reserveVariables,
      refetchQueries,
    })
      .then((res) => {
        hide(res);
        setLoadingSet(false);
      })
      .catch(() => {
        showError(ReservePriceStrings.error);
        onHide();
      });
  };

  const cancel = () => {
    setAnchor(undefined);
    setNumber(undefined);
    onHide(false);
  };

  const clearReservePrice = (ev) => {
    setLoadingClear(true);
    ev.preventDefault();
    const reserveVariables = {
      reserveAnchor: 'FlatRate',
      reserveNumber: 0,
    };
    setReservePrice({
      variables: reserveVariables,
      refetchQueries,
    })
      .then((res) => {
        hide(res);
        setLoadingClear(false);
      })
      .catch(() => {
        showError(ReservePriceStrings.errorClear);
        onHide();
      });
  };

  const generateTextInputLabelText = (currentAnchorValue, currencyType) => {
    if (currentAnchorValue === ReservePriceStrategyAnchorsKeysObject.FlatRate && currencyType) {
      return `${ReservePriceStrings.flatRate} (${currencyType})`;
    }
    if (currentAnchorValue === ReservePriceStrategyAnchorsKeysObject.FlatRate) {
      return ReservePriceStrings.flatRate;
    }
    return ReservePriceStrings.percent;
  };

  const changeReserveNumber = (value) => {
    const cleanValue = value.replace('%', '');
    setNumber(cleanValue);
  };

  const currentAnchor = selectedReserveAnchor
    ? selectedReserveAnchor[0]
    : { value: reserveAnchor, text: ReservePriceStrategyAnchors.get(reserveAnchor) };
  const canSubmit =
    (selectedReserveAnchor && selectedReserveAnchor.length > 0 && selectedReserveNumber >= 0) ||
    (selectedReserveNumber >= 0 && selectedReserveNumber !== '');
  const inputLabelText = generateTextInputLabelText(currentAnchor?.value, user.site.currencyType);

  return (
    <Modal onHide={onHide} open={open} closeOnEsc closeOnOutsideClick>
      <form className={classes.form__container}>
        <ModalContent>
          <h2>{ReservePriceStrings.title}</h2>
          <div className={classes.modalDescription}>{ReservePriceStrings.description}</div>
          <div className={classes.modal__fields}>
            <div className={classes.modal__field}>
              <Select label={ReservePriceStrings.anchor} onChange={setAnchor} selectedItem={currentAnchor}>
                {Array.from(ReservePriceStrategyAnchors.keys()).map((k) => (
                  <SelectOption key={k} value={k}>
                    {ReservePriceStrategyAnchors.get(k)}
                  </SelectOption>
                ))}
              </Select>
            </div>
            <div className={classes.modal__field}>
              <TextInput
                type="text"
                labelText={inputLabelText}
                onChange={changeReserveNumber}
                required
                value={
                  currentAnchor?.value === ReservePriceStrategyAnchorsKeysObject.FlatRate
                    ? `${accounting.formatNumber(selectedReserveNumber || reserveNumber)}`
                    : `${accounting.formatNumber(selectedReserveNumber || reserveNumber, 2)}%`
                }
              />
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button className={classes.modalButton} type="button" onClick={cancel} secondary disabled={loading}>
            Close
          </Button>
          <Button
            warning
            key="clear"
            className={classes.modalButton}
            disabled={loading}
            onClick={clearReservePrice}
            loadingText="Clearing Reserve Price"
            loading={loading && loadingClear}
          >
            Clear Reserve Price
          </Button>
          <Button
            type="submit"
            className={`${classes.modalButton} ${classes.submit}`}
            disabled={!canSubmit}
            primary
            loading={loading && loadingSet}
            loadingText="Setting Reserve Price"
            onClick={setReservePriceFromAnchor}
          >
            {ReservePriceStrings.setReservePrice}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

ReservePriceModal.propTypes = {
  onHide: PropTypes.func,
  open: PropTypes.bool,
  refetchQueries: PropTypes.array,
  reserveAnchor: PropTypes.string,
  reserveNumber: PropTypes.number,
  user: PropTypes.object,
};

export default ReservePriceModal;
