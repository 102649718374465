import { Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  action__button: {
    marginRight: 12,
    backgroundColor: '#FFFFFF',
  },
  header__block: {
    paddingBottom: 25,
    marginTop: -15,
  },
  price_field: {
    display: 'flex',
    width: 100,
    margin: [-7, 'auto', -10],
  },
  price__input_wrap: {
    width: 75,
  },
  price__input: {
    width: '73px !important',
    textAlign: 'right !important',
    paddingRight: 8,
  },
  price__dollar: {
    lineHeight: '34px',
    paddingRight: 3,
  },
  quantity: {
    textDecoration: `underline dashed ${theme.pillFontColor}`,
    cursor: 'pointer',
  },
  inline_button: {
    margin: [-7, 0, -10, 0],
  },
  select__box: {
    order: 0,
    marginRight: '12px !important',
  },
  search: {
    order: 2,
    marginLeft: 'auto',
  },
  search__row: {
    justifyContent: 'flex-start',
  },
  filter__button: {
    order: 3,
  },
  negotiations__wrap: {
    marginBottom: 20,
  },
  'custom-content__container': {
    display: 'flex',
    alignItems: 'center',
  },
  'bulk-action__buttons': {
    order: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 5,
  },
};

export default styles;
