import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useQuery } from '@apollo/client';
import { StatusBadge } from '@spoiler-alert/ui-library';
import StatusChicklet from './status-chicklet';
import OfferListingList from './offer-listing-list';
import { StatusQuery } from './inventory-status-query';

const useStyles = createUseStyles({
  statusBar: {
    display: 'flex',
  },
  emptyTooltip: {
    padding: 10,
    width: 124,
    margin: 0,
  },
  tooltipList: {
    listStyle: 'disc outside',
    textAlign: 'left',
    padding: [10, 10, 10, 20],
    '&>li::marker': {
      marginRight: 5,
    },
  },
});

const stagedListingDeDuper = (stagedListings) => {
  return [
    ...stagedListings
      .reduce((map, sl) => {
        if (map.has(sl.distributionListName)) {
          map.get(sl.distributionListName).count += 1;
        } else {
          map.set(sl.distributionListName, { name: sl.distributionListName, count: 1 });
        }
        return map;
      }, new Map())
      .values(),
  ];
};

const InventoryStatus = ({ status, inventoryId, show, showArchived }) => {
  const classes = useStyles();
  const [hasHovered, setHasHovered] = useState(false);
  const { data, loading } = useQuery(StatusQuery, {
    variables: { inventoryId },
    skip: !hasHovered || !status.staged,
  });

  const categories = useMemo(() => {
    if (!data) return false;
    const { offerListings, stagedListings, publishedStagedListings } = data.inventoryStatusByIdQuery;
    const awarded = offerListings.filter((ol) => ol.status === 'AWARDED');
    const accepted = offerListings.filter((ol) => ol.status === 'ACCEPTED');
    return {
      staged: stagedListingDeDuper([...stagedListings, ...publishedStagedListings]),
      listed: stagedListingDeDuper(publishedStagedListings),
      received: [...offerListings],
      awarded: [...awarded, ...accepted],
      accepted,
    };
  }, [data]);

  const multiStatusBar = () => {
    return (
      <div data-testid="inventory-status" className={classes.statusBar} key={inventoryId} onMouseOver={hasHovered ? null : () => setHasHovered(true)}>
        <StatusChicklet first key="ST" abbr="ST" colorFamily="grey" active={status.staged} header="Staged" loading={loading}>
          {categories?.staged?.length > 0 ? (
            <ul className={classes.tooltipList}>
              {categories.staged.map((st) => (
                <li data-testid="tooltip-ST-content" key={st.name}>
                  {st.name} {st.count > 1 ? <>({st.count})</> : ''}
                </li>
              ))}
            </ul>
          ) : (
            <p className={classes.emptyTooltip}>This item has not been staged.</p>
          )}
        </StatusChicklet>
        <StatusChicklet key="LI" abbr="LI" colorFamily="orange" active={status.listed} header="Listed" loading={loading}>
          {categories?.listed?.length > 0 ? (
            <ul className={classes.tooltipList}>
              {categories.listed.map((st) => (
                <li data-testid="tooltip-LI-content" key={st.name}>
                  {st.name} {st.count > 1 ? <>({st.count})</> : ''}
                </li>
              ))}
            </ul>
          ) : (
            <p className={classes.emptyTooltip}>This item has not been listed.</p>
          )}
        </StatusChicklet>
        <StatusChicklet key="RE" abbr="RE" colorFamily="purple" active={status.received} header="Received Offers" loading={loading}>
          {categories?.received?.length > 0 ? (
            <OfferListingList offerListings={categories.received} />
          ) : (
            <p className={classes.emptyTooltip}>No offers have been received for this item.</p>
          )}
        </StatusChicklet>
        <StatusChicklet key="AW" abbr="AW" colorFamily="teal" active={status.awarded} header="Awarded Offers" loading={loading}>
          {categories?.awarded?.length > 0 ? (
            <OfferListingList offerListings={categories.awarded} />
          ) : (
            <p className={classes.emptyTooltip}>No offers have been awarded for this item.</p>
          )}
        </StatusChicklet>
        <StatusChicklet last key="AC" abbr="AC" colorFamily="green" active={status.accepted} header="Accepted Offers" loading={loading}>
          {categories?.accepted?.length > 0 ? (
            <OfferListingList offerListings={categories.accepted} />
          ) : (
            <p className={classes.emptyTooltip}>No offers have been accepted for this item.</p>
          )}
        </StatusChicklet>
      </div>
    );
  };

  if (showArchived) {
    return (
      <div data-testid="status-badge">
        <StatusBadge status="archived" />
      </div>
    );
  }
  return show ? multiStatusBar() : <div></div>;
};

InventoryStatus.propTypes = {
  inventoryId: PropTypes.string,
  status: PropTypes.shape({
    staged: PropTypes.bool,
    listed: PropTypes.bool,
    received: PropTypes.bool,
    awarded: PropTypes.bool,
    accepted: PropTypes.bool,
  }),
  show: PropTypes.bool,
  showArchived: PropTypes.bool,
};

export default InventoryStatus;
