import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Modal, ModalContent, ModalFooter, Button, DatePicker, TimePicker, SimpleTextArea, Theme, CalendarIcon } from '@spoiler-alert/ui-library';
import moment from 'moment';
import checkFeature from '../../helpers/check-feature-flag';
import featureFlags from '../../enums/feature-flags';

const useStyles = createUseStyles({
  form__container: {
    width: '100%',
  },
  modalInfo: {
    marginBottom: 24,
    fontSize: 16,
  },
  dateContainer: {
    display: 'flex',
    marginBottom: 24,
    alignItems: 'end',
    height: 50,
  },
  datePicker: {
    width: '30%',
    marginRight: 24,
  },
  label: {
    display: 'flex',
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 500,
    marginRight: 12,
  },
  sectionAction: {
    fontSize: 14,
    color: Theme.teal,
    cursor: 'pointer',
  },
  sectionActionDisabled: {
    fontSize: 14,
    color: Theme.teal,
    marginLeft: 2,
    opacity: '0.3',
  },
  reminderNudge: {
    padding: 12,
    display: 'flex',
    marginBottom: 24,
  },
  reminderHeader: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
  },
  reminderText: {
    fontSize: 14,
  },
  reminderIcon: {
    marginRight: 8,
    height: 24,
    width: 44,
  },
});

const PublishListModal = ({ onHide, open, onSubmit, donation }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState();
  const [day, setDay] = useState();
  const [time, setTime] = useState();
  const [defaultHour, setDefaultHour] = useState();
  const [defaultMinutes, setDefaultMinutes] = useState();
  const [defaultMeridiem, setDefaultMeridiem] = useState();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [customMessage, setCustomMessage] = useState();
  const [timePickerDisabled, setTimePickerDisabled] = useState(true);

  const clearDueDate = () => {
    setDay();
    setTime();
    setDate();
    setDefaultHour('');
    setDefaultMinutes('');
    setDefaultMeridiem('');
    setTimePickerDisabled(true);
    setError(false);
  };

  const hide = () => {
    setCustomMessage('');
    clearDueDate();
    onHide();
  };

  const handleDateSelection = (value) => {
    if (value) setDisabled(false);
    const newDate = value.format('LL');
    setDay(value);
    if (!time) {
      setTime('1:00 PM');
      setDefaultHour('1');
      setDefaultMinutes('00');
      setDefaultMeridiem('PM');
    }
    setDate(moment(`${newDate} ${!time ? '1:00 PM' : time}`));
    setTimePickerDisabled(false);
  };

  const handleTimeSelection = (value, errorMessage) => {
    if (errorMessage) {
      setError(true);
      return;
    }
    const newDateTime = day ? moment(`${day.format('LL')} ${value}`) : null;
    setDate(newDateTime);
    setTime(value);
    if (newDateTime.isValid()) setError(false);
  };

  const submitModal = async (event) => {
    setLoading(true);
    setDisabled(true);
    setTimePickerDisabled(true);
    await onSubmit(event, customMessage, date);
    setCustomMessage('');
    clearDueDate();
    setDisabled(false);
    setLoading(false);
  };

  const reminderEmail = () => {
    if (checkFeature(featureFlags.buyerEmailReminder) && !donation) {
      let color = Theme.teal5;
      let iconColor = Theme.teal;
      let header = 'Enable reminder emails!';
      let body =
        'Setting a due date will enable reminder emails to customers who haven’t submitted a bid 24 hours before the due date. Customers that receive a reminder have a higher chance of responding.';

      if (date) {
        color = Theme.orange5;
        iconColor = Theme.orange;
        header = 'Reminders emails not set';
        body = 'Your due date must be set to a later date in order for reminder emails to be scheduled.';
      }

      if (date && moment(date).subtract(24, 'hours').isAfter(moment().endOf('day'))) {
        color = Theme.green5;
        iconColor = Theme.green;
        header = 'Reminder emails enabled';
        body = `Customers who have not submitted bids by ${moment(date).subtract(24, 'hours').format('MMMM DD')} at ${time} ${
          new Date().toLocaleString('en-US', { timeZoneName: 'short' }).split(' ')[3]
        } will be sent a reminder email.`;
      }

      return (
        <div className={classes.reminderNudge} style={{ backgroundColor: color }}>
          <CalendarIcon style={{ color: iconColor }} className={classes.reminderIcon} />
          <div>
            <div className={classes.reminderHeader}>{header}</div>
            <div className={classes.reminderText}>{body}</div>
          </div>
        </div>
      );
    }

    return <></>;
  };

  return (
    <Modal onHide={hide} open={open} closeOnEsc closeOnOutsideClick>
      <form onSubmit={!disabled && !error ? submitModal : null} className={classes.form__container}>
        <ModalContent>
          <h2>Confirm publish to customers</h2>
          <div>
            <div className={classes.modalInfo}>
              <span>
                Let your customers know a preferred date and time to have offers submitted, as well as any additional information they might need
                while bidding.
              </span>
            </div>
            <div className={classes.label}>
              <span className={classes.sectionTitle}>
                Due date & time ({new Date().toLocaleString('en-US', { timeZoneName: 'short' }).split(' ').pop()})
              </span>
              <span className={disabled ? classes.sectionActionDisabled : classes.sectionAction} onClick={clearDueDate}>
                Clear due date
              </span>
            </div>
            <div className={classes.dateContainer}>
              <div className={classes.datePicker}>
                <DatePicker
                  label="Date"
                  validRange={{ start: moment.utc().subtract(1, 'day'), end: moment.utc().add(1, 'year') }}
                  onChange={handleDateSelection}
                  day={day}
                  id={'date-picker'}
                />
              </div>
              <TimePicker
                onChange={handleTimeSelection}
                onError={() => setError(true)}
                disabled={timePickerDisabled}
                defaultHour={defaultHour}
                defaultMinutes={defaultMinutes}
                defaultMeridiem={defaultMeridiem}
              />
            </div>
            <div>{reminderEmail()}</div>
            <div>
              <span className={classes.sectionTitle}>Custom message</span>
              <SimpleTextArea
                placeholder="Use this space if you’d like to communicate any additional information to your customers"
                autoFocus={false}
                value={customMessage}
                onChange={(value) => setCustomMessage(value)}
              />
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button type="button" onClick={hide} secondary disabled={loading}>
            Close
          </Button>
          <Button type="submit" className={classes.submit} disabled={disabled || error} primary loading={loading} loadingText="Publishing...">
            Publish List
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

PublishListModal.propTypes = {
  onHide: PropTypes.func,
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  donation: PropTypes.bool,
};

export default PublishListModal;
