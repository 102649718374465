import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { Card, Button, Select, SelectOption } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { BulkChangeOrganization } from '../../graphql/mutations';

const styles = {
  button__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  input__row: {
    padding: '5px 0',
  },
};

@injectSheet(styles)
@graphql(BulkChangeOrganization)
export default class ChangeOrganizationAction extends Component {
  state = {
    user: this.props.user,
    flyoutUser: this.props.flyoutUser,
    saving: false,
    siteId: this.props.flyoutUser.siteId,
    organizationOptions: this.props.user.siteHierarchy,
  };

  static getDerivedStateFromProps(nextProps) {
    return { user: nextProps.user };
  }

  error = (response) => {
    this.setState({ saving: false });
    const message = 'Sorry, changing organizations failed. If this problem persists, please contact a Spoiler Alert Administrator to help you.';
    this.props.onHide(response, message);
  };

  hide = (response) => {
    this.setState({ saving: false });
    this.props.onHide(response);
  };

  save = (ev) => {
    ev.preventDefault();
    const { refetchQueries, mutate } = this.props;
    this.setState({ saving: true });
    mutate({
      variables: { userIds: [this.state.flyoutUser._id], siteId: this.state.siteId },
      refetchQueries,
    })
      .then(this.hide)
      .catch(this.error);
  };

  setOrganization = (org) => {
    this.setState({ siteId: org[0].value });
  };

  generateOrganizationOptions() {
    return this.state.organizationOptions.map((org) => (
      <SelectOption value={org._id} key={org.siteName} selected={this.state.siteId === org._id}>
        {org.siteName}
      </SelectOption>
    ));
  }

  render() {
    return (
      <Card>
        <form onSubmit={this.save}>
          <h3>Log Transaction</h3>
          <div className={this.props.classes.input__row}>
            <Select label="Organization" onChange={this.setOrganization} required>
              {this.generateOrganizationOptions()}
            </Select>
          </div>
          <div className={this.props.classes.button__container}>
            <Button type="button" link onClick={this.props.onHide} disabled={this.state.deleting}>
              Cancel
            </Button>
            <Button type="submit" primary loading={this.state.logging} disabled={this.state.organization} loadingText="Wait...">
              Change Organization
            </Button>
          </div>
        </form>
      </Card>
    );
  }
}

ChangeOrganizationAction.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  flyoutUser: PropTypes.object,
  onHide: PropTypes.func,
  mutate: PropTypes.func,
  refetchQueries: PropTypes.array,
};

ChangeOrganizationAction.defaultProps = {
  onHide: () => {},
};
