import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Card, Theme as theme, Select, SelectOption, DatePicker, Button, TextInput } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import formStyles from './form-styles';
import { destinationTypes } from '../../../enums';

const styles = {
  ...formStyles,
  vendor__span: {
    marginLeft: 5,
  },
  item__information: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  transaction__price: {
    marginTop: '29px',
  },
  'item__detail--header': {
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontWeight: 'normal',
    margin: 0,
    color: theme.primaryColor,
    paddingTop: 12,
  },
  'item__detail--text': {
    textTransform: 'capitalize',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    margin: 0,
    paddingBottom: 7,
  },
};

@injectSheet(styles)
export default class DestinationForm extends Component {
  constructor(props) {
    super(props);
    const { user, transactionDate, isWaste, outlet } = props;
    this.state = {
      isWaste,
      destination: this.initialDestination,
      outletOptions: this.initialOutletOptions,
      transactionDate: transactionDate ? moment.utc(transactionDate) : undefined,
      destinationOptions: user.site.destinations,
      outlet,
      loading: true,
      transactionPriceErrored: false,
    };

    this.setReady();
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.reset) {
      return {
        destination: undefined,
        outlet: undefined,
        transactionDate: undefined,
        transactionPrice: undefined,
        transactionPriceErrored: false,
        postId: undefined,
      };
    }
    return { postId: nextProps.postId };
  }

  get ready() {
    const { transactionDate, destination, outlet, isDiscountedSales } = this.state;
    const outletValid = this.outletRequired ? outlet && outlet.text !== undefined : true;
    return !!(
      (!isDiscountedSales && transactionDate && destination && outletValid) ||
      (isDiscountedSales && transactionDate && destination && outletValid && this.validTransactionPrice(this.state.transactionPrice))
    );
  }

  get outletRequired() {
    return !this.state.isWaste;
  }

  setOutlet = (_, outlet) => {
    this.setState({ outlet }, this.setReady);
  };

  setReady = () => {
    this.props.setReadyStep(this.ready);
    this.serialize();
  };

  get disabled() {
    return !this.ready;
  }

  serialize() {
    const { destination, outlet, transactionDate, isDiscountedSales, transactionPrice } = this.state;
    this.props.serialize({
      destination,
      outlet,
      transactionDate,
      isDiscountedSales,
      transactionPrice,
    });
  }

  setDestination = (_, destination) => {
    const isDiscountedSales = destination.value === destinationTypes.discountedSales;
    const isWaste = !(destination.value === destinationTypes.donation || isDiscountedSales);
    const selectedDestination = this.state.destinationOptions.find((d) => d.enumValue === destination.value);
    this.setState(
      {
        destination,
        outletOptions: selectedDestination.outlets || [],
        outlet: undefined,
        isWaste,
        isDiscountedSales,
      },
      this.setReady
    );
  };

  componentDidUpdate(nextProps) {
    if (nextProps.reset) this.setReady();
  }

  setDate = (transactionDate = moment.utc(this.state.transactionDate)) => {
    this.setState({ transactionDate }, this.setReady);
  };

  setTransactionPrice = (transactionPrice) => {
    this.setState(
      {
        transactionPrice,
        transactionPriceErrored: !this.validTransactionPrice(transactionPrice),
      },
      this.setReady
    );
  };

  validTransactionPrice(transactionPrice) {
    return transactionPrice && transactionPrice > 0;
  }

  get initialOutletOptions() {
    const initialDestinationDetails = this.initialDestinationDetails ? this.initialDestinationDetails.outlets : [];
    const outletOptions = this.props.destination ? initialDestinationDetails : undefined;
    return outletOptions || [];
  }

  get initialDestinationDetails() {
    const { user, destination } = this.props;
    return destination ? user.site.destinations.find((d) => d.enumValue === destination) : undefined;
  }

  get initialDestination() {
    const { destination } = this.props;
    return destination ? { value: destination, text: this.initialDestinationDetails?.name } : undefined;
  }

  get outlets() {
    const { outlet, outletOptions } = this.state;
    const selected = outlet ? outlet.value : undefined;
    return outletOptions.map((o) => (
      <SelectOption value={o.siteId} key={o.siteName} selected={selected === o.siteId}>
        {o.siteName}
      </SelectOption>
    ));
  }

  get destinations() {
    const { destination, destinationOptions } = this.state;
    const selected = destination ? destination.value : undefined;
    return destinationOptions.map((d) => (
      <SelectOption value={d.enumValue} key={d.name} selected={selected === d.enumValue}>
        {d.name}
      </SelectOption>
    ));
  }

  render() {
    const { classes, goToNextStep, postId } = this.props;
    const { isDiscountedSales, transactionPrice, transactionPriceErrored, transactionDate } = this.state;
    const outletLabel = this.outletRequired ? 'Outlet *' : 'Outlet';
    return (
      <Card className={classes.form__container}>
        <h2 className={classes.form__header}>Destination</h2>
        <div className={classes.form__row}>
          <div className={classes.form__item}>
            <Select label="Destination *" onChange={this.setDestination} required selectedItem={this.state.destination} disabled={!!postId}>
              {this.destinations}
            </Select>
          </div>
          <div className={classes.form__item}>
            <Select label={outletLabel} onChange={this.setOutlet} required={this.outletRequired} selectedItem={this.state.outlet}>
              {this.outlets}
            </Select>
          </div>
        </div>
        <div className={classes.form__row}>
          <div className={classes.form__item}>
            <DatePicker onChange={this.setDate} label="Transaction Date *" day={transactionDate} />
          </div>
          {isDiscountedSales && (
            <div className={`${classes.form__item} ${classes.transaction__price}`}>
              <TextInput
                type="number"
                value={transactionPrice}
                error={transactionPriceErrored}
                labelText="Transaction Price *"
                onChange={this.setTransactionPrice}
                required
                min={0}
              />
            </div>
          )}
        </div>
        <div className={this.props.classes.button__container}>
          <Button type="button" link onClick={goToNextStep} disabled={this.disabled}>
            Next
          </Button>
        </div>
      </Card>
    );
  }
}

DestinationForm.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  data: PropTypes.object,
  goToNextStep: PropTypes.func,
  setReadyStep: PropTypes.func,
  serialize: PropTypes.func,
  reset: PropTypes.bool,
  destination: PropTypes.string,
  isWaste: PropTypes.bool,
  transactionDate: PropTypes.string,
  outlet: PropTypes.object,
  postId: PropTypes.string,
};
