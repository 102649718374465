import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { AlertService, ModalContent, ModalFooter, Button, MinimalRadioTable, AlertWarningIcon, Theme as theme } from '@spoiler-alert/ui-library';
import { getHandlingMappingsBySiteIdQuery } from '../../graphql/queries';
import { UpdateHandlingMappings } from '../../graphql/mutations';
import truckType from '../../enums/truck-type';

const styles = {
  title: {
    width: 652,
    height: 25,
    fontSize: 24,
    lineHeight: 1.17,
    textAlign: 'left',
    marginBottom: 8,
  },
  subtitle: {
    height: 40,
    flexGrow: 0,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    textAlign: 'left',
  },
  warningDiv: {
    backgroundColor: theme.red5,
    borderRadius: 4,
    margin: '24px 0px',
    padding: 12,
    display: 'flex',
  },
  warningIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
    fill: theme.red,
  },
};
const useStyles = createUseStyles(styles, { name: 'ListInventoryModal' });

const HandlingMappingModalContent = ({ onHide, user }) => {
  const classes = useStyles();
  const [handlingMappingsCurrent, setHandlingMappingsCurrent] = useState([]);
  const [handlingMappingIdsToUpdate, setHandlingMappingIdsToUpdate] = useState([]);
  const { data: handlingMappingQueryData } = useQuery(getHandlingMappingsBySiteIdQuery, {
    variables: { siteId: user.site._id },
  });
  const [updateHandlingMappings, { loading: updateHandlingMappingsLoading }] = useMutation(UpdateHandlingMappings);

  const handlingMappingsInDB = useMemo(() => {
    if (!handlingMappingQueryData) return [];
    return handlingMappingQueryData?.getHandlingMappingsBySiteId.map((obj) => ({ _id: obj._id, key: obj.name, value: obj.truckType }));
  }, [handlingMappingQueryData?.getHandlingMappingsBySiteId]);
  useEffect(() => {
    if (handlingMappingQueryData) {
      setHandlingMappingsCurrent(
        handlingMappingQueryData?.getHandlingMappingsBySiteId.map((obj) => ({ _id: obj._id, key: obj.name, value: obj.truckType }))
      );
    }
  }, [handlingMappingQueryData?.getHandlingMappingsBySiteId]);

  const changeTruckType = (value, id) => {
    const updatedMappings = handlingMappingsCurrent.map((mapping) => {
      if (mapping._id === id) {
        mapping.value = value;
      }
      return mapping;
    });
    const idsToUpdate = [];
    const handlingMappingsInDBMap = handlingMappingsInDB.reduce((acc, handling) => acc.set(handling._id, handling), new Map());
    updatedMappings.forEach((updatedMapping) => {
      const dbMapping = handlingMappingsInDBMap.get(updatedMapping._id);
      if (dbMapping.value !== updatedMapping.value) {
        idsToUpdate.push(updatedMapping._id);
      }
    });
    setHandlingMappingIdsToUpdate(idsToUpdate);
    setHandlingMappingsCurrent(updatedMappings);
  };

  const handlingMappingsSaveDisabled = () => {
    return (
      handlingMappingsCurrent.filter((handling) => {
        return !handling.value;
      }).length > 0
    );
  };

  const saveHandlingMappings = () => {
    const handlingsToUpdate = handlingMappingsCurrent
      .filter((handling) => {
        return handlingMappingIdsToUpdate.includes(handling._id);
      })
      .map((handling) => {
        return { handlingId: handling._id, truckType: handling.value };
      });

    if (handlingsToUpdate.length === 0) return null;
    return updateHandlingMappings({
      variables: { handlings: handlingsToUpdate },
    })
      .then((res) => {
        if (res.data.updateHandlingMapping.errors && res.data.updateHandlingMapping.errors.length > 0) {
          throw Error;
        } else {
          setHandlingMappingIdsToUpdate([]);
        }
      })
      .catch(() => {
        AlertService.alert({
          type: 'warning',
          message: 'There was an unexpected error saving your changes, please try again. Contact support if this problem persists.',
        });
      });
  };

  const cancel = () => {
    onHide(false);
  };

  const modalTitle = 'New handling value detected';
  const modalSubtitle =
    'One or more of your selected inventory items has an unmapped handling value. Handling mapping can be managed on your organization settings page at any time.';
  const modalWarning =
    'We require uploaded handling to be mapped to truck type prior to listing inventory. Please map your uploaded handling values to a corresponding truck type.';
  return (
    <div>
      <ModalContent>
        <div className={classes.title}>{modalTitle}</div>
        <div className={classes.subtitle}>{modalSubtitle}</div>
        <div className={classes.warningDiv}>
          <div>
            <AlertWarningIcon className={classes.warningIcon} />
          </div>
          {modalWarning}
        </div>
        <MinimalRadioTable
          keyHeader="Uploaded Handling Value"
          valueHeader="Truck Type"
          possibleValues={Object.values(truckType)}
          data={handlingMappingsCurrent}
          onChange={changeTruckType}
        />
      </ModalContent>
      <ModalFooter>
        <Button type="button" onClick={cancel} secondary disabled={updateHandlingMappingsLoading}>
          Cancel
        </Button>
        <Button
          onClick={saveHandlingMappings}
          primary
          disabled={handlingMappingsSaveDisabled()}
          loading={updateHandlingMappingsLoading}
          loadingText="Saving"
          style={{ marginLeft: 8 }}
        >
          Save and Continue
        </Button>
      </ModalFooter>
    </div>
  );
};

HandlingMappingModalContent.propTypes = {
  classes: PropTypes.object,
  onHide: PropTypes.func,
  user: PropTypes.object,
};

export default HandlingMappingModalContent;
