import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Tooltip, InformationIcon, Theme } from '@spoiler-alert/ui-library';

const styles = {
  info__icon: {
    height: '1rem',
    width: '1rem',
    marginLeft: '4px',
    display: 'inline-block',
    cursor: 'pointer',
    fill: Theme.tooltipColor,
    paddingBottom: '2px',
  },
  'diversion-summary__h3': {
    display: 'inline-block',
    fontSize: '1rem',
    margin: '0',
    fontWeight: '500,',
  },
  'diversion-summary-tooltip__text': {
    display: 'inline-block',
    width: 312,
  },
};

@injectSheet(styles)
export default class DiversionSummaryHeader extends React.Component {
  render() {
    const { widgetName, classes } = this.props;
    return (
      <span>
        <h3 className={classes['diversion-summary__h3']}>{widgetName}</h3>
        <Tooltip
          text={
            <span className={classes['diversion-summary-tooltip__text']}>
              The Recovery Rate is the percentage of all‌ ‌unsold inventory that was recovered for human consumption, including Nonprofit Donations
              and Sales
            </span>
          }
          small
        >
          <InformationIcon className={classes.info__icon} />
        </Tooltip>
      </span>
    );
  }
}

DiversionSummaryHeader.propTypes = {
  classes: PropTypes.object,
  widgetName: PropTypes.string,
};
