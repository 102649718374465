import SettingsStrings from './settings-strings';
import ImportStrings from './import-strings';
import GeneralStrings from './general-strings';
import SiteInfoStrings from './site-info-strings';
import TaxInfoStrings from './tax-info-strings';
import OfferStrings from './offer-strings';
import RefreshStrings from './refresh-strings';
import OfferComparisonStrings from './offer-comparison-strings';
import FilterStrings from './filter-strings';
import DashboardStrings from './dashboard-strings';
import SplitInventoryStrings from './split-inventory-strings';
import SuggestedPriceStrings from './suggested-price-strings';
import ReservePriceStrings from './reserve-price-strings';
import NegotiationStrings from './negotiation-strings';

export {
  SettingsStrings,
  ImportStrings,
  GeneralStrings,
  OfferStrings,
  TaxInfoStrings,
  RefreshStrings,
  SiteInfoStrings,
  OfferComparisonStrings,
  FilterStrings,
  DashboardStrings,
  SplitInventoryStrings,
  SuggestedPriceStrings,
  ReservePriceStrings,
  NegotiationStrings,
};
