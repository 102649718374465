import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Modal, ModalContent, Button, MinimalTextTable, AlertWarningIcon } from '@spoiler-alert/ui-library';
import { event } from 'react-fullstory';
import contactModalStyles from './contact-modal-styles';

const useStyles = createUseStyles(contactModalStyles);

const DeleteContactModal = ({
  onHide,
  open,
  handleSubmit,
  loading,
  distributionLists,
  email,
  selectedContactType,
  match,
  setEditContactModalOpen,
}) => {
  const classes = useStyles();
  const [emailAddress, setEmailAddress] = useState('');

  useEffect(() => {
    if (open) setEditContactModalOpen(false);
    setEmailAddress(email);
  }, [email, open]);

  const cancel = () => {
    onHide(false);
  };

  const submit = async (ev) => {
    ev.preventDefault();
    if (selectedContactType.toLowerCase() === 'customer') event(`Deleted Customer Contact`);
    if (selectedContactType.toLowerCase() === 'internal') event(`Deleted Internal Contact`);
    await handleSubmit(email, selectedContactType);
  };

  const modalInfo =
    selectedContactType?.toLowerCase() === 'customer'
      ? `Deleting ${emailAddress} will remove them from the following distribution lists. If inventory has been listed to this contact they will no
              longer be able to submit bids and they will no longer receive correspondence regarding ${match.params.buyerSiteName}.`
      : `Deleting ${emailAddress} will remove them from the following distribution lists. This contact will no longer receive correspondence regarding ${match.params.buyerSiteName}.`;

  return (
    <Modal onHide={onHide} open={open} closeOnEsc closeOnOutsideClick>
      <form onSubmit={submit} className={classes.form__container}>
        <ModalContent>
          <div data-testid="modal-title" className={classes.modal__title}>
            Delete contact
          </div>
          <div data-testid="modal-body" className={classes.modal__body}>
            <div className={classes.modalInfo}>
              <div className={classes.infoIconContainer}>
                <AlertWarningIcon className={classes.infoIcon} />
              </div>
              {modalInfo}
            </div>
            <div>
              <div className={classes.tableContainer}>
                <MinimalTextTable data={distributionLists} keyHeader={'Distribution List'} />
              </div>
              {distributionLists.length === 0 && (
                <span className={classes.deleteZeroStateText}>{emailAddress} is not currently part of any distribution lists.</span>
              )}
            </div>
          </div>
        </ModalContent>
        <div className={classes.footer__container}>
          <div className={classes.modal__submit}>
            <Button className={classes.deleteButton} testId="submit" type="submit" loadingText={`Deleting Contact`} loading={loading} secondary>
              Delete Contact
            </Button>
            <Button testId="cancel" className={classes.modal__cancel} type="button" onClick={cancel} secondary disabled={loading}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

DeleteContactModal.propTypes = {
  onHide: PropTypes.func,
  open: PropTypes.bool,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  distributionLists: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      key: PropTypes.string,
      value: PropTypes.array,
    })
  ),
  onChange: PropTypes.func,
  email: PropTypes.string,
  selectedContactType: PropTypes.string,
  match: PropTypes.object,
  setEditContactModalOpen: PropTypes.func,
};

export default DeleteContactModal;
