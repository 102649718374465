import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Theme as theme, Button, EmptyStateIcon, Search } from '@spoiler-alert/ui-library';
import CustomerParticipationSummaryTable from './customer-participation-summary-table';
import CustomerParticipationRateChart from '../charts/customer-participation-rate-chart';
import { customerParticipationStatus } from '../../enums';

const useStyles = createUseStyles({
  wrapper: {
    overflowY: 'auto',
    padding: 24,
    backgroundColor: theme.white,
    borderRadius: 4,
    boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.2)',
    border: 'solid 1px',
    borderColor: theme.grey30,
    position: 'absolute',
    right: 24,
    top: 40,
    zIndex: 100,
    width: 485,
  },
  text: {
    fontFamily: 'Roboto',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    color: theme.greyDark,
  },
  title: {
    extend: 'text',
    height: 24,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    textAlign: 'left',
    marginBottom: 24,
  },
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  summaryText: {
    width: '264px',
    margin: 'auto 24px',
  },
  summaryTextLine: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0px',
  },
  key: {
    extend: 'text',
    height: 16,
    fontSize: 12,
    lineHeight: 1.33,
  },
  value: {
    extend: 'text',
    height: 16,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.33,
  },
  line: {
    height: 1,
    alignSelf: 'stretch',
    flexGrow: 0,
    backgroundColor: theme.grey10,
  },
  summaryChart: {
    width: '125px',
    height: '125px',
    position: 'relative',
    margin: '0px 24px',
  },
  summaryChartOverlay: {
    width: '57px',
    height: '100%',
    position: 'absolute',
    top: 38,
    left: 34,
    zIndex: 20,
    textAlign: 'center',
  },
  percentValue: {
    extend: 'text',
    height: 24,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.5,
    color: theme.green,
  },
  percentText: {
    extend: 'text',
    height: 12,
    fontSize: 10,
    color: theme.grey80,
    textAlign: 'center',
  },
  search: {
    margin: '24px 0px',
  },
  table: {
    width: '100%',
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emptyStateTitle: {
    extend: 'text',
    height: 24,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    marginTop: 10,
  },
  emptyStateSubtitle: {
    extend: 'text',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    marginBottom: 10,
  },
});

const CustomerParticipationSummaryTooltip = ({ customerInfo, createListingsHandler }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');

  const offersReceived = customerInfo?.filter((ci) => ci.status === customerParticipationStatus.OFFERS_RECEIVED).length || 0;
  const customerParticipationRate = customerInfo?.length ? Math.round((offersReceived / customerInfo.length) * 100) : 0;
  const filteredCustomerInfo = customerInfo?.filter((ci) => ci?.buyerSiteName?.toLowerCase().includes(searchTerm?.toLowerCase()));

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>Customer Participation Summary</div>
      {customerInfo?.length ? (
        <div>
          <div className={classes.summary}>
            <div className={classes.summaryText}>
              <div className={classes.summaryTextLine}>
                <div className={classes.key}>OFFERS RECEIVED</div>
                <div className={classes.value}>{offersReceived}</div>
              </div>
              <div className={classes.line} />
              <div className={classes.summaryTextLine}>
                <div className={classes.key}>NO OFFERS</div>
                <div className={classes.value}>{customerInfo.filter((ci) => ci.status === customerParticipationStatus.NO_OFFERS).length}</div>
              </div>
              <div className={classes.line} />
              <div className={classes.summaryTextLine}>
                <div className={classes.key}>TOTAL CUSTOMERS</div>
                <div className={classes.value}>{customerInfo.length}</div>
              </div>
            </div>
            <div className={classes.summaryChart}>
              <div className={classes.summaryChartOverlay}>
                <div className={classes.percentValue}>{customerParticipationRate}%</div>
                <div className={classes.percentText}>Participation</div>
                <div className={classes.percentText}>Rate</div>
              </div>
              <CustomerParticipationRateChart participationRate={customerParticipationRate} cutout="70%" />
            </div>
          </div>
          <Search className={classes.search} onChange={setSearchTerm} value={searchTerm} placeholder="Search by customer name..." />
          <div className={classes.table}>
            <CustomerParticipationSummaryTable customerInfo={filteredCustomerInfo} />
          </div>
        </div>
      ) : (
        <div className={classes.emptyState}>
          <EmptyStateIcon />
          <div className={classes.emptyStateTitle}>You haven’t listed any inventory yet</div>
          <div className={classes.emptyStateSubtitle}>
            After listing inventory, check back here to track which customers have submitted their offers.
          </div>
          <Button onClick={createListingsHandler}>Create Listings</Button>
        </div>
      )}
    </div>
  );
};

CustomerParticipationSummaryTooltip.propTypes = {
  customerInfo: PropTypes.arrayOf(PropTypes.object),
  createListingsHandler: PropTypes.func,
};

export default CustomerParticipationSummaryTooltip;
