import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
  ContainerTruckIcon,
  SuggestionsAwardedIcon,
  Theme,
  EmptyStateIcon,
  MarketingViewFavoriteIcon,
  MarketingCalculatorIcon,
  OverlayService,
} from '@spoiler-alert/ui-library';

const styles = {
  title: {
    fontSize: 16,
    fontWeight: '500',
    marginTop: '10px',
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '24px 0px',
    '& svg': {
      marginTop: '48px',
    },
    '& h1': {
      extend: 'title',
      marginTop: '24px',
      marginBottom: '48px',
    },
    '& h2': {
      extend: 'title',
      marginBottom: '0px',
    },
    '& p': {
      fontSize: 14,
      marginBottom: '48px',
    },
    '& a': {
      color: Theme.teal,
      '&:hover': {
        color: Theme.tealDark,
        cursor: 'pointer',
      },
    },
  },
  drilldownEmptyState: {
    extend: 'emptyState',
    margin: '24px',
    marginBottom: 7,
    '& svg': {
      marginTop: '0px',
      maxHeight: 115,
      maxWidth: 230,
    },
    '& h1': {
      marginTop: '10px',
      marginBottom: '0px',
    },
    '& p': {
      marginBottom: '0px',
    },
  },
};
const useStyles = createUseStyles(styles);
const propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  loading: PropTypes.bool,
  recalculation: PropTypes.bool,
  hasData: PropTypes.bool,
};

const TrucklaneFilterZeroState = () => {
  const classes = useStyles();

  return (
    <div className={classes.emptyState}>
      <ContainerTruckIcon />
      <h1>No truck lanes match your search</h1>
    </div>
  );
};

const TrucklaneSuggestionZeroState = ({ history }) => {
  const classes = useStyles();

  const navigateToReview = () => {
    history.push('/review');
  };

  return (
    <div className={classes.emptyState}>
      <SuggestionsAwardedIcon />
      <h2>All Suggestions have been awarded</h2>
      <p>
        Next, you can publish and accept your awards in <a onClick={navigateToReview}>Offer Review</a>
      </p>
    </div>
  );
};

const TrucklaneNonSuggestionZeroState = () => {
  const classes = useStyles();

  return (
    <div className={classes.emptyState}>
      <h1>All Truck lanes have suggested awards</h1>
    </div>
  );
};

const TrucklaneDrilldownOtherOffersZeroState = (loading) => {
  const classes = useStyles();

  return loading ? (
    <div>Retrieving data...</div>
  ) : (
    <div className={classes.drilldownEmptyState} data-testid={'drilldown-no-offers-empty-state'}>
      <EmptyStateIcon />
      <h1>There are no additional offers to add to this truck lane</h1>
    </div>
  );
};

const TrucklaneDrilldownAwardedOffersZeroState = (loading, hasData, history) => {
  const classes = useStyles();

  const navigateToHistory = () => {
    OverlayService.hide();
    history.push('/history');
  };

  if (loading) {
    return <div>Retrieving data...</div>;
  }
  if (hasData) {
    return (
      <div className={classes.drilldownEmptyState} data-testid={'drilldown-all-accepted-empty-state'}>
        <ContainerTruckIcon />
        <h1>All awards have been accepted</h1>
        <p>
          {'You can view previous transactions within '}
          <a data-testid="drilldown-all-accepted-hyperlink" onClick={navigateToHistory}>
            History
          </a>
        </p>
      </div>
    );
  }
  return (
    <div className={classes.drilldownEmptyState} data-testid={'drilldown-no-awards-empty-state'}>
      <EmptyStateIcon />
      <h1>No offers are awarded to this truck lane yet</h1>
    </div>
  );
};

const TrucklaneDrilldownSuggestedOffersZeroState = (loading, recalculation) => {
  const classes = useStyles();

  if (loading) {
    return <div>Retrieving data...</div>;
  }
  if (recalculation) {
    return (
      <div className={classes.drilldownEmptyState} data-testid={'drilldown-recalculation-empty-state'}>
        <MarketingCalculatorIcon />
        <h1>Recalculate to update suggested awards</h1>
      </div>
    );
  }
  return (
    <div className={classes.drilldownEmptyState} data-testid={'drilldown-no-suggestions-empty-state'}>
      <MarketingViewFavoriteIcon />
      <h1>There are no suggested awards for this truck lane</h1>
    </div>
  );
};

TrucklaneFilterZeroState.propTypes = propTypes;
TrucklaneSuggestionZeroState.propTypes = propTypes;
TrucklaneNonSuggestionZeroState.propTypes = propTypes;
TrucklaneDrilldownOtherOffersZeroState.propTypes = propTypes;
TrucklaneDrilldownAwardedOffersZeroState.propTypes = propTypes;
TrucklaneDrilldownSuggestedOffersZeroState.propTypes = propTypes;

export {
  TrucklaneFilterZeroState,
  TrucklaneSuggestionZeroState,
  TrucklaneNonSuggestionZeroState,
  TrucklaneDrilldownOtherOffersZeroState,
  TrucklaneDrilldownAwardedOffersZeroState,
  TrucklaneDrilldownSuggestedOffersZeroState,
};
