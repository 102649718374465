import LocalizedStrings from 'react-localization';

const NegotiationStrings = new LocalizedStrings({
  en: {
    adjustPriceModalTitle: 'Adjust Counter Offer',
    anchor: 'Anchor',
    percent: 'Percent',
    cancel: 'Cancel',
    submit: (num) => `Adjust ${num} Item${num > 1 ? 's' : ''}`,
    flatRate: 'Flat Rate',
    genericError:
      'We were unable to negotiate this offer due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.',
  },
});

export default NegotiationStrings;
