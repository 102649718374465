import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Theme as theme, ChevronIcon } from '@spoiler-alert/ui-library';

const useStyles = createUseStyles({
  opener: {
    width: 30,
    display: 'inline-block',
    cursor: 'pointer',
    marginLeft: ({ indentLevel }) => indentLevel * 30,
  },
  chevron: {
    width: 10,
    height: 7,
    fill: ({ fill }) => fill,
    transition: 'transform 0.3s',
    transform: ({ open }) => `rotate(${open ? 0 : -90}deg)`,
  },
});

const GroupOpener = ({ open, indentLevel, onClick, fill }) => {
  const classes = useStyles({ indentLevel, open, fill });
  return (
    <div className={classes.opener} onClick={onClick}>
      <ChevronIcon className={classes.chevron} />
    </div>
  );
};

GroupOpener.propTypes = {
  open: PropTypes.bool,
  indentLevel: PropTypes.number,
  onClick: PropTypes.func,
  fill: PropTypes.string,
};

GroupOpener.defaultProps = {
  indentLevel: 0,
  fill: theme.grey80,
};

export default GroupOpener;
