import React, { Component } from 'react';
import { AlertService } from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import ProxyHelper from '../utilities/proxy-helper';
import { TitleService } from '../services';

const styles = {
  proxy: {
    textAlign: 'center',
    paddingTop: '3rem',
  },
};

@injectSheet(styles)
export default class Proxy extends Component {
  constructor(props) {
    super(props);
    TitleService.setTitles('Download File');
    this.state = {
      downloading: true,
      downloaded: false,
      errored: false,
    };
  }

  componentDidMount() {
    const { id, type, filename } = this.props.match.params;
    ProxyHelper.downloadFile(id, type, filename)
      .then(() => {
        this.setState({ downloading: false, downloaded: true });
      })
      .catch((err) => {
        this.setState({ errored: true, downloading: false });
        AlertService.alert({ type: 'warning', message: err.message, autoDismiss: false });
      });
  }

  render() {
    return (
      <div className={this.props.classes.proxy}>
        {this.state.errored && <h1>Download failed.</h1>}
        {this.state.downloading && <h1>Downloading {this.props.match.params.filename}...</h1>}
        {this.state.downloaded && <h1>Download of {this.props.match.params.filename} is complete.</h1>}
      </div>
    );
  }
}

Proxy.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  id: PropTypes.string,
  filename: PropTypes.string,
  match: PropTypes.object,
};
