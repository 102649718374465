import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Button, ContentIcon, CloudUploadIcon, HistoryDetailIcon, WandIcon, CogCheckIcon } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';

const styles = {
  about__container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 32px 0 16px',
  },
  about__content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '58vh',
    minHeight: '400px',
    backgroundColor: '#4eaac1',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '2px',
    padding: '24px 48px',
  },
  about__icon: {
    height: 48,
    width: 48,
    fill: '#ffffff',
  },
  about__header: {
    fontSize: '1.675rem',
  },
  text__center: {
    fontSize: '1.2rem',
    fontWeight: 300,
  },
  button: {
    color: '#4eaac1',
  },
  line__break: {
    display: 'block',
  },
  content__icon: {
    height: 36,
    width: 36,
    fill: '#4eaac1',
    margin: 10,
  },
  content__boxes: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content__box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 20,
  },
  box__header: {
    margin: '10px 0 0',
  },
  box__paragraph: {
    margin: '10px 0 0',
  },
  '@media (max-width: 840px)': {
    about__content: {
      height: '88vh',
      padding: '24px',
      minHeight: 0,
    },
    circle: {
      width: '60px',
      height: '60px',
      fontSize: '2.25rem',
    },
    text__center: {
      fontSize: '1.125rem',
      marginBottom: '38px',
    },
    line__break: {
      display: 'inline',
      marginLeft: 4,
    },
    content__boxes: {
      flexDirection: 'column',
    },
  },
  '@media (max-width: 840px) and (orientation: landscape)': {
    about__content: {
      height: '76vh',
      padding: '24px',
      minHeight: 0,
    },
    circle: {
      width: '45px',
      height: '45px',
      fontSize: '1.6rem',
      border: '4px solid #fff',
    },
    text__center: {
      fontSize: '1rem',
      marginBottom: '20px',
    },
  },
};

@injectSheet(styles)
export default class ItemAbout extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('Items');
    this.state = {
      loading: false,
    };
  }

  handleSAASInquiryEmail = () => {
    // MeteorConnector.Meteor.call('sendSAASInquiryEmail', 'Item Library', (err, res) => {
    //   if (err) {
    //     AlertService.alert({ type: 'warning', message: <span>Our apologies, looks like something is wrong with our system. Please contact an Administrator to inquire about this feature.</span> });
    //   } else if (res) {
    //     AlertService.alert({ type: 'success', message: <span>Our team will be in touch to get you started with the Item Library.</span> });
    //   }
    // });
  };

  done = () => {
    this.setState({ loading: false });
  };

  render() {
    const { classes, style } = this.props;
    return (
      <div className={classes.about__container} style={style}>
        <div className={classes.about__content}>
          <ContentIcon className={classes.about__icon} />
          <h1 className={classes.about__header}>Your Item Library</h1>
          <p className={classes.text__center}>
            Create items to speed up posts, improve tracebility,
            <span className={classes.line__break}> and give you more insight into your business.</span>
          </p>
          <Button
            type="button"
            secondary
            className={classes.button}
            onClick={this.handleSAASInquiryEmail}
            loading={this.state.loading}
            loadingText="Loading..."
          >
            Get Started
          </Button>
        </div>
        <div className={classes.content__boxes}>
          <div className={classes.content__box}>
            <CloudUploadIcon className={classes.content__icon} />
            <h5 className={classes.box__header}>Bulk Import</h5>
            <p className={classes.box__paragraph}>Upload all your item data in seconds</p>
          </div>
          <div className={classes.content__box}>
            <HistoryDetailIcon className={classes.content__icon} />
            <h5 className={classes.box__header}>Detailed Item History</h5>
            <p className={classes.box__paragraph}>
              Be prepared for any tax audit
              <span className={classes.line__break}>with centralized reporting</span>
            </p>
          </div>
          <div className={classes.content__box}>
            <WandIcon className={classes.content__icon} />
            <h5 className={classes.box__header}>Item-level Insights</h5>
            <p className={classes.box__paragraph}>
              Improve your business and traceability
              <span className={classes.line__break}>with item queries</span>
            </p>
          </div>
          <div className={classes.content__box}>
            <CogCheckIcon className={classes.content__icon} />
            <h5 className={classes.box__header}>Automated Accounting</h5>
            <p className={classes.box__paragraph}>
              Use new donation reporting tool
              <span className={classes.line__break}>to claim tax benefits</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

ItemAbout.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  style: PropTypes.object,
};
