const styles = {
  action__buttons: {
    order: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  search__row: {
    justifyContent: 'flex-start',
  },
  select__box: {
    order: 0,
    marginRight: '12px',
  },
  search: {
    order: 2,
    marginLeft: 'auto',
  },
  filter__button: {
    order: 3,
  },
  marketplace__icon: {
    maxWidth: '20%',
    maxHeight: '20%',
    fill: '#99a1b0',
  },
  icon__wrapper: {
    marginLeft: '40%',
    marginTop: '15%',
  },
  text__wrapper: {
    marginTop: '1%',
    marginLeft: '-7%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  no__offer: {
    fontWeight: '500',
    fontSize: '16px',
    margin: 0,
  },
  redirect__offer: {
    fontSize: '14px',
    color: '#818589',
  },
  badge__pending: {
    marginLeft: '20px',
    display: 'inline-block',
  },
  badge__wrapper: {
    display: 'flex',
  },
  table_wrap: {
    borderTop: '1px #D1D6DA solid',
    borderRight: '1px #D1D6DA solid',
    borderLeft: '1px #D1D6DA solid',
    borderBottom: '1px #D1D6DA solid',
    borderRadius: 2,
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: [0, 0, 24],
    '&>button': {
      marginLeft: 8,
    },
  },
  offerReviewSearch: {
    width: 350,
    marginRight: 'auto',
  },
  toggleWrap: {
    marginRight: 24,
  },
  buttonIcon: {
    '& svg': {
      width: '24px !important',
      height: '24px !important',
    },
  },
};

export default styles;
