import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Theme as theme } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';

const styles = {
  new_item__container: {
    marginBottom: 16,
  },
  new_item: {
    backgroundColor: theme.primaryButtonColor,
    padding: 12,
    borderRadius: 25,
    border: 'none',
    cursor: 'pointer',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.21)',
    '&:hover': {
      backgroundColor: theme.primaryButtonHoverColor,
    },
  },
  plus_shape: {
    width: 13,
    height: 13,
    display: 'block',
  },
};

@injectSheet(styles)
export default class CreateDropdown extends React.Component {
  state = {
    show: false,
  };

  button = React.createRef();

  showMenu = () => {
    this.setState({ show: true });
  };

  hideMenu = () => {
    this.setState({ show: false });
  };

  render() {
    const { classes, user } = this.props;
    const { canUserAddBulkImport, canUserAddTransactions } = user.privileges;
    return (
      <div className={classes.new_item__container}>
        <button type="button" className={classes.new_item} onClick={this.showMenu} ref={this.button}>
          <svg
            className={classes.plus_shape}
            viewBox="0 0 12 12"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <polygon
              stroke="none"
              strokeWidth="1"
              fill="#ffffff"
              fillRule="evenodd"
              id="plus-shape"
              points="4.8 12 7.2 12 7.2 7.2 12 7.2 12 4.8 7.2 4.8 7.2 0 4.8 0 4.8 4.8 0 4.8 0 7.2 4.8 7.2"
            ></polygon>
          </svg>
        </button>
        <Menu show={this.state.show} position="topright" onHide={this.hideMenu} parent={this.button}>
          {canUserAddTransactions && (
            <MenuItem icon="nonprofitdonation" route="/log/transaction">
              Log Transaction
            </MenuItem>
          )}
          {canUserAddBulkImport && (
            <MenuItem icon="import" route="/import-donations">
              Bulk Data Import
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }
}

CreateDropdown.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
};
