import React from 'react';
import moment from 'moment';
import {
  MetricsWrapper,
  DaterangeFilter,
  TotalMetric,
  DonutLeaderboard,
  Leaderboard,
  AreaChart,
  DiscountSalesMetric,
  DiversionSummaryChart,
  RevenueChart,
  TopItems,
} from '@spoiler-alert/ui-library';
import { topPartnersColumns, topSkuColumns } from '../metrics/leaderboard-columns';
import {
  TotalCostQuery,
  TotalAmountQuery,
  TotalPoundsQuery,
  TotalMealsQuery,
  TotalPoundsOfVerificationsQuery,
  SellThroughRateQuery,
  TotalRevenueQuery,
  BidRateQuery,
} from '../graphql/queries';
import postGroups from '../metrics/post-groups';
import {
  LocationFilter,
  DestinationFilter,
  VendorReturnsFilter,
  CategoryFilter,
  SubcategoryFilter,
  QualityFilter,
  SupplierFilter,
  TimeResolutionFilter,
} from '../components/filters';
import { DashboardStrings } from '../string-resources';
import DiversionSummaryHeader from '../components/widget/diversion-summary-header';
import RevenueSummaryHeader, { revenueColorMapping } from '../components/widget/revenue-summary-header';

export default class ComponentBuilder {
  constructor(user, events, dateRangeProvider, initialValues) {
    this.user = user;
    this.events = events;
    this.dateRangeProvider = dateRangeProvider;
    this.initialValues = initialValues;
  }

  getComponent(widget, state, customSettings) {
    return widget && this[widget.componentType] ? this[widget.componentType](widget, state, customSettings) : null;
  }

  DaterangeFilter(widget, state, customSettings = {}) {
    return (
      <DaterangeFilter
        rangelist
        onChange={this.events.dateRangeChange}
        dateRangeProvider={this.dateRangeProvider}
        initialValues={
          customSettings.useStateFilterParametersForInitialDateRange
            ? { start: moment.utc(state.filterParameters.startDate), end: moment.utc(state.filterParameters.endDate) }
            : {}
        }
      />
    );
  }

  LocationFilter() {
    const { canUserManageMultipleSites } = this.user.privileges;
    if (!canUserManageMultipleSites) return null;
    return (
      <LocationFilter
        onChange={this.events.sitesChange}
        user={this.user}
        filterBasis={canUserManageMultipleSites.canViewInventory ? 'INVENTORY' : 'POST'}
      />
    );
  }

  DestinationFilter() {
    return <DestinationFilter user={this.user} onChange={this.events.destinationsChange} />;
  }

  VendorReturnsFilter() {
    return <VendorReturnsFilter onChange={this.events.returnsChange} />;
  }

  CategoryFilter(widget, state, customSettings = {}) {
    return <CategoryFilter user={this.user} onChange={this.events.categoryChange} categoryParam={customSettings.categoryParam} />;
  }

  SubcategoryFilter(widget, state, customSettings = {}) {
    return <SubcategoryFilter user={this.user} onChange={this.events.subCategoryChange} SubCategoryParm={customSettings.subCategoryParam} />;
  }

  QualityFilter() {
    return <QualityFilter user={this.user} onChange={this.events.qualityChange} />;
  }

  SupplierFilter() {
    return <SupplierFilter user={this.user} onChange={this.events.supplierChange} />;
  }

  TimeResolutionFilter() {
    return <TimeResolutionFilter onChange={this.events.timeResolutionChange} />;
  }

  TotalCostMetricWithQuery(widget, state) {
    return (
      <TotalMetric
        query={TotalCostQuery}
        sites={state.sites}
        metric={widget.filters[0]}
        metricType="cost"
        startDate={state.startDate}
        endDate={state.endDate}
        destinations={state.destinations}
        vendorReturns={state.vendorReturns}
        categories={state.categories}
        subcategories={state.subcategories}
        qualities={state.qualities}
      />
    );
  }

  TotalAmountMetricWithQuery(widget, state) {
    return (
      <TotalMetric
        query={TotalAmountQuery}
        sites={state.sites}
        metric={widget.filters[0]}
        metricType="amount"
        startDate={state.startDate}
        endDate={state.endDate}
        destinations={state.destinations}
        vendorReturns={state.vendorReturns}
        categories={state.categories}
        subcategories={state.subcategories}
        qualities={state.qualities}
      />
    );
  }

  TotalPoundsMetricWithQuery(widget, state) {
    return (
      <TotalMetric
        query={TotalPoundsQuery}
        sites={state.sites}
        metric={widget.filters[0]}
        metricType="pounds"
        startDate={state.startDate}
        endDate={state.endDate}
        destinations={state.destinations}
        vendorReturns={state.vendorReturns}
        categories={state.categories}
        subcategories={state.subcategories}
        qualities={state.qualities}
      />
    );
  }

  TotalPoundsOfVerificationsMetricWithQuery(widget, state) {
    return (
      <TotalMetric
        query={TotalPoundsOfVerificationsQuery}
        metric={widget.filters}
        metricType="pounds"
        startDate={state.startDate}
        endDate={state.endDate}
        destinations={state.destinations}
        vendorReturns={state.vendorReturns}
        categories={state.categories}
        subcategories={state.subcategories}
        qualities={state.qualities}
      />
    );
  }

  TotalMealsMetricWithQuery(widget, state) {
    // should be fetching either this text or the numerical value from UndefinedFoodConstants
    return (
      <TotalMetric
        query={TotalMealsQuery}
        sites={state.sites}
        metric={widget.filters[0]}
        metricType="meals"
        startDate={state.startDate}
        endDate={state.endDate}
        destinations={state.destinations}
        vendorReturns={state.vendorReturns}
        tooltip
        tooltipText={DashboardStrings.totalMealsTooltip}
        categories={state.categories}
        subcategories={state.subcategories}
        qualities={state.qualities}
      />
    );
  }

  SellThroughRateMetricWithQuery(widget, state) {
    return (
      <DiscountSalesMetric
        query={SellThroughRateQuery}
        sites={state.sites}
        metric={widget.filters[0]}
        metricType="sellThrough"
        startDate={state.startDate}
        endDate={state.endDate}
        destinations={state.destinations}
        vendorReturns={state.vendorReturns}
        tooltip
        tooltipText={DashboardStrings.sellThroughRateTooltip}
        categories={state.categories}
        subcategories={state.subcategories}
        qualities={state.qualities}
      />
    );
  }

  TotalRevenueMetricWithQuery(widget, state) {
    return (
      <DiscountSalesMetric
        query={TotalRevenueQuery}
        sites={state.sites}
        metric={widget.filters[0]}
        metricType="totalRevenue"
        startDate={state.startDate}
        endDate={state.endDate}
        destinations={state.destinations}
        vendorReturns={state.vendorReturns}
        tooltip
        tooltipText={DashboardStrings.totalRevenueTooltip}
        categories={state.categories}
        // subcategories={state.subcategories}
        qualities={state.qualities}
      />
    );
  }

  BidRateMetricWithQuery(widget, state) {
    return (
      <DiscountSalesMetric
        query={BidRateQuery}
        sites={state.sites}
        metric={widget.filters[0]}
        metricType="bidRate"
        startDate={state.startDate}
        endDate={state.endDate}
        destinations={state.destinations}
        vendorReturns={state.vendorReturns}
        tooltip
        tooltipText={DashboardStrings.bidRateTooltip}
        categories={state.categories}
        subcategories={state.subcategories}
        qualities={state.qualities}
      />
    );
  }

  AreaChartWithQuery(widget, state) {
    return (
      <MetricsWrapper metrics={widget.filters} title={widget.widgetName} size="m">
        <AreaChart
          sites={state.sites}
          fiscalYearStart={this.user.site.fiscalYearStart}
          startDate={state.startDate}
          endDate={state.endDate}
          destinations={state.destinations}
          vendorReturns={state.vendorReturns}
          categories={state.categories}
          subcategories={state.subcategories}
          qualities={state.qualities}
        />
      </MetricsWrapper>
    );
  }

  StackedColumnChartWithQuery(widget, state) {
    return (
      <MetricsWrapper metrics={widget.filters} title={<DiversionSummaryHeader widgetName={widget.widgetName} />} size="m">
        <DiversionSummaryChart
          sites={state.sites}
          fiscalYearStart={this.user.site.fiscalYearStart}
          startDate={state.startDate}
          endDate={state.endDate}
          destinations={state.destinations}
          vendorReturns={state.vendorReturns}
          postGroups={postGroups}
          categories={state.categories}
          subcategories={state.subcategories}
          destinationColorMap={this.user.site.destinationColorMapping}
          qualities={state.qualities}
          timeResolution={state.timeResolution}
        />
      </MetricsWrapper>
    );
  }

  RevenueChart(widget, state) {
    return (
      <MetricsWrapper metric={widget.filters[0]} metrics={[]} title={<RevenueSummaryHeader widgetName={widget.widgetName} />} size="m">
        <RevenueChart
          sites={state.sites}
          fiscalYearStart={this.user.site.fiscalYearStart}
          startDate={state.startDate}
          endDate={state.endDate}
          timeResolution={state.timeResolution}
          categories={state.categories}
          subcategories={state.subcategories}
          destinationColorMap={revenueColorMapping}
          qualities={state.qualities}
        />
      </MetricsWrapper>
    );
  }

  TopItems(widget, state) {
    return (
      <MetricsWrapper metric={widget.filters[0]} metrics={[]} title={widget.widgetName} height={350}>
        <TopItems
          sites={state.sites}
          fiscalYearStart={this.user.site.fiscalYearStart}
          startDate={state.startDate}
          endDate={state.endDate}
          timeResolution={state.timeResolution}
          categories={state.categories}
          subcategories={state.subcategories}
          qualities={state.qualities}
        />
      </MetricsWrapper>
    );
  }

  TopPartnersLeaderboardWithQuery(widget, state) {
    return (
      <MetricsWrapper metrics={widget.filters} title={widget.widgetName} size="s">
        <Leaderboard
          sites={state.sites}
          startDate={state.startDate}
          endDate={state.endDate}
          columns={topPartnersColumns}
          destinations={state.destinations}
          vendorReturns={state.vendorReturns}
          categories={state.categories}
          subcategories={state.subcategories}
          qualities={state.qualities}
        />
      </MetricsWrapper>
    );
  }

  ProductMixDonutLeaderboardWithQuery(widget, state) {
    return (
      <MetricsWrapper metrics={widget.filters} title={widget.widgetName} size="s">
        <DonutLeaderboard
          sites={state.sites}
          startDate={state.startDate}
          endDate={state.endDate}
          destinations={state.destinations}
          vendorReturns={state.vendorReturns}
          categories={state.categories}
          subcategories={state.subcategories}
          qualities={state.qualities}
        />
      </MetricsWrapper>
    );
  }

  VariableProductMixDonutLeaderboardWithQuery(widget, state, customSettings) {
    return (
      <MetricsWrapper metrics={[]} metric={widget} title={widget.widgetName} size="variable" height={customSettings.height}>
        <DonutLeaderboard
          sites={state.sites}
          startDate={state.startDate}
          endDate={state.endDate}
          destinations={state.destinations}
          vendorReturns={state.vendorReturns}
          categories={state.categories}
          subcategories={state.subcategories}
          qualities={state.qualities}
        />
      </MetricsWrapper>
    );
  }

  DiversionOutletsDonutLeaderboardWithQuery(widget, state) {
    return (
      <MetricsWrapper metrics={widget.filters} title={widget.widgetName} size="s">
        <DonutLeaderboard
          sites={state.sites}
          startDate={state.startDate}
          endDate={state.endDate}
          destinations={state.destinations}
          vendorReturns={state.vendorReturns}
          categories={state.categories}
          subcategories={state.subcategories}
          destinationColorMap={this.user.site.destinationColorMapping}
          qualities={state.qualities}
        />
      </MetricsWrapper>
    );
  }

  TopFiveMetricsLeaderboardWithQuery(widget, state) {
    return (
      <MetricsWrapper metrics={widget.filters} title={widget.widgetName} size="s">
        <Leaderboard
          sites={state.sites}
          startDate={state.startDate}
          endDate={state.endDate}
          columns={topSkuColumns}
          destinations={state.destinations}
          vendorReturns={state.vendorReturns}
          categories={state.categories}
          subcategories={state.subcategories}
          qualities={state.qualities}
        />
      </MetricsWrapper>
    );
  }
}
