import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
  Theme as theme,
  Checkbox,
  DryIcon,
  RefrigeratedIcon,
  FrozenIcon,
  DeliveryIcon,
  PickupIcon,
  ActiveLaneIcon,
  Tooltip,
} from '@spoiler-alert/ui-library';
import accounting from 'accounting';
import { negativeCostHandler } from '../../utilities/negative-cost-handler';
import GroupOpener from './group-opener';
import { costRecoveryHandler } from '../../utilities/cost-recovery-handler';

const useStyles = createUseStyles({
  row: {
    background: theme.white,
    cursor: 'pointer',
    '&:hover': {
      background: theme.menuItemHoverBg,
    },
  },
  bottomRow: {
    borderBottom: `1px solid ${theme.grey30}`,
  },
  existingRow: {
    extend: 'row',
    background: theme.green5,
  },
  column: {
    padding: [8, 12],
    color: theme.text,
    fontWeight: 'normal',
    fontSize: 14,
  },
  numberColumn: {
    extend: 'column',
    textAlign: 'right',
  },
  trucklaneColumn: {
    extend: 'column',
    paddingLeft: 72,
  },
  existingColumn: {
    extend: 'column',
    fontSize: 12,
    padding: [4, 12],
  },
  existingNumberColumn: {
    extend: 'existingColumn',
    textAlign: 'right',
  },
  existingLabelColumn: {
    extend: 'existingColumn',
    paddingLeft: 72,
  },
  existingTotalLabelColumn: {
    extend: 'existingLabelColumn',
    fontWeight: 500,
  },
  logisticsDetails: {
    color: theme.grey,
    fontSize: 12,
  },
  logisticsIcon: {
    width: 12,
    height: 12,
    fill: theme.grey,
  },
  logisticsDetailRow: {
    display: 'inline-block',
    marginRight: 8,
  },
  greenPlus: {
    color: theme.green,
  },
  activeTrucklaneOpener: {
    color: theme.green,
    cursor: 'pointer',
    fontSize: 12,
  },
  activeLane: {
    position: 'absolute',
    marginLeft: -55,
    marginTop: 14,
  },
  activeLaneIcon: {
    stroke: theme.green,
    fill: 'transparent',
    width: 24,
    cursor: 'help',
  },
  checkbox: {
    padding: [15, 12],
    textAlign: 'center',
    height: 48,
  },
  tooltip: {
    width: 225,
    fontSize: 14,
  },
  erroredTooltip: {
    width: 270,
    fontSize: 12,
  },
  distanceMissingRow: {
    backgroundColor: theme.red5,
  },
  distanceMissing: {
    color: theme.red,
    fontWeight: 'bold',
  },
});

const logisticsIcons = {
  Pickup: PickupIcon,
  Delivery: DeliveryIcon,
};

const truckTypeIcons = {
  Dry: DryIcon,
  Refrigerated: RefrigeratedIcon,
  Frozen: FrozenIcon,
};

const AwardTableActiveLaneRow = ({ lane, includeLanes, excludeLanes, excludedLanes, smartAwardMode }) => {
  const classes = useStyles();

  const [showExistingLane, setShowExistingLane] = useState(false);

  const LogisticsIcon = logisticsIcons[lane.logisticsTerm];
  const TruckIcon = truckTypeIcons[lane.truckType];

  const handleCheck = (event) => {
    event.event.stopPropagation();
    if (event.checked) {
      includeLanes([lane._id]);
    } else {
      excludeLanes([lane._id]);
    }
  };

  const checked = !excludedLanes.includes(lane._id);
  const distanceMissing = lane.logisticsTerm === 'Delivery' && !lane.trucklaneDistance;
  const netRevenueMode = smartAwardMode === 'NET_REVENUE';
  const NAClassName = netRevenueMode ? classes.distanceMissing : null;

  const formattedMoney = distanceMissing ? (
    <span className={NAClassName}>N/A</span>
  ) : (
    <div>
      <span className={classes.greenPlus}>+</span> {accounting.formatMoney(0)}
    </div>
  );
  const formattedDistance = distanceMissing ? 'mileage unknown' : `${accounting.formatNumber(lane.trucklaneDistance, 0)} mi.`;
  const rowClass = distanceMissing && netRevenueMode ? `${classes.row} ${classes.distanceMissingRow}` : classes.row;
  const formattedRevenue =
    distanceMissing && netRevenueMode ? (
      'N/A'
    ) : (
      <div>
        <span className={classes.greenPlus}>+</span> {negativeCostHandler(lane.laneTotals.totalRevenue)}
      </div>
    );

  return (
    <>
      <tr className={showExistingLane ? rowClass : `${rowClass} ${classes.bottomRow}`} onClick={() => setShowExistingLane(!showExistingLane)}>
        <td>
          {distanceMissing && netRevenueMode ? (
            <div className={classes.checkbox}>
              <Tooltip
                className={classes.erroredTooltip}
                small
                bottom
                text={<div className={classes.erroredTooltip}>Excluded because net revenue can’t be determined</div>}
              >
                <Checkbox onChecked={handleCheck} checked={checked} disabled={distanceMissing && netRevenueMode} />
              </Tooltip>
            </div>
          ) : (
            <Checkbox className={classes.checkbox} onChecked={handleCheck} checked={checked} disabled={distanceMissing && netRevenueMode} />
          )}
        </td>
        <td className={classes.trucklaneColumn}>
          <div className={classes.activeLane}>
            <Tooltip
              small
              bottom
              headerBackgroundColor={theme.green5}
              headerTextColor={theme.green}
              header="Active Truck Lane"
              text={
                <div className={classes.tooltip}>
                  Since this lane is already active, it is exempt from lane minimums. We might recommend adding inventory below your lane minimum.
                  <br />
                  <br />
                  Expand row for details
                </div>
              }
            >
              <ActiveLaneIcon className={classes.activeLaneIcon} />
            </Tooltip>
          </div>
          <div>{lane.buyerLocationName}</div>
          <div className={classes.logisticsDetails}>
            <div className={classes.logisticsDetailRow}>
              <LogisticsIcon className={classes.logisticsIcon} /> {lane.logisticsTerm}
            </div>
            <div className={classes.logisticsDetailRow}>
              <TruckIcon className={classes.logisticsIcon} /> {lane.truckType}
            </div>
          </div>
          <div className={classes.activeTrucklaneOpener}>
            Active Trucklane Details <GroupOpener fill={theme.green} open={showExistingLane} />
          </div>
        </td>
        <td className={classes.numberColumn}>
          <span className={classes.greenPlus}>+</span> {accounting.formatNumber(lane.laneTotals.caseCount)}
        </td>
        <td className={classes.numberColumn}>
          <span className={classes.greenPlus}>+</span> {accounting.formatNumber(lane.laneTotals.palletCount, 2)}
        </td>
        <td className={classes.numberColumn}>
          <span className={classes.greenPlus}>+</span> {accounting.formatNumber(lane.laneTotals.totalWeight, 2)} lbs
        </td>
        <td className={classes.numberColumn}>
          <span className={classes.greenPlus}>+</span> {accounting.formatMoney(lane.laneTotals.totalRevenue)}
        </td>
        {lane.logisticsTerm === 'Pickup' ? (
          <td className={classes.numberColumn}>
            <div>-</div>
          </td>
        ) : (
          <td className={classes.numberColumn}>
            <div>{formattedMoney}</div>
            <div>{formattedDistance}</div>
          </td>
        )}
        <td className={classes.numberColumn}>{formattedRevenue}</td>
        <td className={classes.existingNumberColumn}>{costRecoveryHandler(lane.laneTotals.totalPrice, lane.laneTotals.costOfAwardedInventory)}</td>
      </tr>
      {showExistingLane && (
        <>
          <tr className={classes.existingRow}>
            <td className={classes.existingColumn}></td>
            <td className={classes.existingLabelColumn}>Currently awarded on this lane</td>
            <td className={classes.existingNumberColumn}>{accounting.formatNumber(lane.existingLane.caseCount)}</td>
            <td className={classes.existingNumberColumn}>{accounting.formatNumber(lane.existingLane.palletCount, 2)}</td>
            <td className={classes.existingNumberColumn}>{accounting.formatNumber(lane.existingLane.totalWeight, 2)} lbs</td>
            <td className={classes.existingNumberColumn}>{accounting.formatMoney(lane.existingLane.totalRevenue)}</td>
            {lane.logisticsTerm === 'Pickup' ? (
              <td className={classes.existingNumberColumn}>-</td>
            ) : (
              <td className={classes.existingNumberColumn}>({accounting.formatMoney(lane.existingLane.totalEstDeliveryCost)})</td>
            )}
            <td className={classes.existingNumberColumn}>
              {negativeCostHandler(lane.existingLane.totalRevenue - lane.existingLane.totalEstDeliveryCost)}
            </td>
            <td className={classes.existingNumberColumn}>
              {costRecoveryHandler(lane.existingLane.totalPrice, lane.existingLane.costOfAwardedInventory)}
            </td>
          </tr>
          <tr className={classes.existingRow}>
            <td className={classes.existingColumn}></td>
            <td className={classes.existingLabelColumn}>To be added</td>
            <td className={classes.existingNumberColumn}>
              <span className={classes.greenPlus}>+</span> {accounting.formatNumber(lane.laneTotals.caseCount)}
            </td>
            <td className={classes.existingNumberColumn}>
              <span className={classes.greenPlus}>+</span> {accounting.formatNumber(lane.laneTotals.palletCount, 2)}
            </td>
            <td className={classes.existingNumberColumn}>
              <span className={classes.greenPlus}>+</span> {accounting.formatNumber(lane.laneTotals.totalWeight, 2)} lbs
            </td>
            <td className={classes.existingNumberColumn}>
              <span className={classes.greenPlus}>+</span> {accounting.formatMoney(lane.laneTotals.totalRevenue)}
            </td>
            {lane.logisticsTerm === 'Pickup' ? (
              <td className={classes.existingNumberColumn}>-</td>
            ) : (
              <td className={classes.existingNumberColumn}>
                <span className={classes.greenPlus}>+</span> ({accounting.formatMoney(0)})
              </td>
            )}
            <td className={classes.existingNumberColumn}>
              <span className={classes.greenPlus}>+</span> {accounting.formatMoney(lane.laneTotals.totalRevenue)}
            </td>
            <td className={classes.existingNumberColumn}>
              {costRecoveryHandler(lane.laneTotals.totalPrice, lane.laneTotals.costOfAwardedInventory)}
            </td>
          </tr>
          <tr className={showExistingLane ? `${classes.existingRow} ${classes.bottomRow}` : classes.existingRow}>
            <td className={classes.existingColumn}></td>
            <td className={classes.existingTotalLabelColumn}>Lane total after award</td>
            <td className={classes.existingNumberColumn}>{accounting.formatNumber(lane.existingLane.caseCount + lane.laneTotals.caseCount)}</td>
            <td className={classes.existingNumberColumn}>
              {accounting.formatNumber(lane.existingLane.palletCount + lane.laneTotals.palletCount, 2)}
            </td>
            <td className={classes.existingNumberColumn}>
              {accounting.formatNumber(lane.existingLane.totalWeight + lane.laneTotals.totalWeight, 2)} lbs
            </td>
            <td className={classes.existingNumberColumn}>{accounting.formatMoney(lane.existingLane.totalRevenue + lane.laneTotals.totalRevenue)}</td>
            {lane.logisticsTerm === 'Pickup' ? (
              <td className={classes.existingNumberColumn}>-</td>
            ) : (
              <td className={classes.existingNumberColumn}>({accounting.formatMoney(lane.existingLane.totalEstDeliveryCost)})</td>
            )}
            <td className={classes.existingNumberColumn}>
              {negativeCostHandler(lane.existingLane.totalRevenue + lane.laneTotals.totalRevenue - lane.existingLane.totalEstDeliveryCost)}
            </td>
            <td className={classes.existingNumberColumn}>
              {costRecoveryHandler(
                lane.laneTotals.totalPrice + lane.existingLane.totalPrice,
                lane.laneTotals.costOfAwardedInventory + lane.existingLane.costOfAwardedInventory
              )}
            </td>
          </tr>
        </>
      )}
    </>
  );
};

AwardTableActiveLaneRow.propTypes = {
  lane: PropTypes.object,
  includeLanes: PropTypes.func,
  excludeLanes: PropTypes.func,
  excludedLanes: PropTypes.arrayOf(PropTypes.string),
  smartAwardMode: PropTypes.string,
};

export default AwardTableActiveLaneRow;
