import accounting from 'accounting';

const negativeCostHandler = (value) => {
  if (value < 0) {
    return `(${accounting.formatMoney(-1 * value)})`;
  }
  return accounting.formatMoney(value);
};

export { negativeCostHandler };
