import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectOption } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import filterStyles from './filter-styles';
import { FilterStrings } from '../../string-resources';

export const TIME_RESOLUTIONS = new Map([
  ['WEEK', FilterStrings.week],
  ['MONTH', FilterStrings.month],
  // ['QUARTER', FilterStrings.quarter],
  ['YEAR', FilterStrings.year],
]);

export const initialTimeResolutionValue = 'MONTH';

@injectSheet(filterStyles)
export default class TimeResolutionFilter extends React.Component {
  state = {
    values: [{ value: initialTimeResolutionValue, text: TIME_RESOLUTIONS.get(initialTimeResolutionValue) }],
  };

  onChange = (values) => {
    this.setState({ values });
    this.props.onChange(values[0].value);
  };

  render() {
    const resolutions = [...TIME_RESOLUTIONS.entries()].map(([value, label]) => (
      <SelectOption key={`time-resolution-${value}`} checkbox selected={this.state.values[0].value === value} value={value}>
        {label}
      </SelectOption>
    ));
    return (
      <div className={this.props.classes.dashboard__filter}>
        <Select label="Time Resolution" selectedItems={this.state.values} autoWidth onChange={this.onChange}>
          {resolutions}
        </Select>
      </div>
    );
  }
}

TimeResolutionFilter.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
};
