import React from 'react';
import { Theme, MarketingUpwardGraphIcon, TruckMovingIcon } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    border: `1px solid ${Theme.borderColor}`,
    borderRadius: '4px',
    justifyContent: 'space-between',
    padding: 16,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    marginBottom: 24,
  },
  block: {
    display: 'flex',
  },
  header: {
    alignSelf: 'stretch',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1.44,
    letterSpacing: ' -0.2px',

    marginBottom: 0,
  },
  subHeader: {
    alignSelf: 'stretch',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 1.5,
    letterSpacing: '-0.18px',

    marginBottom: 12,
  },
  infoText: {
    flexGrow: 1,
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
  },
  linkText: {
    width: '202px',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    '& a': {
      color: Theme.infoColor,
    },
  },
});

const RecommendedOfferShifts = () => {
  const classes = useStyles();
  const kustomerLink = 'https://spoileralert.kustomer.help/en_us/offer-shifting-faq-rkFCbScsA';
  return (
    <div className={classes.container}>
      <div className={classes.block}>
        <MarketingUpwardGraphIcon style={{ marginRight: '24px', height: 'auto' }} />
        <div>
          <div className={classes.header}>We found opportunities to sell through more inventory!</div>
          <div className={classes.subHeader}>Sell through more inventory by shifting unawarded offers to similar leftover inventory.</div>
          <div className={classes.block}>
            <TruckMovingIcon style={{ marginRight: '8px' }} />
            <span className={classes.infoText}>All offer shifting opportunities are on active truck lanes.</span>
          </div>
        </div>
      </div>
      <div>
        <p className={classes.linkText}>
          How does this work?{' '}
          <a href={kustomerLink} target="_blank" rel="noreferrer">
            Learn more
          </a>
        </p>
      </div>
    </div>
  );
};

RecommendedOfferShifts.propTypes = {};

export default RecommendedOfferShifts;
