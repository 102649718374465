import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import pluralize from 'pluralize';
import { createUseStyles } from 'react-jss';
import { AlertService, Modal, ModalFooter, Button, Checkbox, Search, NoneSelectedIconLarge, ModalContent } from '@spoiler-alert/ui-library';
import destinationType from '../../enums/staged-inventory-destination-type';
import { DiscountedSalesDistributionListQuery } from '../../graphql/queries';
import { createStagedListingsFromInventory } from '../../graphql/mutations';
import { Store } from '../../store';
import HandlingMappingModalContent from './handling-mapping-modal-content';

const styles = {
  modalContents: {
    height: '600px',
  },
  containerWrapper: {
    width: 898,
    maxHeight: 600,
    margin: '0 0 15px 0',
    borderRadius: 2,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  searchInput: {
    width: '570px',
    margin: '24px 8px 8px 24px',
  },
  listArea: {
    justifyContent: 'space-between',
    flex: '1',
    width: '570px',
    height: '326px',
    margin: '0px 8px 0 24px',
    padding: '0px 0 93px',
    border: 'solid 1px #dadbdc',
    overflow: 'scroll',
    borderTop: 'none',
    borderRadius: '0',
  },
  allListArea: {
    flex: '1',
    width: '570px',
    height: '45px',
    margin: '8px 8px 0 24px',
    padding: '12px 0 23px 15px',
    border: 'solid 1px #dadbdc',
    display: 'flex',
    '& input[type="checkbox"] + div': {
      marginLeft: '5px',
    },
  },
  distributionListText: {
    marginBottom: '2px',
    height: '19px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#292f34',
  },
  distributionListTextAll: {
    marginBottom: '2px',
    height: '19px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#292f34',
  },
  buyerPreview: {
    width: '272px',
    height: '415px',
    flexGrow: 0,
    margin: '24px 24px 0 8px',
    padding: '9px 1px 114px 0',
    borderRadius: '2px',
    border: 'solid 1px #dadbdc',
  },
  previewHeader: {
    width: '113px',
    height: '20px',
    flexGrow: 0,
    margin: '0 148px 6px 10px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: ' -0.08px',
    textAlign: 'left',
    color: '#000',
  },
  previewLine: {
    width: '271px',
    height: '1px',
    flexGrow: 0,
    margin: '6px 0 0',
    transform: 'rotate(-360deg)',
    backgroundColor: '#dfdfe0',
  },
  distributionList: {
    display: 'flex',
    paddingLeft: '18px',
    alignItems: 'center',
    padding: '10px 0',
    '& input[type="checkbox"]:checked + div': {
      color: 'green',
    },
    width: '568px',
    '&:hover': {
      background: '#f8f9f9',
    },
    '&:focus': {
      border: 'solid 1px #4eaac1',
      background: '#f8f9f9',
    },
  },
  noDistributionListsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  noneSelectedIcon: {
    width: '165px',
    height: '79px',
    margin: '122px 54px 17px 52px',
    objectFit: 'contain',
  },
  noDistributionListsText: {
    width: '240px',
    height: '47px',
    margin: '17px 20px 0 11px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '-0.18px',
    textAlign: 'center',
    color: '#292f34',
  },
  noDistributionListSearch: {
    width: 525,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.18,
    textAlign: 'left',
    color: '#000000',
    margin: '-162px 0px 0px 24px',
  },
  noDistributionListSearchWrapper: {
    justifyContent: 'space-between',
    flex: '1',
    width: '570px',
    height: '371px',
    margin: '0px 8px 0 24px',
    border: 'solid 1px #dadbdc',
    borderRadius: '0',
  },
  buyerNames: {
    overflow: 'scroll',
    width: '97%',
    height: '371px',
    flexGrow: 0,
    margin: '0px 86px 0 10px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '-0.18px',
    textAlign: 'left',
    color: '#000',
  },
  buyerName: {
    width: '100%',
  },
  ModalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '12px',
    marginTop: '20px',
    padding: '0 24px 24px',
  },
  checkbox: {
    marginRight: '8px',
    '&:checked': {
      color: 'green',
    },
  },
  allCheckbox: {
    marginRight: '8px',
    '&:checked': {
      color: 'green',
    },
    marginLeft: '4px',
  },
  modalHeader: {
    width: '145px',
    height: '28px',
    flexGrow: 0,
    margin: '24px 433px 0px 24px',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000',
    alignSelf: 'flex-start',
  },
};

const useStyles = createUseStyles(styles, { name: 'ListInventoryModal' });

const ListInventoryModal = ({ onHide, open, inventoryIds, refetchQueries, allSelected, filters, user, isDonation }) => {
  const classes = useStyles();
  const { loading, data } = useQuery(DiscountedSalesDistributionListQuery);

  const [selectedDistributionLists, setSelectedDistributionLists] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [CreateStagedListingsFromInventory, { loading: createStageListingLoading }] = useMutation(createStagedListingsFromInventory);

  useEffect(() => {
    setSelectedDistributionLists([]);
  }, [open, loading]);

  // const distributionLists = data?.currentUserQuery.site.distributionLists.filter((dl) => dl.destinationType.toLowerCase() !== 'donation') || [];
  const distributionLists = isDonation
    ? data?.currentUserQuery.site.distributionLists.filter((dl) => dl.destinationType.toLowerCase() === 'donation') || []
    : data?.currentUserQuery.site.distributionLists.filter((dl) => dl.destinationType.toLowerCase() !== 'donation') || [];

  const filteredDistributionLists = distributionLists.filter((dl) => dl?.name?.toLowerCase().includes(searchTerm?.toLowerCase()));

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedDistributionLists([]);
    } else {
      setSelectedDistributionLists(filteredDistributionLists.map((dl) => dl._id));
    }
    setSelectAll(!selectAll);
  };

  const toggleSelectDistributionList = (id) => {
    if (selectedDistributionLists.includes(id)) {
      setSelectedDistributionLists((prev) => prev.filter((dlId) => dlId !== id));
    } else {
      setSelectedDistributionLists((prev) => [...prev, id]);
    }
  };

  const cancel = () => {
    onHide(false);
  };

  // if an error wasn't handled properly and graphql attached it to the response root
  const hideAndError = () => {
    const message = `We were unable to create the listings due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.`;
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });

    // set logged count to 0 to avoid displaying a success message
    onHide(false, 0, 0);
  };

  const hide = (response) => {
    let actionTaken = false;
    let loggedCount = 0;
    let errorCount = inventoryIds.length;
    if (response) {
      const loggedResults = response.data.createStagedListingsFromInventory;
      errorCount = loggedResults.errors?.length;
      actionTaken = loggedResults.inventories?.length > 0;
      loggedCount = loggedResults.inventories?.length - errorCount;
      Store.refreshOfferComp(true);
    }
    if (errorCount > 0) {
      const { message } = response.data.createStagedListingsFromInventory.errors[0];
      AlertService.alert({ type: 'warning', message: <span>{message}</span> });
      onHide(false, 0, 0);
    } else {
      onHide(actionTaken, errorCount, loggedCount);
    }
  };

  const list = () => {
    const inventoriesToStage = [];
    if (allSelected) {
      [...new Set(selectedDistributionLists)].map((dl) => inventoriesToStage.push({ distributionListId: dl }));
    } else {
      inventoryIds.map((i) =>
        [...new Set(selectedDistributionLists)].map((dl) =>
          inventoriesToStage.push({
            inventoryId: i,
            distributionListId: dl,
          })
        )
      );
    }
    const createPostVariables = {
      inventoriesToStage,
      allSelected,
      inventoryFilters: filters,
    };
    return CreateStagedListingsFromInventory({
      variables: { ...createPostVariables, destinationType: isDonation ? destinationType.donation : destinationType.discountedSales },
      refetchQueries,
    })
      .then(hide)
      .catch((err) => {
        hideAndError(err);
      });
  };

  const searchingAndNoResults = () => {
    return filteredDistributionLists.length === 0 && searchTerm.length > 0;
  };

  const renderBuyersList = () => {
    const renderedBuyers = new Set();
    const buyersList = [];

    selectedDistributionLists.forEach((dlId) => {
      const dl = distributionLists.find((li) => li._id === dlId);
      if (dl) {
        dl.buyers.forEach((buyer) => {
          if (!renderedBuyers.has(buyer.siteName)) {
            renderedBuyers.add(buyer.siteName);
            buyersList.push(buyer.siteName);
          }
        });
      }
    });

    return buyersList.sort().map((siteName) => (
      <div className={classes.buyerName} key={siteName}>
        {siteName}
      </div>
    ));
  };

  const modalText = 'Stage listings';

  return (
    <Modal className={classes.modalContents} onHide={onHide} open={open} closeOnEsc closeOnOutsideClick>
      {user.site.hasUnmappedHandlingValues ? (
        <HandlingMappingModalContent user={user} onHide={onHide} />
      ) : (
        <div>
          <ModalContent>
            <div className={classes.modalHeader}>{modalText}</div>
            <div className={classes.containerWrapper}>
              <div className={classes.leftWrapper}>
                <Search onChange={setSearchTerm} value={searchTerm} placeholder="Search" className={classes.searchInput} />
                {!searchingAndNoResults() ? (
                  <div>
                    <div data-testid="all-checkbox-row" data-element="select-all-row" onClick={toggleSelectAll} className={[classes.allListArea]}>
                      <Checkbox className={classes.allCheckbox} onChecked={toggleSelectAll} checked={selectAll} />
                      <div className={classes.distributionListTextAll}>All</div>
                    </div>
                    <div className={classes.listArea} data-testid="modal-list-area">
                      {filteredDistributionLists.map((dl) => (
                        <div
                          onClick={() => toggleSelectDistributionList(dl._id)}
                          className={classes.distributionList}
                          key={dl._id}
                          data-testid="checkbox-row"
                        >
                          <Checkbox
                            className={classes.checkbox}
                            onChecked={() => toggleSelectDistributionList(dl._id)}
                            checked={selectedDistributionLists.includes(dl._id)}
                          />
                          <div className={classes.distributionListText}>
                            {dl.name} ({dl.buyers.length})
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className={[classes.noDistributionListSearchWrapper]}>
                    <div className={classes.noDistributionListSearch}> No distribution lists matched your search. </div>
                  </div>
                )}
              </div>

              <div className={classes.buyerPreview}>
                <div className={classes.previewHeader}>Customer Preview</div>
                <div className={classes.previewLine}></div>
                {selectedDistributionLists.length === 0 && (
                  <div className={classes.noDistributionListsContainer}>
                    <NoneSelectedIconLarge className={classes.noneSelectedIcon} />
                    <p className={classes.noDistributionListsText}>Select a list (left) to see the associated customers.</p>
                  </div>
                )}
                <div className={classes.buyerNames}>{renderBuyersList()}</div>
              </div>
            </div>
          </ModalContent>
          <ModalFooter className={classes.ModalFooter}>
            <Button type="button" onClick={cancel} link disabled={createStageListingLoading}>
              Cancel
            </Button>
            <Button
              onClick={list}
              disabled={!selectedDistributionLists.length}
              primary
              loading={createStageListingLoading}
              loadingText="Staging Listings"
              data-testid="stage-listings-button"
            >
              {pluralize('Stage Listing', inventoryIds.length)}
            </Button>
          </ModalFooter>
        </div>
      )}
    </Modal>
  );
};

ListInventoryModal.propTypes = {
  classes: PropTypes.object,
  onHide: PropTypes.func,
  open: PropTypes.bool,
  refetchQueries: PropTypes.array,
  inventoryIds: PropTypes.array,
  siteId: PropTypes.string,
  allSelected: PropTypes.bool,
  total: PropTypes.number,
  filters: PropTypes.object,
  isDonation: PropTypes.bool,
  user: PropTypes.object,
};

export default ListInventoryModal;
