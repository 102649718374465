import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { GraphQLDataTable, Filter, Flyout, OverlayService, AlertService, Button, ExportIcon } from '@spoiler-alert/ui-library';
import { UserClaimsQuery, verificationCountQuery } from '../../../graphql/queries';
import dataExportService from '../../../services/data-export-service';
import { myClaimsColumns } from './my-claims-columns';
import ClaimsActivityDetails from './claims-activity-details';
import { TitleService } from '../../../services';
import { Breadcrumbs } from '../../../store';

class MyClaims extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('My Claims');
    Breadcrumbs.set([
      {
        url: '/activity/my-claims',
        title: 'My Claims',
      },
    ]);

    this.state = {
      selectedRow: undefined,
      showFlyout: false,
      showUnclaimModal: false,
      showDeleteModal: false,
      showVerifyModal: false,
    };
  }

  createInitialFilters(user) {
    const locationFilter = { displayName: 'Location', field: 'locations', queryField: 'recipients' };
    const defaultFilters = [
      { displayName: 'Date Range', filterType: 'daterange' },
      { displayName: 'Category', field: 'categories', selectAll: true },
      { displayName: 'Suppliers', field: 'suppliers', selectAll: true },
      { displayName: 'Status', field: 'statuses', selectAll: true },
      { displayName: 'Source', field: 'sources', selectAll: true },
    ];
    if (user.privileges.canUserManageMultipleSites) defaultFilters.splice(1, 0, locationFilter);

    const storeFilters = this.props.userFiltersStore.filters.myClaims || [];
    return defaultFilters.map((df) => new Filter({ ...df, ...storeFilters.find((sf) => sf?.displayName === df.displayName) }));
  }

  handleDynamicRowClick = (row) => {
    this.setState({ selectedRow: row, showFlyout: true }, this.showOverlay);
  };

  showOverlay() {
    OverlayService.show();
  }

  hideFlyout = () => {
    if (this.state.showFlyout) this.setState({ showFlyout: false });
  };

  export = () => {
    dataExportService.export('claims').then(this.exportCompleted).catch(this.exportErrored);
  };

  exportCompleted = (response) => {
    if (response.ok || response.data) {
      const message = 'Your export is now processing and we will email you when it is completed.';
      AlertService.alert({ type: 'info', message: <span>{message}</span>, autoDismiss: true, dismissDelay: 5000 });
    } else {
      this.exportErrored();
    }
  };

  exportErrored() {
    const message = 'Sorry we are unable to export your claims. If this problem persists, please contact a Spoiler Alert Administrator to help you.';
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  }

  get refetchQueries() {
    return [
      {
        query: verificationCountQuery,
      },
    ];
  }

  render() {
    return (
      <div id="activity">
        <GraphQLDataTable
          query={UserClaimsQuery}
          queryName="currentUserQuery"
          queryField="claims"
          userId={this.props.user._id}
          search
          transition
          columns={myClaimsColumns}
          filters={this.createInitialFilters(this.props.user)}
          filterParameterField="postFilterParameters"
          filterable="internal"
          updateStoredFilters={(filters) => this.props.userFiltersStore.updateFilters('myClaims', filters)}
          pagination
          perPage={15}
          perPageIncrements={[15, 30, 50, 100]}
          pageActionButtons={[
            <Button key="export" secondary onClick={this.export} icon={ExportIcon}>
              Export
            </Button>,
          ]}
          onRowClick={this.handleDynamicRowClick}
        />
        {this.state.selectedRow && (
          <Flyout position="right" open={this.state.showFlyout} onHide={this.hideFlyout}>
            <ClaimsActivityDetails id={this.state.selectedRow._id} user={this.props.user} refetchQueries={this.refetchQueries} />
          </Flyout>
        )}
      </div>
    );
  }
}

MyClaims.propTypes = {
  user: PropTypes.object,
  userFiltersStore: PropTypes.object,
};

const ConnectedComponent = inject((store) => store)(observer(MyClaims));
export default ConnectedComponent;
