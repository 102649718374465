import React, { useEffect, useState } from 'react';
import { ModalContent, Button, Modal, OverlayService, TextInput } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';

const useStyles = createUseStyles({
  modal__title: {
    fontSize: '24px',
    padding: 0,
    marginTop: 0,
    height: '25px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.42,
    letterSpacing: '-0.5px',
    textAlign: 'left',
    marginBottom: '8px',
  },
  modal__body: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    height: '48px',
    alignSelf: 'stretch',
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    marginBottom: '8px',
  },
  form__container: {
    width: '100%',
  },
  footer__container: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 24,
    alignItems: 'center',
  },
  modal__submit: {
    display: 'flex',
  },
  modal__button: {
    marginRight: '8px',
  },
  input: {
    marginTop: '8px',
  },
});

const ReconcileQuantityModal = ({ open, onHide, rowData, onSubmit, loading }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [quantity, setQuantity] = useState('');

  useEffect(() => {
    if (open) OverlayService.show();
    return () => OverlayService.hide();
  }, []);

  const checkQuantity = (quantityUpdate) => {
    setError(
      quantityUpdate.trim() === '' || isNaN(quantityUpdate) || Number(quantityUpdate) < 0 || Number(quantityUpdate) > rowData?.originalQuantity
    );
    setQuantity(quantityUpdate);
  };

  return (
    <Modal onHide={onHide} open={open} closeOnEsc closeOnOutsideClick>
      <div className={classes.form__container}>
        <ModalContent>
          <div data-testid="modal-title" className={classes.modal__title}>
            Reconcile Quantity
          </div>
          <div data-testid="modal-body" className={classes.modal__body} style={{ marginBottom: 0 }}>
            {`Input the quantity you would like to reconcile for transaction ${rowData?.transaction?.id}`}
          </div>
          <div className={classes.input}>
            <TextInput
              onChange={checkQuantity}
              error={error}
              type="number"
              labelText="Input final adjusted quantity"
              value={quantity}
              min={0}
              max={rowData?.originalQuantity}
              required
            />
          </div>
        </ModalContent>
        <div className={classes.footer__container}>
          <div className={classes.modal__submit}>
            <Button
              data-testid="submit-button"
              className={classes.modal__button}
              type="button"
              onClick={() => onSubmit(quantity)}
              disabled={error || !quantity}
              primary
              loading={loading}
            >
              Submit
            </Button>
            <Button data-testid="cancel-button" className={classes.modal__cancel} type="button" onClick={onHide} warning>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ReconcileQuantityModal.propTypes = {
  onHide: PropTypes.func,
  open: PropTypes.bool,
  rowData: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default ReconcileQuantityModal;
