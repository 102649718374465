import React from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import { createUseStyles } from 'react-jss';
import { Theme } from '@spoiler-alert/ui-library';

const useStyles = createUseStyles({
  ticket: {
    textAlign: 'left',
    padding: '6px 0',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: Theme.grey,
  },
  buyer: {
    color: Theme.text,
    textTransform: 'none',
    fontSize: '14px',
  },
});

const InlineOfferListing = ({ offerListing }) => {
  const classes = useStyles();
  return (
    <div className={classes.ticket} data-testid="offer-listing-content">
      <span className={classes.buyer}>{offerListing.buyerSiteName}</span>
      <div>Price/Case: {accounting.formatMoney(offerListing.totalPrice / offerListing.quantity)}</div>
      <div>Quantity: {offerListing.quantity}</div>
    </div>
  );
};

InlineOfferListing.propTypes = {
  offerListing: PropTypes.shape({
    buyerSiteName: PropTypes.string,
    totalPrice: PropTypes.number,
    quantity: PropTypes.number,
  }),
};

export default InlineOfferListing;
