import accounting from 'accounting';
import pluralize from 'pluralize';

const weightWithUnit = (weightValue, user, uppercase) => {
  return `${
    uppercase
      ? `${accounting.formatNumber(weightValue)} ${pluralize(user?.site?.weightUnit?.toUpperCase() || 'LB', weightValue)}`
      : `${accounting.formatNumber(weightValue)} ${pluralize(user?.site?.weightUnit?.toLowerCase() || 'lb', weightValue)}`
  }`;
};

export { weightWithUnit };
