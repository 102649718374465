import React from 'react';
import { StatusBadge } from '@spoiler-alert/ui-library';

const statusMap = new Map([
  ['AVAILABLE', <StatusBadge status="AVAILABLE" key="status" />],
  ['CLAIMED', <StatusBadge status="CLAIMED" key="status" />],
  ['COMPLETED', <StatusBadge status="COMPLETED" key="status" />],
  ['UNVERIFIED', <StatusBadge status="UNVERIFIED" key="status" />],
  ['ARCHIVED', <StatusBadge status="ARCHIVED" key="status" />],
  ['DELETED', <StatusBadge status="DELETED" key="status" />],
]);

export default statusMap;
