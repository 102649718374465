import React from 'react';
import PropTypes from 'prop-types';
import { Theme, LoadingLine } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';

const styles = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  metric: {
    padding: '12px 0px',
    border: `1px solid ${Theme.borderColor}`,
    borderRadius: 2,
    width: '100%',
    '&:not(:last-child)': {
      marginRight: 8,
    },
    '& h1': {
      margin: 0,
    },
  },
  '@container (max-width: 850px)': {
    metric: {
      padding: '12px 0px',
      border: `1px solid ${Theme.borderColor}`,
      borderRadius: 2,
      flex: '0 1 49%',
      width: '100%',
      marginBottom: 8,
      '&:not(:last-child)': {
        marginRight: 0,
      },
      '& h1': {
        margin: 0,
      },
    },
  },
  current: {
    padding: '0px 16px',
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 'bold',
    margin: '8px 0px !important',
  },
  title: {
    padding: '0px 16px',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 'normal',
  },
  projected: {
    padding: '0px 16px',
    fontSize: '14px',
    fontWeight: 'normal',
  },
  lineLoaderTop: {
    position: 'relative',
    top: -13,
    height: 1,
  },
  lineLoaderBottom: {
    position: 'relative',
    top: 13,
    height: 1,
  },
};
const useStyles = createUseStyles(styles);

const TrucklaneMetricCard = ({ title, current, projected, loading }) => {
  const classes = useStyles();
  return (
    <div className={classes.metric} data-testid="metric-card">
      {loading && <LoadingLine loading={loading} position="top" classes={{ loader__container: classes.lineLoaderTop }} />}
      <h1 className={classes.title}>{title}</h1>
      <h1 className={classes.current}>{loading ? '—' : current}</h1>
      <div className={classes.row}>
        <h1 className={classes.projected}>Projected</h1>
        <h1 className={classes.projected}>{loading ? '—' : projected}</h1>
      </div>
      {loading && <LoadingLine loading={loading} position="bottom" classes={{ loader__container: classes.lineLoaderBottom }} />}
    </div>
  );
};

TrucklaneMetricCard.propTypes = {
  title: PropTypes.string,
  current: PropTypes.string,
  projected: PropTypes.string,
  loading: PropTypes.bool,
};

export default TrucklaneMetricCard;
