import React from 'react';
import { createUseStyles } from 'react-jss';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Theme as theme, ColorBadge } from '@spoiler-alert/ui-library';
const useStyles = createUseStyles({
    breadcrumbWrapper: {
        padding: 16,
        fontSize: 16,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&:hover': {
            overflow: 'visible',
            marginRight: '-16%',
            zIndex: 1,
            background: 'white',
        },
    },
    breadcrumbSpan: {
        '&:after': {
            content: '" / "',
        },
        '&:last-child': {
            '&>a': {
                color: theme.textColorPrimary,
                '&:hover': {
                    cursor: 'pointer',
                },
            },
            '&:after': {
                content: '""',
            },
        },
    },
});
function Breadcrumbs({ breadcrumbsStore }) {
    const classes = useStyles();
    const Badge = (bc) => {
        var _a;
        if ((_a = bc.badge) === null || _a === void 0 ? void 0 : _a.tooltip) {
            const TooltipWithIcon = bc.badge.tooltip;
            return (
            // @ts-expect-error Because React-JSS has poor typing, it deletes the `theme` prop. The prop should be renamed or React-JSS should be updated.
            React.createElement(ColorBadge, { theme: Object.assign(Object.assign({}, bc.badge.colors), { marginLeft: 12 }) },
                bc.badge.text,
                React.createElement(TooltipWithIcon, null)));
        }
        return (
        // @ts-expect-error Because React-JSS has poor typing, it deletes the `theme` prop. The prop should be renamed or React-JSS should be updated.
        React.createElement(ColorBadge, { theme: Object.assign(Object.assign({}, bc.badge.colors), { marginLeft: 12 }) }, bc.badge.text));
    };
    return (React.createElement("div", { className: classes.breadcrumbWrapper }, breadcrumbsStore.breadcrumbs.map((bc) => (React.createElement("span", { key: bc.url, className: classes.breadcrumbSpan },
        bc.url ? React.createElement(Link, { to: bc.url }, bc.title) : React.createElement("a", null, bc.title),
        bc.badge && Badge(bc))))));
}
export default inject((store) => store)(observer(Breadcrumbs));
