import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

class FeatureFlag extends Component {
  state = {
    featureName: this.props.featureName,
  };

  render() {
    const { rootStore, featureName, showChildrenWhenEnabled } = this.props;
    const { featureMap } = rootStore;
    const isChildEnabled = !!featureMap.get(featureName) === showChildrenWhenEnabled;
    return <div className={this.props.className}>{isChildEnabled && this.props.children}</div>;
  }
}

FeatureFlag.propTypes = {
  children: PropTypes.node,
  rootStore: PropTypes.object,
  featureName: PropTypes.string,
  showChildrenWhenEnabled: PropTypes.bool,
  className: PropTypes.string,
};

const ConnectedComponent = inject('rootStore')(observer(FeatureFlag));
export default ConnectedComponent;
