import { Theme } from '@spoiler-alert/ui-library';

const archiveInventoryModalStyles = {
  modal__title: {
    fontSize: '24px',
    padding: 0,
    marginTop: 0,
    height: '25px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.42,
    letterSpacing: '-0.5px',
    textAlign: 'left',
    marginBottom: '24px',
  },
  checkStatus_modal__body: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    alignSelf: 'stretch',
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    marginBottom: '24px',
    paddingTop: 12,
  },
  modal__body: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    alignSelf: 'stretch',
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    marginBottom: '24px',
  },
  none_staged_footer__container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
    borderTop: `1px solid ${Theme.modalFooterBorderColor}`,
    alignItems: 'center',
  },
  footer__container: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 24,
    borderTop: `1px solid ${Theme.modalFooterBorderColor}`,
    alignItems: 'center',
  },
  modal__cancel: {
    marginRight: 8,
  },
  modal__submit: {
    display: 'flex',
  },
  input__row: {
    padding: '6px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  form__container: {
    width: '100%',
  },
  input__container: {
    padding: '0 12px',
    flexGrow: 1,
  },
  checkStatusText: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 16,
  },
  infoIcon: {
    fill: Theme.red,
    width: 16,
    height: 16,
    marginTop: '3px',
  },
  infoIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: '16px',
  },
  modalInfo: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 4,
    padding: 12,
    backgroundColor: Theme.red5,
    fontSize: 14,
    marginBottom: 24,
  },
  customMessage: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    padding: 16,
    backgroundColor: Theme.teal5,
    fontSize: 14,
    maxHeight: 200,
  },
  checkboxContainer: {
    marginBottom: 4,
  },
  tableContainer: {
    marginBottom: 24,
  },
};

export default archiveInventoryModalStyles;
