import { gql } from '@apollo/client';

export const DeletePost = gql`
  mutation DeletePost($id: String!) {
    deletePost(postId: $id) {
      _id
      transactedAt
      source
      category
      quantity
      unitOfMeasure
      destination
      description
      audience
      bestByDate
      mustGoByDate
      claimDate
      completedAtDate
      supplierSiteName
      status
      quality
      images
      maxDeliveryRadiusMiles
      supplierId
      recipientId
      isVendorReturn
      unitPrice
      isForSale
      isDonation
      isGiveaway
      supplierSite {
        _id
        siteName
      }
      outletSite {
        _id
        siteName
      }
      itemName
      actions
      location {
        fullAddress
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const VerifyPost = gql`
  mutation VerifyPost($id: String!, $completedAt: Date!, $quantity: Int!) {
    verifyPost(postId: $id, completedAt: $completedAt, quantity: $quantity) {
      _id
      createdAt
      source
      category
      quantity
      unitOfMeasure
      destination
      description
      audience
      bestByDate
      mustGoByDate
      claimDate
      completedAtDate
      supplierSiteName
      status
      quality
      images
      maxDeliveryRadiusMiles
      supplierId
      recipientId
      isVendorReturn
      unitPrice
      isForSale
      isDonation
      isGiveaway
      supplierSite {
        _id
        siteName
      }
      outletSite {
        _id
        siteName
      }
      itemName
      actions
      location {
        fullAddress
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const VerifyPosts = gql`
  mutation VerifyPosts($ids: [String]) {
    batchVerifyPosts(postIds: $ids) {
      _id
      createdAt
      source
      category
      quantity
      unitOfMeasure
      destination
      description
      audience
      bestByDate
      mustGoByDate
      claimDate
      completedAtDate
      supplierSiteName
      status
      quality
      images
      maxDeliveryRadiusMiles
      supplierId
      recipientId
      isVendorReturn
      unitPrice
      isForSale
      isDonation
      isGiveaway
      supplierSite {
        _id
        siteName
      }
      outletSite {
        _id
        siteName
      }
      itemName
      actions
      location {
        fullAddress
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const RejectPosts = gql`
  mutation RejectPosts($ids: [String]) {
    batchRejectPosts(postIds: $ids) {
      _id
      createdAt
      source
      category
      quantity
      unitOfMeasure
      destination
      description
      audience
      bestByDate
      mustGoByDate
      claimDate
      completedAtDate
      supplierSiteName
      status
      quality
      images
      maxDeliveryRadiusMiles
      supplierId
      recipientId
      isVendorReturn
      unitPrice
      isForSale
      isDonation
      isGiveaway
      supplierSite {
        _id
        siteName
      }
      outletSite {
        _id
        siteName
      }
      itemName
      actions
      location {
        fullAddress
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const UnclaimPost = gql`
  mutation UnclaimPost($id: String!, $reasonCode: Int!, $reasonDetails: String) {
    unclaimPost(postId: $id, reasonCode: $reasonCode, reasonDetails: $reasonDetails) {
      _id
      createdAt
      source
      category
      quantity
      unitOfMeasure
      destination
      description
      audience
      bestByDate
      mustGoByDate
      claimDate
      completedAtDate
      supplierSiteName
      status
      quality
      images
      maxDeliveryRadiusMiles
      supplierId
      recipientId
      isVendorReturn
      unitPrice
      isForSale
      isDonation
      isGiveaway
      supplierSite {
        _id
        siteName
      }
      outletSite {
        _id
        siteName
      }
      itemName
      actions
      location {
        fullAddress
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const createInvite = gql`
  mutation createInvite($firstName: String!, $lastName: String!, $email: String!, $title: String, $privClass: String!, $siteId: String!) {
    createInvite(firstName: $firstName, lastName: $lastName, email: $email, title: $title, privClass: $privClass, siteId: $siteId) {
      _id
      firstName
      lastName
      email
      siteName
      expired
      errored
      error
      privClass {
        name
        displayName
      }
    }
  }
`;

export const createOrUpdateTransaction = gql`
  mutation createOrUpdateTransaction(
    $postId: String
    $itemId: String!
    $quantity: safeFloat
    $quality: String!
    $unitOfMeasure: String!
    $isVendorReturn: Boolean
    $destination: String!
    $outlet: String
    $outletSiteId: String
    $transactionDate: Date!
    $transactionPrice: safeFloat
  ) {
    createOrUpdateTransaction(
      postId: $postId
      itemId: $itemId
      quantity: $quantity
      quality: $quality
      unitOfMeasure: $unitOfMeasure
      isVendorReturn: $isVendorReturn
      destination: $destination
      outlet: $outlet
      outletSiteId: $outletSiteId
      transactionDate: $transactionDate
      transactionPrice: $transactionPrice
    ) {
      errors
    }
  }
`;

export const EditUser = gql`
  mutation editUser($id: String!, $firstName: String!, $lastName: String!, $title: String, $email: String!, $siteId: String!, $privClass: String!) {
    editUser(id: $id, firstName: $firstName, lastName: $lastName, title: $title, email: $email, siteId: $siteId, privClass: $privClass) {
      firstName
      lastName
      title
      email
      siteName
      privClass {
        name
        displayName
      }
    }
  }
`;

export const BulkRevokeInvite = gql`
  mutation bulkRevokeInvite($inviteIds: [String]!) {
    bulkRevokeInvite(inviteIds: $inviteIds) {
      _id
    }
  }
`;

export const BulkDeleteUser = gql`
  mutation bulkDeleteUser($userIds: [String]!) {
    bulkDeleteUser(userIds: $userIds) {
      _id
    }
  }
`;

export const BulkChangeOrganization = gql`
  mutation bulkChangeOrganization($userIds: [String]!, $siteId: String!) {
    bulkChangeOrganization(userIds: $userIds, siteId: $siteId) {
      _id
      siteId
    }
  }
`;

export const BulkResendInvite = gql`
  mutation bulkResendInvite($inviteIds: [String]!) {
    bulkResendInvite(inviteIds: $inviteIds) {
      _id
    }
  }
`;

export const toggleImpersonateSite = gql`
  mutation toggleImpersonateSite($siteIdToImpersonate: String) {
    toggleImpersonateSite(siteIdToImpersonate: $siteIdToImpersonate) {
      _id
    }
  }
`;

export const createStagedListingsFromInventory = gql`
  mutation createStagedListingsFromInventory(
    $inventoriesToStage: [StagedListingInputType]
    $allSelected: Boolean
    $inventoryFilters: InventoryFilterInput
    $destinationType: String
  ) {
    createStagedListingsFromInventory(
      inventoriesToStage: $inventoriesToStage
      allSelected: $allSelected
      inventoryFilters: $inventoryFilters
      destinationType: $destinationType
    ) {
      inventories {
        _id
        status {
          _id
          staged
          listed
          received
          awarded
          accepted
        }
        distributionLists {
          _id
          listId
          listedAt
          published
          color
        }
      }
      stagedListings {
        _id
        anchor
        pricingStrategy
        percentageModifier
        availableQuantity
        suggestedUnitPrice
        suggestedTotalPrice
      }
      errors {
        code
        message
      }
      progress {
        _id
        staged
      }
    }
  }
`;

export const acceptOffer = gql`
  mutation acceptOffer($buyerSiteIds: [String!], $acceptAll: Boolean) {
    acceptOffer(buyerSiteIds: $buyerSiteIds, acceptAll: $acceptAll) {
      offerId
      offerListingIds
      errors
    }
  }
`;

export const UpdateTaxSettings = gql`
  mutation updateTaxSettings($taxId: String!, $purpose: String!, $name: String!, $title: String!, $signature: String!) {
    updateTaxSettings(taxId: $taxId, purpose: $purpose, name: $name, title: $title, signature: $signature) {
      _id
    }
  }
`;

export const UpdateSiteInfo = gql`
  mutation updateSiteInfo($phoneNumber: String, $businessWebsite: String, $industries: [String]) {
    updateSiteInfo(phoneNumber: $phoneNumber, businessWebsite: $businessWebsite, industries: $industries) {
      _id
    }
  }
`;

export const UpdateNotificationsSettings = gql`
  mutation updateNotificationsSettings(
    $pickupPreferences: LocationRadiusInput
    $dropoffPreferences: LocationRadiusInput
    $foodHandlingPreferences: [String]
    $foodQualityPreferences: [String]
  ) {
    updateNotificationsSettings(
      pickupPreferences: $pickupPreferences
      dropoffPreferences: $dropoffPreferences
      foodHandlingPreferences: $foodHandlingPreferences
      foodQualityPreferences: $foodQualityPreferences
    ) {
      _id
    }
  }
`;

const offerListingFieldsFragment = gql`
  fragment offerListingFields on OfferListingType {
    _id
    quantity
    totalPrice
    originalQuantity
    originalTotalPrice
    status
    negotiationListingId
    negotiationStatus
    transactionLocationId
    logisticsTerm
  }
`;

const inventoryFieldsFragment = gql`
  fragment inventoryFields on Inventory {
    _id
    availableQuantity
    onHandQuantity
    unitGrossWeight
    tags
    status {
      _id
      staged
      listed
      received
      awarded
      accepted
    }
  }
`;

export const AwardOfferListing = gql`
  mutation awardOfferListing($id: String!, $quantity: Int!, $costPerCase: String!) {
    awardOfferListing(id: $id, quantity: $quantity, costPerCase: $costPerCase) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors {
        code
        message
      }
      progress {
        _id
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const AwardOfferListings = gql`
  mutation awardOfferListings($offerListingIds: [String], $tags: [String]) {
    awardOfferListings(offerListingIds: $offerListingIds, tags: $tags) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors {
        code
        message
      }
      progress {
        _id
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const AwardSuggestedOfferListings = gql`
  mutation awardSuggestedOfferListings($offerListingIds: [String]) {
    awardSuggestedOfferListings(offerListingIds: $offerListingIds) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors {
        code
        message
      }
      progress {
        _id
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const AwardSuggestedOfferListingsWithUIValidation = gql`
  mutation awardSuggestedOfferListingsWithUIValidation(
    $offerListingIds: [String]
    $trucklaneGroupChannelId: String
    $trucklaneGroupChannelUpdatedAt: String
  ) {
    awardSuggestedOfferListingsWithUIValidation(
      offerListingIds: $offerListingIds
      trucklaneGroupChannelId: $trucklaneGroupChannelId
      trucklaneGroupChannelUpdatedAt: $trucklaneGroupChannelUpdatedAt
    ) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors {
        code
        message
      }
      progress {
        _id
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const AwardSuggestedOffersByInventory = gql`
  mutation awardSuggestedOffersByInventory($inventoryIds: [String], $trucklaneGroupChannelId: String, $trucklaneGroupChannelUpdatedAt: String) {
    awardSuggestedOffersByInventory(
      inventoryIds: $inventoryIds
      trucklaneGroupChannelId: $trucklaneGroupChannelId
      trucklaneGroupChannelUpdatedAt: $trucklaneGroupChannelUpdatedAt
    ) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors {
        code
        message
      }
      progress {
        _id
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const UnawardOfferListing = gql`
  mutation unawardOfferListing($id: String!) {
    unawardOfferListing(id: $id) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors
      progress {
        _id
        inventory
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const UnawardOfferListings = gql`
  mutation unawardOfferListings($offerListingIds: [String]) {
    unawardOfferListings(offerListingIds: $offerListingIds) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors
      progress {
        _id
        inventory
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const UnawardAllOfferListings = gql`
  mutation unawardAllOfferListings($siteId: String!) {
    unawardAllOfferListings(siteId: $siteId) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors
      progress {
        _id
        inventory
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const UnawardOfferListingsByTruckLane = gql`
  mutation unawardOfferListingsByTruckLane(
    $sellerLocationId: String!
    $buyerSiteId: String!
    $transactionLocationId: String!
    $truckType: String!
    $logisticsTerm: String!
  ) {
    unawardOfferListingsByTruckLane(
      sellerLocationId: $sellerLocationId
      buyerSiteId: $buyerSiteId
      transactionLocationId: $transactionLocationId
      truckType: $truckType
      logisticsTerm: $logisticsTerm
    ) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors
      progress {
        _id
        inventory
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const UnawardOfferListingsByBuyerSiteId = gql`
  mutation unawardOfferListingsByBuyerSiteId($siteId: String!) {
    unawardOfferListingsByBuyerSiteId(siteId: $siteId) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors
      progress {
        _id
        inventory
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const UnawardOfferListingsBySellerLocationId = gql`
  mutation unawardOfferListingsBySellerLocationId($buyerSiteId: String!, $sellerLocationId: String!) {
    unawardOfferListingsBySellerLocationId(buyerSiteId: $buyerSiteId, sellerLocationId: $sellerLocationId) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors
      progress {
        _id
        inventory
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const UnawardAndNegotiateOfferListing = gql`
  mutation unawardOfferListing($id: String!, $counterOfferPrice: safeFloat, $negotiationOrigin: String, $counterOfferQuantity: Int) {
    unawardAndNegotiateOfferListing(
      id: $id
      counterOfferPrice: $counterOfferPrice
      negotiationOrigin: $negotiationOrigin
      counterOfferQuantity: $counterOfferQuantity
    ) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors
      progress {
        _id
        inventory
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const IgnoreOfferListings = gql`
  mutation ignoreOfferListingsById($offerListingIds: [String]) {
    ignoreOfferListingsById(offerListingIds: $offerListingIds) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors {
        code
        message
      }
      progress {
        _id
        inventory
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const RestoreOfferListings = gql`
  mutation restoreOfferListingsById($offerListingIds: [String]) {
    restoreOfferListingsById(offerListingIds: $offerListingIds) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors {
        code
        message
      }
      progress {
        _id
        inventory
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const CreateSite = gql`
  mutation createSite($site: SiteParamsInput) {
    createSite(site: $site) {
      result {
        _id
        siteName
      }
      errors
    }
  }
`;

export const PublishStagedListings = gql`
  mutation publishStagedListings($distributionChannelId: String) {
    publishStagedListings(distributionChannelId: $distributionChannelId) {
      listingCount
      errors
      progress {
        _id
        staged
        listed
      }
    }
  }
`;

export const ClearStagedListings = gql`
  mutation clearStagedListings($distributionChannelId: String, $stagedListingIds: [String], $catalogId: String) {
    clearStagedListings(distributionChannelId: $distributionChannelId, stagedListingIds: $stagedListingIds, catalogId: $catalogId) {
      listingCount
      inventories {
        _id
        status {
          staged
          listed
          received
          awarded
          accepted
        }
      }
      errors {
        code
        message
      }
      progress {
        _id
        staged
        listed
      }
    }
  }
`;

export const RelistPublishedListings = gql`
  mutation relistPublishedListings($catalogId: String) {
    RelistCatalog(catalogId: $catalogId) {
      unavailableInventoryCount
      reducedInventoryCount
      errors {
        code
        message
      }
    }
  }
`;

export const AggregateInventories = gql`
  mutation aggregateInventories($inventoryFilters: InventoryFilterInput) {
    aggregateInventories(inventoryFilters: $inventoryFilters) {
      inventories {
        _id
      }
      originalInventoryCount
      aggregatedInventoryCount
      progress {
        _id
        inventory
      }
    }
  }
`;

export const SmartAwardOfferListings = gql`
  mutation smartAwardOfferListings($selections: [OfferSelectionType], $smartAwardType: String, $sellerSiteIds: [String]) {
    smartAwardOfferListings(selections: $selections, smartAwardType: $smartAwardType, sellerSiteIds: $sellerSiteIds) {
      offerListings {
        ...offerListingFields
      }
      inventories {
        ...inventoryFields
      }
      errors {
        code
        message
      }
      progress {
        _id
        inventory
        awarded
      }
    }
  }
  ${offerListingFieldsFragment}
  ${inventoryFieldsFragment}
`;

export const SetSuggestedPriceFromAnchor = gql`
  mutation setSuggestedPriceFromAnchor(
    $stagedListingIds: [String]
    $inventoryFilters: InventoryFilterInput
    $pricingStrategy: String
    $anchorValue: safeFloat
    $allSelected: Boolean
    $limit: Int
  ) {
    setSuggestedPriceFromAnchor(
      stagedListingIds: $stagedListingIds
      inventoryFilters: $inventoryFilters
      pricingStrategy: $pricingStrategy
      anchorValue: $anchorValue
      allSelected: $allSelected
      limit: $limit
    ) {
      stagedListings {
        _id
        pricingStrategy
        percentageModifier
        suggestedUnitPrice
        anchor
      }
      stagedListingCount
      errors {
        code
        message
      }
    }
  }
`;

export const createNegotiationStagedListings = gql`
  mutation createNegotiationStagedListings($negotiationOrigin: String, $counterOffers: [counterOffer]) {
    createNegotiationStagedListings(negotiationOrigin: $negotiationOrigin, counterOffers: $counterOffers) {
      negotiationStagedListings {
        _id
        suggestedQuantity
        suggestedUnitPrice
      }
      offerListings {
        _id
        negotiationListingId
        negotiationStatus
      }
      errors {
        code
        message
      }
    }
  }
`;

export const clearNegotiationStagedListings = gql`
  mutation clearNegotiationStagedListings($buyerSiteIds: [String]) {
    clearNegotiationStagedListings(buyerSiteIds: $buyerSiteIds) {
      negotiationStagedListingsCount
      offerListings {
        _id
        negotiationListingId
        negotiationStatus
      }
      errors
    }
  }
`;

export const publishNegotiationListings = gql`
  mutation publishNegotiationListings($buyerSiteIds: [String], $customMessage: String) {
    publishNegotiationListings(buyerSiteIds: $buyerSiteIds, customMessage: $customMessage) {
      negotiationStagedListingsCount
      errors
      offerListings {
        ...offerListingFields
      }
    }
  }
  ${offerListingFieldsFragment}
`;

export const resetNegotiationStagedListingsByIds = gql`
  mutation resetNegotiationStagedListingsByIds($negotiationStagedListingIds: [String]) {
    resetNegotiationStagedListingsByIds(negotiationStagedListingIds: $negotiationStagedListingIds) {
      negotiationStagedListingsCount
      offerListings {
        _id
        negotiationListingId
        negotiationStatus
      }
      errors
    }
  }
`;

export const SetSuggestedNegotiationPriceFromAnchor = gql`
  mutation SetSuggestedNegotiationPriceFromAnchor(
    $negotiationStagedListingIds: [String]
    $allSelected: Boolean
    $inventoryFilters: InventoryFilterInput
    $pricingStrategy: String
    $anchorValue: safeFloat
  ) {
    SetSuggestedNegotiationPriceFromAnchor(
      negotiationStagedListingIds: $negotiationStagedListingIds
      allSelected: $allSelected
      inventoryFilters: $inventoryFilters
      pricingStrategy: $pricingStrategy
      anchorValue: $anchorValue
    ) {
      negotiationStagedListings {
        _id
      }
      negotiationStagedListingsCount
      errors {
        code
        message
      }
    }
  }
`;

export const SetReservePrice = gql`
  mutation SetReservePrice($reserveAnchor: String, $reserveNumber: safeFloat) {
    SetReservePrice(reserveAnchor: $reserveAnchor, reserveNumber: $reserveNumber) {
      errors {
        code
        message
      }
      modifiedCount
      reserveAnchor
      reserveNumber
    }
  }
`;

export const archiveInventories = gql`
  mutation archiveInventories($inventoryIds: [String], $allSelected: Boolean, $inventoryFilters: InventoryFilterInput, $customMessage: String) {
    archiveInventories(inventoryIds: $inventoryIds, allSelected: $allSelected, inventoryFilters: $inventoryFilters, customMessage: $customMessage) {
      errors {
        code
        message
      }
      modifiedCount
      expectedModifiedCount
    }
  }
`;

export const unarchiveInventories = gql`
  mutation unarchiveInventories($inventoryIds: [String], $allSelected: Boolean, $inventoryFilters: InventoryFilterInput) {
    unarchiveInventories(inventoryIds: $inventoryIds, allSelected: $allSelected, inventoryFilters: $inventoryFilters) {
      errors {
        code
        message
      }
      modifiedCount
    }
  }
`;

export const RemoveTransactions = gql`
  mutation removeTransactions($offerListingIds: [String], $allSelected: Boolean, $filter: InventoryFilterInput) {
    removeTransactions(offerListingIds: $offerListingIds, allSelected: $allSelected, filter: $filter) {
      errors {
        code
        message
      }
      modifiedCount
    }
  }
`;

export const ReverseTransactions = gql`
  mutation reverseTransactions($offerListingIds: [String], $allSelected: Boolean, $filter: InventoryFilterInput) {
    reverseTransactions(offerListingIds: $offerListingIds, allSelected: $allSelected, filter: $filter) {
      errors {
        code
        message
      }
      modifiedCount
    }
  }
`;

export const ReconcileFinalQuantities = gql`
  mutation reconcileFinalQuantities($offerListingIds: [String], $quantityUpdate: Int!) {
    reconcileFinalQuantities(offerListingIds: $offerListingIds, quantityUpdate: $quantityUpdate) {
      errors {
        code
        message
      }
      modifiedCount
    }
  }
`;

export const UpdateHandlingMappings = gql`
  mutation updateHandlingMapping($handlings: [UpdateHandlingMappingInput]) {
    updateHandlingMapping(handlings: $handlings) {
      errors {
        code
        message
      }
      modifiedCount
      inventories {
        _id
        truckType
      }
      offerListings {
        _id
        truckType
      }
      handlings {
        _id
        truckType
      }
      site {
        _id
        hasUnmappedHandlingValues
      }
    }
  }
`;

export const UpdateTrucklaneMinimums = gql`
  mutation updateTrucklaneMinimums($minimums: TrucklaneMinimumInput) {
    updateTrucklaneMinimums(minimums: $minimums) {
      minimums {
        _id
        mode
        delivery {
          dry
          refrigerated
          frozen
        }
        pickup {
          dry
          refrigerated
          frozen
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

export const AddBSRContact = gql`
  mutation addBSRContact($bsrContact: ManageBSRContactInput) {
    addBSRContact(bsrContact: $bsrContact) {
      errors {
        code
        message
      }
    }
  }
`;

export const EditBSRContact = gql`
  mutation editBSRContact($bsrContact: ManageBSRContactInput) {
    editBSRContact(bsrContact: $bsrContact) {
      errors {
        code
        message
      }
    }
  }
`;

export const DeleteBSRContact = gql`
  mutation deleteBSRContact($bsrContact: ManageBSRContactInput) {
    deleteBSRContact(bsrContact: $bsrContact) {
      errors {
        code
        message
      }
    }
  }
`;

export const updateTruckTypeCosts = gql`
  mutation updateTrucktypeCosts($truckTypeCosts: TruckTypeCostsInputType) {
    updateTrucktypeCosts(truckTypeCosts: $truckTypeCosts) {
      site {
        _id
        truckTypeCosts {
          dry {
            fixedCost
            costPerMile
          }
          refrigerated {
            fixedCost
            costPerMile
          }
          frozen {
            fixedCost
            costPerMile
          }
        }
      }
      offerListings {
        _id
        trucklaneCost
      }
      errors {
        code
        message
      }
    }
  }
`;

export const CreateDistributionList = gql`
  mutation createDistributionList($distributionList: DistributionListInputType, $bsrs: [BuyerSellerRelationshipInputType]) {
    createDistributionList(distributionList: $distributionList, bsrs: $bsrs) {
      distributionList {
        _id
        name
        archived
        buyerSellerRelationshipIds
        offerEmailTemplate
        destinationType
      }
      errors {
        code
        message
      }
    }
  }
`;

export const UpdateDistributionList = gql`
  mutation updateDistributionList($distributionList: DistributionListInputType, $bsrs: [BuyerSellerRelationshipInputType]) {
    updateDistributionList(distributionList: $distributionList, bsrs: $bsrs) {
      distributionList {
        _id
        name
        archived
        buyerSellerRelationshipIds
        offerEmailTemplate
        destinationType
      }
      errors {
        code
        message
      }
    }
  }
`;

export const ArchiveDistributionList = gql`
  mutation archiveDistributionList($distributionListId: String!) {
    archiveDistributionList(distributionListId: $distributionListId) {
      modifiedCount
      errors {
        code
        message
      }
    }
  }
`;

export const ForceRecalculation = gql`
  mutation forceRecalculation {
    forceRecalculation {
      errors {
        code
        message
      }
    }
  }
`;

export const AcceptShiftedOfferListings = gql`
  mutation acceptShiftedOfferListings($shiftedOfferListings: [shiftedOfferListingInputType], $buyerSiteName: String) {
    acceptShiftedOfferListings(shiftedOfferListings: $shiftedOfferListings, buyerSiteName: $buyerSiteName) {
      offerShifts {
        _id
        status
        inventoryStatus
      }
      errors {
        code
        message
      }
    }
  }
`;
