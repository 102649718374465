import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { TitleService } from '../services';
import { Breadcrumbs } from '../store';

const styles = {
  dashboard: {
    minHeight: 'calc(100vh - 68px)',
    width: '100%',
  },
};

function ReportsDashboard(props) {
  const { classes, user } = props;
  TitleService.setTitles('Reports', 'Reports');
  Breadcrumbs.set([
    {
      url: '/',
      title: 'Reports',
    },
  ]);
  return <iframe className={classes.dashboard} src={user.programPerformanceDashboardSsoUrl} frameBorder="0"></iframe>;
}

ReportsDashboard.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
};

const StyledComponent = injectSheet(styles)(ReportsDashboard);
export default StyledComponent;
