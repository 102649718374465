import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TextInput, Button, Checkbox, Loading, AlertService } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { InviteService, LoginService, TitleService } from '../../services';
import AppSettings from '../../app-settings';
import client from '../../apollo/client';
import routePaths from '../../route-paths';

const styles = {
  login__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: '1170px',
    margin: '0 auto',
  },
  login__title: {
    fontSize: '1.3rem',
    marginBottom: '40px',
    textAlign: 'center',
  },
  form__row: {
    width: '360px',
    marginBottom: '15px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  form__container: {
    marginBottom: '2.8rem',
  },
  form__button: {
    width: '100%',
    textTransform: 'uppercase',
  },
  message__container: {
    width: '360px',
    borer: '1px solid #d6e9c6',
    backgroundColor: '#dff0d8',
    color: '#3c763d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    height: '36px',
    marginBottom: '15px',
  },
  'message__container--error': {
    borer: '1px solid #ebccd1',
    backgroundColor: '#f2dede',
    color: '#a94442',
    textAlign: 'center',
  },
  'form__input--left': {
    marginRight: '5px',
  },
  'form__input--right': {
    marginLeft: '5px',
  },
  login__link: {
    color: '#5d9bd1',
    cursor: 'pointer',
  },
  loading__container: {
    maxHeight: '76vh',
  },
};

@injectSheet(styles)
export default class RegisterInvitee extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('Register Invitee');
    this.state = {
      errored: false,
      expired: false,
      notFound: false,
      exists: false,
      phone: '',
      password: '',
      confirmPassword: '',
      agreeToTerms: false,
      messageType: 'none',
      site: {
        siteName: '',
        _id: '',
      },
      invite: {
        email: '',
        first: '',
        last: '',
        _id: '',
      },

      loading: true,
    };

    if (this.isAuthenticated) return this.props.history.push(routePaths.home);
  }

  marketingWebsiteTermsUrl = AppSettings.MARKETING_TERMS_URL;

  marketingWebsitePrivacyUrl = AppSettings.MARKETING_PRIVACY_URL;

  get isAuthenticated() {
    return !!localStorage.getItem('token');
  }

  async componentDidMount() {
    try {
      const response = await InviteService.getInvite(this.props.match.params.token);
      const invite = await response.json();
      if (!response.ok) throw Error(invite.message);
      this.setState({
        invite: {
          email: invite.email,
          first: invite.first,
          last: invite.last,
          _id: invite.id,
          title: invite.title,
        },
        site: {
          siteName: invite.siteName,
          _id: invite.siteId,
        },
        loading: false,
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  handleError = (err) => {
    this.setState({
      loading: false,
      errored: true,
      expired: err.message === 'Invitation is expired',
      notFound: err.message === 'Invitation is not found',
      exists: err.message === 'User with that email address already exists',
    });
  };

  updateForm(key, newValue) {
    let value = newValue;

    if (key === 'phone' && value.length >= 10) {
      value = value.replace(/[^0-9]/g, '');
      value = `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6, 10)}`;
    }

    this.setState({
      [key]: value,
    });
  }

  updateInvite(key, value) {
    const { invite } = this.state;
    this.setState({
      invite: {
        ...invite,
        [key]: value,
      },
    });
  }

  handleAgreeChecked = (data) => {
    this.setState({
      agreeToTerms: data.checked,
    });
  };

  validatePassword() {
    const { password, confirmPassword } = this.state;
    const valid = password.trim().length >= 6 && password === confirmPassword;
    if (!valid) {
      this.setState({
        messageType: 'error',
        message: 'Your passwords do not match.',
      });
    }
    return valid;
  }

  validateTerms() {
    const { agreeToTerms } = this.state;
    if (!agreeToTerms) {
      this.setState({
        messageType: 'error',
        message: 'Please accept our terms and Privacy Policy.',
      });
    }
    return agreeToTerms;
  }

  validateOnSubmit = () => this.validatePassword() && this.validateTerms();

  refetch = async () => {
    await client.resetStore();
    if (this.isAuthenticated) return this.props.history.push(routePaths.home);
    return undefined;
  };

  createUserAndLogin = (profile) => {
    const { password, invite } = this.state;
    InviteService.acceptInvite(invite._id, password, profile)
      .then(() => {
        this.setState({
          message: 'Account Created! Now Logging In...',
          messageType: 'success',
        });
        LoginService.login(profile.email, password)
          .then(this.refetch)
          .catch(() => AlertService.alert({ type: 'warning', message: <span>We had a problem logging in.</span>, autoDismiss: true }));
      })
      .catch(() => {
        this.setState({
          messageType: 'error',
          message: 'We had a problem creating the account.',
          saving: false,
        });
      });
  };

  submitNewInvitee = (event) => {
    event.preventDefault();
    if (!this.validateOnSubmit()) return;

    const { phone, invite } = this.state;
    this.setState({ saving: true });

    const profile = {
      firstName: invite.first,
      lastName: invite.last,
      email: invite.email,
      title: invite.title,
      cellNumber: phone,
      notifications: {
        toggle_sms: false,
        toggle_email: true,
        toggle_inapp: true,
      },
    };
    this.createUserAndLogin(profile);
  };

  render() {
    const { classes } = this.props;
    const {
      expired,
      notFound,
      messageType,
      phone,
      message,
      loading,
      saving,
      invite,
      password,
      site,
      confirmPassword,
      agreeToTerms,
      exists,
      errored,
    } = this.state;
    const messageClassName = `${classes.message__container} ${messageType === 'error' ? classes['message__container--error'] : ''}`;

    return (
      <div className={classes.loading__container}>
        <Loading loading={loading}>
          <div>
            {!errored && (
              <div className={classes.login__container} data-element="register-invitee">
                <h4 className={classes.login__title}>
                  Welcome to Spoiler Alert! You&apos;re invited to join the
                  <b> {site.siteName}</b> team on Spoiler Alert.
                  <br />
                  <br />
                  Fill in the details below to create your account for <b>{invite.email}</b>.
                </h4>
                {message && (
                  <div className={messageClassName} data-element={`message-block-${messageType}`}>
                    {message}
                  </div>
                )}
                <form onSubmit={this.submitNewInvitee} className={classes.form__container}>
                  <div className={classes.form__row} data-element="name">
                    <TextInput
                      id="first-name"
                      className={classes['form__input--left']}
                      onChange={this.updateInvite.bind(this, 'first')}
                      required
                      type="text"
                      labelText="First Name"
                      value={invite.first}
                      tabIndex={1}
                    />
                    <TextInput
                      id="last-name"
                      className={classes['form__input--right']}
                      onChange={this.updateInvite.bind(this, 'last')}
                      required
                      type="text"
                      labelText="Last Name"
                      value={invite.last}
                      tabIndex={2}
                    />
                  </div>

                  <div className={classes.form__row} data-element="phone">
                    <TextInput
                      id="phone"
                      onChange={this.updateForm.bind(this, 'phone')}
                      pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
                      maxLength={14}
                      title="Phone number must be in format (123) 456-7890"
                      type="phone"
                      labelText="Phone"
                      value={phone}
                      tabIndex={3}
                    />
                  </div>

                  <div className={classes.form__row} data-element="password">
                    <TextInput
                      id="password"
                      onChange={this.updateForm.bind(this, 'password')}
                      required
                      type="password"
                      labelText="New Password"
                      value={password}
                      pattern=".{6,}"
                      title="Your Password must be at least 6 characters"
                      tabIndex={4}
                    />
                  </div>

                  <div className={classes.form__row} data-element="confirm-password">
                    <TextInput
                      id="confirm-password"
                      onChange={this.updateForm.bind(this, 'confirmPassword')}
                      required
                      type="password"
                      labelText="Confirm New Password"
                      value={confirmPassword}
                      pattern=".{6,}"
                      title="Your Password must be at least 6 characters"
                      tabIndex={5}
                    />
                  </div>

                  <div className={classes.form__row} data-element="agree-to-terms">
                    <Checkbox value="agreeToTerms" id="agree-to-terms" onChecked={this.handleAgreeChecked} checked={agreeToTerms} />
                    <span style={{ marginLeft: '10px' }}>
                      I agree to Spoiler Alert
                      <a target="_blank" rel="noopener noreferrer" className={classes.login__link} href={this.marketingWebsiteTermsUrl}>
                        {' '}
                        Terms
                      </a>{' '}
                      and
                      <a target="_blank" rel="noopener noreferrer" className={classes.login__link} href={this.marketingWebsitePrivacyUrl}>
                        {' '}
                        Privacy Policy
                      </a>
                      *
                    </span>
                  </div>

                  <div className={classes.form__row}>
                    <Button className={classes.form__button} id="create-account" type="submit" loading={saving} loadingText="Loading...">
                      Create my Spoiler Alert account
                    </Button>
                  </div>
                </form>
              </div>
            )}
            {expired && <h3>We&apos;re sorry, but your invitation is expired.</h3>}
            {notFound && <h3>We&apos;re sorry, but the invitation was not found.</h3>}
            {exists && (
              <h3>
                The invitation was already accepted. Try <Link to={routePaths.signIn}>logging in</Link> instead.
              </h3>
            )}
          </div>
        </Loading>
      </div>
    );
  }
}

RegisterInvitee.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};
