import { TrucklaneSortOptions } from './trucklane-sort-options.enum';
import { offerlistingStatuses, truckTypeEnum } from '../../enums';

const buildSortOptions = (suggested) => {
  return suggested
    ? [
        {
          title: TrucklaneSortOptions.HIGHEST_SUGGESTED_NET_REV,
          value: 'HIGHEST_SUGGESTED_NET_REV',
        },
        {
          title: TrucklaneSortOptions.LOWEST_SUGGESTED_NET_REV,
          value: 'LOWEST_SUGGESTED_NET_REV',
        },
        {
          title: TrucklaneSortOptions.HIGHEST_SUGGESTED_REV,
          value: 'HIGHEST_SUGGESTED_REV',
        },
        {
          title: TrucklaneSortOptions.LOWEST_SUGGESTED_REV,
          value: 'LOWEST_SUGGESTED_REV',
        },
        {
          title: TrucklaneSortOptions.HIGHEST_SUGGESTED_WEIGHT,
          value: 'HIGHEST_SUGGESTED_WEIGHT',
        },
        {
          title: TrucklaneSortOptions.LOWEST_SUGGESTED_WEIGHT,
          value: 'LOWEST_SUGGESTED_WEIGHT',
        },
      ]
    : [
        {
          title: TrucklaneSortOptions.HIGHEST_NET_REV,
          value: 'HIGHEST_NET_REV',
        },
        {
          title: TrucklaneSortOptions.LOWEST_NET_REV,
          value: 'LOWEST_NET_REV',
        },
        {
          title: TrucklaneSortOptions.HIGHEST_REV,
          value: 'HIGHEST_REV',
        },
        {
          title: TrucklaneSortOptions.LOWEST_REV,
          value: 'LOWEST_REV',
        },
        {
          title: TrucklaneSortOptions.HIGHEST_WEIGHT,
          value: 'HIGHEST_WEIGHT',
        },
        {
          title: TrucklaneSortOptions.LOWEST_WEIGHT,
          value: 'LOWEST_WEIGHT',
        },
      ];
};

const sortRevenue = (trucklaneData, netMode = false) => {
  return trucklaneData
    .map((trucklane) => {
      const calculatedData = {
        trucklane,
        value: trucklane.offers.reduce((acc, offer) => {
          return [offerlistingStatuses.ACCEPTED, offerlistingStatuses.AWARDED].includes(offer.status) ? acc + offer.totalPrice : acc;
        }, 0),
      };
      if (netMode) calculatedData.value -= trucklane.offers[0].trucklaneCost;
      return calculatedData;
    })
    .sort((a, b) => b.value - a.value);
};

const sortSuggestedRevenue = (trucklaneData, netMode = false) => {
  return trucklaneData
    .map((trucklane) => {
      const calculatedData = {
        trucklane,
        value: trucklane.offers.reduce((acc, offer) => {
          if (offer.suggestions.award.suggested && ![offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status))
            return offer.suggestions.award.quantity * (offer.totalPrice / offer.quantity) + acc;
          return acc;
        }, 0),
      };
      if (netMode) calculatedData.value -= trucklane.offers[0].trucklaneCost;
      return calculatedData;
    })
    .sort((a, b) => b.value - a.value);
};

const sortWeight = (trucklaneData, suggested) => {
  return trucklaneData
    .map((trucklane) => {
      const calculatedData = {
        trucklane,
        value: trucklane.offers.reduce((acc, offer) => {
          if (suggested) {
            return offer.suggestions.award.suggested &&
              ![offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED, offerlistingStatuses.IGNORED].includes(offer.status)
              ? offer.suggestions.award.quantity * offer.inventory.unitGrossWeight + acc
              : acc;
          }
          return [offerlistingStatuses.ACCEPTED, offerlistingStatuses.AWARDED].includes(offer.status)
            ? offer.quantity * offer.inventory.unitGrossWeight + acc
            : acc;
        }, 0),
      };
      return calculatedData;
    })
    .sort((a, b) => b.value - a.value);
};

const includeLocationFilter = (offer, filters) => {
  return !filters.locations?.length || filters.locations.some((location) => location.value === offer.inventory.siteId);
};

const includeCustomerFilter = (offer, filters) => {
  return !filters.customers?.length || filters.customers.some((customer) => customer.value === offer.buyerSiteId);
};

const includeLogisticsFilter = (offer, filters) => {
  return !filters.logistics?.length || filters.logistics.some((logistic) => logistic === offer.logisticsTerm);
};

const includeTruckTypeFilter = (offer, filters) => {
  return !filters.truckType?.length || filters.truckType.some((type) => type === offer.truckType);
};

const includeOfferStatusFilter = (offer, filters) => {
  return (
    !filters.offerStatus.length ||
    filters.offerStatus.includes(offer.status) ||
    (filters.offerStatus.includes('NEGOTIATED') && offer.negotiationStatus)
  );
};

const includeTruckLaneStatusFilter = (offers, filters) => {
  return (
    !filters.trucklaneStatus.length ||
    (filters.trucklaneStatus.includes('Active') &&
      offers.some((offer) => [offerlistingStatuses.ACCEPTED, offerlistingStatuses.AWARDED].includes(offer.status))) ||
    (filters.trucklaneStatus.includes('Inactive') &&
      offers.every((offer) => ![offerlistingStatuses.ACCEPTED, offerlistingStatuses.AWARDED].includes(offer.status)))
  );
};

const searchData = (offer, searchText) => {
  const searchRegex = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
  return searchText.trim() !== ''
    ? searchRegex.test([offer.buyerDestinationName, offer.inventory.siteName, offer.inventory.itemName, offer.inventory.description])
    : true;
};

const buildFilterOptions = (originalTrucklanes) => {
  const locations = new Map();
  const customers = new Map();
  originalTrucklanes.forEach((trucklane) => {
    trucklane.offers.forEach((offer) => {
      locations.set(offer.inventory.siteId, { id: offer.inventory.siteId, name: offer.inventory.siteName });
      customers.set(offer.buyerSiteId, { id: offer.buyerSiteId, name: offer.buyerSiteName });
    });
  });
  return {
    locations: [...locations.values()],
    customers: [...customers.values()],
    logistics: ['Pickup', 'Delivery'],
    trucklaneStatus: ['Active', 'Inactive'],
    truckType: [truckTypeEnum.dry, truckTypeEnum.refrigerated, truckTypeEnum.frozen],
    offerStatus: [
      offerlistingStatuses.ACTIVE,
      offerlistingStatuses.AWARDED,
      offerlistingStatuses.ACCEPTED,
      'NEGOTIATED',
      offerlistingStatuses.IGNORED,
    ],
  };
};

export {
  buildSortOptions,
  sortRevenue,
  sortSuggestedRevenue,
  sortWeight,
  includeLocationFilter,
  includeCustomerFilter,
  includeLogisticsFilter,
  includeTruckTypeFilter,
  includeOfferStatusFilter,
  includeTruckLaneStatusFilter,
  searchData,
  buildFilterOptions,
};
