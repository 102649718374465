import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { graphql } from '@apollo/client/react/hoc';
import { GraphQLDataTable, Flyout, Button, OverlayService, FadeIn, DateRangeProvider, AlertService } from '@spoiler-alert/ui-library';
import ComponentBuilder from '../../../utilities/component-builder';
import { UserVerificationsQuery, verificationCountQuery } from '../../../graphql/queries';
import columns from './verification-columns';
import VerifyModal from '../../../components/modals/verify-modal';
import VerifyMultipleModal from '../../../components/modals/verify-multiple-modal';
import RejectModal from '../../../components/modals/reject-modal';
import ClaimsActivityDetails from '../my-claims/claims-activity-details';
import VerificationsComplete from './verifications-complete';
import NoVerifications from './no-verifications';
import NoSignature from './no-signature';
import { FilterRow } from '../../../components/filters';
import { TitleService } from '../../../services';
import { Breadcrumbs } from '../../../store';

const styles = {
  search__row: {
    justifyContent: 'flex-start',
  },
  select__box: {
    order: 1,
    marginRight: '12px !important',
  },
  search: {
    order: 3,
    marginLeft: 'auto',
  },
  action__buttons: {
    order: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  action__button: {
    marginRight: '12px',
  },
  grid: {
    width: 'calc(100% + 16px)',
    margin: '-8px',
  },
  filter__container: {
    flexGrow: 1,
    padding: '0 10px',
    maxWidth: '33.33%',
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  widget__row: {
    marginBottom: 36,
    width: '100%',
  },
  filter__row: {
    marginBottom: 24,
    width: '100%',
  },
  widget__container: {
    width: '100%',
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  '@media (max-width: 840px)': {
    verifications: {
      margin: 0,
    },
    grid: {
      margin: 0,
      width: '100%',
    },
  },
};

class MyVerifications extends Component {
  constructor(props) {
    super(props);
    const { data } = props;

    this.dateRangeProvider = new DateRangeProvider(props.user.site.fiscalYearStart, props.user.site.allTimeStart);
    const events = {
      dateRangeChange: this.dateRangeChange,
      categoryChange: this.categoryChange,
      supplierChange: this.supplierChange,
    };

    this.componentBuilder = new ComponentBuilder(props.user, events, this.dateRangeProvider);
    TitleService.setTitles('Pending Verifications');
    Breadcrumbs.set([
      {
        url: '/activity/my-verifications',
        title: 'Pending Verifications',
      },
    ]);

    const categories = props.user.postFilterParameters.categoriesForVerifications.map((c) => c.id);
    const sites = props.user.postFilterParameters.suppliers.map((s) => s.id);
    const startDate = this.dateRangeProvider.allTimeStart.toDate();
    const endDate = this.dateRangeProvider.allTimeEnd.toDate();

    this.state = {
      selectedRow: undefined,
      showFlyout: false,
      showVerifyModal: false,
      showVerifyMultipleModal: false,
      showRejectModal: false,
      deselect: false,
      total: data.currentUserQuery ? data.currentUserQuery.pendingVerificationsCount : 0,
      selected: [],
      allSelected: false,
      loading: data.loading,
      variables: { paginate: { pageNumber: 1, limit: 100 } },
      startDate,
      endDate,
      sites,
      categories,
      filterParameters: {
        startDate,
        endDate,
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data } = nextProps;
    if (!data.loading && data.loading !== prevState.loading && !data.error && data.currentUserQuery) {
      return { total: data.currentUserQuery.pendingVerificationsCount, loading: false };
    }
    return null;
  }

  handleDynamicRowClick = (row) => {
    this.setState({ selectedRow: row, showFlyout: true }, this.showOverlay);
  };

  showOverlay = () => {
    OverlayService.show();
  };

  // include error handling here
  hideFlyout = () => {
    if (this.state.showFlyout) this.setState({ showFlyout: false });
  };

  handleSelectChange = (selected, _, allSelected) => {
    this.setState({ selected, allSelected, deselect: false });
  };

  showVerifyModal = () => {
    const { selected } = this.state;
    this.setState({ showVerifyModal: selected.length === 1, showVerifyMultipleModal: selected.length > 1 });
  };

  showRejectModal = () => {
    this.setState({ showRejectModal: true });
  };

  hideModals = (actionTaken) => {
    if (!actionTaken) {
      AlertService.alert({
        type: 'warning',
        message: `There was a problem verifying your donations. Please try again. If the problem persists contact support@spoileralert.com`,
      });
    } else {
      AlertService.alert({
        type: 'success',
        message: `Verification successful`,
        autoDismiss: true,
        dismissDelay: 5000,
      });
    }
    this.close(actionTaken);
  };

  close = (actionTaken) => {
    this.setState({
      showVerifyModal: false,
      showVerifyMultipleModal: false,
      showRejectModal: false,
      deselect: actionTaken,
      selected: actionTaken ? [] : this.state.selected,
      allSelected: actionTaken ? false : this.state.allSelected,
    });
  };

  updateFilterParameters(filter, selectedItems) {
    const currentFilters = { ...this.state.filterParameters };
    currentFilters[filter] = selectedItems;
    return currentFilters;
  }

  handleQueryChange = (variables) => {
    this.setState({ variables });
  };

  dateRangeChange = (startDate, endDate) => {
    const filterParameters = { ...this.state.filterParameters };
    filterParameters.startDate = startDate;
    filterParameters.endDate = endDate;
    this.setState({ startDate, endDate, filterParameters });
  };

  categoryChange = (categories) => {
    const filterParameters = this.updateFilterParameters('categories', categories);
    this.setState({ categories, filterParameters });
  };

  supplierChange = (suppliers) => {
    const filterParameters = this.updateFilterParameters('suppliers', suppliers);
    this.setState({ sites: suppliers, filterParameters });
  };

  get actionButtons() {
    const { classes } = this.props;
    const showActions = this.state.selected.length > 0;
    return (
      <FadeIn in={showActions} appear duration="200ms" exit={false} timeout={0}>
        <div className={classes.action__buttons}>
          <Button type="button" secondary className={classes.action__button} onClick={this.showVerifyModal}>
            Verify
          </Button>
          <Button type="button" secondary className={classes.action__button} onClick={this.showRejectModal}>
            Reject
          </Button>
        </div>
      </FadeIn>
    );
  }

  get refetchQueries() {
    return [
      {
        query: UserVerificationsQuery,
        variables: this.state.variables,
      },
      {
        query: verificationCountQuery,
      },
    ];
  }

  get showDone() {
    return this.hasSignature && this.state.total === 0 && !this.loading;
  }

  get showNone() {
    return this.hasSignature && this.state.total === 0 && !this.loading;
  }

  get showTable() {
    return this.hasSignature && (this.state.total > 0 || this.loading);
  }

  get hasSignature() {
    const { responsibleParty } = this.props.user.site;
    return responsibleParty && responsibleParty.signatureImage !== undefined && responsibleParty.signatureImage !== null;
  }

  renderWidgetRow() {
    const productMixVerificationWidget = {
      componentType: 'VariableProductMixDonutLeaderboardWithQuery',
      widgetName: 'Product Mix',
      title: 'Default title',
      id: 'product-mix-verifications',
      query: 'productMixPoundsOfVerifications',
      gql: 'query ProductMixQuery($postFilter: PostFilterInput!) {\n    siteMetricsQuery(postFilter: $postFilter) {\n      productMixPoundsOfVerifications {\n        type\n        value\n        percentage\n      }\n    }\n  }',
      mask: 'lbs',
    };

    return (
      <div className={this.props.classes.widget__container}>
        {this.componentBuilder.getComponent(productMixVerificationWidget, this.state, { height: 350 })}
      </div>
    );
  }

  renderFilterRow() {
    return (
      <FilterRow key="dashboard-filter-row">
        <div className={this.props.classes.filter__container}>
          {this.componentBuilder.getComponent({ componentType: 'DaterangeFilter' }, this.state, {
            ...this.dateRangeProvider,
            useStateFilterParametersForInitialDateRange: true,
          })}
        </div>
        <div className={this.props.classes.filter__container}>
          {this.componentBuilder.getComponent({ componentType: 'CategoryFilter' }, this.state, { categoryParam: 'categoriesForVerifications' })}
        </div>
        <div className={this.props.classes.filter__container}>
          {this.componentBuilder.getComponent({ componentType: 'SupplierFilter' }, this.state)}
        </div>
      </FilterRow>
    );
  }

  render() {
    const { classes, user } = this.props;
    const {
      filterParameters,
      deselect,
      selectedRow,
      showFlyout,
      selected,
      total,
      allSelected,
      showRejectModal,
      showVerifyModal,
      showVerifyMultipleModal,
    } = this.state;
    return (
      <div id="verifications">
        {this.hasSignature && <div className={classes.filter__row}>{this.renderFilterRow()}</div>}
        {this.hasSignature && <div className={classes.widget__row}> {this.renderWidgetRow()} </div>}
        {this.showTable && (
          <GraphQLDataTable
            query={UserVerificationsQuery}
            queryName="currentUserQuery"
            queryField="pendingVerifications"
            search
            selectable
            selectableText="unverified donations"
            columns={columns}
            filters={[]}
            queryParameters={filterParameters}
            onRowClick={this.handleDynamicRowClick}
            onSelectChange={this.handleSelectChange}
            deselect={deselect}
            onQueryChange={this.handleQueryChange}
            pageActionButtons={[
              <Button primary key="verify" type="button" className={classes.action__button} onClick={this.showVerifyModal}>
                Verify
              </Button>,
              <Button warning key="reject" type="button" className={classes.action__button} onClick={this.showRejectModal}>
                Reject
              </Button>,
            ]}
          />
        )}
        {this.showDone && (
          <FadeIn in={true} appear timeout={0}>
            <VerificationsComplete />
          </FadeIn>
        )}
        {this.showNone && (
          <FadeIn in={true} appear timeout={0}>
            <NoVerifications />
          </FadeIn>
        )}
        {!this.hasSignature && (
          <FadeIn in={true} appear timeout={0}>
            <NoSignature user={this.props.user} />
          </FadeIn>
        )}
        {selectedRow && (
          <Flyout position="right" open={showFlyout} onHide={this.hideFlyout}>
            <ClaimsActivityDetails id={selectedRow._id} user={this.props.user} refetchQueries={this.refetchQueries} />
          </Flyout>
        )}
        <RejectModal
          posts={selected}
          total={total}
          allSelected={allSelected}
          open={showRejectModal}
          close={this.close}
          onHide={this.hideModals}
          refetchQueries={this.refetchQueries}
        />
        <VerifyModal
          post={selected[0]}
          user={user}
          open={showVerifyModal}
          onHide={this.hideModals}
          refetchQueries={this.refetchQueries}
          close={this.close}
        />
        <VerifyMultipleModal
          posts={selected}
          user={user}
          total={total}
          allSelected={allSelected}
          open={showVerifyMultipleModal}
          onHide={this.hideModals}
          close={this.close}
          refetchQueries={this.refetchQueries}
        />
      </div>
    );
  }
}

MyVerifications.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  data: PropTypes.object,
};

const StyledComponent = injectSheet(styles)(MyVerifications);
const GqlComponent = graphql(verificationCountQuery)(StyledComponent);
export default GqlComponent;
