import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import SiteHeader from '../components/header/site-header';
import Refresher from '../components/refresher';

const styles = {
  site__container: {
    width: '100%',
    height: '100%',
  },
};

@injectSheet(styles)
class Layout extends React.Component {
  render() {
    return (
      <div className={this.props.classes.site__container}>
        <SiteHeader showLink={this.props.showLink} />
        {this.props.children}
        <Refresher />
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  showLink: PropTypes.bool,
};

export default Layout;
