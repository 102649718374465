import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useQuery } from '@apollo/client';
import { Theme as theme, ClickAwayListener } from '@spoiler-alert/ui-library';
import CustomerParticipationRateChart from '../charts/customer-participation-rate-chart';
import CustomerParticipationSummaryTooltip from './customer-participation-summary-tooltip';
import { customerParticipationStatus } from '../../enums';
import { CustomerParticipationSummaryQuery } from '../../graphql/queries';

const useStyles = createUseStyles({
  participationRateButton: {
    width: 133,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    border: 'solid 1px',
    borderRadius: 2,
    borderColor: theme.white,
  },
  participationRateButtonInactive: {
    extend: 'participationRateButton',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.grey80,
    },
  },
  participationRateButtonActive: {
    extend: 'participationRateButton',
    borderColor: theme.green,
    backgroundColor: theme.green5,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.green10,
    },
  },
  participationRateButtonContent: {
    height: 28,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    textAlign: 'center',
  },
  text: {
    height: 14,
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: theme.greyDark,
  },
  numberText: {
    extend: 'text',
    fontWeight: 500,
  },
});

const CustomerParticipationSummaryButton = ({ createListingsHandler }) => {
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);
  const { data } = useQuery(CustomerParticipationSummaryQuery);
  const customerInfo = data?.customerParticipationSummaryQuery;

  const offersReceived = customerInfo?.filter((ci) => ci.status === customerParticipationStatus.OFFERS_RECEIVED).length || 0;
  const customerParticipationRate = customerInfo?.length ? Math.round((offersReceived / customerInfo.length) * 100) : 0;
  const handleClick = () => {
    setTimeout(() => {
      const newStatus = !showTooltip;
      setShowTooltip(newStatus);
    }, 25);
  };
  const closeTooltip = () => {
    setShowTooltip(false);
  };

  return (
    <ClickAwayListener onClickAway={closeTooltip}>
      <div>
        {data ? (
          <div>
            <div className={showTooltip ? classes.participationRateButtonActive : classes.participationRateButtonInactive} onClick={handleClick}>
              <div className={classes.participationRateButtonContent}>
                <CustomerParticipationRateChart participationRate={customerParticipationRate} cutout="55%" backgroundColor={theme.green30} />
                <div>
                  <div className={classes.numberText}>{customerParticipationRate}%</div>
                  <div className={classes.text}>Participation</div>
                </div>
              </div>
            </div>
            {showTooltip && <CustomerParticipationSummaryTooltip customerInfo={customerInfo} createListingsHandler={createListingsHandler} />}
          </div>
        ) : (
          <div className={classes.participationRateButton}>
            <div className={classes.participationRateButtonContent}>
              <CustomerParticipationRateChart participationRate={100} cutout="55%" subjectColor={theme.grey30} />
              <div>
                <div className={classes.numberText}>Calculating</div>
                <div className={classes.text}>Participation...</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

CustomerParticipationSummaryButton.propTypes = {
  customerInfo: PropTypes.arrayOf(PropTypes.object),
  createListingsHandler: PropTypes.func,
};

export default CustomerParticipationSummaryButton;
