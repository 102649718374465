import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectOption } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import filterStyles from './filter-styles';

@injectSheet(filterStyles)
export default class CategoryFilter extends React.Component {
  constructor(props) {
    super(props);
    this.categories = this.getCategoryList();
    this.state = {
      values: this.categories.map((c) => ({ value: c.value, text: c.text })),
    };
  }

  getCategoryList() {
    const { categoryParam, user } = this.props;
    const categoriesToReturn = user.postFilterParameters[categoryParam];
    return categoriesToReturn.map((c) => ({ text: c.name, value: c.id }));
  }

  onChange = (values) => {
    this.setState({ values });
    this.props.onChange(values.map((val) => val.value));
  };

  render() {
    const catItems = this.categories.map((itm, idx) => (
      <SelectOption
        key={`cat-item-${idx}`}
        checkbox
        selected={this.state.values.findIndex((item) => item.value === itm.value) > -1}
        value={itm.value}
      >
        {itm.text}
      </SelectOption>
    ));
    return (
      <div className={this.props.classes.dashboard__filter}>
        <Select label="Categories" selectedItems={this.state.values} multiple selectAll search autoWidth onChange={this.onChange}>
          {catItems}
        </Select>
      </div>
    );
  }
}

CategoryFilter.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  onChange: PropTypes.func,
  categoryParam: PropTypes.string,
};

CategoryFilter.defaultProps = {
  categoryParam: 'categories',
};
