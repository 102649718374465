import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { LoadingLarge } from '@spoiler-alert/ui-library';

const styles = {
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

@injectSheet(styles)
export default class StaticLoading extends Component {
  render() {
    return (
      <div className={this.props.classes.container}>
        <LoadingLarge />
      </div>
    );
  }
}

StaticLoading.propTypes = {
  classes: PropTypes.object,
};
