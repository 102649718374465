import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectOption } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import filterStyles from './filter-styles';
import { postStatuses } from '../../enums';

@injectSheet(filterStyles)
export default class StatusFilter extends React.Component {
  constructor(props) {
    super(props);
    this.defaults = [
      {
        value: 'all',
        statuses: ['CLAIMED', 'UNVERIFIED', 'COMPLETED'],
        statusQuery: { $gte: postStatuses.claimed, $lte: postStatuses.completed },
        text: 'All',
      },
      {
        value: 'unverified',
        statuses: ['CLAIMED', 'UNVERIFIED'],
        statusQuery: { $gte: postStatuses.claimed, $lte: postStatuses.unverified },
        text: 'Unverified',
      },
      {
        value: 'verified',
        statuses: ['COMPLETED'],
        statusQuery: postStatuses.completed,
        text: 'Verified',
      },
    ];

    this.state = {
      value: [{ value: this.defaults[0].value, text: this.defaults[0].text }],
    };
  }

  onChange = (item) => {
    this.setState({ value: item });
    const selected = this.defaults.find((itm) => itm.value === item[0].value);
    this.props.onChange({ value: selected.statuses, statusQuery: selected.statusQuery });
  };

  render() {
    const items = this.defaults.map((itm, ix) => (
      <SelectOption key={`item-${ix}`} selected={this.state.value[0].value === itm.value} value={itm.value}>
        {itm.text}
      </SelectOption>
    ));
    return (
      <div className={this.props.classes.dashboard__filter}>
        <Select label="Status" selectedItems={this.state.value} onChange={this.onChange}>
          {items}
        </Select>
      </div>
    );
  }
}

StatusFilter.propTypes = {
  onChange: PropTypes.func,
  classes: PropTypes.object,
};
