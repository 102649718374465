import { UserQuery } from '../../graphql/queries';
/* eslint-disable @typescript-eslint/no-explicit-any */
const deleteCachedFieldsOnUserQuery = (cache, keysToDelete) => {
    const userQueryRes = cache.readQuery({
        query: UserQuery,
        returnPartialData: true,
    });
    const fieldsObject = keysToDelete.reduce((acc, val) => {
        acc[val] = (_fieldValue, details) => details.DELETE;
        return acc;
    }, {});
    cache.modify({
        id: cache.identify(userQueryRes === null || userQueryRes === void 0 ? void 0 : userQueryRes.currentUserQuery),
        fields: fieldsObject,
    });
    cache.gc();
};
export default deleteCachedFieldsOnUserQuery;
/* eslint-disable @typescript-eslint/no-explicit-any */
