import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, TabList, TabPanel, Button, Flyout, OverlayService } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { PendingUsersQuery, ActiveUsersQuery } from '../../graphql/queries';
import ActiveUsers from './active-users';
import PendingUsers from './pending-users';
import InviteModal from '../../components/modals/invite-modal';
import ActiveUserDetails from './active-user-details';
import PendingUserDetails from './pending-user-details';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';

const styles = {
  invite__button: {
    marginLeft: 'auto',
    alignSelf: 'center',
  },
};

class UserManagement extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('User Management');
    Breadcrumbs.set([
      {
        url: '/users',
        title: 'User Management',
      },
    ]);
    this.state = {
      showModal: false,
      showFlyout: false,
      activeUser: true,
      activeVariables: { paginate: { pageNumber: 1, limit: 30, filter: { sortBy: { asc: true, sortProperty: 'lastName' } } } },
      pendingVariables: { paginate: { pageNumber: 1, limit: 30, filter: { sortBy: { asc: true, sortProperty: 'lastName' } } } },
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.getQueryVariables = this.getQueryVariables.bind(this);
    this.handleDynamicRowClickActive = this.handleDynamicRowClickActive.bind(this);
    this.handleDynamicRowClickPending = this.handleDynamicRowClickPending.bind(this);
    this.hideFlyout = this.hideFlyout.bind(this);
  }

  get pendingRefetchQueries() {
    return [
      {
        query: PendingUsersQuery,
        variables: this.state.pendingVariables,
      },
    ];
  }

  get activeRefetchQueries() {
    return [
      {
        query: ActiveUsersQuery,
        variables: this.state.activeVariables,
      },
    ];
  }

  showOverlay() {
    OverlayService.show();
  }

  showModal() {
    this.setState({ showModal: true });
  }

  hideModal() {
    this.setState({ showModal: false });
  }

  getActiveQueryVariables(activeVariables) {
    this.setState({ activeVariables });
  }

  getQueryVariables(pendingQueryVariables) {
    this.setState({ pendingQueryVariables });
  }

  hideFlyout() {
    if (this.state.showFlyout) {
      this.setState({ showFlyout: false, activeSelectedRow: undefined, pendingSelectedRow: undefined });
      OverlayService.hide();
    }
  }

  handleDynamicRowClickActive(row) {
    this.setState({ activeSelectedRow: row, showFlyout: true, activeUser: true }, this.showOverlay);
  }

  handleDynamicRowClickPending(row) {
    this.setState({ pendingSelectedRow: row, showFlyout: true, activeUser: false }, this.showOverlay);
  }

  render() {
    const { classes, user } = this.props;
    const { activeSelectedRow, pendingSelectedRow } = this.state;
    return (
      <div id="user-management">
        <Tabs>
          <TabList>
            <Tab active target="active-users">
              Active
            </Tab>
            <Tab target="pending-users">Pending</Tab>
            <Button className={classes.invite__button} onClick={this.showModal} primary>
              Invite User
            </Button>
          </TabList>
          <TabPanel id="active-users" active>
            <ActiveUsers user={user} handleDynamicRowClick={this.handleDynamicRowClickActive} onQueryChange={this.getActiveVariables} />
          </TabPanel>
          <TabPanel id="pending-users">
            <PendingUsers user={user} onQueryChange={this.getQueryVariables} handleDynamicRowClick={this.handleDynamicRowClickPending} />
          </TabPanel>
        </Tabs>
        <InviteModal user={user} open={this.state.showModal} queryVariables={this.state.pendingQueryVariables} onHide={this.hideModal} />
        {activeSelectedRow && (
          <Flyout position="right" open={this.state.showFlyout} onHide={this.hideFlyout}>
            <ActiveUserDetails
              id={activeSelectedRow._id}
              onHide={this.hideFlyout}
              refetchQueries={this.activeRefetchQueries}
              user={this.props.user}
            />
          </Flyout>
        )}
        {pendingSelectedRow && (
          <Flyout position="right" open={this.state.showFlyout} onHide={this.hideFlyout}>
            <PendingUserDetails id={pendingSelectedRow._id} onHide={this.hideFlyout} refetchQueries={this.pendingRefetchQueries} />
          </Flyout>
        )}
      </div>
    );
  }
}

UserManagement.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
};

const StyledComponent = injectSheet(styles)(UserManagement);
export default StyledComponent;
