import { makeAutoObservable } from 'mobx';
class BreadcrumbsStore {
    constructor() {
        this.breadcrumbs = [{ url: '/', title: 'Home' }];
        makeAutoObservable(this);
    }
    set(crumbs) {
        this.breadcrumbs = crumbs;
    }
    pop() {
        this.breadcrumbs.pop();
    }
    push(crumb) {
        this.breadcrumbs.push(crumb);
    }
    update(index, crumb) {
        this.breadcrumbs[index] = crumb;
    }
}
export default BreadcrumbsStore;
