import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import accounting from 'accounting';
import {
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  Select,
  SelectOption,
  TextInput,
  PricingStrategyAnchor,
  PricingStrategyAnchorKeysObject,
} from '@spoiler-alert/ui-library';
import { SetSuggestedPriceFromAnchor } from '../../graphql/mutations';
import { SuggestedPriceStrings } from '../../string-resources';

const useStyles = createUseStyles({
  form__container: {
    width: '100%',
  },
  modal__field: {
    width: '48%',
  },
  modal__fields: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const SuggestedPriceModal = ({ onHide, open, stagedListingIds, total, allSelected, filters, user }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedAnchor, setSelectedAnchor] = useState();
  const [anchorValue, setAnchorValue] = useState(0);
  const [setSuggestedPriceFromAnchor] = useMutation(SetSuggestedPriceFromAnchor);

  const setNewAnchor = (newSelectedAnchor) => {
    setSelectedAnchor(newSelectedAnchor[0]);
    setAnchorValue(newSelectedAnchor[0].value === PricingStrategyAnchorKeysObject.SetOnUpload ? 100 : 0);
  };

  const hide = (response) => {
    let actionTaken = false;
    let loggedCount = 0;
    let errorCount = 0;
    if (response.data) {
      const loggedResults = response.data.setSuggestedPriceFromAnchor;
      errorCount = loggedResults.errors.length;
      actionTaken = loggedResults.stagedListingCount || 1;
      loggedCount = loggedResults.stagedListingCount || 1 - errorCount;
    }
    setLoading(false);
    onHide(actionTaken, errorCount, loggedCount);
  };

  const setSuggestedPrices = (ev) => {
    ev.preventDefault();
    setLoading({ loading: true });
    const setPriceVariables = {
      stagedListingIds,
      inventoryFilters: filters,
      pricingStrategy: selectedAnchor.value,
      anchorValue,
      allSelected,
      limit: total,
    };
    setSuggestedPriceFromAnchor({ variables: setPriceVariables }).then(hide).catch(hide);
  };

  const cancel = () => {
    onHide(false);
  };

  const getPricingAnchors = (bypassItemValidation) => {
    const pricingStrategyAnchors = Array.from(PricingStrategyAnchor.keys());
    let finalOptions;

    if (bypassItemValidation) {
      finalOptions = pricingStrategyAnchors.filter((anchor) => anchor !== 'BestPriceReceivedGlobal' && anchor !== 'BestPriceReceivedDL');
    } else {
      finalOptions = pricingStrategyAnchors;
    }

    return finalOptions.map((k) => (
      <SelectOption key={k} value={k}>
        {PricingStrategyAnchor.get(k)}
      </SelectOption>
    ));
  };

  const generateTextInputLabelText = (currencyType) => {
    if (selectedAnchor === PricingStrategyAnchorKeysObject.FlatRate && currencyType) {
      return `${SuggestedPriceStrings.flatRate} (${currencyType})`;
    }
    if (selectedAnchor === PricingStrategyAnchorKeysObject.FlatRate) {
      return SuggestedPriceStrings.flatRate;
    }
    return SuggestedPriceStrings.percent;
  };

  const inventoryImportProfile = user.site?.importProfiles?.filter((ip) => ip.importType === 'INVENTORY')[0];
  const { bypassItemValidation } = inventoryImportProfile;
  const displayTotal = allSelected ? total : stagedListingIds.length;
  const canSubmit = selectedAnchor && anchorValue > 0;

  return (
    <Modal onHide={onHide} open={open} closeOnEsc closeOnOutsideClick>
      <form onSubmit={canSubmit ? setSuggestedPrices : null} className={classes.form__container}>
        <ModalContent>
          <h2>{SuggestedPriceStrings.title}</h2>
          <div className={classes.modal__fields}>
            <div className={classes.modal__field} data-testid="anchor-select">
              <Select
                label={SuggestedPriceStrings.anchor}
                onChange={(newSelectedAnchor) => setNewAnchor(newSelectedAnchor)}
                selectedItem={selectedAnchor}
              >
                {getPricingAnchors(bypassItemValidation)}
              </Select>
            </div>
            <div className={classes.modal__field}>
              <TextInput
                type="text"
                labelText={generateTextInputLabelText(user.site.currencyType)}
                onChange={(newAnchorValue) => setAnchorValue(accounting.unformat(newAnchorValue))}
                required
                value={
                  selectedAnchor?.value === PricingStrategyAnchorKeysObject.FlatRate
                    ? `${accounting.formatNumber(anchorValue, 2)}`
                    : `${accounting.formatNumber(anchorValue, 2)}%`
                }
              />
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button type="button" onClick={cancel} secondary disabled={loading}>
            Close
          </Button>
          <Button type="submit" className={classes.submit} disabled={!canSubmit} primary loading={loading} loadingText="Wait...">
            {SuggestedPriceStrings.submit(displayTotal)}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

SuggestedPriceModal.propTypes = {
  onHide: PropTypes.func,
  open: PropTypes.bool,
  stagedListingIds: PropTypes.array,
  total: PropTypes.number,
  allSelected: PropTypes.bool,
  filters: PropTypes.object,
  setSuggestedPriceFromAnchor: PropTypes.func,
  user: PropTypes.object,
};

export default SuggestedPriceModal;
