import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Button } from '@spoiler-alert/ui-library';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { TitleService, PasswordService } from '../../services';
import routePaths from '../../route-paths';
import styles from './sign-in-styles';

const useStyles = createUseStyles(styles);

const ForgotPassword = ({ history, match }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const isAuthenticated = () => !!localStorage.getItem('token');

  useEffect(() => {
    TitleService.setTitleTag('Forgot Password');
    if (isAuthenticated()) history.push(routePaths.home);
  }, []);

  const passwordResult = (msg, res) => {
    setMessage({ text: msg, type: res.ok ? 'success' : 'error' });
    setLoading(false);
  };

  const submitForgotPassword = (event) => {
    event.preventDefault();
    setLoading(true);
    const msg = 'Please check your email for a link to reset your password.';
    PasswordService.forgotPassword(email.trim().toLowerCase())
      .then((res) => passwordResult(msg, res))
      .catch((err) => passwordResult(err.reason || 'Sorry, but we had trouble with that address.', err));
  };

  useEffect(() => {
    if (match.params.path) getURI();
  }, [match.params.path]);

  const emailHandler = (_email) => {
    setEmail(_email);
    setMessage(null);
  };

  const messageClassName = `${classes.messageContainer} ${message?.type === 'error' ? classes.messageContainerError : ''}`;

  return (
    <div className={classes.loginContainer}>
      <h4 className={classes.loginTitle}>Reset your password</h4>
      {message && (
        <div className={messageClassName} data-element={`message-block-${message.type}`}>
          {message.text}
        </div>
      )}
      <form onSubmit={submitForgotPassword} className={classes.formContainer}>
        <div className={classes.formRow}>
          <TextInput onChange={emailHandler} id="email" required type="email" labelText="Email" value={email} autoComplete="username" />
        </div>

        <div className={classes.formRow}>
          <Button className={classes.formButton} id="sign-in" type="submit" loading={loading} loadingText="Loading...">
            Send Password Reset Link
          </Button>
        </div>
      </form>
      <div className={classes.footerContainer}>
        <Link className={classes.loginLink} to={routePaths.signIn}>
          Sign in with email and password
        </Link>
        <Link className={classes.loginLink} id="sso-login-link" to={routePaths.ssoSignIn}>
          Sign in using Single Sign On (SSO)
        </Link>
        <a className={classes.loginLink} href="https://www.spoileralert.com/request-a-demo" target="_blank" rel="noreferrer">
          Request a Demo
        </a>
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default ForgotPassword;
