import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { StickyFooter } from '@spoiler-alert/ui-library';
import accounting from 'accounting';
import { weightWithUnit } from '../../utilities/unit-helper';

const styles = {
  action__button: {
    marginRight: '12px',
    backgroundColor: '#FFFFFF',
  },
  offer__text: {
    marginRight: '36px',
    color: '#FFFFFF',
    height: '19px',
    fontSize: '1rem',
    fontWeight: '900',
    lineHeight: '1rem',
  },
  footer__hr: {
    color: '#FFFFFF',
    height: '24px',
    width: '2px',
    border: '1px solid #FFFFFF',
    marginRight: '15px',
  },
  suggested__text: {
    marginRight: '16px',
    color: '#FFFFFF',
    height: '19px',
    fontSize: '1rem',
    lineHeight: '1rem',
  },
  'action__button--round': {
    marginRight: '24px',
    backgroundColor: '#FFFFFF',
    height: '24px',
    width: '68px',
    borderRadius: '100px',
    color: '#2AB24A',
    fontSize: '.9rem',
    fontWeight: '500',
    lineHeight: '1.5rem',
    textAlign: 'center',
  },
};

@injectSheet(styles)
export default class Footer extends Component {
  state = {
    totalAwardedWeight: this.props.totalAwardedWeight,
    totalOfferPrice: this.props.totalOfferPrice,
    logistics: this.props.logistics,
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      totalAwardedWeight: nextProps.totalAwardedWeight,
      totalOfferPrice: nextProps.totalOfferPrice,
      logistics: nextProps.logistics,
    };
  }

  render() {
    const { classes, buttons, user } = this.props;
    const { totalAwardedWeight, totalOfferPrice, logistics } = this.state;
    return (
      <StickyFooter>
        <div className={classes['action__button--round']}>{logistics}</div>
        <div>
          <div className={classes.suggested__text}>Total Weight: {weightWithUnit(totalAwardedWeight, user)}</div>
        </div>
        <div className={classes.footer__hr}>
          <hr />
        </div>
        <div className={classes.offer__text}>
          <div>Total Offer Price: {accounting.formatMoney(totalOfferPrice)}</div>
        </div>
        {buttons}
      </StickyFooter>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  style: PropTypes.object,
  totalAwardedWeight: PropTypes.number,
  totalOfferPrice: PropTypes.number,
  logistics: PropTypes.string,
  buttons: PropTypes.node,
};
