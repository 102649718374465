import LocalizedStrings from 'react-localization';

const ImportStrings = new LocalizedStrings({
  en: {
    downloadCSVTemplate: 'Download CSV Template',
    itemImport: 'Item Import',
    itemImportDescription:
      'You can import or update items in your Spoiler Alert account using a CSV file or plaintext files in CSV format. Items are deduplicated based on item number. When you import an item that is already in your Item Catalog, that item will simply be updated with any new information in your CSV file.',
    importUploadedMessage: 'Your import is uploaded and is now processing.',
    importErrorMessage:
      'Sorry we are unable to import your items. If this problem persists, please contact a Spoiler Alert Administrator to help you.',
    downloadCSVTemplateDescription:
      'The CSV file specifies the data fields you will need to provide to create or update an item for the selected site.',
    downloadTemplate: 'Download Template',
    addData: 'Add Your Data',
    addDataDescription:
      'To make this go as smoothly as possible, add your data to the CSV template provided, or if you are using your own file, make sure the headers map to the column headers in the template.',
    selectDate: 'Select Date of Item Report',
    itemImportDate: 'Item Import Date *',
    uploadFile: 'Upload Your File',
    dragAndDrop: 'Drag and Drop',
    yourCSVFile: 'your CSV file, or',
    browseFile: 'Browse Files',
    processing: 'Processing...',
  },
});

export default ImportStrings;
