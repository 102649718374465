import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import BREAKPOINTS, { keys } from './breakpoints';
import Hidden from './hidden';

const GUTTERS = [8, 16, 24, 40];
const GRID_SIZES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

function generateGrid(globalStyles, breakpoint) {
  // For the auto layouting
  const styles = {
    [`grid-${breakpoint}`]: {
      flexBasis: 0,
      flexGrow: 1,
      maxWidth: '100%',
    },
  };

  GRID_SIZES.forEach((size) => {
    // Only keep 6 significant numbers.
    const width = `${Math.round((size / 12) * 10 ** 6) / 10 ** 4}%`;

    styles[`grid-${breakpoint}-${size}`] = {
      flexBasis: width,
      maxWidth: width,
    };
  });

  // No need for a media query for the first size.
  if (breakpoint === 'xs') {
    Object.assign(globalStyles, styles);
  } else {
    globalStyles[`@media (min-width:${BREAKPOINTS[breakpoint]}px)`] = styles;
  }
}

function generateGutters() {
  const styles = {};

  GUTTERS.forEach((gutter) => {
    styles[`gutter-${gutter}`] = {
      margin: -gutter / 2,
      width: `calc(100% + ${gutter}px)`,
      '& > $item': {
        padding: gutter / 2,
      },
    };
  });

  return styles;
}

const styles = {
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  item: {
    boxSizing: 'border-box',
    flex: '0 0 auto',
    margin: '0',
  },
  'direction-xs-column': {
    flexDirection: 'column',
  },
  'direction-xs-column-reverse': {
    flexDirection: 'column-reverse',
  },
  'direction-xs-row-reverse': {
    flexDirection: 'row-reverse',
  },
  'wrap-xs-nowrap': {
    flexWrap: 'nowrap',
  },
  'align-xs-center': {
    alignItems: 'center',
  },
  'align-xs-flex-start': {
    alignItems: 'flex-start',
  },
  'align-xs-flex-end': {
    alignItems: 'flex-end',
  },
  'justify-xs-center': {
    justifyContent: 'center',
  },
  'justify-xs-flex-end': {
    justifyContent: 'flex-end',
  },
  'justify-xs-space-between': {
    justifyContent: 'space-between',
  },
  'justify-xs-space-around': {
    justifyContent: 'space-around',
  },
  ...generateGutters(),
  ...keys.reduce((breakpointStyles, key) => {
    // Use side effect for performance.
    generateGrid(breakpointStyles, key);
    return breakpointStyles;
  }, {}),
};

@injectSheet(styles)
export default class Grid extends React.Component {
  get containerClass() {
    const { classes, container } = this.props;
    return container ? classes.container : classes.item;
  }

  render() {
    const {
      classes,
      className: classNameProp,
      container,
      component,
      children,
      hidden,
      gutter,
      direction,
      wrap,
      align,
      justify,
      xs,
      sm,
      md,
      lg,
      xl,
      ...other
    } = this.props;

    const className = classNames(
      {
        [classes.container]: container,
        [classes.item]: !container,
        [classes[`gutter-${String(gutter)}`]]: container && gutter !== 0,
        [classes[`direction-xs-${String(direction)}`]]: direction !== 'row',
        [classes[`wrap-xs-${String(wrap)}`]]: wrap !== 'wrap',
        [classes[`align-xs-${String(align)}`]]: align !== 'stretch',
        [classes[`justify-xs-${String(justify)}`]]: justify !== 'flex-start',
        [classes['grid-xs']]: xs === true,
        [classes[`grid-xs-${String(xs)}`]]: xs && xs !== true,
        [classes['grid-sm']]: sm === true,
        [classes[`grid-sm-${String(sm)}`]]: sm && sm !== true,
        [classes['grid-md']]: md === true,
        [classes[`grid-md-${String(md)}`]]: md && md !== true,
        [classes['grid-lg']]: lg === true,
        [classes[`grid-lg-${String(lg)}`]]: lg && lg !== true,
        [classes['grid-xl']]: xl === true,
        [classes[`grid-xl-${String(xl)}`]]: xl && xl !== true,
      },
      classNameProp
    );

    const ComponentProp = component || Grid.defaultProps.component;

    const gridProps = { className, ...other };

    if (hidden) {
      return (
        <Hidden {...hidden}>
          <ComponentProp {...gridProps} />
        </Hidden>
      );
    }

    return <ComponentProp {...gridProps}>{children}</ComponentProp>;
  }
}

Grid.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  component: PropTypes.string,
  container: PropTypes.bool,
  align: PropTypes.string,
  direction: PropTypes.string,
  gutter: PropTypes.number,
  hidden: PropTypes.object,
  justify: PropTypes.string,
  wrap: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

Grid.defaultProps = {
  component: 'div',
  container: false,
  align: 'stretch',
  direction: 'row',
  gutter: 16,
  justify: 'flex-start',
  wrap: 'wrap',
  hidden: undefined,
};
