import React from 'react';
import moment from 'moment';
import { Column } from '@spoiler-alert/ui-library';
import accounting from 'accounting';

function getMyTaxDeductibleColumns(user) {
  return [
    new Column({
      field: 'transactedAt',
      displayName: 'Donation Date',
      sortable: true,
      defaultSort: true,
      formatter: (value) => moment(value).format('M/DD/YYYY'),
    }),
    new Column({ field: 'supplierSiteName', displayName: 'Site', sortable: true }),
    new Column({ field: 'transactionId', displayName: 'Transaction #', sortable: true }),
    new Column({
      field: 'description',
      displayName: 'Description',
      sortable: true,
      formatter(value) {
        let displayText = value;
        if (value && value.length > 50) displayText = `${value.substr(0, 50).trim()}\u2026`;
        return <span title={value}>{displayText}</span>;
      },
    }),
    new Column({ field: 'quantity', displayName: 'Quantity', sortable: true, formatter: (value, row) => `${value} ${row.unitOfMeasure}` }),
    new Column({
      field: 'totalPrice',
      displayName: 'Fair Market Value',
      sortable: true,
      formatter(value) {
        return accounting.formatMoney(value);
      },
    }),
    new Column({
      field: 'totalCost',
      displayName: 'Cost of Goods Reduction',
      sortable: true,
      formatter(value) {
        return accounting.formatMoney(value);
      },
    }),
    new Column({
      field: 'enhancedTaxDeduction',
      displayName: 'Enhanced Tax Deduction',
      sortable: true,
      formatter(value) {
        return accounting.formatMoney(value);
      },
    }),
    new Column({ field: 'outletName', displayName: 'Recipient', sortable: true }),
    new Column({
      field: 'status',
      displayName: 'Status',
      sortable: true,
      formatter(value, row) {
        if (value === 'COMPLETED') {
          return user.privileges.canViewTaxDashboard ? <a href={`/tax/receipt/${row._id}`}>Verified</a> : 'Verified';
        }
        return 'Unverified';
      },
    }),
  ];
}

export default getMyTaxDeductibleColumns;
