export const DASHBOARD_LOCATIONS = {
  DASHBOARD_TAB: 'DASHBOARD_TAB',
  REPORTS_TAB: 'REPORTS_TAB',
  OFFER_VIZ: 'OFFER_VIZ',
};

export const DASHBOARD_TYPES = {
  CORE: 'CORE',
  CUSTOM: 'CUSTOM',
};
