import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import accounting from 'accounting';
import injectSheet from 'react-jss';
import { TextBox, Theme as theme } from '@spoiler-alert/ui-library';
import { createNegotiationStagedListings } from '../../graphql/mutations';

const CYPRESS_TAGS = {
  INPUT: 'negotiation-detail-change-price-input',
};

const styles = {
  price_field: {
    display: 'flex',
    width: 100,
    margin: [-7, 'auto', -10],
  },
  price__input: {
    width: '73px !important',
    textAlign: 'right !important',
    paddingRight: '8px !important',
  },
  price__input_wrap: {
    width: 75,
  },
  price__dollar: {
    lineHeight: '34px',
    paddingRight: 3,
  },
  price__textbox: {
    height: '34px !important',
  },
  price__textbox_saved: {
    borderColor: `${theme.primaryColor} !important`,
  },
  price__textbox_failure: {
    borderColor: `${theme.downArrowColor} !important`,
  },
};

@graphql(createNegotiationStagedListings, { name: 'createNegotiationStagedListings' })
@injectSheet(styles)
export class NegotiationTextbox extends Component {
  static propTypes = {
    onFailure: PropTypes.func,
    createNegotiationStagedListings: PropTypes.func,
    row: PropTypes.object,
    classes: PropTypes.object,
    refetchQueries: PropTypes.array,
  };

  state = {
    status: undefined,
    value: accounting.formatMoney(this.props.row.negotiationListing.suggestedUnitPrice, ''),
  };

  componentDidUpdate(prevProps) {
    const newValue = this.props.row.negotiationListing.suggestedUnitPrice;
    if (prevProps.row.negotiationListing.suggestedUnitPrice !== newValue) {
      this.setState({
        value: accounting.formatMoney(newValue, ''),
      });
    }
  }

  savePrice = (row, value) => {
    const { onFailure, refetchQueries } = this.props;
    const price = accounting.unformat(value);
    if (price <= 0) {
      this.setState({ status: 'failure', value: accounting.formatMoney(this.props.row.negotiationListing.suggestedUnitPrice, '') });
      return;
    }
    const variables = {
      counterOffers: {
        offerListingId: row.offerListing._id,
        counterOfferQuantity: row.negotiationListing.suggestedQuantity,
        counterOfferPrice: price,
      },
    };
    this.props
      .createNegotiationStagedListings({ variables, refetchQueries })
      .then((response) => {
        const result = response.data.createNegotiationStagedListings;
        if (result.errors?.length > 0) throw new Error(result.errors[0].message);
        this.setState({ status: 'success', value: accounting.formatMoney(value, '') });
        setTimeout(this.clearSavedStatus.bind(this, row._id), 5000);
      })
      .catch(() => {
        this.setState({ status: 'failure' });
        onFailure('A problem occurred while saving the listing price.');
      });
  };

  clearSavedStatus = () => {
    this.setState({ status: undefined });
  };

  updateValue = (value) => {
    this.setState({ value });
  };

  render() {
    const { classes, row } = this.props;
    const { status, value } = this.state;
    let textboxClass = '';
    if (status === 'success') textboxClass = classes.price__textbox_saved;
    if (status === 'failure') textboxClass = classes.price__textbox_failure;
    return (
      <div className={classes.price_field} onClick={(e) => e.stopPropagation()}>
        <span className={classes.price__dollar}>$</span>
        <div className={classes.price__input_wrap}>
          <TextBox
            type="text"
            className={`${classes.price__textbox} ${textboxClass}`}
            onChange={this.updateValue}
            onBlur={this.savePrice.bind(this, row)}
            inputClassName={classes.price__input}
            labelSROnly
            labelText="Number Input"
            value={value}
            cypressTag={CYPRESS_TAGS.INPUT}
          />
        </div>
      </div>
    );
  }
}

export default NegotiationTextbox;
