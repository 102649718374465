export default {
  CALCULATING: {
    path: '/assets/calculating.json',
    messages: ['Comparing offers', 'Selecting best bids', 'Finishing up...'],
  },
  OFFER_LOADER: {
    path: '/assets/offer_loader.json',
    messages: ['Verifying buyers', 'Awarding Bids', 'Finishing up...'],
  },
  CALCULATING_SHORT: {
    path: '/assets/calculating.json',
    messages: [''],
  },
};
