import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import mapboxgl from 'mapbox-gl';
import { Theme, AlertService } from '@spoiler-alert/ui-library';
import { fetchWithToken } from '../../services/fetch-with-token';
import appSettings from '../../app-settings';

const styles = {
  emptyMap: {
    height: '100%',
    width: 459,
    backgroundColor: Theme.grey5,
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    padding: 0,
    opacity: '0.5',
    borderRadius: 2,
  },
};
const useStyles = createUseStyles(styles);

const TruckRouteMap = ({ origin, destination, trucklaneId, logisticsTerm, loading }) => {
  const classes = useStyles();
  const [mapState, setMapState] = useState('LOADING');
  mapboxgl.accessToken = appSettings.MAP_BOX_KEY;
  const mapContainer = useRef(null);
  const map = useRef(null);

  const checkCache = () => {
    const cachedCoords = localStorage.getItem(`${trucklaneId}-coordinates`);
    if (cachedCoords) return JSON.parse(cachedCoords);
    return null;
  };

  const setCache = (coordinates) => {
    try {
      localStorage.setItem(`${trucklaneId}-coordinates`, JSON.stringify(coordinates));
    } catch (error) {
      // need to start clearing cache due to too many cached maps
      const regex = RegExp('coordinates');
      for (let i = 0; i < localStorage.length; i++) {
        if (regex.exec(localStorage.key(i))) localStorage.removeItem(localStorage.key(i));
      }
      localStorage.setItem(`${trucklaneId}-coordinates`, JSON.stringify(coordinates));
    }
  };

  const fetchMapCoordinates = async () => {
    try {
      if (logisticsTerm === 'Pickup') return [[origin.long, origin.lat]];
      const cachedData = checkCache();
      if (cachedData && cachedData.length) return cachedData;
      const locationUrl = `${appSettings.GRAPHQL_SERVER_URL}location/truck-route?origin=${origin.long},${origin.lat}&destination=${destination.long},${destination.lat}`;
      const options = { method: 'GET' };
      const result = await fetchWithToken(locationUrl, options);
      if (result.status !== 200) throw new Error(await result.text());
      const coordinates = await result.json();
      setCache(coordinates);
      return coordinates;
    } catch (error) {
      AlertService.alert({
        type: 'warning',
        autoDismiss: true,
        message: <span>{error.message}</span>,
      });
      throw new Error();
    }
  };

  useEffect(() => {
    if (!map.current && !loading) {
      const setupMap = async () => {
        try {
          const coordinates = await fetchMapCoordinates(origin, destination);
          const center = coordinates[Math.floor(coordinates.length / 2)];
          setMapState('VALID');
          map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/navigation-day-v1',
            center,
            zoom: 4,
            interactive: false,
            boxZoom: false,
            scrollZoom: false,
            dragPan: false,
            dragRotate: false,
            attributionControl: false,
          });
          map.current.on('load', () => {
            map.current
              .getStyle()
              .layers.filter((layer) => layer.source === 'mapbox-traffic')
              .forEach((layer) => map.current.removeLayer(layer.id));
            map.current.removeLayer('settlement-major-label');
            map.current.removeLayer('settlement-minor-label');
            if (logisticsTerm === 'Delivery') {
              map.current.addSource('route', {
                type: 'geojson',
                data: {
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'LineString',
                    coordinates,
                  },
                },
              });
              map.current.addLayer({
                id: 'route',
                type: 'line',
                source: 'route',
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                paint: {
                  'line-color': `${Theme.green}`,
                  'line-width': 6,
                },
              });
            }

            map.current.addSource('points', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features:
                  logisticsTerm === 'Delivery'
                    ? [
                        {
                          type: 'Feature',
                          geometry: {
                            type: 'Point',
                            coordinates: coordinates[0],
                          },
                          properties: {
                            title: 'Origin',
                          },
                        },
                        {
                          type: 'Feature',
                          geometry: {
                            type: 'Point',
                            coordinates: coordinates[coordinates.length - 1],
                          },
                          properties: {
                            title: 'Destination',
                          },
                        },
                      ]
                    : [
                        {
                          type: 'Feature',
                          geometry: {
                            type: 'Point',
                            coordinates: coordinates[0],
                          },
                          properties: {
                            title: 'Pickup',
                          },
                        },
                      ],
              },
            });
            map.current.addLayer({
              id: 'points',
              type: 'symbol',
              source: 'points',
              layout: {
                'icon-image': 'dot-11',
                'icon-size': 2,
                'text-field': ['get', 'title'],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 1.25],
                'text-size': 12,
                'text-anchor': 'top',
              },
            });
            const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[coordinates.length - 1]);
            map.current.fitBounds(bounds, { maxZoom: 6, padding: 40, animate: false });
          });
        } catch (error) {
          setMapState('ERRORED');
        }
      };
      setupMap();
    }

    return () => {
      if (map.current) map.current.remove();
    };
  }, [loading]);

  return (
    <div>
      {mapState !== 'VALID' && <div className={classes.emptyMap}>{mapState === 'LOADING' ? 'LOADING MAP...' : 'Error Loading Map'}</div>}
      {mapState === 'VALID' && <div style={{ width: '459px', height: '100%', borderRadius: '2px' }} ref={mapContainer} className="map-container" />}
    </div>
  );
};

TruckRouteMap.propTypes = {
  origin: PropTypes.shape({
    lat: PropTypes.number,
    long: PropTypes.number,
  }),
  destination: PropTypes.shape({
    lat: PropTypes.number,
    long: PropTypes.number,
  }),
  trucklaneId: PropTypes.string,
  logisticsTerm: PropTypes.string,
  loading: PropTypes.bool,
};

export default TruckRouteMap;
