import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import { GraphQLDataTable, Column, Modal, ModalContent, ModalFooter, Button, AlertService, OverlayService } from '@spoiler-alert/ui-library';
import { graphql } from '@apollo/client/react/hoc';
import { toggleImpersonateSite } from '../../graphql/mutations';
import { AllSitesQuery, UserQuery } from '../../graphql/queries';
import { TitleService } from '../../services';
import routePaths from '../../route-paths';
import { Breadcrumbs } from '../../store';

const styles = {
  action__buttons: {
    order: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  search__row: {
    justifyContent: 'flex-start',
  },
  select__box: {
    order: 0,
    marginRight: '12px',
  },
  search: {
    order: 2,
    marginLeft: 'auto',
  },
  filter__button: {
    order: 3,
  },
  'admin-container__wrapper': {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '1em',
  },
};

class SiteList extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('Site Impersonation');
    Breadcrumbs.set([
      {
        url: '/administration/impersonate',
        title: 'Site Impersonation',
      },
    ]);
    this.state = {
      modalOpen: false,
      impersonating: false,
      siteNameToImpersonate: null,
      siteIdToImpersonate: null,
    };
  }

  handleDynamicRowClick = (row) => {
    this.setState({ modalOpen: true, siteIdToImpersonate: row._id, siteNameToImpersonate: row.siteName });
  };

  hideModal = () => {
    this.setState({ modalOpen: false, siteIdToImpersonate: null, siteNameToImpersonate: null });
  };

  getSiteColumns() {
    const siteColumns = [
      new Column({ field: 'siteName', displayName: 'Site Name', sortable: true, defaultSort: true }),
      new Column({ field: 'physicalAddress', displayName: 'State', sortable: true, formatter: (value) => value.stateCode }),
      new Column({ field: 'isEnterprise', displayName: 'Enterprise Site', sortable: true, formatter: (value) => (value ? 'Yes' : 'No') }),
    ];
    return siteColumns;
  }

  error() {
    AlertService.alert({ type: 'warning', message: <span>Sorry there was an error impersonating.</span> });
  }

  impersonate = () => {
    this.setState({ impersonating: true });
    this.props
      .mutate({
        variables: { siteIdToImpersonate: this.state.siteIdToImpersonate },
        refetchQueries: [
          {
            query: UserQuery,
          },
        ],
      })
      .then(this.redirect)
      .catch(this.error);
  };

  redirect = () => {
    OverlayService.hide();
    this.props.history.push('/');
  };

  render() {
    const { classes } = this.props;
    return (
      <div id="all-sites">
        <div className={classes['admin-container__wrapper']}>
          <Link to={routePaths.addSite}>
            <Button type="button" link>
              Add Site
            </Button>
          </Link>
        </div>
        <GraphQLDataTable
          query={AllSitesQuery}
          queryName="currentUserQuery"
          queryField="getAllSites"
          userId={this.props.user._id}
          search
          transition
          columns={this.getSiteColumns()}
          onSelectChange={this.handleSelectChange}
          pagination
          perPage={15}
          perPageIncrements={[15, 30, 50, 100]}
          headerStyles={this.headerStyles}
          onRowClick={this.handleDynamicRowClick}
          onQueryChange={this.handleQueryChange}
        />
        <Modal onHide={this.hideModal} open={this.state.modalOpen} closeOnEsc closeOnOutsideClick>
          <ModalContent>
            <h2 className={classes['question-modal__title']}>Are you sure you want to impersonate {this.state.siteNameToImpersonate}?</h2>
          </ModalContent>
          <ModalFooter>
            <Button type="button" onClick={this.hideModal} link disabled={this.state.impersonating}>
              Cancel
            </Button>
            <Button
              type="button"
              onClick={this.impersonate}
              disabled={this.disabled}
              primary
              loading={this.state.impersonating}
              loadingText="Wait..."
            >
              Impersonate
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

SiteList.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object,
  mutate: PropTypes.func,
};

const StyledComponent = injectSheet(styles)(SiteList);
const GqlComponent = graphql(toggleImpersonateSite)(StyledComponent);
export default GqlComponent;
