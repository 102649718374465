const postStatuses = {
  opened: 0,
  claimed: 1,
  verifiedByPoster: 2,
  unverified: 3,
  completed: 4,
  archived: 5,
};

export default postStatuses;
