import React from 'react';
import { NavLink } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  SettingsLayout: {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  settings__navigation: {
    width: '200px',
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingRight: '24px',
  },
  contentCards: {
    display: 'flex',
    flexDirection: 'column',
  },
  settings__navlist: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    top: 5,
    position: 'sticky',
  },
  settings__listitem: {
    display: 'block',
    margin: 0,
    padding: 0,
    boxShadow: `0 1px 0 0 ${theme.borderColor}`,
    '&:last-child': {
      boxShadow: 'none',
    },
  },
  settings__navlink: {
    cursor: 'pointer',
    display: 'block',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    textDecoration: 'none',
    color: theme.textColorPrimary,
    padding: 10,
    marginTop: 1,
    '&:hover': {
      color: theme.textColorPrimary,
      backgroundColor: theme.menuItemHoverBg,
    },
    '&:active': {
      textDecoration: 'none',
    },
  },
  'settings__navlink--active': {
    fontWeight: 500,
    color: theme.primaryColor,
  },
  settings: {
    width: '100%',
    position: 'relative',
    zIndex: 5,
    paddingLeft: 258,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
};

const useStyles = createUseStyles(styles, { name: 'SettingsLayout' });

const SettingsLayout = ({ style, children, id, links }) => {
  const classes = useStyles();

  return (
    <div className={classes.SettingsLayout} style={style} id={id}>
      <nav className={classes.settings__navigation}>
        <ul className={classes.settings__navlist}>
          {links.map(({ path, label }) => {
            return (
              <li className={classes.settings__listitem} key={path}>
                <NavLink exact to={path} className={classes.settings__navlink} activeClassName={classes['settings__navlink--active']}>
                  {label}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className={classes.contentCards}>{children}</div>
    </div>
  );
};

SettingsLayout.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  style: PropTypes.object,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default SettingsLayout;
