import React from 'react';
import PropTypes from 'prop-types';
import checkFeature from './helpers/check-feature-flag';

const FeatureFlagSwitcher = ({ user, isOn, isOff, featureFlag, ...rest }) => {
  let Component = isOff;
  if (checkFeature(featureFlag)) Component = isOn;
  return <Component user={user} {...rest} />;
};

FeatureFlagSwitcher.propTypes = {
  user: PropTypes.object,
  isOn: PropTypes.any,
  isOff: PropTypes.any,
  featureFlag: PropTypes.string,
};

export default FeatureFlagSwitcher;
