import { fetchWithToken } from './fetch-with-token';
import AppSettings from '../app-settings';

class DataExportService {
  export = (exportType, params) => {
    return fetchWithToken(`${AppSettings.GRAPHQL_SERVER_URL}export/${exportType}`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };
}

const dataExportService = new DataExportService();
export default dataExportService;
