import { Theme } from '@spoiler-alert/ui-library';

const contactModalStyles = {
  modal__title: {
    fontSize: '24px',
    padding: 0,
    marginTop: 0,
    height: '25px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.42,
    letterSpacing: '-0.5px',
    textAlign: 'left',
    marginBottom: '24px',
  },
  modal__body: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    alignSelf: 'stretch',
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
  footer__container: {
    display: 'flex',
    justifyContent: 'end',
    padding: 24,
    borderTop: `1px solid ${Theme.modalFooterBorderColor}`,
    alignItems: 'center',
  },
  footer__container_space_between: {
    justifyContent: 'space-between',
  },
  modal__cancel: {
    marginRight: 8,
  },
  modal__submit: {
    display: 'flex',
  },
  input__row: {
    padding: '6px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  form__container: {
    width: '100%',
  },
  input__container: {
    padding: '0 12px',
    flexGrow: 1,
  },
  row: {
    marginBottom: 24,
  },
  email: {
    width: '48%',
  },
  warningIcon: {
    width: 16,
    height: 16,
    marginRight: 7,
    fill: Theme.red,
  },
  errorMessage: {
    fontSize: 12,
    color: Theme.red,
    display: 'flex',
    justifyContent: 'start',
  },
  contactType: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: Theme.grey,
    marginBottom: 3,
  },
  deleteButton: {
    marginRight: '8px',
    color: Theme.red,
    borderColor: Theme.red,
    '&:hover': {
      backgroundColor: `${Theme.red} !important`,
      color: `${Theme.white} !important`,
      borderColor: `${Theme.red} !important`,
    },
  },
  deleteButtonNoBorder: {
    border: 'none',
    '&:hover': {
      color: `${Theme.red} !important`,
      backgroundColor: `${Theme.white} !important`,
    },
  },
  infoIcon: {
    fill: Theme.red,
    width: 16,
    height: 16,
    marginTop: '3px',
  },
  infoIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: '16px',
  },
  modalInfo: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 4,
    padding: '13px 17px 13px 17px',
    backgroundColor: Theme.red5,
    fontSize: 14,
    marginBottom: 24,
  },
  zeroStateText: {
    fontSize: 16,
    color: Theme.greyDark,
    margin: 'auto',
    width: '228px',
  },
  zeroStateInfo: {
    fontSize: 16,
    color: Theme.greyDark,
    fontWeight: 500,
    marginBottom: 16,
  },
  zeroStateContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  zeroStateIconContainer: {
    display: 'flex',
    margin: 'auto',
  },
  deleteZeroStateText: {
    marginLeft: 12,
  },
  tableTitle: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: 10,
    color: Theme.greyDark,
  },
  tableContainer: {
    marginBottom: 12,
  },
};

export default contactModalStyles;
