import React from 'react';
import moment from 'moment';
import { Column, StatusBadge, Theme as theme } from '@spoiler-alert/ui-library';
import ProxyHelper from '../../utilities/proxy-helper';
import ErrorDownload from './error-download';

const statusMap = new Map([
  [0, <StatusBadge status="PENDING" key="status" />],
  [1, <StatusBadge status="RUNNING" key="status" />],
  [2, <StatusBadge status="COMPLETED" key="status" />],
  [3, <StatusBadge status="COMPLETED" key="status" />],
  [4, <StatusBadge status="FAILED" key="status" />],
]);

async function downloadFile(ev, id, type, fileName) {
  ev.preventDefault();
  ProxyHelper.downloadFile(id, type, fileName);
}

export function getColumns() {
  const bulkImportColumns = [
    new Column({
      field: 'importFile',
      displayName: 'Name',
      sortable: true,
      formatter(value, row) {
        const fileName = value.split('/').pop();
        return (
          <a
            href={`/proxy/importfile/${row._id}/${fileName}`}
            download={fileName}
            onClick={(ev) => {
              downloadFile(ev, row._id, 'importfile', fileName);
            }}
          >
            {fileName}
          </a>
        );
      },
    }),
    new Column({
      field: 'whenLoaded',
      displayName: 'Date',
      sortable: true,
      defaultSort: true,
      defaultSortDirection: 'desc',
      formatter: (value) => moment(value).format('M/DD/YYYY h:mm a'),
    }),
    new Column({ field: 'userName', displayName: 'User', sortable: true }),
    new Column({ field: 'siteName', displayName: 'Site', sortable: true }),
    new Column({ field: 'importType', displayName: 'Type', sortable: true }),
    new Column({
      field: 'totalRows',
      displayName: 'Processed',
      sortable: false,
    }),
    new Column({ field: 'totalImported', displayName: 'Added', sortable: true }),
    new Column({
      field: 'totalErrors',
      displayName: 'Errors',
      sortable: false,
      formatter(value, row) {
        if (value === 0) return 0;
        const fileName = row.errorReportFile.split('/').pop();
        return (
          <a
            href={`/proxy/errorreport/${row._id}/${fileName}`}
            style={{ color: theme.errorColor, fontWeight: 'bold' }}
            download={fileName}
            onClick={(ev) => {
              downloadFile(ev, row._id, 'errorreport', fileName);
            }}
          >
            {value} <ErrorDownload />
          </a>
        );
      },
    }),
    new Column({
      field: 'status',
      displayName: 'Status',
      sortable: true,
      formatter: (value, row, col) => col.enumMap.get(value),
      enumMap: statusMap,
    }),
  ];
  return bulkImportColumns;
}

export { statusMap };
