import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Button } from '@spoiler-alert/ui-library';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import queryString from 'query-string';
import { LoginService, TitleService } from '../../services';
import routePaths from '../../route-paths';
import styles from './sign-in-styles';

const useStyles = createUseStyles(styles);

const SSOSignIn = ({ history, match }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const isAuthenticated = () => !!localStorage.getItem('token');

  useEffect(() => {
    TitleService.setTitleTag('Sign In with SSO');
    if (isAuthenticated()) history.push(routePaths.home);
  }, []);

  const getURI = async () => {
    const { path } = match.params;
    setLoading(true);
    LoginService.getProviderURI(email, path)
      .then(async (res) => {
        if (!res.ok) {
          const err = await res.text();
          setError(err);
          setLoading(false);
        } else {
          const data = await res.json();
          window.location.href = data.authorizationURI;
        }
      })
      .catch((errorMsg) => {
        setError(errorMsg);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (match.params.path) getURI();
  }, [match.params.path]);

  useEffect(() => {
    const values = queryString.parse(location.search);
    const { errorMessage, redirect } = values;
    if (errorMessage) setError(errorMessage);
    if (redirect) {
      localStorage.setItem('redirect', decodeURIComponent(redirect));
    }
  }, [location.search]);

  const emailHandler = (_email) => {
    setEmail(_email);
    setError(null);
  };

  const submitLogin = async (event) => {
    event.preventDefault();
    getURI();
  };

  return (
    <div className={classes.loginContainer}>
      <h4 className={classes.loginTitle}>Sign in using SSO</h4>
      {error && (
        <div className={classes.errorContainer} data-element="error-message">
          {error}
        </div>
      )}
      <form onSubmit={submitLogin} className={classes.formContainer}>
        <div className={classes.formRow}>
          <TextInput onChange={emailHandler} id="email" required type="email" labelText="Email" value={email} autoComplete="username" />
        </div>

        <div className={classes.formRow}>
          <Button className={classes.formButton} id="sign-in" type="submit" loading={loading} loadingText="Loading...">
            Sign In
          </Button>
        </div>
      </form>
      <div className={classes.footerContainer}>
        <Link className={classes.loginLink} to={routePaths.signIn}>
          Sign in with email and password
        </Link>
      </div>
    </div>
  );
};

SSOSignIn.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default SSOSignIn;
