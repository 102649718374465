import LocalizedStrings from 'react-localization';

const RefreshStrings = new LocalizedStrings({
  en: {
    refresherText: 'There is a newer version of Spoiler Alert available.',
    refreshButton: 'REFRESH',
  },
});

export default RefreshStrings;
