import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { event } from 'react-fullstory';
import { SelectOptionGroup, SelectOption, Select, LoadingLarge } from '@spoiler-alert/ui-library';
import { DASHBOARD_LOCATIONS, DASHBOARD_TYPES } from '../enums/holistics-dashboard-enums';
import { HolisticsDashboardsQuery } from '../graphql/queries';
import { TitleService } from '../services';
import { Breadcrumbs } from '../store';
import routePaths from '../route-paths';

const useStyles = createUseStyles(
  {
    loadingContainer: {
      height: 'calc(100vh - 75px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    controls: {
      marginBottom: 8,
    },
    select: {
      width: 523,
    },
    iframeWrapper: {
      height: 'calc(100vh - 135px)',
    },
  },
  { name: 'HolisticsDashboard' }
);

const HolisticsDashboard = ({ locationDefault }) => {
  const classes = useStyles();
  const [activeDashboard, setActiveDashboard] = useState(null);
  const { data, loading } = useQuery(HolisticsDashboardsQuery);

  useEffect(() => {
    let title = 'Dashboard';
    let url = '/';
    if (locationDefault === DASHBOARD_LOCATIONS.REPORTS_TAB) {
      title = 'Reports';
      url = routePaths.reportsDashboard;
    }
    TitleService.setTitles(title);
    Breadcrumbs.set([
      {
        url,
        title,
      },
    ]);
  }, []);

  const dashboards = data?.holisticsDashboardsQuery || [];

  useEffect(() => {
    if (dashboards.length > 0 && locationDefault) {
      const defaultDashboards = dashboards.filter((d) => d.defaultForLocation === DASHBOARD_LOCATIONS[locationDefault]);
      let _activeDash = defaultDashboards[0];
      if (defaultDashboards.length > 1) {
        const custom = defaultDashboards.find((dd) => dd.type === DASHBOARD_TYPES.CUSTOM);
        if (custom) _activeDash = custom;
      } else {
        [_activeDash] = dashboards;
      }
      event('Viewed Holistics Dashboard', { dashboardName: _activeDash.name, defaultViewOnPageLoad: true });
      setActiveDashboard(_activeDash);
    }
  }, [dashboards, locationDefault]);

  const iframeUrl = useMemo(
    () => (activeDashboard ? `https://us.holistics.io/embed/${activeDashboard.embed.embedCode}?_token=${activeDashboard.embed.token}` : ''),
    [activeDashboard]
  );

  const dropdownOptions = useMemo(() => {
    const coreReports = [];
    const customReports = [];
    if (dashboards.length > 0) {
      [...dashboards]
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        .forEach((d) => {
          if (d.type === DASHBOARD_TYPES.CORE) coreReports.push(d);
          if (d.type === DASHBOARD_TYPES.CUSTOM) customReports.push(d);
        });
    }
    const options = [];
    if (coreReports.length > 0)
      options.push(
        <SelectOptionGroup key="core" name="CORE" search>
          {coreReports.map((r) => (
            <SelectOption key={r._id} value={r._id}>
              {r.name}
            </SelectOption>
          ))}
        </SelectOptionGroup>
      );
    if (customReports.length > 0)
      options.push(
        <SelectOptionGroup key="custom" name="CUSTOM" search>
          {customReports.map((r) => (
            <SelectOption key={r._id} value={r._id}>
              {r.name}
            </SelectOption>
          ))}
        </SelectOptionGroup>
      );
    return options;
  }, [dashboards]);

  const selectedItem = activeDashboard ? [{ value: activeDashboard._id, text: activeDashboard.name }] : [];

  const handleReportChange = (options) => {
    if (options.length > 0) {
      const _active = dashboards.find((d) => d._id === options[0].value);
      setActiveDashboard(_active);
      event('Viewed Holistics Dashboard', { dashboardName: _active.name, defaultViewOnPageLoad: false });
    }
  };

  return (
    <div>
      {loading ? (
        <div className={classes.loadingContainer}>
          <LoadingLarge />
        </div>
      ) : (
        <>
          <div className={classes.controls}>
            <Select containerClassName={classes.select} label="Select report" search selectedItems={selectedItem} onChange={handleReportChange}>
              {dropdownOptions}
            </Select>
          </div>
          <div className={classes.iframeWrapper}>
            {iframeUrl !== '' && (
              <iframe src={iframeUrl} id="embedded-iframe" style={{ width: '100%', height: '100%' }} frameBorder="0" allowFullScreen></iframe>
            )}
          </div>
        </>
      )}
    </div>
  );
};

HolisticsDashboard.propTypes = {
  locationDefault: PropTypes.string,
};

export default HolisticsDashboard;
