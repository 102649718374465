import React from 'react';
import { Modal, ModalContent, ModalFooter, AlertWarningIcon, Button, Theme as theme, OverlayService } from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useMutation } from '@apollo/client';
import { ArchiveDistributionList } from '../../../graphql/mutations';
import { DistributionListsWithBuyersQuery } from '../../../graphql/queries';

const styles = {
  warningContent: {
    display: 'flex',
    alignItems: 'baseline',
    backgroundColor: theme.red5,
    width: '639px',
    padding: '12px',
    borderRadius: '4px',
    fontSize: '16px',
  },
  warningText: {
    marginLeft: '12px',
  },
  infoIcon: {
    fill: theme.red,
    width: '16px',
  },
};

const ArchiveDistributionListWarningModal = ({
  setShowArchiveWarning,
  showArchiveWarning,
  listName,
  listId,
  siteId,
  basePath,
  history,
  flashSnackbar,
}) => {
  const useStyles = createUseStyles(styles, { name: 'ArchiveDistributionListWarningModal' });
  const classes = useStyles();

  const [archiveDistributionList] = useMutation(ArchiveDistributionList);

  const handleArchive = () => {
    archiveDistributionList({
      variables: { distributionListId: listId },
      refetchQueries: [{ query: DistributionListsWithBuyersQuery, variables: { siteId } }],
    }).then((res) => {
      setShowArchiveWarning(false);
      OverlayService.hide();
      if (res.data.archiveDistributionList.errors.length > 0) {
        AlertService.alert({
          type: 'warning',
          message: (
            <span>
              {`We were unable to archive the distribution list due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.`}
            </span>
          ),
          autoDismiss: true,
        });
      } else {
        flashSnackbar(`The list, ${listName}, was archived successfully`);
      }
      history.push(basePath);
    });
  };

  const handleCancelArchive = () => {
    setShowArchiveWarning(false);
    OverlayService.hide();
  };
  return (
    <Modal open={showArchiveWarning} closeOnEsc closeOnOutsideClick onHide={handleCancelArchive}>
      <ModalContent>
        <div data-testid="modal-body">
          <div className={classes.warningContent}>
            <div>
              <AlertWarningIcon className={classes.infoIcon} />
            </div>
            <div
              className={classes.warningText}
            >{`Archiving the ${listName} list will make it immediately unavailable and can only be unarchived by support.`}</div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button testId="submit" type="button" warning onClick={handleArchive}>
          Archive List
        </Button>
        <Button testId="cancel" type="button" onClick={handleCancelArchive} secondary>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ArchiveDistributionListWarningModal.propTypes = {
  setShowArchiveWarning: PropTypes.func,
  showArchiveWarning: PropTypes.bool,
  listName: PropTypes.string,
  listId: PropTypes.string,
  siteId: PropTypes.string,
  basePath: PropTypes.string,
  history: PropTypes.object,
  flashSnackbar: PropTypes.func,
};

export default ArchiveDistributionListWarningModal;
