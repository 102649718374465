import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Theme as theme, StatusBadge } from '@spoiler-alert/ui-library';
import { customerParticipationStatus } from '../../enums';

const useStyles = createUseStyles({
  scroll: {
    overflowY: 'auto',
    // 100vh - cps content above table - space between top of vh and cps tooltip - margin below table - desired space between bottom of screen - table header
    maxHeight: 'calc(100vh - 288px - 104px - 24px - 24px - 33px)',
  },
  cell: {
    height: 20,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    textAlign: 'left',
    color: theme.greyDark,
  },
  table: {
    width: '100%',
    maxHeight: '100%',
    borderSpacing: '0px',
    borderCollapse: 'separate',
    overflow: 'auto',
  },
  th: {
    extend: 'cell',
    padding: '6.5px 12px',
    backgroundColor: theme.grey10,
    fontWeight: 500,
    position: 'sticky',
    top: 0,
  },
  th1: {
    extend: 'th',
    borderRadius: '8px 0px 0px 0px',
    width: '55%',
  },
  th2: {
    extend: 'th',
    borderRadius: '0px 8px 0px 0px',
    width: '45%',
  },
  tr: {
    '&:hover': {
      backgroundColor: theme.grey5,
    },
  },
  customerName: {
    extend: 'cell',
    padding: '10px 12px',
    fontWeight: 'normal',
    width: '55%',
  },
  customerStatus: {
    padding: '8px 5px',
    height: 20,
    width: '45%',
  },
  noMatches: {
    extend: 'cell',
    padding: '24px 0px',
    textAlign: 'center',
  },
});

const CustomerParticipationSummaryTable = ({ customerInfo }) => {
  const classes = useStyles();
  const sortedCustomerInfo = useMemo(() => {
    return customerInfo.sort((a, b) => {
      if (a.status === b.status) return a.buyerSiteName.localeCompare(b.buyerSiteName);
      if (a.status === customerParticipationStatus.OFFERS_RECEIVED) return -1;
      return 1;
    });
  }, [customerInfo]);

  return (
    <div>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.th1}>Customer</th>
            <th className={classes.th2}>Status</th>
          </tr>
        </thead>
      </table>
      <div className={classes.scroll}>
        <table className={classes.table}>
          <tbody>
            {customerInfo?.length > 0 ? (
              sortedCustomerInfo.map((customer) => (
                <tr key={customer.buyerSiteName} className={classes.tr}>
                  <td className={classes.customerName}>{customer.buyerSiteName}</td>
                  <td className={classes.customerStatus}>
                    <StatusBadge status={customer.status} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className={classes.noMatches}>
                  No customers match your search.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

CustomerParticipationSummaryTable.propTypes = {
  customerInfo: PropTypes.arrayOf(PropTypes.object),
};

export default CustomerParticipationSummaryTable;
