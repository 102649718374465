import { Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  grid: {
    display: 'grid',
    'grid-template-columns': 'repeat(2, 1fr)',
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignContent: 'space-between',
  },
  title: {
    display: 'flex',
    padding: '12px 0px',
  },
  item: {
    flexBasis: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  logisticDetailsHeader: {
    color: theme.grey,
    fontSize: 12,
    fontWeight: 400,
    marginRight: 8,
  },
  logisticDetailsTitle: {
    color: theme.grey,
    fontSize: 14,
    marginRight: 12,
    width: 75,
    marginBottom: 4,
  },
  logisticsDetailsContent: {
    color: theme.greyDark,
    fontSize: 14,
    marginBottom: 4,
    marginRight: 24,
  },
  icon: {
    height: 24,
    width: 24,
    fill: theme.grey,
  },
  iconMetric: {
    height: 16,
    width: 16,
    marginRight: 8,
    fill: `${theme.grey}`,
  },
  removePadding: {
    padding: '0 !important',
    marginBottom: 25,
  },
  border: {
    borderRight: `1px solid ${theme.borderColor}`,
  },
  floatRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  unawardAll: {
    color: `${theme.red}`,
    background: 'none',
    fill: `${theme.red}`,
    '&:hover': {
      border: `1px solid ${theme.red} !important`,
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: `${theme.red} !important`,
    },
  },
  tableBorder: {
    marginBottom: 25,
    borderLeft: `1px solid ${theme.borderColor}`,
    borderTop: `1px solid ${theme.borderColor}`,
    borderBottom: `1px solid ${theme.borderColor}`,
  },
  tableFooter: {
    backgroundColor: `${theme.blue5} !important`,
    boxShadow: `${theme.borderColor} 0px 1px inset !important`,
  },
  emptyState: {
    marginTop: 12,
    '& h1': {
      fontSize: 16,
      color: theme.greyDark,
    },
    '& svg': {},
  },
  offerCardBorder: {
    borderTop: `1px solid ${theme.borderColor}`,
    borderLeft: `1px solid ${theme.borderColor}`,
  },
  negativeTotalValue: {
    color: theme.red,
  },
};

export default styles;
