import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import accounting from 'accounting';
import { RadioButtonCard, TextInput, Theme as theme } from '@spoiler-alert/ui-library';

const useStyles = createUseStyles({
  title: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    textAlign: 'left',
    margin: 0,
  },
  titleDescription: {
    fontSize: '14px',
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    marginBottom: 20,
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    textAlign: 'left',
    margin: [10, 0, 12, 0],
  },
  subtitleDescription: {
    fontSize: '12px',
    lineHeight: 1.33,
    fontWeight: 500,
    letterSpacing: 'normal',
    textAlign: 'left',
    marginBottom: 12,
  },
  cardWrapper: {
    position: 'relative',
    paddingBottom: '16px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputContainer: {
    width: 161,
  },
  comingSoon: {
    height: 18,
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    padding: [1, 4],
    borderRadius: 3,
    background: theme.teal,
    color: theme.white,
    cursor: 'not-allowed',
    fontSize: '12px',
  },
  megaphone: {
    fill: theme.white,
    width: 12,
    height: 12,
  },
  lineBreak: {
    marginTop: '40px',
    paddingBottom: '36px',
    padding: 0,
  },
});

export const TRUCKLANE_MINIMUM_MODE = {
  TOTAL_REVENUE: 'TOTAL_REVENUE',
  NET_REVENUE: 'NET_REVENUE',
};

const emptyTruckTypeMinimum = () => ({
  dry: 0,
  refrigerated: 0,
  frozen: 0,
});

export const initializeStateFromDB = (trucklaneMinimums) => {
  if (!trucklaneMinimums) {
    return { mode: TRUCKLANE_MINIMUM_MODE.TOTAL_REVENUE, delivery: emptyTruckTypeMinimum(), pickup: emptyTruckTypeMinimum() };
  }
  const { delivery, pickup } = trucklaneMinimums;
  return {
    mode: trucklaneMinimums.mode || null,
    delivery: {
      dry: delivery.dry || 0,
      refrigerated: delivery.refrigerated || 0,
      frozen: delivery.frozen || 0,
    },
    pickup: {
      dry: pickup.dry || 0,
      refrigerated: pickup.refrigerated || 0,
      frozen: pickup.frozen || 0,
    },
  };
};

export const minimumReducer = (state, action) => {
  if (action.type === 'initializeState') {
    initializeStateFromDB(action.dbState);
  }
  const { mode, delivery, pickup } = state;
  const newState = {
    mode,
    delivery: { ...delivery },
    pickup: { ...pickup },
  };
  if (action.type === 'setMinimum') newState[action.term][action.truckType] = action.value;
  if (action.type === 'setMode') newState.mode = action.value;
  return newState;
};

const SmartAwardSettingsInput = ({ userMinimums, setMode, setMinimum, setTouched, style }) => {
  const classes = useStyles();

  const modeOptions = [
    {
      label: 'Minimum Truck Revenue',
      value: TRUCKLANE_MINIMUM_MODE.TOTAL_REVENUE,
      description: (
        <span>
          Set a minimum revenue required to run a truck lane. Smart Awarding will not suggest truck lanes below your set minimums. <br />
          <br /> Useful to eliminate small revenue truck lanes.
        </span>
      ),
    },
    {
      label: 'Minimum Truck Net Revenue',
      value: TRUCKLANE_MINIMUM_MODE.NET_REVENUE,
      description: (
        <span>
          Set a minimum net revenue required to run a truck lane. Net Revenue is defined as revenue minus the estimated delivery cost. <br />
          <br />
          Useful to ensure that delivery cost is covered by the revenue of each truck lane.
        </span>
      ),
    },
  ];

  return (
    <>
      <h1 className={`${style?.title} ${classes.title}`}>Smart Awarding Settings</h1>
      <div className={classes.titleDescription}>
        Customize your Smart Awarding preferences to automatically build viable truck lanes by revenue or net revenue. Once set, Smart Awarding will
        suggest viable truck lanes that maximize your selected revenue type.
      </div>
      <h2 className={classes.subtitle}>Calculation Type</h2>
      <div className={classes.cardWrapper}>
        <RadioButtonCard onChange={setMode} options={modeOptions} selected={userMinimums.mode} />
      </div>
      <h2 className={classes.subtitle}>Delivery truck lane minimums</h2>
      <div className={classes.subtitleDescription}>Truck type</div>
      <div className={classes.row}>
        <div className={classes.inputContainer}>
          <TextInput
            type="text"
            value={accounting.formatMoney(userMinimums.delivery?.dry)}
            labelText="Dry"
            onChange={() => setTouched(true)}
            onBlur={setMinimum.bind(this, 'delivery', 'dry')}
          />
        </div>
        <div className={classes.inputContainer}>
          <TextInput
            type="text"
            value={accounting.formatMoney(userMinimums.delivery?.refrigerated)}
            labelText="Refrigerated"
            onChange={() => setTouched(true)}
            onBlur={setMinimum.bind(this, 'delivery', 'refrigerated')}
          />
        </div>
        <div className={classes.inputContainer}>
          <TextInput
            type="text"
            value={accounting.formatMoney(userMinimums.delivery?.frozen)}
            labelText="Frozen"
            onChange={() => setTouched(true)}
            onBlur={setMinimum.bind(this, 'delivery', 'frozen')}
          />
        </div>
      </div>
      <hr className={classes.lineBreak} />
      <h2 className={classes.subtitle}>Pickup truck lane minimums</h2>
      <div className={classes.subtitleDescription}>Truck type</div>
      <div className={classes.row}>
        <div className={classes.inputContainer}>
          <TextInput
            type="text"
            value={accounting.formatMoney(userMinimums.pickup?.dry)}
            labelText="Dry"
            onChange={() => setTouched(true)}
            onBlur={setMinimum.bind(this, 'pickup', 'dry')}
          />
        </div>
        <div className={classes.inputContainer}>
          <TextInput
            type="text"
            value={accounting.formatMoney(userMinimums.pickup?.refrigerated)}
            labelText="Refrigerated"
            onChange={() => setTouched(true)}
            onBlur={setMinimum.bind(this, 'pickup', 'refrigerated')}
          />
        </div>
        <div className={classes.inputContainer}>
          <TextInput
            type="text"
            value={accounting.formatMoney(userMinimums.pickup?.frozen)}
            labelText="Frozen"
            onChange={() => setTouched(true)}
            onBlur={setMinimum.bind(this, 'pickup', 'frozen')}
          />
        </div>
      </div>
    </>
  );
};

const TruckTypeMinimums = PropTypes.shape({
  dry: PropTypes.number,
  refrigerated: PropTypes.number,
  frozen: PropTypes.number,
});

SmartAwardSettingsInput.propTypes = {
  userMinimums: PropTypes.shape({
    mode: PropTypes.string,
    delivery: TruckTypeMinimums,
    pickup: TruckTypeMinimums,
  }),
  setMinimum: PropTypes.func,
  setMode: PropTypes.func,
  setTouched: PropTypes.func,
  style: PropTypes.object,
};

export default SmartAwardSettingsInput;
