import { gql } from '@apollo/client';

export const StatusQuery = gql`
  query inventoryStatusByIdQuery($inventoryId: String!) {
    inventoryStatusByIdQuery(inventoryId: $inventoryId) {
      stagedListings {
        _id
        distributionListName
      }
      publishedStagedListings {
        _id
        distributionListName
      }
      offerListings {
        _id
        status
        buyerSiteName
        totalPrice
        quantity
      }
    }
  }
`;
