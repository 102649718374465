import { gql } from '@apollo/client';
export const OfferListingFragment = gql `
  fragment OfferListingDetail on OfferListingType {
    _id
    buyerDestinationName
    buyerProposedQuantity
    buyerProposedTotalPrice
    buyerSiteId
    buyerSiteName
    conversionRate
    costRecoveryRate
    createdAt
    currencyType
    destinationType
    discountRate
    exportRunId
    exportStatus
    ftpImportUsername
    importedCurrencyTotalPrice
    importedCurrencyType
    inventoryId
    listingId
    logisticsCost
    logisticsRelationshipName
    logisticsTerm
    logisticsTime
    negotiationListingId
    negotiationStatus
    offerId
    originalQuantity
    originalTotalPrice
    poNumber
    priceOfInventoryAwarded
    quantity
    requestedDeliveryDate
    reserveAnchor
    reserveNumber
    sellerSiteName
    status
    tags
    totalPrice
    transactionCycleId
    transactionLocationId
    unitPriceRecoveryRate
    weightUnit
    writeOffDiscountPercentage
    suggestedUnitPrice
    trucklaneDistance
    trucklaneCost
    suggestions {
      award {
        suggested
        quantity
      }
    }
  }
`;
