import React from 'react';
import { ModalContent, Button, AlertWarningIcon } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import archiveInventoryModalStyles from './archive-inventory-modal-styles';

const useStyles = createUseStyles(archiveInventoryModalStyles);

const AllAcceptedArchiveInventoryModalContent = ({ cancel }) => {
  const classes = useStyles();
  const modalTitle = 'Archive Inventory';
  const modalInfo = 'The selected inventory cannot be archived because it has accepted offers. Contact support if you need to reconcile offers.';
  return (
    <div>
      <ModalContent>
        <div data-testid="modal-title" className={classes.modal__title}>
          {modalTitle}
        </div>
        <div data-testid="modal-info" className={classes.modalInfo} style={{ marginBottom: 0 }}>
          <div className={classes.infoIconContainer}>
            <AlertWarningIcon className={classes.infoIcon} />
          </div>
          {modalInfo}
        </div>
      </ModalContent>
      <div className={classes.footer__container}>
        <div className={classes.modal__submit}>
          <Button data-testid="cancel-button" className={classes.modal__cancel} type="button" onClick={cancel} secondary>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

AllAcceptedArchiveInventoryModalContent.propTypes = {
  cancel: PropTypes.func,
};

export default AllAcceptedArchiveInventoryModalContent;
