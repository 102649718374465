import { makeAutoObservable } from 'mobx';
const localStorageKey = 'usersFilter';
class UserFiltersStore {
    constructor() {
        this.filters = JSON.parse(localStorage.getItem(localStorageKey)) || {};
        makeAutoObservable(this);
    }
    updateFilters(name, val) {
        this.filters[name] = val;
        localStorage.setItem(localStorageKey, JSON.stringify(this.filters));
    }
}
export default UserFiltersStore;
