import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { graphql } from '@apollo/client/react/hoc';
import { Modal, ModalContent, ModalFooter, Button } from '@spoiler-alert/ui-library';
import { RejectPosts } from '../../graphql/mutations';

const styles = {
  'question-modal__title': {
    fontSize: '1.5rem',
    padding: 0,
    marginTop: 0,
    color: '#292e34',
    fontWeight: 'normal',
  },
  input__row: {
    padding: '6px 0',
  },
};

@graphql(RejectPosts)
@injectSheet(styles)
export default class RejectModal extends Component {
  state = {
    open: false,
    posts: this.props.posts,
    allSelected: this.props.allSelected,
    total: this.props.total,
    rejecting: false,
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      open: nextProps.open,
      posts: nextProps.posts,
      allSelected: nextProps.allSelected,
      total: nextProps.total,
    };
  }

  cancel = () => {
    this.props.close();
  };

  reject = () => {
    const { refetchQueries, mutate } = this.props;
    this.setState({ rejecting: true });
    const variables = { ids: this.state.allSelected ? [] : this.state.posts.map((post) => post._id) };
    mutate({ variables, refetchQueries }).then(this.hide).catch(this.hide);
  };

  hide = () => {
    this.setState({ rejecting: false });
    this.props.close();
  };

  get total() {
    return this.state.allSelected ? this.state.total : this.state.posts.length;
  }

  get postText() {
    return this.total === 1 ? 'this donation' : `these ${this.total} donations`;
  }

  render() {
    const { classes } = this.props;
    return (
      <Modal onHide={this.props.onHide} open={this.state.open} closeOnEsc closeOnOutsideClick>
        <ModalContent>
          <h2 className={classes['question-modal__title']}>Reject Donations</h2>
          <p>Are you sure you want to reject this {this.postText}?</p>
        </ModalContent>
        <ModalFooter>
          <Button type="button" onClick={this.cancel} link disabled={this.state.verifying}>
            Cancel
          </Button>
          <Button type="button" onClick={this.reject} primary loading={this.state.rejecting} loadingText="Wait...">
            Reject
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

RejectModal.propTypes = {
  classes: PropTypes.object,
  onHide: PropTypes.func,
  open: PropTypes.bool,
  close: PropTypes.func,
  posts: PropTypes.array,
  total: PropTypes.number,
  allSelected: PropTypes.bool,
  mutate: PropTypes.func,
  refetchQueries: PropTypes.array,
};
