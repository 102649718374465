import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Modal, ModalContent, Button, InformationIcon } from '@spoiler-alert/ui-library';
import pluralize from 'pluralize';
import removeOrReverseTransactionsStyles from './remove-or-reverse-transactions-modal-styles';

const useStyles = createUseStyles(removeOrReverseTransactionsStyles);

const RemoveOrReverseTransactionsModal = ({ onHide, open, handleSubmit, loading, action, transactionCount, transactionAmount }) => {
  const classes = useStyles();

  const cancel = () => {
    onHide(false);
  };

  const submit = (ev) => {
    ev.preventDefault();
    handleSubmit();
  };

  const capitalizedAction = action.charAt(0).toUpperCase() + action.slice(1);
  const modalTitle = `${capitalizedAction} transactions`;
  const modalBody = `Are you sure you want to ${action.toLowerCase()} ${transactionCount} ${pluralize(
    'transactions',
    transactionCount
  )} worth ${transactionAmount}? This can not be undone.`;
  const modalInfo =
    action === 'remove'
      ? 'Removing transactions after a cycle has closed means the bid will be completely removed from the platform as if it were never submitted. The inventory associated with the transaction will become leftover.'
      : 'Reversing transactions after a cycle has closed means the bid will be unaccepted and unawarded, but the original bid will remain. The inventory associated with the transaction will become left over.';
  return (
    <Modal onHide={onHide} open={open} closeOnEsc closeOnOutsideClick>
      <form onSubmit={submit} className={classes.form__container}>
        <ModalContent>
          <div data-testid="modal-title" className={classes.modal__title}>
            {modalTitle}
          </div>
          <div data-testid="modal-body" className={classes.modal__body}>
            {modalBody}
          </div>
          <div className={classes.modalInfo}>
            <div className={classes.infoIconContainer}>
              <InformationIcon className={classes.infoIcon} />
            </div>
            {modalInfo}
          </div>
        </ModalContent>
        <div className={classes.footer__container}>
          <div className={classes.modal__submit}>
            <Button
              className={classes.actionButton}
              testId="submit"
              type="submit"
              secondary
              loadingText={`${action === 'remove' ? 'Removing' : 'Reversing'} Transactions`}
              loading={loading}
            >
              {`${capitalizedAction} ${transactionCount} ${pluralize('Transaction', transactionCount)}`}
            </Button>
            <Button testId="cancel" className={classes.modal__cancel} type="button" onClick={cancel} secondary disabled={loading}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

RemoveOrReverseTransactionsModal.propTypes = {
  onHide: PropTypes.func,
  open: PropTypes.bool,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  action: PropTypes.string,
  transactionCount: PropTypes.number,
  transactionAmount: PropTypes.string,
};

export default RemoveOrReverseTransactionsModal;
