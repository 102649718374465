import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Modal, ModalContent, ModalFooter, Button } from '@spoiler-alert/ui-library';

const styles = {
  'error-modal__title': {
    fontSize: '1.5rem',
    padding: 0,
    marginTop: 0,
    color: '#292e34',
    fontWeight: 'normal',
  },
};

@injectSheet(styles)
export default class ErrorModal extends Component {
  state = {
    open: false,
    message: '',
    loading: false,
    error: '',
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      open: nextProps.open,
      error: nextProps.error,
    };
  }

  close = () => {
    this.props.onHide();
  };

  render() {
    const { classes } = this.props;
    return (
      <Modal onHide={this.props.onHide} open={this.state.open} closeOnEsc closeOnOutsideClick>
        <ModalContent>
          <h2 className={classes['error-modal__title']}>Uh Oh!</h2>
          <p>Something went wrong. Please try again.</p>
        </ModalContent>
        <ModalFooter>
          <Button type="button" onClick={this.close} primary>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ErrorModal.propTypes = {
  classes: PropTypes.object,
  onHide: PropTypes.func,
  open: PropTypes.bool,
  error: PropTypes.object,
};
