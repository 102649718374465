import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectOption } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import filterStyles from './filter-styles';

@injectSheet(filterStyles)
export default class SupplierFilter extends React.Component {
  constructor(props) {
    super(props);
    this.suppliers = this.getSupplierList();
    this.state = {
      values: this.suppliers.map((s) => ({ value: s.value, text: s.text })),
    };
  }

  getSupplierList() {
    return this.props.user.postFilterParameters.suppliers.map((s) => ({ text: s.name, value: s.id }));
  }

  onChange = (values) => {
    this.setState({ values });
    this.props.onChange(values.map((val) => val.value));
  };

  render() {
    const supplierItems = this.suppliers.map((itm, idx) => (
      <SelectOption
        key={`supplier-item-${idx}`}
        checkbox
        selected={this.state.values.findIndex((item) => item.value === itm.value) > -1}
        value={itm.value}
      >
        {itm.text}
      </SelectOption>
    ));
    return (
      <div className={this.props.classes.dashboard__filter}>
        <Select label="Supplier" selectedItems={this.state.values} multiple selectAll search autoWidth onChange={this.onChange}>
          {supplierItems}
        </Select>
      </div>
    );
  }
}

SupplierFilter.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  onChange: PropTypes.func,
};
