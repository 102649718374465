const ReservePriceStrategyAnchors = new Map([
  ['COGS', 'COGS'],
  ['FlatRate', 'Flat Rate'],
  ['Price', 'Price'],
  ['SetOnUpload', 'Set on Upload'],
]);

const ReservePriceStrategyAnchorsKeysObject = [...ReservePriceStrategyAnchors].reduce(
  (acc, val) => ({
    ...acc,
    [val[0]]: val[0],
  }),
  {}
);

export default ReservePriceStrategyAnchors;
export { ReservePriceStrategyAnchorsKeysObject };
