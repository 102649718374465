import { DeleteAction, VerifyAction } from '../components/actions';
import routePaths from '../route-paths';

export default new Map([
  [
    'VIEW',
    {
      text: 'View',
      route(id) {
        return `${routePaths.post}${id}`;
      },
    },
  ],
  [
    'EDIT',
    {
      text: 'Edit',
      route(id) {
        return `${routePaths.editTransaction}${id}`;
      },
    },
  ],
  [
    'DELETE',
    {
      text: 'Delete',
      route() {
        return undefined;
      },
      onClick(el) {
        el.setState({ action: DeleteAction, codes: undefined });
      },
    },
  ],
  [
    'COMPLETE',
    {
      text: 'Complete',
      route() {
        return undefined;
      },
      onClick(el) {
        el.setState({ action: VerifyAction, codes: undefined });
      },
    },
  ],
]);
