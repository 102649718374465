import LocalizedStrings from 'react-localization';

const TaxDashboardStrings = new LocalizedStrings({
  en: {
    firstStep: 'Thanks for taking the first step towards Verifying Donations!',
    oneTimeStep: 'We require an initial, one-time setup to complete this process.',
    clickToStart: 'To start verifying your donations, please click the link below to',
    createSiteSignature: 'Create Site Signature',
    contactSiteAdmin: 'Contact Site Administrator',
    createYourSiteSignature: 'create your Site Signature',
    contactYourSiteAdmin: 'contact your site administrator to create a site signature',
    thanksWellLetYouKnow: "Thanks {0}, we'll let you know when you can view your tax receipt.",
    sorrySomethingWentWrong: 'Sorry, something went wrong. Please try again or contact an administrator to assist you.',
    loading: 'Loading...',
    wellLetYouKnow: "We'll let you know when you can view your tax receipt.",
    wereCurrentlyWaitingFor: "We're currently waiting for a Site Signature from {0}",
    onceWeObtain: "Once we obtain the signature, we'll be able to provide you with your receipt.",
    toNotifySite: 'To notify {0} to provide a signature, please click the button below.',
    notifySite: 'Notify {0}',
  },
});

export default TaxDashboardStrings;
