import React from 'react';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { Theme } from '@spoiler-alert/ui-library';

ChartJS.register(ArcElement);

const CustomerParticipationRateChart = ({ participationRate, cutout = '50%', subjectColor = Theme.green, backgroundColor = Theme.green10 }) => {
  const chartData = {
    datasets: [
      {
        data: [participationRate || 1, 100 - (participationRate || 1)],
        backgroundColor: [subjectColor, backgroundColor],
        hoverBackgroundColor: [subjectColor, backgroundColor],
        borderWidth: 0,
      },
    ],
  };
  const chartOptions = {
    animation: { animateRotate: false },
    cutout,
  };

  return <Doughnut data={chartData} options={chartOptions} />;
};

CustomerParticipationRateChart.propTypes = {
  participationRate: PropTypes.number,
  cutout: PropTypes.string,
  subjectColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default CustomerParticipationRateChart;
