import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectOption } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import filterStyles from './filter-styles';

const styles = {
  ...filterStyles,
  option__capitalize: {
    textTransform: 'capitalize',
  },
};

@injectSheet(styles)
export default class DestinationFilter extends React.Component {
  constructor(props) {
    super(props);
    this.destinations = this.getDestinations();
    this.state = {
      values: this.destinations.map((d) => ({ value: d.value, text: d.text })),
    };
  }

  getDestinations() {
    return this.props.user.postFilterParameters.destinations.map((o) => ({ text: o.name, value: o.id }));
  }

  onChange = (values) => {
    this.setState({ values });
    this.props.onChange(values.map((val) => val.value));
  };

  selectionRenderer(values) {
    if (values.length === 1) return this.destinations.find((d) => values[0] === d.value).text;
    if (values.length > 1) return 'Multiple';
    return '';
  }

  render() {
    const items = this.destinations.map((itm, ix) => (
      <SelectOption
        key={`item-${ix}`}
        className={this.props.classes.option__capitalize}
        checkbox
        selected={this.state.values.findIndex((item) => item.value === itm.value) > -1}
        value={itm.value}
      >
        {itm.text}
      </SelectOption>
    ));
    return (
      <div data-element="destination-filter" className={this.props.classes.dashboard__filter}>
        <Select label="Destination" selectedItems={this.state.values} multiple selectAll autoWidth onChange={this.onChange}>
          {items}
        </Select>
      </div>
    );
  }
}

DestinationFilter.propTypes = {
  user: PropTypes.object,
  onChange: PropTypes.func,
  classes: PropTypes.object,
};
