import AppSettings from '../app-settings';
import { fetchWithToken } from './fetch-with-token';
import { fetchWithToken as buyerFetchWithToken } from '../buyer/services/buyer-fetch-with-token';

class RefreshService {
  async checkServerVersion() {
    const response = await fetch(AppSettings.REFRESH_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        pragma: 'no-cache',
        'cache-control': 'no-cache',
      },
    });
    return response.json();
  }

  async checkFeatures(buyer) {
    let allowedHeaders = {
      'Content-Type': 'application/json',
    };
    if (['local', 'circleci_mabl'].includes(AppSettings.ENVIRONMENT_NAME?.toLowerCase()))
      allowedHeaders = {
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': 'true',
      };
    const response =
      (!buyer &&
        (await fetchWithToken(`${AppSettings.GRAPHQL_SERVER_URL}features/load`, {
          method: 'GET',
          headers: allowedHeaders,
        }))) ||
      (await buyerFetchWithToken(`${AppSettings.GRAPHQL_SERVER_URL}features/buyer-load`, {
        method: 'GET',
        headers: allowedHeaders,
      }));
    return response.json();
  }
}

const refreshService = new RefreshService();
export default refreshService;
