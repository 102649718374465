import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { GraphQLDataTable, Filter, Flyout, OverlayService, AlertService, Button, ExportIcon } from '@spoiler-alert/ui-library';
import { UserPostsQuery } from '../../../graphql/queries';
import dataExportService from '../../../services/data-export-service';
import { getMyPostsColumns } from './my-posts-columns';
import PostsActivityDetails from './posts-activity-details';
import { TitleService } from '../../../services';
import { Breadcrumbs } from '../../../store';
import { stripEmptyFilters } from '../../../utilities/clean-filters';

class MyPosts extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('My Posts');
    Breadcrumbs.set([
      {
        url: '/activity/my-posts',
        title: 'My Posts',
      },
    ]);

    this.state = {
      selectedRow: undefined,
      showFlyout: false,
      showUnclaimModal: false,
      showDeleteModal: false,
      showVerifyModal: false,
      variables: {
        paginate: {
          pageNumber: 1,
          limit: 30,
          filter: {
            sortBy: { sortProperty: 'transactedAt', asc: true },
          },
        },
      },
    };
  }

  componentDidMount = () => {
    const initialFilters = this.createInitialFilters(this.props.user);
    const filters = initialFilters?.reduce((filterObj, filter) => {
      // Date filters
      if (filter.filterType === 'daterange' && filter.range) {
        filterObj[filter.options?.startParam || 'startDate'] = moment(filter.range?.start);
        filterObj[filter.options?.endParam || 'endDate'] = moment(filter.range?.end);
      }
      // Select filters
      if (filter.queryField) {
        filterObj[filter.queryField] = filter.values.map((v) => v.value);
      }
      return filterObj;
    }, {});
    const paginateFilter = { ...this.state.variables.paginate.filter, ...filters };
    this.setState({ variables: { paginate: { ...this.state.variables.paginate, filter: stripEmptyFilters(paginateFilter) } } });
  };

  createInitialFilters(user) {
    const locationFilter = { displayName: 'Location', field: 'locations', queryField: 'suppliers' };
    const defaultFilters = [
      { displayName: 'Date Range', filterType: 'daterange' },
      { displayName: 'Destination', field: 'destinations', selectAll: true },
      { displayName: 'Subcategory', field: 'subcategories', selectAll: true },
      { displayName: 'Recipients', field: 'recipients', selectAll: true },
      { displayName: 'Status', field: 'statuses', selectAll: true },
      { displayName: 'Source', field: 'sources', selectAll: true },
      { displayName: 'Vendor Returns', field: 'vendorReturns', selectAll: true },
    ];
    if (user.privileges.canUserManageMultipleSites) defaultFilters.splice(1, 0, locationFilter);

    const storeFilters = this.props.userFiltersStore.filters.myPosts || [];
    return defaultFilters.map((df) => new Filter({ ...df, ...storeFilters.find((sf) => sf?.displayName === df.displayName) }));
  }

  handleDynamicRowClick = (row) => {
    this.setState({ selectedRow: row, showFlyout: true }, this.showOverlay);
  };

  showOverlay() {
    OverlayService.show();
  }

  hideFlyout = () => {
    if (this.state.showFlyout) this.setState({ showFlyout: false });
  };

  export = () => {
    const postFilters = this.state.variables.paginate.filter;
    dataExportService.export('posts', { postFilters }).then(this.exportCompleted).catch(this.exportErrored);
  };

  exportCompleted = (response) => {
    if (response.ok || response.data) {
      const message = 'Your export is now processing and we will email you when it is completed.';
      AlertService.alert({ type: 'info', message: <span>{message}</span>, autoDismiss: true, dismissDelay: 5000 });
    } else {
      this.exportErrored();
    }
  };

  exportErrored() {
    const message = 'Sorry we are unable to export your posts. If this problem persists, please contact a Spoiler Alert Administrator to help you.';
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  }

  handleQueryChange = (variables) => {
    this.setState({ variables });
  };

  render() {
    return (
      <div id="activity">
        <GraphQLDataTable
          query={UserPostsQuery}
          queryName="currentUserQuery"
          queryField="posts"
          search
          columns={getMyPostsColumns(this.props.user)}
          filterable="internal"
          filterParameterField="postFilterParameters"
          filters={this.createInitialFilters(this.props.user)}
          updateStoredFilters={(filters) => this.props.userFiltersStore.updateFilters('myPosts', filters)}
          pagination
          perPage={15}
          pageActionButtons={[
            <Button key="export" secondary onClick={this.export} icon={ExportIcon}>
              Export
            </Button>,
          ]}
          onRowClick={this.handleDynamicRowClick}
          onFilterChange={this.handleQueryChange}
        />
        {this.state.selectedRow && (
          <Flyout position="right" open={this.state.showFlyout} onHide={this.hideFlyout}>
            <PostsActivityDetails id={this.state.selectedRow._id} user={this.props.user} />
          </Flyout>
        )}
      </div>
    );
  }
}

MyPosts.propTypes = {
  user: PropTypes.object,
  userFiltersStore: PropTypes.object,
};

const ConnectedComponent = inject((store) => store)(observer(MyPosts));
export default ConnectedComponent;
