import { Column, buildColumnFormatter } from '@spoiler-alert/ui-library';

const columnStore = new Map();

export const getDefaultSortFromDataTableProfile = (profileName, profiles) => {
  let foundProfile;
  if (columnStore.get(profileName)) {
    foundProfile = columnStore.get(profileName);
  } else {
    foundProfile = profiles.find((d) => d.dataTableName === profileName).columns;
  }
  const defaultSortColumn = foundProfile.find((c) => c.defaultSort);
  return {
    sortProperty: defaultSortColumn.field,
    asc: defaultSortColumn.defaultSortDirection === 'asc',
  };
};

export const getColumnsFromDataTableProfile = (profileName, profiles, columnOverrides = {}, noCache = false) => {
  if (columnStore.get(profileName) && !noCache) {
    const foundProfile = columnStore.get(profileName);
    return foundProfile.map((col) => {
      const override = columnOverrides[col.field];
      if (override && override.formatter) {
        col.formatter = override.formatter;
      }
      if (override && override.groupHeader) {
        col.groupHeader = override.groupHeader;
      }
      return col;
    });
  }
  const profile = profiles.find((d) => d.dataTableName === profileName);
  if (!profile) throw new Error(`No profile found for the ${profileName} table`);
  const columns = profile.columns.map((column) => {
    const { field, displayName, visible, sortable, defaultSort, defaultSortDirection } = column;
    const formatter = buildColumnFormatter(column);
    return new Column({
      field,
      displayName,
      visible,
      sortable,
      defaultSort,
      defaultSortDirection,
      formatter,
      ...columnOverrides[field],
    });
  });
  columnStore.set(profileName, columns);
  return columns;
};
