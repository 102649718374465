import React from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import { Tooltip, TextBox, Theme as theme } from '@spoiler-alert/ui-library';

const useFieldStyles = createUseStyles({
  fieldLabel: (color) => ({
    textTransform: 'uppercase',
    fontSize: '12px',
    color: color || theme.yellowDark,
    fontWeight: 500,
    display: 'inline-block',
  }),
});
export const Field = ({ label, children, className, color }) => {
  const classes = useFieldStyles(color);
  return (
    <div className={className}>
      <div className={classes.fieldLabel}>{label}</div>
      {children}
    </div>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  color: PropTypes.string,
};

const usePriceIndicatorStyles = createUseStyles({
  priceIndicatorOuterWrap: {
    marginLeft: 9,
    height: 40,
    '&>span': {
      display: 'block',
      height: 40,
    },
  },
  priceIndicatorWrap: {
    width: 27,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: [0, 8, 0, 8],
    height: 40,
  },
  priceIndicatorTooltip: {
    fontSize: 14,
  },
  suggestedPrice: {
    color: theme.grey80,
    textTransform: 'uppercase',
    fontSize: 12,
  },
  priceIndicatorBar: {
    height: 3,
    width: 11,
    borderRadius: 1.5,
    background: '#f2f2f3',
    marginTop: 1,
  },
  priceIndicatorChevron: {
    width: 8,
    height: 2,
    borderRadius: 1,
    background: theme.green,
    transform: 'rotate(45deg)',
    transformOrigin: 'bottom right',
    marginTop: 2,
    position: 'relative',
    left: 1.5,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 2,
      height: 8,
      borderRadius: 1,
      background: theme.green,
    },
  },
});

export const PriceIndicator = ({ suggestedUnitPrice, unitPrice }) => {
  const classes = usePriceIndicatorStyles();
  const ratio = unitPrice / suggestedUnitPrice;
  let max = 10;
  let bars = 0;
  let showCap = false;
  let color = theme.red;
  if (ratio >= 0.01) bars = 1;
  if (ratio >= 0.16) bars = 2;
  if (ratio >= 0.26) {
    bars = 3;
    color = theme.orange;
  }
  if (ratio >= 0.36) bars = 4;
  if (ratio >= 0.46) bars = 5;
  if (ratio >= 0.56) bars = 6;
  if (ratio >= 0.66) bars = 7;
  if (ratio >= 0.76) {
    bars = 8;
    color = theme.green;
  }
  if (ratio >= 0.86) bars = 9;
  if (ratio >= 0.96) bars = 10;
  if (ratio > 1) {
    bars = 7;
    max = 7;
    showCap = true;
  }

  return (
    <div className={classes.priceIndicatorOuterWrap}>
      <Tooltip
        small
        text={
          <div className={classes.priceIndicatorTooltip}>
            {Math.round(ratio * 100)}% of Suggested Price{' '}
            <div className={classes.suggestedPrice}>Suggested Price: {accounting.formatMoney(suggestedUnitPrice)}</div>
          </div>
        }
      >
        <div className={classes.priceIndicatorWrap}>
          {showCap && (
            <div className={classes.priceIndicatorCap}>
              <div className={classes.priceIndicatorChevron}></div>
              <div className={classes.priceIndicatorChevron}></div>
            </div>
          )}
          {[...Array(max - bars)].map((_, i) => (
            <div key={`empty${i}`} className={classes.priceIndicatorBar}></div>
          ))}
          {[...Array(bars)].map((_, i) => (
            <div key={`filled${i}`} style={{ background: color }} className={classes.priceIndicatorBar}></div>
          ))}
        </div>
      </Tooltip>
    </div>
  );
};

PriceIndicator.propTypes = {
  suggestedUnitPrice: PropTypes.number,
  unitPrice: PropTypes.number,
};

const useCustomTextBoxStyles = createUseStyles({
  customTextBox: {
    height: '32px !important',
    width: '88px !important',
    borderRadius: '2px !important',
  },
  customTextBoxInput: {
    color: `${theme.greyDark} !important`,
    fontSize: '16px !important',
    textAlign: 'right !important',
    width: '85px !important',
    padding: '8px !important',
  },
});

export const CustomTextBox = (props) => {
  const classes = useCustomTextBoxStyles();
  return <TextBox {...props} className={classes.customTextBox} inputClassName={classes.customTextBoxInput} />;
};

export const TooltipIcon = ({ children, ...rest }) => (
  <Tooltip small bottom {...rest}>
    {children}
  </Tooltip>
);

TooltipIcon.propTypes = {
  children: PropTypes.node,
  text: PropTypes.node,
};
