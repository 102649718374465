import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import accounting from 'accounting';
import { createUseStyles } from 'react-jss';
import { AlertService, Button, Snackbar, Timing } from '@spoiler-alert/ui-library';
import { UpdateTrucklaneMinimums } from '../../graphql/mutations';
import SmartAwardSettingsInput, { initializeStateFromDB, minimumReducer } from './smart-award-settings-input';

const useStyles = createUseStyles({
  wrapper: {
    padding: '24px',
  },
  saveSettings: {
    display: 'flex',
    justifyContent: 'flex-end',
    boxShadow: 'inset 0px 1px 0 0 #d9dee1',
    padding: '24px',
    width: '100%',
    '& button': {
      marginLeft: 8,
    },
  },
});

const SmartAwardSettings = ({ user, cancellable, saveText, onClose, onSave, style, refetchQueries }) => {
  const classes = useStyles();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [touched, setTouched] = useState(false);

  const [userMinimums, setUserMinimums] = useReducer(minimumReducer, null, () => initializeStateFromDB(user.site.trucklaneMinimums));

  useEffect(() => {
    setUserMinimums({
      type: 'initializeState',
      dbState: user.site.trucklaneMinimums,
    });
  }, [user.site.trucklaneMinimums]);

  const [updateMinimums, { loading: settingMinimums }] = useMutation(UpdateTrucklaneMinimums);

  const setMinimum = Timing.debounce((term, truckType, value) => {
    setUserMinimums({
      type: 'setMinimum',
      term,
      truckType,
      value: accounting.unformat(value),
    });
  }, 10);

  const setMode = (option) => {
    setTouched(true);
    setUserMinimums({
      type: 'setMode',
      value: option.value,
    });
  };

  const hideSuccessMessage = () => setShowSuccessMessage(false);

  const save = () => {
    updateMinimums({ variables: { minimums: userMinimums }, refetchQueries })
      .then((res) => {
        if (res.data.updateTrucklaneMinimums.errors && res.data.updateTrucklaneMinimums.errors.length > 0) {
          throw new Error(res.data.updateTrucklaneMinimums.errors[0].message);
        } else if (onSave) {
          onSave();
        } else {
          setShowSuccessMessage(true);
          setTouched(false);
        }
      })
      .catch((err) => {
        AlertService.alert({
          type: 'warning',
          message: err.message,
        });
      });
  };

  return (
    <>
      <div className={`${style?.wrapper} ${classes.wrapper}`}>
        <SmartAwardSettingsInput userMinimums={userMinimums} setMode={setMode} setMinimum={setMinimum} setTouched={setTouched} style={style} />
      </div>
      <div className={classes.saveSettings}>
        {cancellable && (
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
        )}
        <Button onClick={save} primary disabled={!touched} loading={settingMinimums} loadingText="Saving">
          {saveText || 'Save'}
        </Button>
      </div>
      <Snackbar show={showSuccessMessage} callback={hideSuccessMessage} message="Settings saved successfully" />
    </>
  );
};

SmartAwardSettings.propTypes = {
  user: PropTypes.object,
  cancellable: PropTypes.bool,
  saveText: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  style: PropTypes.object,
  refetchQueries: PropTypes.array,
};

export default SmartAwardSettings;
