export default new Map([
  ['DONATE', 'Nonprofit Donation'],
  ['SALE', 'Sale'],
  ['DISCOUNTED_SALES', 'Discounted Sales'],
  ['GIVE_AWAY', 'Give Away'],
  ['ANIMAL_FEED', 'Animal Feed'],
  ['CHARITY', 'Charity'],
  ['BIOMATERIAL_PROCESSING', 'Biomaterial Processing'],
  ['CO_ANAEROBIC_DIGESTION', 'Co-anaerobic digestion'],
  ['COMPOST', 'Compost'],
  ['CONTROLLED_COMBUSTION', 'Controlled Combustion'],
  ['LAND_APPLICATION', 'Land Application'],
  ['LANDFILL', 'Landfill'],
  ['NOT_HARVESTED', 'Not Harvested'],
  ['REFUSE_DISCARDS', 'Refuse / Discards'],
  ['SEWER', 'Sewer'],
  ['CONDEMNS', 'Condemns'],
  ['LIQUIDATION', 'Liquidation'],
  ['KITCHEN_USE', 'Kitchen Use'],
]);
