import StackTrace from 'stacktrace-js';
import AppSettings from '../app-settings';

class StackdriverErrorReporter {
  get baseAPIUrl() {
    return 'https://clouderrorreporting.googleapis.com/v1beta1/projects/';
  }

  noop = () => {};

  start() {
    if (!AppSettings.STACKDRIVER_LOGGING_ENABLED) return;
    this.serviceContext = { service: `web-app-${AppSettings.ENVIRONMENT_NAME}`, version: '1', resourceType: 'k8s_node' };
    this.reportUncaughtExceptions = true;
    this.reportUnhandledPromiseRejections = true;
    this.disabled = !AppSettings.STACKDRIVER_LOGGING_DISABLED || false;
    if (this.reportUncaughtExceptions) this.initUncaughtExceptions();
    if (this.reportUnhandledPromiseRejections) this.initUnhandledRejections();
  }

  initUncaughtExceptions = () => {
    const oldErrorHandler = window.onerror || this.noop;

    window.onerror = (message, source, lineno, colno, error) => {
      if (error) this.report(error).catch(this.noop);
      oldErrorHandler(message, source, lineno, colno, error);
      return true;
    };
  };

  initUnhandledRejections = () => {
    const oldPromiseRejectionHandler = window.onunhandledrejection || this.noop;

    window.onunhandledrejection = (promiseRejectionEvent) => {
      if (promiseRejectionEvent) this.report(promiseRejectionEvent.reason).catch(this.noop);
      oldPromiseRejectionHandler(promiseRejectionEvent.reason);
      return true;
    };
  };

  /**
   * Report an error to the Stackdriver Error Reporting API
   * @param {Error|String} error - The Error object or message string to report.
   * @param {Object} opts - Configuration for this report.
   * @param {number} [opts.skipLocalFrames=1] - Omit number of frames if creating stack.
   * @returns {Promise} A promise that completes when the report has been sent.
   */
  report = (error, opts) => {
    let err = error;
    const options = opts || {};

    if (!AppSettings.STACKDRIVER_LOGGING_ENABLED) return Promise.resolve(null);
    if (!err) return Promise.reject(new Error('no error to report'));

    const payload = {};
    payload.serviceContext = this.serviceContext;
    payload.context = {};
    payload.context.httpRequest = {
      userAgent: window.navigator.userAgent,
      url: window.location.href,
    };

    let firstFrameIndex = 0;
    if (typeof err === 'string' || err instanceof String) {
      // Transform the message in an error, use try/catch to make sure the stacktrace is populated.
      try {
        throw new Error(err);
      } catch (e) {
        err = e;
      }
      // the first frame when using report() is always this library
      firstFrameIndex = options.skipLocalFrames || 1;
    }

    // This will use sourcemaps and normalize the stack frames
    // eslint-disable-next-line no-undef
    return StackTrace.fromError(err)
      .then(
        (stack) => {
          const lines = [err.toString()];
          // Reconstruct to a JS stackframe as expected by Error Reporting parsers.
          for (let s = firstFrameIndex; s < stack.length; s++) {
            // Cannot use stack[s].source as it is not populated from source maps.
            lines.push(
              [
                '    at ',
                // If a function name is not available '<anonymous>' will be used.
                stack[s].getFunctionName() || '<anonymous>',
                ' (',
                stack[s].getFileName(),
                ':',
                stack[s].getLineNumber(),
                ':',
                stack[s].getColumnNumber(),
                ')',
              ].join('')
            );
          }
          return lines.join('\n');
        },
        (reason) => {
          // Failure to extract stacktrace
          const errors = [
            'Error extracting stack trace: ',
            reason,
            '\n',
            err.toString(),
            '\n',
            '    (',
            err.file,
            ':',
            err.line,
            ':',
            err.column,
            ')',
          ].join('');
          return errors;
        }
      )
      .then((message) => {
        payload.message = message;
        return this.sendErrorPayload(payload);
      });
  };

  sendErrorPayload = (payload) => {
    const url = `${this.baseAPIUrl}${AppSettings.GOOGLE_PROJECT_ID}/events:report?key=${AppSettings.STACKDRIVER_API_KEY}`;
    const result = fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return result;
  };
}

const stackdriverErrorReporter = new StackdriverErrorReporter();
export default stackdriverErrorReporter;
