import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Theme, LottieLoader, Checkbox, Button, CalculatorIcon, AwardIcon, Modal, Timing, AlertService } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import { useQuery } from '@apollo/client';
import { LocationFilter } from '../../../components/filters';
import LottieAnimations from '../../../enums/lottie-animations';
import appSettings from '../../../app-settings';
import AwardTable from '../../../components/award-table/award-table';
import BuyersFlyup from '../buyers-flyup';
import { Breadcrumbs, Store } from '../../../store';
import SmartAwardSettings from '../../org-settings/smart-award-settings';
import { AwardSummaryQuery, PossibleNegotiationsSummary, SmartAwardSummaryV3Query } from '../../../graphql/queries';
import safeGet from '../../../utilities/safe-get';
import { OfferComparisonStrings } from '../../../string-resources';
import { TitleService } from '../../../services';
import RecommendedNegotiations from '../../../components/banners/recommended-negotiations';

const useStyles = createUseStyles({
  smartAwardWrap: {
    marginBottom: -40,
  },
  smartAwardContainer: {
    minHeight: 'calc(100vh - 106px)',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  filterItem: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 185,
    marginRight: 24,
  },
  checkboxWrapper: {
    marginRight: 24,
    border: `1px ${Theme.borderColor} solid`,
    padding: '9px 19px',
    height: 36,
    borderRadius: 2,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    '& button': {
      marginLeft: 8,
    },
  },
  title: {
    fontSize: '24px',
  },
  settingsWrapper: {
    width: '100%',
  },
});

const SmartAwardV3 = ({ user, history }) => {
  const classes = useStyles();
  const [animation, setAnimation] = useState(LottieAnimations.CALCULATING);
  const [selectedSellerSiteIds, setSelectedSellerSiteIds] = useState(
    [...Store.smartAwardBuyers?.sellerSiteIds] || [user.site.id, ...user.site.children.map((childSite) => childSite.id)]
  );
  const [touched, setTouched] = useState(false);
  const [excludedLanes, setExcludedLanes] = useState([]);
  const [displayAwardModal, setDisplayAwardModal] = useState(false);

  const [smartAwardArguments, setSmartAwardArguments] = useState({
    commitAwards: false,
    excludedTrucklanes: [],
    includedOrigins: [user.site._id, ...user.site.children.map((childSite) => childSite._id)],
  });

  const { data: negotiationsData, refetch: refetchNegotiations } = useQuery(PossibleNegotiationsSummary);
  const showBanner = negotiationsData?.possibleNegotiationsSummaryQuery?.offerListingsToNegotiate.length > 0;

  const {
    data,
    loading: smartAwardSummaryLoading,
    refetch: refetchSummary,
    error: smartAwardSummaryError,
  } = useQuery(SmartAwardSummaryV3Query, {
    variables: smartAwardArguments,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const { refetch: refetchBuyerFlyup } = useQuery(AwardSummaryQuery);

  const lanes = safeGet(data, 'currentUserQuery.smartAwardSummaryV3.trucklanes', []) || [];
  const showZeroState = safeGet(data, 'currentUserQuery.smartAwardSummaryV3.showZeroState', false);

  useEffect(() => {
    TitleService.setTitles('Offer Comparison');
    Breadcrumbs.set([
      {
        url: '/offer-comparison',
        title: 'Offer Comparison',
      },
      {
        url: '/offer-comparison/smart-award',
        title: 'Smart Award',
      },
    ]);
    if (lanes && !smartAwardSummaryLoading) {
      refetchSummary();
    }
  }, []);

  // If the lane is a delivery lane with no distance we don't want to send it to the algo,
  // So we are calling this to pre-exclude any of these lanes.
  const excludeMissingDistanceLanes = () => {
    const erroredLaneIds = [];
    lanes.filter((lane) => {
      const missingDistanceLane = lane.logisticsTerm === 'Delivery' && !lane.trucklaneDistance && user.site.trucklaneMinimums.mode === 'NET_REVENUE';
      if (missingDistanceLane) {
        erroredLaneIds.push(lane._id);
      }
      return !missingDistanceLane;
    });
    return erroredLaneIds;
  };

  useEffect(() => {
    const resultError = safeGet(data, 'currentUserQuery.smartAwardSummaryV3.errors', []) || smartAwardSummaryError;
    if (resultError && resultError.length) {
      AlertService.alert({ type: 'warning', message: <span>{resultError[0].message || resultError}</span>, autoDismiss: true });
    }
    if (lanes.length) {
      const erroredLaneIds = excludeMissingDistanceLanes();
      const laneSet = new Set([...erroredLaneIds, ...excludedLanes]);
      setExcludedLanes([...laneSet.values()]);
    }
  }, [data, smartAwardSummaryError]);

  const areAllSelected = () => !excludedLanes.length;

  const areSomeSelected = () => {
    if (excludedLanes.length === lanes.length) return false;
    if (excludedLanes.length) return true;
    return null;
  };

  const toggleAll = () => {
    setTouched(true);
    const erroredLanes = excludeMissingDistanceLanes();
    if (excludedLanes.length - erroredLanes.length > 0) {
      setExcludedLanes(erroredLanes);
    } else {
      setExcludedLanes(lanes.map((lane) => lane._id));
    }
  };

  const sellerSiteChange = (_, values) => {
    setSelectedSellerSiteIds([...values]);
    setTouched(true);
  };

  const mapOrigins = () => {
    if (selectedSellerSiteIds.length && selectedSellerSiteIds[0].value) return selectedSellerSiteIds.map((site) => site.value);
    return [user.site._id, ...user.site.children.map((childSite) => childSite._id)];
  };

  const recalculate = Timing.throttle((forceRecalculate) => {
    setAnimation(LottieAnimations.CALCULATING);
    setSmartAwardArguments({
      commitAwards: false,
      excludedTrucklanes: excludedLanes,
      includedOrigins: mapOrigins(),
    });
    if (forceRecalculate) refetchSummary();
    setTouched(false);
  }, 1000);

  const award = Timing.throttle(() => {
    setAnimation(LottieAnimations.OFFER_LOADER);
    refetchSummary({
      commitAwards: true,
      excludedTrucklanes: excludedLanes,
      includedOrigins: mapOrigins(),
    })
      .then(async (result) => {
        setTouched(true);
        refetchBuyerFlyup();
        const resultError = safeGet(result, 'data.currentUserQuery.smartAwardSummaryV3.errors', []);
        if (resultError && resultError.length) {
          throw new Error(resultError[0].message);
        }
        const awardedCount = safeGet(result, 'data.currentUserQuery.smartAwardSummaryV3.offersAwarded', 0);
        const offerListingsMessage = `${awardedCount} ${awardedCount === 1 ? 'Offer' : 'Offers'} Awarded`;
        Store.refreshOfferComp(true);
        AlertService.alert({
          type: 'success',
          message: <div>{offerListingsMessage}</div>,
          autoDismiss: true,
        });
      })
      .catch(() => {
        AlertService.alert({ type: 'warning', message: <span>{OfferComparisonStrings.smartAwardError}</span>, autoDismiss: true });
      });
  }, 1000);

  const displayAwardSettings = () => {
    setDisplayAwardModal(true);
  };

  const updateExcludedLanes = (laneExclusions) => {
    const erroredLanes = excludeMissingDistanceLanes();
    const laneSet = new Set([...laneExclusions, ...erroredLanes]);
    setExcludedLanes([...laneSet.values()]);
    setTouched(true);
  };

  const awardDisabled = () => {
    const viableOffers = safeGet(data, 'currentUserQuery.smartAwardSummaryV3.offersAwarded', 0) < 1;
    return touched || smartAwardSummaryLoading || !lanes.length || viableOffers || showZeroState;
  };

  return (
    <div className={classes.smartAwardWrap}>
      <div className={classes.smartAwardContainer}>
        <div className={classes.filterContainer}>
          <div className={classes.checkboxWrapper}>
            <Checkbox
              value="true"
              onChecked={() => toggleAll()}
              checked={areAllSelected()}
              partial={areSomeSelected()}
              className={classes.selectBox}
              disabled={showZeroState}
            />
          </div>
          <div className={classes.filterItem}>
            <LocationFilter
              onChange={sellerSiteChange}
              user={user}
              filterBasis={'INVENTORY'}
              selectedIds={selectedSellerSiteIds}
              disabled={showZeroState}
            />
          </div>
          <div className={classes.filterItem}>
            <Button secondary onClick={displayAwardSettings}>
              Settings
            </Button>
          </div>
          <div data-testid="action-buttons" className={classes.buttons}>
            <Button
              secondary
              icon={CalculatorIcon}
              disabled={!touched || smartAwardSummaryLoading || showZeroState}
              onClick={() => {
                recalculate();
              }}
            >
              Recalculate
            </Button>
            <Button primary icon={AwardIcon} disabled={awardDisabled()} onClick={() => award()}>
              Award
            </Button>
          </div>
        </div>
        <div>
          <LottieLoader loading={smartAwardSummaryLoading} animation={animation} transition={300} minTimeout={appSettings.LOTTIE_TIMEOUT * 1000}>
            {showBanner && (
              <RecommendedNegotiations negotiationsData={negotiationsData} refetchNegotiations={refetchNegotiations} history={history} />
            )}
            {!smartAwardSummaryLoading && (
              <AwardTable
                lanes={lanes}
                excludedLanes={excludedLanes}
                setExcludedLanes={updateExcludedLanes}
                smartAwardMode={user.site.trucklaneMinimums.mode}
                showZeroState={showZeroState}
              />
            )}
          </LottieLoader>
        </div>
      </div>
      <BuyersFlyup user={user} history={history} />
      <Modal
        onHide={() => {
          setDisplayAwardModal(false);
        }}
        open={displayAwardModal}
        closeOnEsc
        closeOnOutsideClick
      >
        <SmartAwardSettings
          user={user}
          cancellable={true}
          onClose={() => {
            setDisplayAwardModal(false);
          }}
          onSave={() => {
            setDisplayAwardModal(false);
            recalculate(true);
          }}
          saveText={'Save and Recalculate'}
          style={{ title: classes.title, wrapper: classes.settingsWrapper }}
        />
      </Modal>
    </div>
  );
};

SmartAwardV3.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
};

export default SmartAwardV3;
