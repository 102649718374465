import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
  Modal,
  ModalContent,
  Button,
  AlertWarningIcon,
  TextInput,
  RadioButtonCard,
  MinimalCheckboxTable,
  Timing,
  EmptyStateIcon,
} from '@spoiler-alert/ui-library';
import { event } from 'react-fullstory';
import contactModalStyles from './contact-modal-styles';
import contactTypes from './contact-types';
import possibleValues from './possible-values';

const useStyles = createUseStyles(contactModalStyles);
const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const AddContactModal = ({
  onHide,
  open,
  handleSubmit,
  loading,
  distributionLists,
  onChange,
  match,
  addContactType,
  setAddContactDistributionLists,
}) => {
  const classes = useStyles();
  const [emailAddress, setEmailAddress] = useState('');
  const [contactType, setContactType] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const cancel = () => {
    onHide(false);
    setEmailAddress('');
    setContactType('');
    setEmailError(false);
  };

  useEffect(() => {
    setContactType('');
    setAddContactDistributionLists(distributionLists.map((dl) => ({ _id: dl._id, key: dl.key, value: ['Offer Sheets', 'Award Sheets'] })));
  }, [open]);

  const submit = async (ev) => {
    ev.preventDefault();
    if (contactType.toLowerCase() === 'customer') event(`Added Customer Contact`);
    if (contactType.toLowerCase() === 'internal') event(`Added Internal Contact`);
    const error = await handleSubmit(emailAddress, contactType || addContactType, distributionLists);
    if (error?.code === 'DUPLICATE_EMAIL') {
      setErrorMessage('Duplicate email. Email must be unique within your organization.');
      setEmailError(true);
    } else {
      setEmailAddress('');
      setContactType('');
    }
  };

  const handleChange = Timing.debounce((newEmail) => {
    const emailMatch = emailRegex.test(newEmail);
    if (!emailMatch) setErrorMessage('Please enter a valid email address');
    setEmailError(!emailMatch);
    setEmailAddress(newEmail);
  }, 500);

  return (
    <Modal onHide={onHide} open={open} closeOnEsc closeOnOutsideClick>
      <form onSubmit={submit} className={classes.form__container}>
        <ModalContent>
          <div data-testid="modal-title" className={classes.modal__title}>
            Add New Contact
          </div>
          <div data-testid="modal-body" className={classes.modal__body}>
            <div className={`${classes.row} ${classes.email}`}>
              <TextInput labelText="Email Address *" value={emailAddress} onChange={handleChange} required error={emailError} />
              {emailError && (
                <span className={classes.errorMessage}>
                  <AlertWarningIcon className={classes.warningIcon} />
                  {errorMessage}
                </span>
              )}
            </div>
            <div className={classes.row}>
              <span className={classes.contactType}>Contact Type *</span>
              <RadioButtonCard options={contactTypes} selected={contactType || addContactType} onChange={(option) => setContactType(option.value)} />
            </div>
            <div className={classes.tableTitle}>Add contact to distribution lists</div>
            <div>
              {(distributionLists.length > 0 && (
                <MinimalCheckboxTable data={distributionLists} possibleValues={possibleValues} onChange={onChange} keyHeader={'Distribution List'} />
              )) || (
                <div className={classes.zeroStateContainer}>
                  <span className={classes.zeroStateInfo}>Add contact to distribution lists</span>
                  <div className={classes.zeroStateIconContainer}>
                    <EmptyStateIcon />
                    <span className={classes.zeroStateText}>{match.params.buyerSiteName} hasn’t been added to any distribution lists yet.</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ModalContent>
        <div className={classes.footer__container}>
          <div className={classes.modal__submit}>
            <Button testId="cancel" className={classes.modal__cancel} type="button" onClick={cancel} secondary disabled={loading}>
              Cancel
            </Button>
            <Button
              className={classes.actionButton}
              testId="submit"
              type="submit"
              loadingText={`Adding Contact`}
              loading={loading}
              disabled={!emailAddress || emailError || (!contactType && !addContactType)}
            >
              Add Contact
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

AddContactModal.propTypes = {
  onHide: PropTypes.func,
  open: PropTypes.bool,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  distributionLists: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      key: PropTypes.string,
      value: PropTypes.array,
    })
  ),
  onChange: PropTypes.func,
  match: PropTypes.object,
  addContactType: PropTypes.string,
  setAddContactDistributionLists: PropTypes.func,
};

export default AddContactModal;
