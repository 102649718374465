import React from 'react';
import { ModalContent, LoadingSmall } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import archiveInventoryModalStyles from './archive-inventory-modal-styles';

const useStyles = createUseStyles(archiveInventoryModalStyles);

const CheckStatusArchiveInventoryModalContent = () => {
  const classes = useStyles();
  const modalTitle = 'Archive Inventory';
  return (
    <ModalContent>
      <div data-testid="modal-title" className={classes.modal__title}>
        {modalTitle}
      </div>
      <div data-testid="modal-body" className={classes.checkStatus_modal__body}>
        <LoadingSmall loading={true} />
        <span className={classes.checkStatusText}>Checking inventory status...</span>
      </div>
      <div style={{ height: 40 }} />
    </ModalContent>
  );
};

export default CheckStatusArchiveInventoryModalContent;
