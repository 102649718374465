import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GraphQLDataTable } from '@spoiler-alert/ui-library';
import { ActiveUsersQuery } from '../../graphql/queries';
import { activeUserColumns } from './active-user-columns';

export default class ActiveUsers extends Component {
  render() {
    return (
      <GraphQLDataTable
        query={ActiveUsersQuery}
        queryName="currentUserQuery"
        queryField="site.users"
        userId={this.props.user._id}
        search
        transition
        columns={activeUserColumns}
        pagination
        onRowClick={this.props.handleDynamicRowClick}
        perPage={15}
        perPageIncrements={[15, 30, 50, 100]}
      />
    );
  }
}

ActiveUsers.propTypes = {
  user: PropTypes.object,
  handleDynamicRowClick: PropTypes.func,
};
