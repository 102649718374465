import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Theme, Tooltip, LoadingSmall } from '@spoiler-alert/ui-library';

const selectColorVariant = (family, variant) => Theme[`${family}${variant}`];

const useStyles = createUseStyles({
  wrap: {
    margin: [0, 1, 0, 0],
  },
  chicklet: {
    width: 26,
    height: 24,
    padding: [5, 0],
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '14px',
    cursor: 'pointer',
    color: ({ colorFamily }) => selectColorVariant(colorFamily, ''),
    backgroundColor: ({ colorFamily }) => selectColorVariant(colorFamily, 10),
    borderRadius: ({ first, last }) => {
      if (first) return '3px 0 0 3px';
      if (last) return '0 3px 3px 0';
      return 0;
    },
    '&:hover': {
      backgroundColor: ({ colorFamily }) => selectColorVariant(colorFamily, 30),
    },
    '&$chickletActive': {
      color: Theme.white,
    },
  },
  chickletActive: {
    color: Theme.white,
    backgroundColor: ({ colorFamily }) => selectColorVariant(colorFamily, ''),
    '&:hover': {
      backgroundColor: ({ colorFamily }) => selectColorVariant(colorFamily, 'Dark'),
    },
  },
});

const StatusChicklet = (props) => {
  const { active, loading, abbr, colorFamily, header, children } = props;
  const classes = useStyles(props);
  const text = loading ? <LoadingSmall /> : children;
  const chickletClass = `${classes.chicklet} ${active ? classes.chickletActive : ''}`;
  return (
    <div data-testid="status-chicklet" key={abbr} className={classes.wrap}>
      <Tooltip
        bottom
        small
        headerTextColor={selectColorVariant(colorFamily, '')}
        headerBackgroundColor={selectColorVariant(colorFamily, 10)}
        header={header}
        text={text}
      >
        <div data-testid="status-chicklet-detail" className={chickletClass}>
          {abbr}
        </div>
      </Tooltip>
    </div>
  );
};

StatusChicklet.propTypes = {
  loading: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool,
  active: PropTypes.bool,
  abbr: PropTypes.string,
  colorFamily: PropTypes.string,
  headerColor: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.node,
};

export default StatusChicklet;
