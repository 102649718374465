import { ProxyService } from '../services';

export default class ProxyHelper {
  static async downloadFile(id, type, filename) {
    const path = `${id}/${type}`;
    const response = await ProxyService.downloadFile(path);
    if (response.ok) {
      const file = await response.blob();
      const a = document.createElement('a');
      a.style = 'display: none';
      a.id = 'file-download-link';
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      return Promise.resolve(true);
    }
    return Promise.reject(new Error("You don't have permission to view this file."));
  }
}
