import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { inject, observer } from 'mobx-react';
import {
  FloatingMenu,
  ActionMenuOption,
  OverlayService,
  AlertService,
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  compose,
} from '@spoiler-alert/ui-library';
import {
  DiscountedSalesDistributionListQuery,
  StagedListingSummaryWithSiteQuery,
  StagedListingSummaryQuery,
  UserInventoriesQuery,
  ProgressBarsQuery,
  AwardSummaryQuery,
  ActiveListingsQuery,
} from '../../graphql/queries';
import destinationType from '../../enums/staged-inventory-destination-type';
import { createStagedListingsFromInventory, AggregateInventories, archiveInventories, unarchiveInventories } from '../../graphql/mutations';
import deleteCachedFieldsOnUserQuery from '../../apollo/cache-helpers/delete-cached-fields-on-user-query';
import ArchiveInventoryModal from '../../components/modals/archive-inventory-modal';
import SplitInventoryModal from '../../components/modals/split-inventory-modal';
import ListInventoryModal from '../../components/modals/list-inventory-modal';
import StagedInventoryFlyup from './staged-inventory-flyup';
import InventoryTable from './inventory-table';
import { TitleService } from '../../services';
import safeGet from '../../utilities/safe-get';
import { Breadcrumbs } from '../../store';
import checkFeature from '../../helpers/check-feature-flag';
import featureFlags from '../../enums/feature-flags';

const styleSheet = {
  inventory: {
    height: '100%',
  },
  'inventory--table-wrap': {
    minHeight: 'calc(100vh - 68px)',
  },
  alert__title: {
    height: 24,
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 'normal',
    textAlign: 'left',
    marginBottom: '8px',
  },
};

@compose(
  graphql(DiscountedSalesDistributionListQuery, { name: 'distributionLists' }),
  graphql(createStagedListingsFromInventory, { name: 'createStagedListingsFromInventory' }),
  graphql(archiveInventories, { name: 'archiveInventories' }),
  graphql(unarchiveInventories, { name: 'unarchiveInventories' }),
  graphql(AggregateInventories, { name: 'aggregateInventories' })
)
@injectSheet(styleSheet)
export class Inventory extends Component {
  constructor(props) {
    super(props);
    TitleService.setTitles('Inventory');
    Breadcrumbs.set([
      {
        url: '/inventory',
        title: 'Inventory',
      },
    ]);
  }

  static propTypes = {
    user: PropTypes.object,
    classes: PropTypes.object,
    distributionLists: PropTypes.object,
    createStagedListingsFromInventory: PropTypes.func,
    archiveInventories: PropTypes.func,
    unarchiveInventories: PropTypes.func,
    aggregateInventories: PropTypes.func,
    rootStore: PropTypes.object,
  };

  state = {
    siteIdToList: null,
    inventoryIdToSplit: null,
    inventoryIdsToList: [],
    variables: { paginate: { pageNumber: 1, limit: 30, filter: { sortBy: { asc: true, sortProperty: 'bestByDate' } } } },
    showArchiveModal: false,
    showSplitModal: false,
    showListModal: false,
    showMenu: false,
    showListInventoryMenu: false,
    showDonateInventoryMenu: false,
    deselect: false,
    selected: [],
    allSelected: false,
    updateColumns: true,
    listing: false,
    showAggregateModal: false,
    disableAggregate: false,
    actionOnRows: [],
    donateActionOnRows: [],
    archiveLoading: false,
  };

  get refetchQueries() {
    return [
      { query: StagedListingSummaryQuery },
      { query: StagedListingSummaryWithSiteQuery },
      { query: ProgressBarsQuery },
      { query: AwardSummaryQuery },
      { query: ActiveListingsQuery },
    ];
  }

  createMessage(createdCount) {
    const message = createdCount === 1 ? '1 item was successfully listed.' : `${createdCount} items were successfully listed.`;
    AlertService.alert({ type: 'success', message: <span>{message}</span>, autoDismiss: true, dismissDelay: 3000 });
  }

  handleQueryChange = (variables) => {
    this.setState({ variables });
  };

  hideModal = (actionTaken, errorCount, loggedCount) => {
    if (loggedCount > 0 && errorCount === 0) this.createMessage(loggedCount);
    this.setState(
      {
        showArchiveModal: false,
        archiveLoading: false,
        showSplitModal: false,
        showListModal: false,
        deselect: actionTaken,
        selected: actionTaken ? [] : this.state.selected,
      },
      this.hideOverlay
    );
  };

  listMultipleInventories = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const inventoryIdsToList = this.state.selected.map((i) => i._id);
    this.setState({ showListModal: true, siteIdToList: this.state.selected[0].siteId, inventoryIdsToList, isDonation: false });
  };

  donateMultipleInventories = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const inventoryIdsToList = this.state.selected.map((i) => i._id);
    this.setState({ showListModal: true, siteIdToList: this.state.selected[0].siteId, inventoryIdsToList, isDonation: true });
  };

  handleArchiveInventory = (mutation, showSuccess, showError, customMessage) => {
    this.setState({ archiveLoading: true });
    const inventoryIdsToArchive = this.state.selected.map((i) => i._id);
    const archiveVariables = {
      inventoryIds: inventoryIdsToArchive,
      allSelected: this.state.allSelected,
      inventoryFilters: this.state.variables.paginate.filter,
      customMessage,
    };
    const selectedNum = this.state.allSelected ? this.state.total : inventoryIdsToArchive.length;
    return mutation({
      variables: archiveVariables,
      update: (cache) => {
        deleteCachedFieldsOnUserQuery(cache, ['inventory', 'stagedListingsForChannel', 'stagedListingSummaryByCatalog']);
      },
      refetchQueries: [...this.refetchQueries, { query: UserInventoriesQuery, variables: this.state.variables }],
    })
      .then((res) => {
        const errors = res.data?.archiveInventories?.errors;
        if (errors?.length > 0) showError(errors[0]);
        else if (res.data.archiveInventories?.expectedModifiedCount > res.data.archiveInventories?.modifiedCount)
          this.archiveInventoryCaution(res.data.archiveInventories.modifiedCount, res.data.archiveInventories.expectedModifiedCount);
        else showSuccess(res, selectedNum);
        this.setState({ allSelected: false, deselect: true });
      })
      .catch(() =>
        showError({
          message:
            'We were unable to archive the inventory due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.',
        })
      );
  };

  archiveInventoryError = (err) => {
    AlertService.alert({
      type: 'warning',
      message: (
        <div>
          <div className={this.props.classes.alert__title} data-testid="archive-warning-header">
            Archive unsuccessful
          </div>
          <span className={this.props.classes.alert__body}>{err.message}</span>
        </div>
      ),
      autoDismiss: true,
      dismissDelay: 6000,
    });
    this.hideModal(false);
  };

  archiveInventoryCaution = (modifiedCount, expectedModifiedCount) => {
    const notModifiedCount = expectedModifiedCount - modifiedCount;
    AlertService.alert({
      type: 'caution',
      message: (
        <div>
          <div className={this.props.classes.alert__title} data-testid="archive-warning-header">
            {`${modifiedCount} of ${expectedModifiedCount} inventory items successfully archived`}
          </div>
          <span className={this.props.classes.alert__body}>
            {`${notModifiedCount} ${pluralize('items', notModifiedCount)} could not be archived because ${
              notModifiedCount > 1 ? 'they' : 'it'
            } already ${notModifiedCount > 1 ? 'have' : 'has an'} accepted ${pluralize('offer', notModifiedCount)}.`}
            <br></br>
            <br></br>
            To restore archived inventory, use the filter menu to view archived inventory and the bulk actions button to unarchive.
          </span>
        </div>
      ),
      autoDismiss: true,
      dismissDelay: 6000,
    });
    this.hideModal(false);
  };

  unarchiveInventoryError = (err) => {
    AlertService.alert({
      type: 'warning',
      message: (
        <div>
          <div className={this.props.classes.alert__title}>Unarchive unsuccessful</div>
          <span className={this.props.classes.alert__body}>{err.message}</span>
        </div>
      ),
      autoDismiss: true,
      dismissDelay: 6000,
    });
    this.hideModal(false);
  };

  archiveInventorySuccess = (res, num) => {
    AlertService.alert({
      message: (
        <div>
          <div className={this.props.classes.alert__title} data-testid="archive-success-header">
            {num} {pluralize('item', num)} successfully archived
          </div>
          <span className={this.props.classes.alert__body} data-testid="archive-success-body">
            To restore archived inventory, use the filter menu to view archived inventory and bulk actions button to unarchive.
          </span>
        </div>
      ),
      autoDismiss: true,
      dismissDelay: 6000,
    });
    this.hideModal(true);
  };

  unarchiveInventorySuccess = (res, num) => {
    AlertService.alert({
      message: (
        <div>
          <div className={this.props.classes.alert__title}>
            {num} {pluralize('item', num)} successfully unarchived
          </div>
          <span className={this.props.classes.alert__body}>
            Inventory has been successfully unarchived. To view your active inventory, use the filter menu.
          </span>
        </div>
      ),
      autoDismiss: true,
      dismissDelay: 6000,
    });
    this.hideModal(true);
  };

  archiveMultipleInventories = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (localStorage.getItem('hideArchiveDisclaimer') === 'true' && !this.state.selected.some((inv) => inv.status.staged === true)) {
      this.handleArchiveInventory(this.props.archiveInventories, this.archiveInventorySuccess, this.archiveInventoryError);
    } else {
      this.setState({ showArchiveModal: true });
    }
  };

  unarchiveMultipleInventories = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.handleArchiveInventory(this.props.unarchiveInventories, this.unarchiveInventorySuccess, this.unarchiveInventoryError);
  };

  showOverlay() {
    OverlayService.show();
  }

  hideOverlay() {
    OverlayService.hide();
  }

  handleSplitListingClick = (row) => {
    this.setState({ showSplitModal: true, inventoryIdToSplit: row._id }, this.showOverlay);
  };

  showListModal = (siteId, inventoryId) => {
    const inventoryIdsToList = inventoryId || this.state.inventory;
    this.setState({ showListModal: true, siteIdToList: siteId, inventoryIdsToList });
  };

  addToActionOnRows(rowId) {
    const newRows = [...this.state.actionOnRows];
    newRows.push(rowId);
    return newRows;
  }

  removeFromActionOnRows(rowId) {
    const newRows = [...this.state.actionOnRows];
    const idx = newRows.findIndex((id) => id === rowId);
    if (idx > -1) newRows.splice(idx, 1);
    return newRows;
  }

  addToDonateActionOnRows(rowId) {
    const newRows = [...this.state.donateActionOnRows];
    newRows.push(rowId);
    return newRows;
  }

  removeFromDonateActionOnRows(rowId) {
    const newRows = [...this.state.donateActionOnRows];
    const idx = newRows.findIndex((id) => id === rowId);
    if (idx > -1) newRows.splice(idx, 1);
    return newRows;
  }

  openListInventoryMenu = (row, event) => {
    if (this.props.user.site.hasUnmappedHandlingValues) {
      this.setState({ showListModal: true, siteIdToList: row.siteId, inventoryIdsToList: [row._id], isDonation: false });
    } else {
      this.setState({
        showListInventoryMenu: true,
        showDonateInventoryMenu: false,
        listInventoryMenuTop: event.clientY,
        listInventoryMenuLeft: event.clientX,
        quickMenuSiteId: row.siteId,
        quickMenuRowId: row._id,
      });
    }
  };

  openDonateInventoryMenu = (row, event) => {
    this.setState({
      showDonateInventoryMenu: true,
      showListInventoryMenu: false,
      listInventoryMenuTop: event.clientY,
      listInventoryMenuLeft: event.clientX,
      quickMenuSiteId: row.siteId,
      quickMenuRowId: row._id,
    });
  };

  handleSelectChange = (selected, total, allSelected) => {
    this.setState({ selected, total, allSelected, deselect: false });
  };

  // This is called from the Row Action Button <ActionMenuOption />, and not from Split Inventory or the Header button "Create Listings"
  listInventory = (distributionListId, rowId) => {
    const { quickMenuRowId } = this.state;
    const actionOnRows = this.addToActionOnRows(rowId);
    this.setState({ actionOnRows });
    const stagedListingVariables = {
      inventoriesToStage: [{ inventoryId: quickMenuRowId, distributionListId }],
      allSelected: false,
      inventoryFilters: {},
      destinationType: destinationType.discountedSales,
    };
    return this.props
      .createStagedListingsFromInventory({ variables: stagedListingVariables, refetchQueries: this.refetchQueries })
      .then((res) => this.hide(res, rowId))
      .catch((err) => this.hideAndError(err, rowId));
  };

  donateInventory = (distributionListId, rowId) => {
    const { quickMenuRowId } = this.state;
    const donateActionOnRows = this.addToDonateActionOnRows(rowId);
    this.setState({ donateActionOnRows });
    const stagedListingVariables = {
      inventoriesToStage: [{ inventoryId: quickMenuRowId, distributionListId }],
      allSelected: false,
      inventoryFilters: {},
      destinationType: destinationType.donation,
    };
    return this.props
      .createStagedListingsFromInventory({ variables: stagedListingVariables, refetchQueries: this.refetchQueries })
      .then((res) => this.hide(res, rowId))
      .catch((err) => this.hideAndError(err, rowId));
  };

  generateDistributionListOptions = (donation) => {
    const { distributionLists } = this.props;
    let dls = safeGet(distributionLists, 'currentUserQuery.site.distributionLists', []);
    if (donation) {
      dls = dls.filter((dl) => dl.destinationType.toLowerCase() === 'donation');
      return dls.map((list) => (
        <ActionMenuOption
          value={list._id}
          key={list._id}
          onClick={() => this.donateInventory(list._id, this.state.quickMenuRowId)}
          eventClickThrough={false}
        >
          {list.name}
        </ActionMenuOption>
      ));
    }

    dls = dls.filter((dl) => dl.destinationType.toLowerCase() !== 'donation');
    return dls.map((list) => (
      <ActionMenuOption
        value={list._id}
        key={list._id}
        onClick={() => this.listInventory(list._id, this.state.quickMenuRowId)}
        eventClickThrough={false}
      >
        {list.name}
      </ActionMenuOption>
    ));
  };

  hide = (response, rowId) => {
    let actionTaken = false;
    let loggedCount = 0;
    let errorCount = 0;
    if (response.data) {
      const loggedResults = response.data.createStagedListingsFromInventory;
      errorCount = loggedResults.errors?.length;
      actionTaken = loggedResults.inventories?.length > 0;
      loggedCount = loggedResults.inventories?.length - errorCount;
    }
    if (errorCount > 0) {
      AlertService.alert({ type: 'warning', message: response.data.createStagedListingsFromInventory.errors[0].message });
    }
    if (loggedCount > 0 && errorCount === 0) this.createMessage(loggedCount);
    const actionOnRows = this.removeFromActionOnRows(rowId);
    const donateActionOnRows = this.removeFromDonateActionOnRows(rowId);
    this.setState({
      showSplitModal: false,
      showListModal: false,
      showArchiveModal: false,
      deselect: actionTaken,
      listing: false,
      actionOnRows,
      donateActionOnRows,
    });
  };

  hideAndError = (error, rowId) => {
    const message = `We were unable to create the listings due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.`;
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });

    const actionOnRows = this.removeFromActionOnRows(rowId);
    const donateActionOnRows = this.removeFromDonateActionOnRows(rowId);

    this.setState({
      showSplitModal: false,
      showListModal: false,
      showArchiveModal: false,
      listing: false,
      actionOnRows,
      donateActionOnRows,
    });
  };

  hideAggregateModal = () => {
    this.setState({ showAggregateModal: false, disableAggregate: false });
  };

  showAggregateModal = () => {
    this.setState({ showAggregateModal: true });
  };

  aggregateInventory = () => {
    const { variables, disableAggregate } = this.state;
    if (disableAggregate) return null;
    this.setState({ disableAggregate: true });
    if (!variables.paginate.filter.customFilters) {
      AlertService.alert({ type: 'warning', message: 'Custom filter must be active to aggregate' });
      return this.hideAggregateModal();
    }
    return this.props
      .aggregateInventories({
        variables: { inventoryFilters: { customFilters: variables.paginate.filter.customFilters } },
        refetchQueries: this.refetchQueries,
      })
      .then((result) => {
        AlertService.alert({
          type: 'success',
          message: `${result.data.aggregateInventories.originalInventoryCount} inventories have been aggregated to ${result.data.aggregateInventories.aggregatedInventoryCount} inventories`,
          autoDismiss: true,
          dismissDelay: 3000,
        });
        return this.hideAggregateModal();
      })
      .catch(() => {
        AlertService.alert({ type: 'warning', message: 'There was an error aggregating the inventory.' });
      });
  };

  render() {
    const { classes, user } = this.props;
    const {
      deselect,
      allSelected,
      inventoryIdsToList,
      inventoryIdToSplit,
      variables,
      showArchiveModal,
      showListModal,
      siteIdToList,
      showSplitModal,
      listInventoryMenuLeft,
      listInventoryMenuTop,
      showListInventoryMenu,
      showDonateInventoryMenu,
      disableAggregate,
      actionOnRows,
      donateActionOnRows,
      archiveLoading,
    } = this.state;
    const customFilters = variables.paginate.filter ? variables.paginate.filter.customFilters : [];
    const customFiltersActive = customFilters ? variables.paginate.filter.customFilters.length > 0 : false;
    const aggregateButtonText = disableAggregate ? 'Aggregating...' : 'Aggregate';
    const archiveEnabled = checkFeature(featureFlags.archiveInventory);
    return (
      <div id="inventory" className={classes.inventory}>
        <div className={classes['inventory--table-wrap']}>
          <InventoryTable
            user={user}
            deselect={deselect}
            handleSelectChange={this.handleSelectChange}
            handleQueryChange={this.handleQueryChange}
            onSplitListingClick={this.handleSplitListingClick}
            openListInventoryMenu={this.openListInventoryMenu}
            openDonateInventoryMenu={this.openDonateInventoryMenu}
            listMultipleInventories={this.listMultipleInventories}
            donateMultipleInventories={this.donateMultipleInventories}
            archiveMultipleInventories={this.archiveMultipleInventories}
            unarchiveMultipleInventories={this.unarchiveMultipleInventories}
            actionOnRows={actionOnRows}
            donateActionOnRows={donateActionOnRows}
            customFilterActive={customFiltersActive}
            showAggregateModal={this.showAggregateModal}
            archiveLoading={archiveLoading}
            archiveEnabled={archiveEnabled}
            viewingArchived={!!this.state.variables.paginate.filter.showArchived}
          />
        </div>
        <StagedInventoryFlyup user={user} />
        <ArchiveInventoryModal
          open={showArchiveModal}
          onHide={this.hideModal}
          handleArchiveInventory={(customMessage) =>
            this.handleArchiveInventory(this.props.archiveInventories, this.archiveInventorySuccess, this.archiveInventoryError, customMessage)
          }
          archiveLoading={archiveLoading}
          allSelected={allSelected}
          filters={variables.paginate.filter}
          selectedInventory={allSelected ? [] : this.state.selected}
        />

        <ListInventoryModal
          siteId={siteIdToList}
          filters={variables.paginate.filter}
          allSelected={allSelected}
          open={showListModal}
          onHide={this.hideModal}
          inventoryIds={inventoryIdsToList}
          refetchQueries={this.refetchQueries}
          isDonation={this.state.isDonation}
          user={user}
        />

        <SplitInventoryModal
          key={inventoryIdToSplit}
          open={showSplitModal}
          onHide={this.hideModal}
          inventoryId={inventoryIdToSplit}
          refetchQueries={this.refetchQueries}
          filters={variables.paginate.filter}
        />
        <Modal open={this.state.showAggregateModal} onHide={this.hideAggregateModal} closeOnOutsideClick closeOnEsc>
          <ModalContent>
            <h3>Are you sure you want to aggregate all inventory on the selected custom filter? This action is irreversible.</h3>
          </ModalContent>
          <ModalFooter>
            <Button type="button" link onClick={this.hideAggregateModal}>
              Cancel
            </Button>
            <Button type="button" primary disabled={disableAggregate} onClick={this.aggregateInventory}>
              {aggregateButtonText}
            </Button>
          </ModalFooter>
        </Modal>
        <FloatingMenu show={showListInventoryMenu} position="topleft" top={listInventoryMenuTop} left={listInventoryMenuLeft}>
          {this.generateDistributionListOptions()}
        </FloatingMenu>
        <FloatingMenu show={showDonateInventoryMenu} position="topleft" top={listInventoryMenuTop} left={listInventoryMenuLeft}>
          {this.generateDistributionListOptions(true)}
        </FloatingMenu>
      </div>
    );
  }
}

const ConnectedComponent = inject((store) => store)(observer(Inventory));

export default ConnectedComponent;
