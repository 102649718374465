import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import capitalize from 'capitalize';
import injectSheet from 'react-jss';
import { Card, Theme as theme, TypeAhead, Select, SelectOption, TextInput, Checkbox, Button } from '@spoiler-alert/ui-library';
import { ItemNameTypeAheadQuery } from '../../../graphql/queries';
import formStyles from './form-styles';

const styles = {
  ...formStyles,
  vendor__span: {
    marginLeft: 5,
  },
  item__information: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  'item__detail--header': {
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontWeight: 'normal',
    margin: 0,
    color: theme.primaryColor,
    paddingTop: 12,
  },
  'item__detail--text': {
    textTransform: 'capitalize',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    margin: 0,
    paddingBottom: 7,
  },
};

@injectSheet(styles)
export default class ProductForm extends Component {
  constructor(props) {
    super(props);
    const { quality, unitOfMeasure, isDiscountedSales, item, itemName, isVendorReturn } = props;
    this.state = {
      typeaheadDisabled: !!item,
      itemName,
      isDiscountedSales,
      item,
      quality: quality ? { value: quality, text: quality } : undefined,
      quantity: props.quantity,
      unitOfMeasure: unitOfMeasure ? this.uomOption(unitOfMeasure) : undefined,
      uomDisabled: false,
      isVendorReturn,
      quantityErrored: false,
    };

    this.setReady();
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.reset) {
      return {
        itemName: '',
        item: undefined,
        quality: undefined,
        quantity: undefined,
        unitOfMeasure: undefined,
        uomDisabled: false,
        isVendorReturn: false,
        quantityErrored: false,
        isDiscountedSales: false,
      };
    }
    return { isDiscountedSales: nextProps.isDiscountedSales };
  }

  componentDidUpdate(nextProps) {
    if (nextProps.reset) this.setReady();
  }

  setItem = (selected) => {
    const item = selected ? selected.result : undefined;
    const itemName = selected ? selected.displayText : undefined;
    const unitOfMeasure = item ? this.uomOption(item.unitOfMeasure) : undefined;
    this.setState({ item, itemName, unitOfMeasure }, this.setReady);
  };

  uomOption(uom) {
    return { value: uom, text: capitalize(uom) };
  }

  get packs() {
    return this.uomOption('packs');
  }

  setQuality = (_, quality) => {
    this.setState({ quality }, this.setReady);
  };

  setUOM = (_, unitOfMeasure) => {
    this.setState({ unitOfMeasure }, this.setReady);
  };

  setQuantity = (quantity) => {
    this.setState({ quantity, quantityErrored: !this.validQuantity(quantity) }, this.setReady);
  };

  setVendorReturn = (isVendorReturn) => {
    this.setState({ isVendorReturn: isVendorReturn.checked }, this.setReady);
  };

  validQuantity(quantity) {
    return quantity && quantity > 0;
  }

  setReady = () => {
    this.props.setReadyStep(this.ready);
    this.serialize();
  };

  serialize() {
    const { item, quality, quantity, unitOfMeasure, isVendorReturn, isDiscountedSales } = this.state;
    this.props.serialize({
      item,
      quality,
      quantity,
      unitOfMeasure,
      isVendorReturn,
      isDiscountedSales,
    });
  }

  get packUOM() {
    return pluralize(this.state.item.packUOM, this.state.item.packSize);
  }

  get ready() {
    return !!(this.state.item && this.state.quality && this.validQuantity(this.state.quantity) && this.state.unitOfMeasure);
  }

  get qualities() {
    const selected = this.state.quality;
    const qualities = this.props.user.site.foodQualityList;
    return qualities.map((quality, idx) => (
      <SelectOption key={`quality-${idx}`} selected={selected && selected.value === quality} value={quality}>
        {quality}
      </SelectOption>
    ));
  }

  get unitOfMeasureOptions() {
    if (!this.state.item) return [];
    const unitOfMeasure = this.uomOption(this.state.item.unitOfMeasure);
    return [unitOfMeasure, this.packs];
  }

  get unitsOfMeasure() {
    const selected = this.state.unitOfMeasure;
    return this.unitOfMeasureOptions.map((uom) => (
      <SelectOption key={uom.value} value={uom.value} selected={selected === uom.value}>
        {uom.text}
      </SelectOption>
    ));
  }

  render() {
    const { classes, user, goToPreviousStep } = this.props;
    const { itemName, typeaheadDisabled, item, quality, quantity, uomDisabled, unitOfMeasure, isVendorReturn, quantityErrored, isDiscountedSales } =
      this.state;
    const sites = user.siteHierarchy.map((site) => site._id);
    return (
      <Card className={classes.form__container}>
        <h2 className={classes.form__header}>Product</h2>
        {item && (
          <div className={classes.item__information}>
            <div className={classes.item__detail}>
              <h4 className={classes['item__detail--header']}>Description</h4>
              <p className={classes['item__detail--text']}>{item.itemDesc}</p>
            </div>
            <div className={classes.item__detail}>
              <h4 className={classes['item__detail--header']}>Primary UOM</h4>
              <p className={classes['item__detail--text']}>{item.unitOfMeasure}</p>
            </div>
            <div className={classes.item__detail}>
              <h4 className={classes['item__detail--header']}>Packs Per {pluralize.singular(item.unitOfMeasure)}</h4>
              <p className={classes['item__detail--text']}>{item.packQty}</p>
            </div>
            <div className={classes.item__detail}>
              <h4 className={classes['item__detail--header']}>Pack Size</h4>
              <p className={classes['item__detail--text']}>
                {item.packSize} {this.packUOM}
              </p>
            </div>
          </div>
        )}
        <div className={classes.form__row}>
          <div className={classes.form__item}>
            <TypeAhead
              sites={sites}
              query={ItemNameTypeAheadQuery}
              queryName="itemNameTypeAheadQuery"
              dataFormat={[{ displayText: 'itemName' }]}
              labelText="Item Number *"
              onChange={this.setItem}
              autoWidth
              disabled={typeaheadDisabled}
              value={itemName}
            />
          </div>
          <div className={classes.form__item}>
            <Select label="Quality *" selectedItem={quality} onChange={this.setQuality}>
              {this.qualities}
            </Select>
          </div>
        </div>
        <div className={classes.form__row}>
          <div className={classes.form__item}>
            <TextInput
              type="number"
              value={quantity}
              error={quantityErrored}
              labelText="Quantity *"
              onChange={this.setQuantity}
              required
              min={0.01}
            />
          </div>
          <div className={classes.form__item}>
            <Select label="Unit of Measure *" disabled={uomDisabled} selectedItem={unitOfMeasure} onChange={this.setUOM}>
              {this.unitsOfMeasure}
            </Select>
          </div>
        </div>
        {!isDiscountedSales && (
          <div className={classes.form__row}>
            <div className={classes['form__item--row']}>
              <Checkbox value="true" onChecked={this.setVendorReturn} checked={isVendorReturn} />
              <span className={classes.vendor__span}>Vendor Return</span>
            </div>
          </div>
        )}
        <div className={this.props.classes.button__container}>
          <Button type="button" secondary onClick={goToPreviousStep}>
            Previous
          </Button>
        </div>
      </Card>
    );
  }
}

ProductForm.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  goToPreviousStep: PropTypes.func,
  setReadyStep: PropTypes.func,
  serialize: PropTypes.func,
  reset: PropTypes.bool,
  itemName: PropTypes.string,
  item: PropTypes.object,
  quality: PropTypes.string,
  quantity: PropTypes.number,
  unitOfMeasure: PropTypes.string,
  isVendorReturn: PropTypes.bool,
  isDiscountedSales: PropTypes.bool,
};

ProductForm.defaultProps = {
  isVendorReturn: false,
};
