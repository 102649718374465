import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { DataTableNaked, Search, Timing } from '@spoiler-alert/ui-library';
import { useQuery } from '@apollo/client';
import { TitleService } from '../../services';
import { GetBuyerProfilesQuery } from '../../graphql/queries';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import { Breadcrumbs } from '../../store';

const useStyles = createUseStyles({
  tableWrap: {
    border: '1px #D1D6DA solid',
    borderRadius: 2,
    marginTop: 1,
    marginBottom: '25px',
  },
  search: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '17px',
    width: 400,
  },
});

const CustomerProfiles = ({ user, history }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const { loading, data } = useQuery(GetBuyerProfilesQuery);

  useEffect(() => {
    TitleService.setTitles('Customer profiles');
    Breadcrumbs.set([
      {
        url: '/customers',
        title: 'Customer profiles',
      },
    ]);
  }, []);

  const handleRowClick = (row) => {
    history.push(`/customers/${row.buyerName}`);
  };

  const handleSearch = Timing.debounce((text) => setSearchText(text), 300);

  return (
    <div>
      <Search onChange={handleSearch} className={classes.search} placeholder="Search by customer" />
      <div className={classes.tableWrap}>
        <DataTableNaked
          data={data?.buyerProfiles || []}
          loading={loading}
          noDataMessage={loading ? 'Loading...' : 'No customer profiles matched your search.'}
          userId={user._id}
          transition
          searchText={searchText}
          onRowClick={handleRowClick}
          columns={getColumnsFromDataTableProfile('Buyer Profiles', user.site.dataTableProfiles)}
          sticky
        />
      </div>
    </div>
  );
};

CustomerProfiles.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
};

export default CustomerProfiles;
