import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import {
  DaterangeFilter,
  DateRangeProvider,
  AreaChart,
  TotalMetric,
  MetricsWrapper,
  GraphQLDataTable,
  AlertService,
  Theme as theme,
  Button,
  ExportIcon,
} from '@spoiler-alert/ui-library';
import { TotalTaxDeductibleQuery, TaxDeductionsAreaChartQuery, TaxDeductiblePostsQuery } from '../../graphql/queries';
import { taxDeductions } from '../../metrics/metric-types';
import dataExportService from '../../services/data-export-service';
import { LocationFilter, StatusFilter, FilterRow } from '../../components/filters';
import getMyTaxDeductibleColumns from './tax-columns';
import { Grid } from '../../components/grid';
import { postStatuses } from '../../enums';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';

const styles = {
  root: {
    flexGrow: 1,
  },
  documentation__table: {
    marginTop: 10,
  },
  documentation__title: {
    fontSize: '1rem',
    padding: 0,
    marginTop: 0,
    color: theme.textColorPrimary,
    fontWeight: 'normal',
  },
  filter__container: {
    flexGrow: 1,
    padding: '0 10px',
    maxWidth: '33.33%',
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  '@media (max-width: 1423px) and (min-width: 840px)': {
    filter__container: {
      minWidth: '33.33%',
      flexBasis: 0,
      flexGrow: 0,
      '&:last-child': {
        paddingRight: '10px',
      },
      '&:nth-child(3n+1)': {
        paddingLeft: 0,
      },
      '&:nth-child(3n+3)': {
        paddingRight: 0,
      },
    },
  },
  '@media (max-width: 840px)': {
    filter__container: {
      flexGrow: 1,
      midWidth: '100%',
      maxWidth: '100%',
      padding: 0,
    },
  },
};

class TaxDashboard extends React.Component {
  constructor(props) {
    super(props);
    if (!props.user.privileges.canViewTaxDashboard) props.history.push('/');

    TitleService.setTitles('Tax Portal');
    Breadcrumbs.set([
      {
        url: '/tax',
        title: 'Tax',
      },
    ]);

    this.dateRangeChange = this.dateRangeChange.bind(this);
    this.sitesChange = this.sitesChange.bind(this);
    this.dateRangeProvider = new DateRangeProvider(props.user.site.fiscalYearStart, props.user.site.allTimeStart);
    this.state = {
      completedAtStartDate: this.dateRangeProvider.fiscalYear.start.toDate(),
      completedAtEndDate: this.dateRangeProvider.fiscalYear.end.toDate(),
      sites: props.user.postFilterParameters.locations.map((l) => l.id),
      donationStatusQuery: { $gte: postStatuses.claimed, $lte: postStatuses.completed },
      statuses: ['CLAIMED', 'UNVERIFIED', 'COMPLETED'],
      activeTimeframeDropdownId: 0,
      vendorReturns: ['false'],
      filterParameters: {
        completedAtStartDate: this.dateRangeProvider.fiscalYear.start.toDate(),
        completedAtEndDate: this.dateRangeProvider.fiscalYear.end.toDate(),
      },
    };
    this.export = this.export.bind(this);
    this.exportCompleted = this.exportCompleted.bind(this);
    this.exportErrored = this.exportErrored.bind(this);
    this.statusChanged = this.statusChanged.bind(this);

    const hasTaxModule = props.user.privileges.canUserSiteUseTax;
    if (!hasTaxModule) return props.history.push('taxesAbout');
  }

  dateRangeChange(completedAtStartDate, completedAtEndDate) {
    const filterParameters = { ...this.state.filterParameters, completedAtStartDate, completedAtEndDate };
    this.setState({ completedAtStartDate, completedAtEndDate, filterParameters });
  }

  sitesChange(sites) {
    const filterParameters = { ...this.state.filterParameters, suppliers: sites };
    this.setState({ sites, filterParameters });
  }

  export() {
    dataExportService.export('tax-deductions').then(this.exportCompleted).catch(this.exportErrored);
  }

  exportCompleted(response) {
    if (response.ok || response.data) {
      const message = 'Your export is now processing and we will email you when it is completed.';
      AlertService.alert({ type: 'info', message: <span>{message}</span>, autoDismiss: true, dismissDelay: 5000 });
    } else {
      this.exportErrored();
    }
  }

  exportErrored() {
    const message =
      'Sorry we are unable to export your tax deductions. If this problem persists, please contact a Spoiler Alert Administrator to help you.';
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  }

  statusChanged({ value, statusQuery }) {
    const filterParameters = { ...this.state.filterParameters, statuses: value };
    this.setState({ donationStatusQuery: statusQuery, statuses: value, filterParameters });
  }

  render() {
    const { classes, user } = this.props;
    const className = `sa-dashboard-container tax-dashboard ${classes.root}`;
    const taxDashboardPrivs = this.props.user.privileges.canViewTaxDashboard;
    return (
      <div id="tax-dashboard" className={className}>
        <Grid container gutter={this.gutterSize}>
          <FilterRow key="dashboard-filter-row">
            <div className={classes.filter__container}>
              <DaterangeFilter rangelist onChange={this.dateRangeChange} dateRangeProvider={this.dateRangeProvider} />
            </div>
            {this.props.user.privileges.canUserManageMultipleSites && (
              <div className={classes.filter__container}>
                <LocationFilter onChange={this.sitesChange} filterBasis={'POST'} user={this.props.user} />
              </div>
            )}
            <div className={classes.filter__container}>
              <StatusFilter onChange={this.statusChanged} />
            </div>
          </FilterRow>
          <Grid md={4} xs={12}>
            <TotalMetric
              query={TotalTaxDeductibleQuery}
              sites={this.state.sites}
              metric={taxDeductions}
              color="blue"
              completedAtStartDate={this.state.completedAtStartDate}
              completedAtEndDate={this.state.completedAtEndDate}
              statuses={this.state.statuses}
              vendorReturns={this.state.vendorReturns}
            />
          </Grid>
          <Grid md={8} xs={12}>
            <MetricsWrapper
              metrics={[]}
              metric={{ query: 'taxDeductionPerPeriod', mask: '$0,0', gql: TaxDeductionsAreaChartQuery }}
              title="Enhanced Tax Deductions Over Time (Cumulative)"
              size="s"
            >
              <AreaChart
                sites={this.state.sites}
                fiscalYearStart={this.props.user.site.fiscalYearStart}
                startDate={this.state.completedAtStartDate}
                endDate={this.state.completedAtEndDate}
                statuses={this.state.statuses}
                vendorReturns={this.state.vendorReturns}
              />
            </MetricsWrapper>
          </Grid>
          <Grid xs={12} className={classes.documentation__table}>
            <h2 className={classes.documentation__title}>Documentation</h2>
            <GraphQLDataTable
              query={TaxDeductiblePostsQuery}
              queryName="currentUserQuery"
              queryField="taxDeductiblePosts"
              userId={this.props.user._id}
              search
              exportable
              transition
              columns={getMyTaxDeductibleColumns(user)}
              queryParameters={this.state.filterParameters}
              filterParameterField="postFilterParameters"
              filters={[]}
              pagination
              perPage={15}
              perPageIncrements={[15, 30, 50, 100]}
              pageActionButtons={[
                <Button key="export" secondary icon={ExportIcon} onClick={taxDashboardPrivs ? this.export : undefined}>
                  Export
                </Button>,
              ]}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

TaxDashboard.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object,
};

const StyledComponent = injectSheet(styles)(TaxDashboard);
export default StyledComponent;
