import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import accounting from 'accounting';
import { Theme as theme, Checkbox } from '@spoiler-alert/ui-library';
import AwardTableRow from './award-table-row';
import { totalsReducer } from './award-table-grouping';
import GroupOpener from './group-opener';
import AwardTableActiveLaneRow from './award-table-active-lane-row';
import { negativeCostHandler } from '../../utilities/negative-cost-handler';
import { costRecoveryHandler } from '../../utilities/cost-recovery-handler';

const useStyles = createUseStyles({
  originHead: {
    background: theme.blue5,
    borderBottom: `1px solid ${theme.grey30}`,
    cursor: 'pointer',
    '&:hover': {
      background: theme.menuItemHoverBg,
    },
  },
  originHeadCell: {
    padding: [8, 12],
    color: theme.text,
    fontWeight: 500,
    fontSize: 14,
  },
  checkbox: {
    padding: [15, 12],
    textAlign: 'center',
    height: 48,
  },
  numberColumn: {
    extend: 'originHeadCell',
    textAlign: 'right',
  },
});

const AwardTableOrigin = ({ lanes, groupKey, includeLanes, excludeLanes, excludedLanes, smartAwardMode }) => {
  const classes = useStyles();

  const [showChildren, setShowChildren] = useState(true);

  const totals = useMemo(() => {
    return totalsReducer(lanes);
  }, [lanes]);

  useEffect(() => {
    setShowChildren(totals.caseCount !== 0);
  }, [totals.caseCount === 0]);

  const handleCheck = (event) => {
    event.event.stopPropagation();
    if (event.checked) {
      includeLanes(lanes.map((l) => l._id));
    } else {
      excludeLanes(lanes.map((l) => l._id));
    }
  };

  const { checked, partial } = useMemo(() => {
    let excludedCount = 0;
    lanes.forEach((lane) => {
      if (excludedLanes.includes(lane._id)) excludedCount += 1;
    });
    return {
      checked: excludedCount === 0,
      partial: excludedCount > 0 && excludedCount < lanes.length,
    };
  }, [excludedLanes, lanes]);

  const handleRowClick = (e) => {
    e.stopPropagation();
    setShowChildren(!showChildren);
  };

  return (
    <>
      <tr className={classes.originHead} onClick={handleRowClick}>
        <td>
          <Checkbox onChecked={handleCheck} className={classes.checkbox} checked={checked} partial={partial} />
        </td>
        <td className={classes.originHeadCell}>
          <GroupOpener open={showChildren} indentLevel={1} fill={theme.greyDark} />
          {groupKey}
        </td>
        <td className={classes.numberColumn}>{accounting.formatNumber(totals.caseCount)}</td>
        <td className={classes.numberColumn}>{accounting.formatNumber(totals.palletCount, 2)}</td>
        <td className={classes.numberColumn}>{accounting.formatNumber(totals.totalWeight, 2)} lbs</td>
        <td className={classes.numberColumn}>{accounting.formatMoney(totals.totalRevenue)}</td>
        <td className={classes.numberColumn}>({accounting.formatMoney(totals.totalEstDeliveryCost)})</td>
        <td className={classes.numberColumn}>{negativeCostHandler(totals.totalRevenue - totals.totalEstDeliveryCost)}</td>
        <td className={classes.numberColumn}>{costRecoveryHandler(totals.totalPrice, totals.costOfAwardedInventory)}</td>
      </tr>
      {showChildren &&
        lanes.map((lane) =>
          lane.existingLane ? (
            <AwardTableActiveLaneRow
              includeLanes={includeLanes}
              excludeLanes={excludeLanes}
              excludedLanes={excludedLanes}
              key={lane._id}
              lane={lane}
              smartAwardMode={smartAwardMode}
            />
          ) : (
            <AwardTableRow
              includeLanes={includeLanes}
              excludeLanes={excludeLanes}
              excludedLanes={excludedLanes}
              key={lane._id}
              lane={lane}
              smartAwardMode={smartAwardMode}
            />
          )
        )}
    </>
  );
};

AwardTableOrigin.propTypes = {
  lanes: PropTypes.arrayOf(PropTypes.object),
  groupKey: PropTypes.string,
  includeLanes: PropTypes.func,
  excludeLanes: PropTypes.func,
  excludedLanes: PropTypes.arrayOf(PropTypes.string),
  smartAwardMode: PropTypes.string,
};

export default AwardTableOrigin;
