import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { graphql } from '@apollo/client/react/hoc';
import { Modal, ModalContent, ModalFooter, Button } from '@spoiler-alert/ui-library';
import { VerifyPosts } from '../../graphql/mutations';

const styles = {
  'question-modal__title': {
    fontSize: '1.5rem',
    padding: 0,
    marginTop: 0,
    color: '#292e34',
    fontWeight: 'normal',
  },
  input__row: {
    padding: '6px 0',
  },
};

@graphql(VerifyPosts)
@injectSheet(styles)
export default class VerifyMultipleModal extends Component {
  state = {
    open: false,
    posts: this.props.posts,
    allSelected: this.props.allSelected,
    total: this.props.total,
    verifying: false,
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      open: nextProps.open,
      posts: nextProps.posts,
      allSelected: nextProps.allSelected,
      total: nextProps.total,
    };
  }

  cancel = () => {
    this.props.close();
  };

  verify = () => {
    const { refetchQueries, mutate } = this.props;
    this.setState({ verifying: true });
    const variables = { ids: this.state.allSelected ? [] : this.state.posts.map((post) => post._id) };
    mutate({ variables, refetchQueries }).then(this.hide).catch(this.hide);
  };

  hide = (response) => {
    this.setState({ verifying: false });
    const error = response.data && response.data.error === undefined;
    this.props.onHide(error);
  };

  get total() {
    return this.state.allSelected ? this.state.total : this.state.posts.length;
  }

  render() {
    const { classes, user } = this.props;
    return (
      <Modal onHide={this.props.close} open={this.state.open} closeOnEsc closeOnOutsideClick>
        <ModalContent>
          <h2 className={classes['question-modal__title']}>Verify the donations</h2>
          <p>
            By clicking “Complete”, you are confirming that the products of all the selected posts have been received in the quantities listed, dates
            specified, and accepted by {user.site.siteName}.
          </p>
        </ModalContent>
        <ModalFooter>
          <Button type="button" onClick={this.cancel} link disabled={this.state.verifying}>
            Cancel
          </Button>
          <Button type="button" onClick={this.verify} primary loading={this.state.verifying} loadingText="Wait...">
            Complete All {this.total} Posts
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

VerifyMultipleModal.propTypes = {
  classes: PropTypes.object,
  onHide: PropTypes.func,
  close: PropTypes.func,
  open: PropTypes.bool,
  user: PropTypes.object,
  posts: PropTypes.array,
  total: PropTypes.number,
  allSelected: PropTypes.bool,
  mutate: PropTypes.func,
  refetchQueries: PropTypes.array,
};
