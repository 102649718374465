import { Theme as theme } from '@spoiler-alert/ui-library';

const settingsStyles = {
  card: {
    padding: '28px 32px 32px',
  },
  section__title: {
    fontSize: '1.5rem',
    marginBottom: 18,
  },
  section__subtitle: {
    fontSize: '1rem',
    lineHeight: '1.1875rem',
    fontWeight: 'bold',
  },
  info__icon: {
    height: '1rem',
    width: '1rem',
    marginLeft: '4px',
    display: 'inline-block',
    cursor: 'pointer',
    fill: theme.tooltipColor,
    paddingBottom: '2px',
  },
  loading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  section__description: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
  },
};

export default settingsStyles;
