import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import queryString from 'query-string';
import { TextInput, Button } from '@spoiler-alert/ui-library';
import { Link } from 'react-router-dom';
import client from '../../apollo/client';
import { LoginService, TitleService } from '../../services';
import routePaths from '../../route-paths';
import styles from './sign-in-styles';

const useStyles = createUseStyles(styles);

const SignIn = ({ history }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const isAuthenticated = () => !!localStorage.getItem('token');

  useEffect(() => {
    TitleService.setTitleTag('Sign In');
    if (isAuthenticated()) history.push(routePaths.home);
  }, []);

  useEffect(() => {
    const values = queryString.parse(location.search);
    const { errorMessage } = values;
    if (errorMessage) setError(errorMessage);
  }, [location.search]);

  const redirect = () => {
    const values = queryString.parse(location.search);
    const redirectParam = values.redirect;
    return redirectParam ? decodeURIComponent(redirectParam) : routePaths.home;
  };

  const emailHandler = (_email) => {
    setEmail(_email);
    setError(null);
  };

  const passwordHandler = (_password) => {
    setPassword(_password);
    setError(null);
  };

  const refetch = async () => {
    await client.resetStore();
    localStorage.setItem('isSSOUser', 'no');
    history.push(redirect());
  };

  const submitLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    LoginService.login(email.trim().toLowerCase(), password)
      .then(refetch)
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  return (
    <div className={classes.loginContainer}>
      <h4 className={classes.loginTitle}>Sign in</h4>
      {error && (
        <div className={classes.errorContainer} data-element="error-message">
          {error}
        </div>
      )}
      <form onSubmit={submitLogin} className={classes.formContainer}>
        <div className={classes.formRow}>
          <TextInput onChange={emailHandler} id="email" required type="email" labelText="Email" value={email} autoComplete="username" />
        </div>

        <div className={classes.formRow}>
          <TextInput
            onChange={passwordHandler}
            id="password"
            required
            type="password"
            labelText="Password"
            value={password}
            autoComplete="current-password"
          />
        </div>

        <p className={classes.loginText}>
          <Link className={classes.loginLink} id="forgot-password-link" to="/forgot-password">
            Forgot Password?
          </Link>
        </p>

        <div className={classes.formRow}>
          <Button className={classes.formButton} id="sign-in" type="submit" loading={loading} loadingText="Loading...">
            Sign In
          </Button>
        </div>
      </form>
      <div className={classes.footerContainer}>
        <Link className={classes.loginLink} id="sso-login-link" to={routePaths.ssoSignIn}>
          Sign in using Single Sign On (SSO)
        </Link>
        <a className={classes.loginLink} href="https://www.spoileralert.com/request-a-demo" target="_blank" rel="noreferrer">
          Request a Demo
        </a>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

export default SignIn;
