import { Theme } from '@spoiler-alert/ui-library';

const companyLogoStyles = {
  wrapper: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    width: 800,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  instructions: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
  },
  header: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    textAlign: 'left',
    margin: 0,
  },
  subTitle: {
    marginBottom: 12,
    fontSize: 14,
  },
  logoContainer: {
    width: 200,
    margin: '0 0 0 24px',
  },
  uploadImage: {
    height: 200,
    flexGrow: 0,
    borderRadius: 4,
    border: `solid 1px ${Theme.borderColor}`,
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 1,
    '&:hover': {
      backgroundColor: Theme.grey5,
      borderColor: Theme.greyDark,
    },
  },
  logo: {
    height: 200,
    flexGrow: 0,
    borderRadius: 4,
    border: `solid 1px ${Theme.borderColor}`,
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 1,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  uploadInput: {
    width: 25,
    height: 25,
    marginBottom: 4,
  },
  uploadText: {
    fontSize: 14,
    fontWeight: 500,
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  removeLogo: {
    color: Theme.red,
    fontSize: 14,
    cursor: 'pointer',
  },
  replaceLogo: {
    color: Theme.teal,
    fontSize: 14,
    cursor: 'pointer',
  },
  detail: {
    color: Theme.grey,
  },
  exportIcon: {
    stroke: Theme.white,
    fill: Theme.greyDark,
  },
};

export default companyLogoStyles;
