import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useQuery } from '@apollo/client';
import { Modal } from '@spoiler-alert/ui-library';
import { event } from 'react-fullstory';
import archiveInventoryModalStyles from './archive-inventory-modal-styles';
import { GetStatusOfSelectedInventoryForArchiveQuery } from '../../graphql/queries';
import ReadyOrStagedArchiveInventoryModalContent from './ready-or-staged-archive-inventory-modal-content';
import CheckStatusArchiveInventoryModalContent from './check-status-archive-inventory-modal-content';
import ListedArchiveInventoryModalContent from './listed-archive-inventory-modal-content';
import AllAcceptedArchiveInventoryModalContent from './all-accepted-archive-inventory-modal-content';

const useStyles = createUseStyles(archiveInventoryModalStyles);

const ArchiveInventoryModal = ({ onHide, open, handleArchiveInventory, archiveLoading, allSelected, filters, selectedInventory }) => {
  const classes = useStyles();
  const [hideArchiveDisclaimer, setHideArchiveDisclaimer] = useState(false);
  const [showCustomMessage, setShowCustomMessage] = useState(false);
  const [customMessage, setCustomMessage] = useState();
  const { data, loading } = useQuery(GetStatusOfSelectedInventoryForArchiveQuery, {
    variables: { inventoryFilters: filters },
    skip: allSelected === false,
  });
  const status = data?.getStatusOfSelectedInventoryForArchive.status;

  const getDistributionLists = () => {
    const dls = [];
    if (allSelected) {
      data.getStatusOfSelectedInventoryForArchive.distributionLists.forEach((dl) => {
        dls.push({ key: dl, value: [] });
      });
    } else {
      selectedInventory.forEach((inv) => {
        inv.distributionLists.forEach((dl) => {
          dls.push({ key: dl.name, value: [] });
        });
      });
    }
    dls.sort((a, b) => {
      const nameA = a?.key?.toUpperCase();
      const nameB = b?.key?.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    return dls.filter((value, index, distributionLists) => index === distributionLists.findIndex((dl) => dl.key === value.key));
  };

  const toggleHideArchiveDisclaimer = (checkbox) => {
    setHideArchiveDisclaimer(checkbox.checked);
  };

  const cancel = () => {
    setHideArchiveDisclaimer(false);
    onHide(false);
    setShowCustomMessage(false);
    setCustomMessage('');
  };

  const isReadyOrStaged =
    status === 'READY OR STAGED' || (selectedInventory?.length > 0 && !selectedInventory.some((inv) => inv.status.listed === true));

  const isListed =
    status === 'PUBLISHED' ||
    (selectedInventory?.length > 0 &&
      selectedInventory.some((inv) => inv.status.listed === true) &&
      !selectedInventory.every((inv) => inv.status.accepted === true));

  const submit = async (ev) => {
    ev.preventDefault();
    if (hideArchiveDisclaimer) localStorage.setItem('hideArchiveDisclaimer', 'true');
    if (isReadyOrStaged) event('Archived Ready or Staged Inventory');
    if (isListed && !customMessage) event('Archived Published Inventory');
    if (isListed && customMessage) event('Archived Published Inventory with Custom Message');
    await handleArchiveInventory(customMessage);
    setShowCustomMessage(false);
    setCustomMessage('');
  };

  return (
    <Modal onHide={onHide} open={open} closeOnEsc closeOnOutsideClick staticHeight={true}>
      <form onSubmit={submit} className={classes.form__container}>
        {loading && <CheckStatusArchiveInventoryModalContent />}
        {isReadyOrStaged && (
          <ReadyOrStagedArchiveInventoryModalContent
            toggleHideArchiveDisclaimer={toggleHideArchiveDisclaimer}
            hideArchiveDisclaimer={hideArchiveDisclaimer}
            cancel={cancel}
            archiveLoading={archiveLoading}
          />
        )}
        {isListed && (
          <ListedArchiveInventoryModalContent
            customMessage={customMessage}
            showCustomMessage={showCustomMessage}
            setCustomMessage={setCustomMessage}
            setShowCustomMessage={setShowCustomMessage}
            distributionLists={getDistributionLists()}
            cancel={cancel}
            archiveLoading={archiveLoading}
          />
        )}
        {(status === 'ALL ACCEPTED' || (selectedInventory?.length > 0 && selectedInventory.every((inv) => inv.status.accepted === true))) && (
          <AllAcceptedArchiveInventoryModalContent cancel={cancel} />
        )}
      </form>
    </Modal>
  );
};

ArchiveInventoryModal.propTypes = {
  onHide: PropTypes.func,
  open: PropTypes.bool,
  handleArchiveInventory: PropTypes.func,
  archiveLoading: PropTypes.bool,
  allSelected: PropTypes.bool,
  filters: PropTypes.object,
  selectedInventory: PropTypes.array,
};

export default ArchiveInventoryModal;
