import React from 'react';
import PropTypes from 'prop-types';
import { Banner, CautionIcon, AlertService } from '@spoiler-alert/ui-library';
import { useMutation } from '@apollo/client';
import accounting from 'accounting';
import pluralize from 'pluralize';
import { createNegotiationStagedListings } from '../../graphql/mutations';
import { negotiationOrigin } from '../../enums';
import routePaths from '../../route-paths';
import { StagedNegotiationSummaryQuery } from '../../graphql/queries';

const RecommendedNegotiations = ({ negotiationsData, refetchNegotiations, history }) => {
  const estimatedRevenueIncrease = negotiationsData?.possibleNegotiationsSummaryQuery?.estimatedRevenueIncrease;
  const numberOfCases = negotiationsData?.possibleNegotiationsSummaryQuery?.numberOfCases;
  const offerListingsToNegotiate = negotiationsData?.possibleNegotiationsSummaryQuery?.offerListingsToNegotiate;
  const numberOfNegotiations = negotiationsData?.possibleNegotiationsSummaryQuery?.offerListingsToNegotiate.length;

  const [stageNegotiations, { loading }] = useMutation(createNegotiationStagedListings);

  const showError = (message) => {
    return AlertService.alert({ type: 'warning', message: <span>{message}</span>, autoDismiss: true });
  };

  const stageRecommendedNegotiations = () => {
    const cleanedOfferListingsToNegotiate = offerListingsToNegotiate.map((ol) => ({
      offerListingId: ol.id,
      counterOfferQuantity: ol.quantity,
    }));
    const variables = {
      negotiationOrigin: negotiationOrigin.SMART_AWARD,
      counterOffers: cleanedOfferListingsToNegotiate,
    };
    stageNegotiations({ variables, refetchQueries: [{ query: StagedNegotiationSummaryQuery }] })
      .then((result) => {
        const { errors } = result.data.createNegotiationStagedListings;
        if (errors && errors.length > 0) {
          showError(errors[0].message);
        }
        const alertTitle = `${numberOfNegotiations} ${pluralize('Negotiation', numberOfNegotiations)} Staged`;
        const negotiationMessage = 'We have automatically staged negotiations for the best offers below your reserve price';
        AlertService.alert({
          message: (
            <div>
              <div style={{ height: 24, fontSize: 16, fontWeight: 'bold', letterSpacing: 'normal', textAlign: 'left', marginBottom: '8px' }}>
                {alertTitle}
              </div>
              <span>{negotiationMessage}</span>
            </div>
          ),
          autoDismiss: true,
          dismissDelay: 6000,
        });
        refetchNegotiations();
        history.push(routePaths.negotiations);
      })
      .catch(() => {
        const message =
          'We were unable to negotiate these offers due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.';
        showError(message);
      });
  };

  return (
    <Banner
      icon={CautionIcon}
      header={`${accounting.formatNumber(numberOfCases)} cases will not be awarded because they only have offers below reserve price`}
      text={`This represents $${accounting.formatNumber(
        estimatedRevenueIncrease,
        2
      )} in additional potential revenue. Maximize your sell through rate by negotiating the best offers on this inventory.`}
      action={{
        name: `Stage ${numberOfNegotiations} ${pluralize('Negotiation', numberOfNegotiations)}`,
        loading,
        loadingText: `Staging ${numberOfNegotiations} ${pluralize('Negotiation', numberOfNegotiations)}`,
        onClick: () => {
          stageRecommendedNegotiations();
        },
      }}
    ></Banner>
  );
};

RecommendedNegotiations.propTypes = {
  negotiationsData: PropTypes.object,
  refetchNegotiations: PropTypes.func,
  history: PropTypes.object,
};

export default RecommendedNegotiations;
