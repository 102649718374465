import { Theme as theme } from '@spoiler-alert/ui-library';

const styleSheet = {
  progressBarsWrapper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  progressBarsItems: {
    width: 84,
    height: 22,
    display: 'flex',
    flexDirection: 'row',
    marginRight: 16,
    backgroundColor: '#f2f2f3',
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 12,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  progressBarsItemsAmount: {
    marginRight: 2,
  },
  progressBarsStaged: {
    width: 90,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 8,
    paddingRight: 8,
    fontSize: 11,
    color: theme.grey,
  },
  progressBarsStagedBar: {
    height: 5,
    background: theme.grey30,
    width: 90,
    borderRadius: 2.5,
    position: 'relative',
  },
  progressBarsListed: {
    width: 90,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 8,
    paddingRight: 8,
    fontSize: 11,
    color: theme.orange,
  },
  progressBarsListedBar: {
    height: 5,
    background: theme.orange30,
    width: 90,
    borderRadius: 2.5,
    position: 'relative',
  },
  progressBarsReceived: {
    width: 90,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 8,
    paddingRight: 8,
    fontSize: 11,
    color: theme.purple,
  },
  progressBarsReceivedBar: {
    height: 5,
    background: theme.purple30,
    width: 90,
    borderRadius: 2.5,
    position: 'relative',
  },
  progressBarsAwarded: {
    width: 90,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 8,
    paddingRight: 8,
    fontSize: 11,
    color: theme.teal,
  },
  progressBarsAwardedBar: {
    height: 5,
    background: theme.teal30,
    position: 'relative',
    width: 90,
    borderRadius: 2.5,
  },
  progressBarsAccepted: {
    width: 90,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 8,
    paddingRight: 8,
    fontSize: 11,
    color: theme.green,
  },
  progressBarsAcceptedBar: {
    height: 5,
    background: theme.green30,
    position: 'relative',
    width: 90,
    borderRadius: 2.5,
  },
  progressBarsStagedBarInternal: {
    position: 'absolute',
    height: '100%',
    width: 0,
    left: 0,
    top: 0,
    transition: 'width .3s linear',
    background: theme.grey,
    borderRadius: 2.5,
  },
  progressBarsListedBarInternal: {
    position: 'absolute',
    height: '100%',
    width: 0,
    left: 0,
    top: 0,
    transition: 'width .3s linear',
    background: theme.orange,
    borderRadius: 2.5,
  },
  progressBarsReceivedBarInternal: {
    position: 'absolute',
    height: '100%',
    width: 0,
    left: 0,
    top: 0,
    transition: 'width .3s linear',
    background: theme.purple,
    borderRadius: 2.5,
  },
  progressBarsAwardedBarInternal: {
    position: 'absolute',
    height: '100%',
    width: 0,
    left: 0,
    top: 0,
    transition: 'width .3s linear',
    background: theme.teal,
    borderRadius: 2.5,
  },
  progressBarsAcceptedBarInternal: {
    position: 'absolute',
    height: '100%',
    width: 0,
    left: 0,
    top: 0,
    transition: 'width .3s linear',
    background: theme.green,
    borderRadius: 2.5,
  },
  stagedTooltipBox: {
    minWidth: 117,
    color: theme.grey5,
    minHeight: 60,
    paddingLeft: 16,
    paddingRight: 16,
  },
  stagedTooltipHeader: {
    background: theme.grey5,
    width: '100%',
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.grey,
    fontFamily: 'Roboto',
  },
  stagedTooltipSubtitle: {
    fontSize: 12,
    color: '#818589',
    lineHeight: 1.42,
    marginBottom: 16,
  },
  stagedTooltipAmount: {
    fontSize: 14,
    color: theme.grey,
    marginTop: 10,
  },
  stagedTooltipText: {
    whiteSpace: 'nowrap',
    fontSize: 14,
    color: '#333',
    fontWeight: 'normal',
    fontFamily: 'Roboto, sans-serif',
  },
  progressHeaderText: {
    letterSpacing: 0.6,
  },
  stagedTooltipTextGrey: {
    color: theme.grey,
  },
  stagedTooltipTextOrange: {
    color: theme.orange,
  },
  stagedTooltipTextPurple: {
    color: theme.purple,
  },
  stagedTooltipTextTeal: {
    color: theme.teal,
  },
  stagedTooltipTextGreen: {
    color: theme.green,
  },
};

export default styleSheet;
