import React, { useState } from 'react';
import { Theme as theme, MinimalCheckboxTable, ChevronIcon, MiniTooltip, EmptyStateIcon } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { event } from 'react-fullstory';

const styles = {
  tableSection: {
    margin: '24px 0',
  },
  customerTableHeader: {
    padding: ({ open }) => (open ? '12px 12px 22px' : '12px'),
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '16px',
    backgroundColor: theme.grey10,
    borderRadius: '8px',
    marginBottom: '-10px',
  },
  buyerName: {
    fontWeight: 500,
  },
  chevron: {
    marginLeft: 8,
    width: 10,
    height: 7,
    fill: theme.textColorPrimary,
    transition: 'transform 0.3s',
    transform: ({ open }) => `rotate(${open ? 180 : 0}deg)`,
    cursor: 'pointer',
  },
  removeButton: {
    color: theme.red,
    cursor: 'pointer',
  },
  removeButtonDisabled: {
    color: theme.red,
    opacity: '30%',
    cursor: 'pointer',
  },
  zeroState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 24,
  },
  zeroStateText: {
    width: 375,
    textAlign: 'center',
  },
};

const AddedCustomer = ({ customer, contacts, handleRemoveCustomer, setTouched, notInUse, edit }) => {
  const useStyles = createUseStyles(styles, { name: 'AddedCustomers' });
  const [open, setOpen] = useState(true);
  const [emails, setEmails] = useState(contacts || []);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleChange = (option, id) => {
    const updated = contacts.map((selectedRow) => {
      if (selectedRow._id === id && selectedRow.value.includes(option)) {
        selectedRow.value = selectedRow.value.filter((val) => val !== option);
        if (selectedRow.groupBy === 'Customer') event('Updated Customer Contact Permissions');
        if (selectedRow.groupBy === 'Internal') event('Updated Internal Contact Permissions');
        return selectedRow;
      }
      if (selectedRow._id === id && !selectedRow.value.includes(option)) {
        if (selectedRow.groupBy === 'Customer') event('Updated Customer Contact Permissions');
        if (selectedRow.groupBy === 'Internal') event('Updated Internal Contact Permissions');
        selectedRow.value.push(option);
        return selectedRow;
      }
      return selectedRow;
    });
    setEmails(updated);
    setTouched(true);
  };
  const classes = useStyles({ open });
  return (
    <div className={classes.tableSection}>
      <div className={classes.customerTableHeader}>
        <div>
          <span className={classes.buyerName}>{customer.buyerName}</span>
          <span onClick={handleOpen}>
            <ChevronIcon className={classes.chevron} />
          </span>
        </div>
        {edit && (
          <>
            {notInUse ? (
              <div className={classes.removeButton} onClick={() => handleRemoveCustomer(customer.buyerSiteId)}>
                Remove
              </div>
            ) : (
              <MiniTooltip text="Customers cannot be removed while a list is in-use">
                <div className={classes.removeButtonDisabled} onClick={() => undefined}>
                  Remove
                </div>
              </MiniTooltip>
            )}
          </>
        )}
      </div>
      {open && <MinimalCheckboxTable keyHeader="Email" possibleValues={['Offer Sheets', 'Award Sheets']} data={emails} onChange={handleChange} />}
      {!emails?.length && (
        <div className={classes.zeroState}>
          <EmptyStateIcon />
          <div className={classes.zeroStateText}>
            This customer has no contacts associated with it.
            <br />
            To add customers, visit the customer profiles section.
          </div>
        </div>
      )}
    </div>
  );
};

AddedCustomer.propTypes = {
  customer: PropTypes.object,
  contacts: PropTypes.array,
  handleRemoveCustomer: PropTypes.func,
  setTouched: PropTypes.func,
  notInUse: PropTypes.bool,
  edit: PropTypes.bool,
};

export default AddedCustomer;
