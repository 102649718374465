import { Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  offerCardWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 8,
    borderRadius: 2,
    border: `solid 1px ${theme.grey30}`,
    width: 443,
    '&.awarded': {
      borderColor: theme.green,
    },
    '&.negotiated': {
      borderColor: theme.orange,
    },
    '&.negotiationStaged': {
      borderColor: theme.grey,
    },
    '&.negotiationReceived': {
      borderColor: theme.blue,
    },
  },
  offerCardAwardedTag: {
    background: theme.green,
    width: '100%',
    height: 23,
    lineHeight: '1em',
    fontWeight: 500,
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    textAlign: 'left',
    padding: '5px 0px 4px 8px',
  },
  offerCardNegotiationTag: {
    background: theme.orange,
    width: '100%',
    height: 23,
    padding: '5px 0px 4px 8px',
    lineHeight: '1em',
    fontWeight: 500,
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    textAlign: 'left',
  },
  offerCardNegotiationTagDark: {
    background: theme.greyDark,
  },
  offerCardNegotiationTagBlue: {
    background: theme.blue,
  },
  offerCardIgnoredTag: {
    background: theme.grey30,
    width: '100%',
    height: 23,
    padding: '5px 0px 4px 8px',
    lineHeight: '1em',
    fontWeight: 500,
    color: theme.greyDark,
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    textAlign: 'left',
  },
  offerCardHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: [12, 16],
  },
  offerCardTitles: {
    flexGrow: 1,
    marginRight: 'auto',
    '&>h4': {
      margin: 0,
      fontSize: '16px',
      fontWeight: 500,
    },
    '&>h5': {
      margin: 0,
      fontSize: '14px',
      color: theme.grey,
      fontWeight: 'normal',
    },
  },
  trucklane: {
    display: 'flex',
    height: '21px',
    margin: '0px 16px',
    backgroundColor: theme.green10,
    borderRadius: '40px',
    justifyContent: 'space-between',
    color: theme.text,
    paddingLeft: 8,
    marginBottom: 16,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.green30,
      '&:hover #ChevronContainer': {
        backgroundColor: '#2ab24a',
      },
      '&:hover #Chevron': {
        fill: theme.white,
      },
      '&:hover #StatusText': {
        color: theme.greenVeryDark,
      },
    },
  },
  inactiveTrucklane: {
    display: 'flex',
    height: '21px',
    margin: '0px 16px',
    backgroundColor: theme.grey10,
    borderRadius: '40px',
    justifyContent: 'space-between',
    color: theme.text,
    paddingLeft: 8,
    marginBottom: 16,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.grey30,
      '&:hover #ChevronContainer': {
        backgroundColor: theme.greyDark,
      },
      '&:hover #Chevron': {
        fill: theme.white,
      },
      '&:hover #StatusText': {
        color: theme.greyDark,
      },
    },
  },
  trucklaneExpanded: {
    display: 'flex',
    height: '21px',
    margin: '0px 16px',
    backgroundColor: theme.green30,
    borderRadius: '40px',
    justifyContent: 'space-between',
    color: theme.text,
    paddingLeft: 8,
    marginBottom: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  expandedTrucklaneContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    padding: '0 16px 16px',
  },
  firstTrucklaneRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  trucklaneRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 7,
  },
  trucklaneRowWithBorder: {
    extend: 'trucklaneRow',
    borderBottom: `1px solid ${theme.grey10}`,
  },
  trucklaneRowNetRevenue: {
    extend: 'trucklaneRow',
    backgroundColor: theme.blue5,
    color: theme.blue,
    fontWeight: 500,
  },
  trucklaneCell: {
    textAlign: 'left',
    width: '100%',
    fontWeight: 500,
    margin: '0 2px 7px 0',
    lineHeight: 1.33,
    color: theme.greyDark,
    fontSize: '12px',
  },
  trucklaneCellNegative: {
    extend: 'trucklaneCell',
    color: theme.red,
  },
  header: {
    textAlign: 'left',
    width: '100%',
    color: theme.grey,
    fontSize: '12px',
    lineHeight: 1.33,
    margin: '0 2px 7px 0',
  },
  headerNetRevenue: {
    textAlign: 'left',
    width: '100%',
    fontSize: '12px',
    lineHeight: 1.33,
    margin: '0 2px 7px 0',
  },
  trucklaneText: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 1,
  },
  offerCardEditable: {
    padding: [0, 16, 16],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  offerCardFooter: {
    background: theme.grey5,
    padding: [12, 12, 6],
    display: 'flex',
    flexDirection: 'row',
  },
  offerCardTotal: {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.greyDark,
  },
  offerCardTotalField: {
    marginRight: 24,
    color: theme.greyDark,
  },
  listedCasesRow: {
    fontSize: '16px',
    lineHeight: '1em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  offerCardButtons: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  unawardButton: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  },
  awardButton: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  },
  svg: {
    fill: theme.grey50,
    height: '24px',
    width: '24px',
    display: 'inline-block',
    '&:hover': {
      fill: theme.text,
    },
  },
  belowReserveSvg: {
    fill: theme.grey50,
    height: '14px',
    width: '14px',
    marginBottom: '2px',
    display: 'inline-block',
    marginLeft: '7px',
  },
  negotiateIcon__button: {
    marginRight: '8px',
  },
  ignoreRestore__button: {
    marginRight: '8px',
    background: 'rgba(255, 255, 255, 1)',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  },
  truckTooltipBox: {
    padding: 8,
    textAlign: 'left',
    '& > :nth-child(3n-2)': {
      marginBottom: 8,
    },
    '& > :last-child': {
      marginBottom: 0,
    },
  },
  truckTooltipSubtitle: {
    fontWeight: 500,
  },
  belowReserveIndicator: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontStretch: 'condensed',
    fontStyle: 'normal',
    fontSize: '10px',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#fff',
    verticalAlign: 'top',
  },
  belowReserveIndicatorBackground: {
    padding: '1px',
    borderRadius: '1px',
    backgroundColor: '#e36363',
    display: 'inline-block',
    height: '11px',
    margin: '2px 0 0 5px',
    verticalAlign: 'top',
  },
  belowReservePriceIndicatorContainer: {
    display: 'inline-block',
  },
  averagePriceContainer: {
    margin: '0 10px',
  },
  quickEntry: {
    top: '45px',
    left: '-50px',
    zIndex: 1,
    backgroundColor: theme.white,
  },
  chevron: {
    fill: theme.greyDark,
    width: 10,
    height: 6,
    color: theme.greenDark,
  },
  statusText: {
    marginRight: 8,
    marginTop: 1,
    color: theme.greenDark,
  },
  inactiveStatusText: {
    marginRight: 8,
    marginTop: 1,
    color: theme.greyDark,
  },
  statusTextExpanded: {
    marginRight: 8,
    marginTop: 1,
    color: theme.greenVeryDark,
  },
  chevronContainer: {
    backgroundColor: theme.green30,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    width: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  inactiveChevronContainer: {
    backgroundColor: theme.grey30,
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    width: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  chevronContainerExpanded: {
    backgroundColor: '#2ab24a',
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    width: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  textboxWithNumerator: {
    backgroundColor: theme.green5,
    color: theme.green,
    marginRight: 15,
  },
  textboxWithDenominator: {
    marginRight: 15,
  },
  donationTextboxWithDenominator: {
    justifyContent: 'left',
  },
  additionalInfoPrice: {
    marginRight: 1,
  },
  trucklaneDistance: {
    marginRight: 8,
  },
  wandIcon: {
    width: 24,
    height: 24,
    fill: theme.green,
  },
};

export default styles;
