import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Button, AlertService, Timing } from '@spoiler-alert/ui-library';
import emailService from '../../services/email-service';
import TaxDashboardStrings from '../../string-resources/tax-dashboard';

const styles = {
  about__container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 32px 0 16px',
  },
  about__content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '58vh',
    minHeight: '400px',
    backgroundColor: '#4eaac1',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '2px',
    padding: '24px 48px',
  },
  circle: {
    height: '75px',
    width: '75px',
    borderRadius: '100%',
    border: '5px solid #fff',
    fontSize: '3.375rem',
    color: '#fff',
    fontWeight: 500,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  about__icon: {
    height: 48,
    width: 48,
    fill: '#ffffff',
  },
  about__header: {
    fontSize: '1.675rem',
  },
  text__center: {
    fontSize: '1.2rem',
    fontWeight: 300,
  },
  button: {
    color: '#4eaac1',
  },
  line__break: {
    display: 'block',
  },
  '@media (max-width: 840px)': {
    about__content: {
      height: '88vh',
      padding: '24px',
      minHeight: 0,
    },
    circle: {
      width: '60px',
      height: '60px',
      fontSize: '2.25rem',
    },
    text__center: {
      fontSize: '1.125rem',
      marginBottom: '38px',
    },
    line__break: {
      display: 'inline',
      marginLeft: 4,
    },
  },
  '@media (max-width: 840px) and (orientation: landscape)': {
    about__content: {
      height: '76vh',
      padding: '24px',
      minHeight: 0,
    },
    circle: {
      width: '45px',
      height: '45px',
      fontSize: '1.6rem',
      border: '4px solid #fff',
    },
    text__center: {
      fontSize: '1rem',
      marginBottom: '20px',
    },
  },
};

@injectSheet(styles)
export default class TaxMissingSignature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  notifySite = Timing.throttle(() => {
    const { outletSite } = this.props;
    this.setState({ loading: true });

    emailService
      .SiteResponsibleParty(outletSite._id)
      .then(() => {
        AlertService.alert({ type: 'success', message: <span>{TaxDashboardStrings.wellLetYouKnow}</span> });
        this.setState({ loading: false });
      })
      .catch(() => {
        AlertService.alert({ type: 'warning', message: <span>{TaxDashboardStrings.sorrySomethingWentWrong}</span> });
        this.setState({ loading: false });
      });
  }, 20000);

  render() {
    const { classes, style, outletSite } = this.props;
    return (
      <div className={classes.about__container} style={style}>
        <div className={classes.about__content}>
          <div className={classes.circle}>$</div>
          <h1 className={classes.about__header}>
            {TaxDashboardStrings.formatString(TaxDashboardStrings.wereCurrentlyWaitingFor, outletSite.siteName)}
          </h1>
          <p className={classes.text__center}>
            {TaxDashboardStrings.onceWeObtain}
            <span className={classes.line__break}> {TaxDashboardStrings.formatString(TaxDashboardStrings.toNotifySite, outletSite.siteName)}</span>
          </p>
          <Button
            type="button"
            secondary
            className={classes.button}
            onClick={this.notifySite}
            loading={this.state.loading}
            loadingText={TaxDashboardStrings.loading}
          >
            {TaxDashboardStrings.formatString(TaxDashboardStrings.notifySite, outletSite.siteName)}
          </Button>
        </div>
      </div>
    );
  }
}

TaxMissingSignature.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object,
  outletSite: PropTypes.object,
};
