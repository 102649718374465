import LocalizedStrings from 'react-localization';

const OfferComparisonStrings = new LocalizedStrings({
  en: {
    buyers: 'Buyers',
    clickToExpand: 'Click to expand',
    totalPoundsAwarded: 'Total Weight Awarded',
    totalAcceptedOffers: 'Total Awarded Offers',
    items: 'Items',
    offers: 'Offers',
    filters: 'Filters',
    clearFilters: 'Clear Filters',
    totalOffer: 'Total Offer',
    acceptedOffer: 'Accepted Offer',
    unaward: 'Unaward',
    awarded: 'Awarded',
    award: 'Award',
    negotiation: 'Negotiate',
    quickAward: 'Quick Award',
    cases: 'Cases',
    listed: 'Listed',
    case: 'case',
    lb: 'lb',
    awardingError:
      'Sorry there was an error awarding these offers. If this problem persists, please contact a Spoiler Alert Administrator to help you.',
    unawardAll: 'Unaward All Offers',
    unawarding: 'Unawarding...',
    unawardingError:
      'Sorry there was an error unawarding these offers. If this problem persists, please contact a Spoiler Alert Administrator to help you.',
    noData: 'No Data Available',
    smartAwardButtonText: 'Smart Award',
    smartAwardError:
      'We were unable to Smart Award the offers due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.',
    loadingMessages: [
      'One moment please...',
      'Retrieving data...',
      'Loading data...',
      'Please wait while the appropriate data is compiled and formatted for your screen...',
      'Still waiting on that data...',
      'Okay, this is taking a while...',
      'Putting on the finishing touches...',
      'This should wrap up anytime now...',
      'This is starting to get embarrassing...',
    ],
    ofCogs: 'of COGS',
    cogRecovered: 'COG Recovered:  ',
  },
});

export default OfferComparisonStrings;
