import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import pluralize from 'pluralize';
import {
  Button,
  WandIcon,
  UnawardIcon,
  FilterIcon,
  Search,
  OverlayService,
  Flyout,
  Theme,
  CheckboxWithLabel,
  Select,
  SelectOption,
  Timing,
} from '@spoiler-alert/ui-library';
import capitalize from 'capitalize';
import TrucklaneBlock from './trucklane-block';
import { OfferComparisonStrings } from '../../string-resources';
import { UserFilters } from '../../store';
import {
  buildFilterOptions,
  includeCustomerFilter,
  includeLocationFilter,
  includeLogisticsFilter,
  includeOfferStatusFilter,
  includeTruckLaneStatusFilter,
  includeTruckTypeFilter,
  searchData,
} from './trucklane-filter-utility';

const styles = {
  spacing: {
    paddingBottom: 36,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  marginRow: {
    extend: 'row',
    marginBottom: 24,
  },
  stickyRow: {
    extend: 'row',
    position: 'sticky',
    zIndex: 9,
    top: '45px',
    padding: '24px 0px',
    backgroundColor: Theme.white,
  },
  search: {
    width: 500,
    marginRight: 10,
  },
  filterFlyout: {
    extend: 'marginRow',
    '& h4': {
      margin: 0,
      marginRight: 'auto',
      fontSize: '1rem',
      fontWeight: 500,
    },
    '& a': {
      color: Theme.linkTextColor,
      cursor: 'pointer',
      fontSize: '0.875rem',
    },
  },
  filterHeader: {
    marginBottom: 24,
    '& h2': {
      fontSize: '14px',
      fontWeight: 500,
      marginBottom: 0,
    },
  },
};

const useStyles = createUseStyles(styles);
const defaultFilterState = {
  locations: [],
  customers: [],
  logistics: [],
  trucklaneStatus: [],
  truckType: [],
  offerStatus: [],
};

const TrucklaneFilter = ({ user, history, originalTrucklanes, setDisplaySettingsModal, openDrilldown }) => {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({ ...defaultFilterState, ...UserFilters.filters.trucklane });
  const [searchText, setSearchText] = useState('');

  const filterOptions = useMemo(() => buildFilterOptions(originalTrucklanes), [originalTrucklanes]);

  const { suggestedTrucklanes, nonSuggestedTrucklanes } = useMemo(() => {
    const filteredTrucklanes = new Map();
    originalTrucklanes.forEach((trucklane) => {
      trucklane.offers.forEach((offer) => {
        if (filteredTrucklanes.has(trucklane.id)) return;
        if (
          searchData(offer, searchText) &&
          includeLocationFilter(offer, filters) &&
          includeCustomerFilter(offer, filters) &&
          includeLogisticsFilter(offer, filters) &&
          includeTruckTypeFilter(offer, filters) &&
          includeOfferStatusFilter(offer, filters) &&
          includeTruckLaneStatusFilter(trucklane.offers, filters)
        ) {
          filteredTrucklanes.set(trucklane.id, trucklane);
        }
      });
    });
    const lanes = [...filteredTrucklanes.values()];
    return {
      suggestedTrucklanes: lanes.filter((ft) => ft.hasSuggestions),
      nonSuggestedTrucklanes: lanes.filter((ft) => !ft.hasSuggestions),
    };
  }, [filters, searchText, originalTrucklanes]);

  const clearFilters = () => {
    setFilters({
      locations: [],
      customers: [],
      logistics: [],
      trucklaneStatus: [],
      truckType: [],
      offerStatus: [],
    });
    UserFilters.updateFilters('trucklane', {
      locations: [],
      customers: [],
      logistics: [],
      trucklaneStatus: [],
      truckType: [],
      offerStatus: [],
    });
    document.getElementById('text-search').children[2].click();
  };

  const filtersAppliedCount = useMemo(() => {
    return Object.keys(filters).filter((fkey) => {
      const filter = filters[fkey];
      if (Array.isArray(filter)) {
        return filter.length > 0;
      }
      if (filter === true) return true;
      if (filter.min !== undefined || filter.max !== undefined) return true;
      return false;
    }).length;
  }, [filters]);

  const handleFilterChange = useCallback(
    (prop, value) => {
      setFilters({
        ...filters,
        [prop]: value,
      });
    },
    [filters]
  );

  const removeItem = (array, item) => {
    const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  };

  const handleCheckFilterChange = useCallback((prop, event) =>
    handleFilterChange(prop, event.checked ? [...filters[prop], event.value] : removeItem(filters[prop], event.value), [handleFilterChange])
  );

  const renderedFilters = useMemo(() => {
    return [
      <Select
        key="locations"
        label="Location"
        multiple
        search
        onChange={handleFilterChange.bind(this, 'locations')}
        selectedItems={filters.locations}
      >
        {filterOptions.locations.map((location) => (
          <SelectOption checkbox key={location.id} value={location.id}>
            {location.name}
          </SelectOption>
        ))}
      </Select>,
      <Select
        key="customers"
        label="Customers"
        multiple
        search
        onChange={handleFilterChange.bind(this, 'customers')}
        selectedItems={filters.customers}
      >
        {filterOptions.customers.map((customer) => (
          <SelectOption checkbox key={customer.id} value={customer.id}>
            {customer.name}
          </SelectOption>
        ))}
      </Select>,
      filterOptions.logistics.length > 1 && (
        <div key="logistics" className={classes.filterHeader}>
          <h2>Logistics</h2>
          {filterOptions.logistics.map((logistic) => (
            <CheckboxWithLabel
              id={logistic}
              label={logistic}
              key={logistic}
              value={logistic}
              checked={filters.logistics.includes(logistic)}
              onChecked={handleCheckFilterChange.bind(this, 'logistics')}
            />
          ))}
        </div>
      ),
      filterOptions.trucklaneStatus.length > 1 && (
        <div key="laneStatus" className={classes.filterHeader}>
          <h2>Truck Lane Status</h2>
          {filterOptions.trucklaneStatus.map((status) => (
            <CheckboxWithLabel
              id={`lane-${status}`}
              label={status}
              key={`lane-${status}`}
              value={status}
              checked={filters.trucklaneStatus.includes(status)}
              onChecked={handleCheckFilterChange.bind(this, 'trucklaneStatus')}
            />
          ))}
        </div>
      ),
      filterOptions.truckType.length > 1 && (
        <div key="truckType" className={classes.filterHeader}>
          <h2>Truck Type</h2>
          {filterOptions.truckType.map((truckType) => (
            <CheckboxWithLabel
              id={`truckType-${truckType}`}
              label={truckType}
              key={`truckType-${truckType}`}
              value={truckType}
              checked={filters.truckType.includes(truckType)}
              onChecked={handleCheckFilterChange.bind(this, 'truckType')}
            />
          ))}
        </div>
      ),
      filterOptions.offerStatus.length > 1 && (
        <div key="offerStatus" className={classes.filterHeader}>
          <h2>Offer Status</h2>
          {filterOptions.offerStatus.map((status) => (
            <CheckboxWithLabel
              id={`offer-${status}`}
              label={capitalize(status)}
              key={`offer-${status}`}
              value={status}
              checked={filters.offerStatus.includes(status)}
              onChecked={handleCheckFilterChange.bind(this, 'offerStatus')}
            />
          ))}
        </div>
      ),
    ];
  }, [filters, filterOptions]);

  const toggleFilters = () => {
    if (showFilters) {
      OverlayService.hide();
      UserFilters.updateFilters('trucklane', filters);
    } else {
      OverlayService.show();
    }
    setShowFilters(!showFilters);
  };

  const handleSearch = Timing.debounce((text) => {
    setSearchText(text);
  }, 300);

  const displaySettings = () => {
    setDisplaySettingsModal(true);
  };

  return (
    <div>
      <div className={classes.stickyRow}>
        <div className={classes.row}>
          <Search id={'text-search'} onChange={handleSearch} className={classes.search} />
          <Button icon={FilterIcon} secondary onClick={toggleFilters} filter={filtersAppliedCount !== 0}>
            {filtersAppliedCount > 0 ? `${filtersAppliedCount} ${pluralize('Filter', filtersAppliedCount)}` : 'Filter'}
          </Button>
        </div>
        <Button secondary onClick={displaySettings}>
          Smart Award Settings
        </Button>
      </div>
      <div className={classes.spacing}>
        <TrucklaneBlock
          title="Smart Award Suggestions"
          trucklaneData={suggestedTrucklanes}
          buttonDetails={{ title: 'Award Suggestions', icon: WandIcon, loadingText: 'Awarding Suggestions...' }}
          history={history}
          user={user}
          suggested
          filters={filtersAppliedCount > 0 || !!searchText}
          clearFilters={clearFilters}
          openDrilldown={openDrilldown}
        />
      </div>
      <TrucklaneBlock
        title="No Suggestions"
        trucklaneData={nonSuggestedTrucklanes}
        buttonDetails={{ title: 'Unaward All', icon: UnawardIcon, warning: true, loadingText: 'Unawarding...' }}
        history={history}
        user={user}
        filters={filtersAppliedCount > 0 || !!searchText}
        clearFilters={clearFilters}
        openDrilldown={openDrilldown}
      />
      <Flyout position="right" open={showFilters} onHide={toggleFilters}>
        <div className={classes.filterFlyout} data-element="filters-header">
          <h4>{OfferComparisonStrings.filters}</h4>
          <a tabIndex="1" onClick={clearFilters} data-element="clear-filters">
            {OfferComparisonStrings.clearFilters}
          </a>
        </div>
        {renderedFilters}
      </Flyout>
    </div>
  );
};

TrucklaneFilter.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  originalTrucklanes: PropTypes.array,
  setDisplaySettingsModal: PropTypes.func,
  openDrilldown: PropTypes.func,
};

export default TrucklaneFilter;
