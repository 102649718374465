import LocalizedStrings from 'react-localization';

const ReservePriceStrings = new LocalizedStrings({
  en: {
    title: 'Reserve Price',
    description: 'Set the reserve price for all items',
    anchor: 'Anchor',
    percent: 'Percent',
    cancel: 'Cancel',
    setReservePrice: 'Set Reserve Price',
    flatRate: 'Flat Rate',
    error:
      'We were unable to set the reserve price due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.',
    errorClear:
      'We were unable to clear the reserve price due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.',
  },
});

export default ReservePriceStrings;
