import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import accounting from 'accounting';
import { Theme as theme, Checkbox } from '@spoiler-alert/ui-library';
import AwardTableOrigin from './award-table-origin';
import { groupingFunction, totalsReducer } from './award-table-grouping';
import GroupOpener from './group-opener';
import { negativeCostHandler } from '../../utilities/negative-cost-handler';
import { costRecoveryHandler } from '../../utilities/cost-recovery-handler';

const useStyles = createUseStyles({
  buyerHead: {
    background: theme.blue10,
    borderBottom: `1px solid ${theme.grey30}`,
    cursor: 'pointer',
    '&:hover': {
      background: theme.menuItemHoverBg,
    },
  },
  buyerHeadCell: {
    padding: [8, 12],
    color: theme.text,
    fontWeight: 500,
    fontSize: 16,
  },
  checkbox: {
    padding: [15, 12],
    textAlign: 'center',
    height: 48,
  },
  numberColumn: {
    extend: 'buyerHeadCell',
    textAlign: 'right',
  },
});

const AwardTableBuyer = ({ lanes, groupKey, includeLanes, excludeLanes, excludedLanes, smartAwardMode }) => {
  const classes = useStyles();

  const [showChildren, setShowChildren] = useState(true);

  const laneGroups = useMemo(() => {
    return groupingFunction(lanes, 'sellerLocationName');
  }, [lanes]);

  const totals = useMemo(() => {
    return totalsReducer(lanes);
  }, [lanes]);

  useEffect(() => {
    setShowChildren(totals.caseCount !== 0);
  }, [totals.caseCount === 0]);

  const handleCheck = (event) => {
    event.event.stopPropagation();
    if (event.checked) {
      includeLanes(lanes.map((l) => l._id));
    } else {
      excludeLanes(lanes.map((l) => l._id));
    }
  };

  const { checked, partial } = useMemo(() => {
    let excludedCount = 0;
    lanes.forEach((lane) => {
      if (excludedLanes.includes(lane._id)) excludedCount += 1;
    });
    return {
      checked: excludedCount === 0,
      partial: excludedCount > 0 && excludedCount < lanes.length,
    };
  }, [excludedLanes, lanes]);

  const handleRowClick = (e) => {
    e.stopPropagation();
    setShowChildren(!showChildren);
  };

  return (
    <tbody className={classes.buyerGroup} data-testid="award-table-body">
      <tr className={classes.buyerHead} onClick={handleRowClick}>
        <td>
          <Checkbox className={classes.checkbox} onChecked={handleCheck} checked={checked} partial={partial} />
        </td>
        <td className={classes.buyerHeadCell}>
          <GroupOpener open={showChildren} fill={theme.greyDark} />
          {groupKey}
        </td>
        <td className={classes.numberColumn}>{accounting.formatNumber(totals.caseCount)}</td>
        <td className={classes.numberColumn}>{accounting.formatNumber(totals.palletCount, 2)}</td>
        <td className={classes.numberColumn}>{accounting.formatNumber(totals.totalWeight, 2)} lbs</td>
        <td className={classes.numberColumn}>{accounting.formatMoney(totals.totalRevenue)}</td>
        <td className={classes.numberColumn}>({accounting.formatMoney(totals.totalEstDeliveryCost)})</td>
        <td className={classes.numberColumn}>{negativeCostHandler(totals.totalRevenue - totals.totalEstDeliveryCost)}</td>
        <td className={classes.numberColumn}>{costRecoveryHandler(totals.totalPrice, totals.costOfAwardedInventory)}</td>
      </tr>
      {showChildren &&
        [...laneGroups.keys()]
          .sort()
          .map((sellerLocationName) => (
            <AwardTableOrigin
              includeLanes={includeLanes}
              excludeLanes={excludeLanes}
              excludedLanes={excludedLanes}
              key={sellerLocationName}
              groupKey={sellerLocationName}
              lanes={laneGroups.get(sellerLocationName)}
              smartAwardMode={smartAwardMode}
            />
          ))}
    </tbody>
  );
};

AwardTableBuyer.propTypes = {
  lanes: PropTypes.arrayOf(PropTypes.object),
  groupKey: PropTypes.string,
  includeLanes: PropTypes.func,
  excludeLanes: PropTypes.func,
  smartAwardMode: PropTypes.string,
  excludedLanes: PropTypes.arrayOf(PropTypes.string),
};

export default AwardTableBuyer;
