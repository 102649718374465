import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Card, TextInput, Button, Select, SelectOption } from '@spoiler-alert/ui-library';
import formStyles from '../activity/forms/form-styles';
import listOfStates from '../../utilities/states';

const styles = {
  ...formStyles,
  button__prev: {
    marginRight: 'auto',
  },
  address: {
    display: 'flex',
    flexDirection: 'row',
  },
  city: {
    width: '50%',
    marginRight: 10,
  },
  state: {
    width: '20%',
    marginRight: 10,
  },
  zip: {
    width: '30%',
  },
};

@injectSheet(styles)
export default class SiteInfo extends Component {
  static propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
    goToPreviousStep: PropTypes.func,
    goToNextStep: PropTypes.func,
    setReadyStep: PropTypes.func,
    serialize: PropTypes.func,
  };

  state = {
    siteName: undefined,
    abbr: undefined,
    street: undefined,
    city: undefined,
    stateName: undefined,
    zip: undefined,
  };

  serialize() {
    this.props.serialize(this.state);
  }

  get ready() {
    const { siteName, abbr, street, city, stateName, zip } = this.state;
    return siteName && abbr && street && city && stateName && zip;
  }

  setReady = () => {
    this.props.setReadyStep(this.ready);
    this.serialize();
  };

  updateFormItem = (key, value) => {
    const stateUpdate = {};
    stateUpdate[key] = value;
    this.setState(stateUpdate, this.setReady);
  };

  setRegion = (_, stateName) => {
    this.updateFormItem('stateName', stateName);
    this.setReady();
  };

  get disabled() {
    return !this.ready;
  }

  get states() {
    return (
      <Select onChange={this.setRegion} required label="State *" selectedItem={this.state.stateName}>
        {listOfStates.map((region) => (
          <SelectOption key={region.id} value={region.id}>
            {region.text}
          </SelectOption>
        ))}
      </Select>
    );
  }

  render() {
    const { classes, goToNextStep, goToPreviousStep } = this.props;
    const { siteName, abbr, street, city, zip } = this.state;
    return (
      <Card className={classes.form__container}>
        <h2 className={classes.form__header}>Basic Information</h2>
        <TextInput
          className={classes.input__field}
          onChange={this.updateFormItem.bind(this, 'siteName')}
          value={siteName}
          type="text"
          labelText="Site Name *"
          required
        />
        <TextInput
          className={classes.input__field}
          onChange={this.updateFormItem.bind(this, 'abbr')}
          value={abbr}
          type="text"
          labelText="Site Abbreviation *"
          required
        />
        <TextInput
          className={classes.input__field}
          onChange={this.updateFormItem.bind(this, 'street')}
          value={street}
          type="text"
          labelText="Street Address *"
          required
        />
        <div className={classes.address}>
          <TextInput
            className={classes.city}
            onChange={this.updateFormItem.bind(this, 'city')}
            value={city}
            type="text"
            labelText="City *"
            required
          />
          <div className={classes.state}>{this.states}</div>
          <TextInput className={classes.zip} onChange={this.updateFormItem.bind(this, 'zip')} value={zip} type="text" labelText="Zip *" required />
        </div>
        <div className={this.props.classes.button__container}>
          <Button type="button" className={classes.button__prev} link onClick={goToPreviousStep}>
            Prev
          </Button>
          <Button type="button" link onClick={goToNextStep} disabled={this.disabled}>
            Next
          </Button>
        </div>
      </Card>
    );
  }
}
