import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

export default class MarketplaceFade extends Component {
  constructor(props) {
    super(props);
    this.defaultStyle = {
      transition: '300ms ease-in-out',
      transitionProperty: 'opacity, transform',
    };

    this.transitionStyles = {
      entering: {
        opacity: 0,
        transform: 'translateY(10%)',
      },
      entered: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    };
    this.renderChild = this.renderChild.bind(this);
  }

  renderChild(state) {
    return React.cloneElement(this.props.children, {
      style: { ...this.defaultStyle, ...this.transitionStyles[state] },
    });
  }

  render() {
    return <Transition {...this.props}>{this.renderChild}</Transition>;
  }
}

MarketplaceFade.propTypes = {
  children: PropTypes.node,
};
