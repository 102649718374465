import React, { useEffect } from 'react';
import { Search, Button, MinimalTextTable } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';

const styles = {
  wrapper: {
    padding: '24px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    textAlign: 'left',
    margin: 0,
  },
  description: {
    fontSize: '14px',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '24px 0',
  },
  search: {
    flexGrow: 4,
    marginRight: '24px',
  },
  button: {
    padding: '0 16px',
  },
};

const DistributionLists = ({ handleSearch, handleRowClick, createNewList, distributionListsForTable, searchText, setSearchText }) => {
  const useStyles = createUseStyles(styles, { name: 'DistributionLists' });
  const classes = useStyles();

  useEffect(() => {
    setSearchText('');
  }, []);

  return (
    <div className={classes.wrapper}>
      <h1 className={classes.title}>Distribution Lists</h1>
      <div className={classes.description}>
        Distribution lists are used to create groupings of one or more customers. These lists are then used to send offers to your customers. All
        contacts for each customer can be added or edited within the buyer profile for that particular customer.
      </div>
      <div>
        <div className={classes.top}>
          <Search className={classes.search} placeholder="Search by list name" value={searchText} onChange={handleSearch} />
          <Button className={classes.button} onClick={createNewList}>
            Create New List
          </Button>
        </div>
        <div>
          <MinimalTextTable data={distributionListsForTable} keyHeader={'List Name'} possibleValues={['Customers']} onRowClick={handleRowClick} />
        </div>
      </div>
    </div>
  );
};

DistributionLists.propTypes = {
  handleSearch: PropTypes.func,
  createNewList: PropTypes.func,
  handleRowClick: PropTypes.func,
  distributionListsForTable: PropTypes.array,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
};

export default DistributionLists;
