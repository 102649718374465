import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import injectSheet from 'react-jss';
import accounting from 'accounting';
import {
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  Select,
  SelectOption,
  TextInput,
  PricingStrategyAnchorKeysObject,
} from '@spoiler-alert/ui-library';
import { NegotiationStrings } from '../../string-resources';
import NegotiationPricingStrategyAnchor from './negotiation-pricing-strategy-anchors';
import { SetSuggestedNegotiationPriceFromAnchor } from '../../graphql/mutations';

const CYPRESS_TAGS = {
  FORM: 'negotiation-suggested-price-modal-form',
  SELECT_BUTTON: 'negotiation-suggested-price-modal-select-button',
  SELECT_OPTIONS: 'negotiation-suggested-price-modal-select-options',
  PERCENT_MODIFIER_INPUT: 'negotiation-suggested-price-modal-percent-modifier-input',
  SUBMIT_BUTTON: 'negotiation-suggested-price-modal-submit-button',
};

const styles = {
  form__container: {
    width: '100%',
  },
  modal__field: {
    width: '48%',
  },
  modal__fields: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

@graphql(SetSuggestedNegotiationPriceFromAnchor, { name: 'SetSuggestedNegotiationPriceFromAnchor' })
@injectSheet(styles)
export default class NegotiationSuggestedPriceModal extends Component {
  state = {
    loading: false,
    selectedAnchor: undefined,
    anchorValue: 100,
  };

  static propTypes = {
    classes: PropTypes.object,
    onHide: PropTypes.func,
    open: PropTypes.bool,
    negotiationStagedListingIds: PropTypes.array,
    total: PropTypes.number,
    allSelected: PropTypes.bool,
    filters: PropTypes.object,
    SetSuggestedNegotiationPriceFromAnchor: PropTypes.func,
    refetchQueries: PropTypes.array,
    user: PropTypes.object,
  };

  setAnchor = (selectedAnchor) => this.setState({ selectedAnchor: selectedAnchor[0] });

  setAnchorValue = (value) => this.setState({ anchorValue: accounting.unformat(value) });

  setSuggestedPrices = (ev) => {
    ev.preventDefault();
    const { selectedAnchor, anchorValue } = this.state;
    const { allSelected, filters, negotiationStagedListingIds, refetchQueries } = this.props;
    this.setState({ loading: true });
    const setPriceVariables = {
      negotiationStagedListingIds,
      allSelected,
      inventoryFilters: filters,
      pricingStrategy: selectedAnchor.value,
      anchorValue,
    };
    this.props
      .SetSuggestedNegotiationPriceFromAnchor({
        variables: setPriceVariables,
        refetchQueries,
      })
      .then(this.hide)
      .catch(this.hide);
  };

  cancel = () => {
    this.props.onHide(false);
  };

  hide = (response) => {
    let loggedCount = 0;
    let errorCount = 0;
    if (response.data) {
      const loggedResults = response.data.SetSuggestedNegotiationPriceFromAnchor;
      errorCount = loggedResults.errors.length;
      loggedCount = loggedResults.negotiationStagedListingsCount;
    }
    this.setState({ loading: false });
    this.props.onHide(errorCount, loggedCount);
  };

  generateTextInputLabelText = (selectedAnchorValue, currencyType) => {
    if (selectedAnchorValue === PricingStrategyAnchorKeysObject.FlatRate && currencyType) {
      return `${NegotiationStrings.flatRate} (${currencyType})`;
    }
    if (selectedAnchorValue === PricingStrategyAnchorKeysObject.FlatRate) {
      return NegotiationStrings.flatRate;
    }
    return NegotiationStrings.percent;
  };

  render() {
    const { open, classes, allSelected, negotiationStagedListingIds, total, onHide } = this.props;
    const { loading, selectedAnchor, anchorValue } = this.state;
    const displayTotal = allSelected ? total : negotiationStagedListingIds.length;
    const canSubmit = selectedAnchor && anchorValue > 0;
    const inputLabelText = this.generateTextInputLabelText(selectedAnchor?.value, this.props.user.site.currencyType);

    return (
      <Modal onHide={onHide} open={open} closeOnEsc closeOnOutsideClick>
        <form onSubmit={canSubmit ? this.setSuggestedPrices : null} className={classes.form__container} data-cy={CYPRESS_TAGS.FORM}>
          <ModalContent>
            <h2>{NegotiationStrings.adjustPriceModalTitle}</h2>
            <div className={classes.modal__fields}>
              <div className={classes.modal__field}>
                <Select
                  label={NegotiationStrings.anchor}
                  onChange={this.setAnchor}
                  selectedItem={selectedAnchor}
                  cypressTag={CYPRESS_TAGS.SELECT_BUTTON}
                >
                  {Array.from(NegotiationPricingStrategyAnchor.keys()).map((k) => (
                    <SelectOption key={k} value={k} cypressTag={CYPRESS_TAGS.SELECT_OPTIONS}>
                      {NegotiationPricingStrategyAnchor.get(k)}
                    </SelectOption>
                  ))}
                </Select>
              </div>
              <div className={classes.modal__field}>
                <TextInput
                  type="text"
                  labelText={inputLabelText}
                  onChange={this.setAnchorValue}
                  required
                  value={
                    selectedAnchor?.value === PricingStrategyAnchorKeysObject.FlatRate
                      ? `${accounting.formatNumber(selectedAnchor, 2)}`
                      : `${accounting.formatNumber(selectedAnchor, 2)}%`
                  }
                  cypressTag={CYPRESS_TAGS.PERCENT_MODIFIER_INPUT}
                />
              </div>
            </div>
          </ModalContent>
          <ModalFooter>
            <Button type="button" onClick={this.cancel} secondary disabled={loading}>
              Close
            </Button>
            <Button
              type="submit"
              className={classes.submit}
              disabled={!canSubmit}
              primary
              loading={loading}
              loadingText="Wait..."
              cypressTag={CYPRESS_TAGS.SUBMIT_BUTTON}
            >
              {NegotiationStrings.submit(displayTotal)}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}
