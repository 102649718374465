import { Theme as theme } from '@spoiler-alert/ui-library';

const downloadTemplateButtonStyle = {
  backgroundColor: theme.secondaryButtonColor,
  color: theme.secondaryButtonTextColor,
  height: '36px',
  lineHeight: '36px',
  borderRadius: theme.borderRadius,
  display: 'inline-flex',
  border: 'none',
  position: 'relative',
  outline: 'none',
  padding: '0 16px',
  textDecoration: 'none',
  fontSize: '1rem',
  textAlign: 'center',
  cursor: 'pointer',
  transitionDuration: '300ms',
  overflow: 'hidden',
  '&:focus': {
    textDecoration: 'none',
  },
  '&:not([disabled]):hover': {
    backgroundColor: theme.secondaryButtonHoverColor,
  },
  '&:not([disabled]):after': {
    content: '""',
    pointerEvents: 'none',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    margin: '0',
    background: 'rgba(0, 0, 0, .2)',
    opacity: 0,
    borderRadius: '36px / 72px',
    transform: 'scale(0, 0) translate(-50%)',
    transformOrigin: '50% 50%',
  },
};

export default downloadTemplateButtonStyle;
