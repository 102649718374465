import React from 'react';
import { Modal, ModalContent, ModalFooter, AlertWarningIcon, Button, Theme as theme, OverlayService } from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const styles = {
  warningContent: {
    display: 'flex',
    alignItems: 'baseline',
    backgroundColor: theme.red5,
    width: '639px',
    padding: '12px',
    borderRadius: '4px',
  },
  warningText: {
    fontSize: '16px',
    marginLeft: '12px',
  },
  infoIcon: {
    fill: theme.red,
    width: 16,
    height: 16,
  },
};

const WarningModal = ({ setShowWarning, showWarning, history, basePath }) => {
  const useStyles = createUseStyles(styles, { name: 'WarningModal' });
  const classes = useStyles();

  const handleLeavePage = () => {
    setShowWarning(false);
    OverlayService.hide();
    history.push(basePath);
  };

  const handleCancelLeavePage = () => {
    setShowWarning(false);
    OverlayService.hide();
  };

  return (
    <Modal open={showWarning} closeOnEsc closeOnOutsideClick onHide={handleCancelLeavePage}>
      <ModalContent>
        <div data-testid="modal-body">
          <div className={classes.warningContent}>
            <div className={classes.infoIcon}>
              <AlertWarningIcon />
            </div>
            <div className={classes.warningText}>Are you sure you want to leave this page? All unsaved changes will be lost.</div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button className={classes.deleteButton} testId="submit" type="button" warning onClick={handleLeavePage}>
          Leave Page
        </Button>
        <Button testId="cancel" type="button" onClick={handleCancelLeavePage} secondary>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

WarningModal.propTypes = {
  setShowWarning: PropTypes.func,
  showWarning: PropTypes.bool,
  history: PropTypes.object,
  basePath: PropTypes.string,
};

export default WarningModal;
