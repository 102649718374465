import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Card, Checkbox, Button } from '@spoiler-alert/ui-library';
import formStyles from '../activity/forms/form-styles';

const styles = {
  ...formStyles,
  button__prev: {
    marginRight: 'auto',
  },
  checkbox__container: {
    marginBottom: '10px',
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
  },
  checkbox__label: {
    marginLeft: 10,
  },
};

@injectSheet(styles)
export default class Webhooks extends Component {
  static propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
    goToPreviousStep: PropTypes.func,
    goToNextStep: PropTypes.func,
    setReadyStep: PropTypes.func,
    serialize: PropTypes.func,
  };

  state = {
    createItemImportWebhook: false,
    createInventoryImportWebhook: false,
    createTransactionImportWebhook: false,
  };

  serialize() {
    this.props.serialize(this.state);
  }

  setReady = () => {
    this.props.setReadyStep(true);
    this.serialize();
  };

  setRegion = (_, stateName) => {
    this.setState({ stateName });
    this.setReady();
  };

  toggle = (webhook) => {
    const newState = {};
    newState[webhook] = !this.state[webhook];
    this.setState(newState, this.setReady);
  };

  render() {
    const { classes, goToPreviousStep } = this.props;
    const { createItemImportWebhook, createInventoryImportWebhook, createTransactionImportWebhook } = this.state;
    return (
      <Card className={classes.form__container}>
        <h2 className={classes.form__header}>Webhook Information</h2>
        <div className={classes.checkbox__container}>
          <Checkbox value="createItemImportWebhook" checked={createItemImportWebhook} onChecked={this.toggle.bind(this, 'createItemImportWebhook')} />
          <span className={classes.checkbox__label}>Create Item Import Webhook?</span>
        </div>
        <div className={classes.checkbox__container}>
          <Checkbox
            value="createInventoryImportWebhook"
            checked={createInventoryImportWebhook}
            onChecked={this.toggle.bind(this, 'createInventoryImportWebhook')}
          />
          <span className={classes.checkbox__label}>Create Inventory Import Webhook?</span>
        </div>
        <div className={classes.checkbox__container}>
          <Checkbox
            value="createTransactionImportWebhook"
            checked={createTransactionImportWebhook}
            onChecked={this.toggle.bind(this, 'createTransactionImportWebhook')}
          />
          <span className={classes.checkbox__label}>Create Transaction Import Webhook?</span>
        </div>
        <div className={this.props.classes.button__container}>
          <Button type="button" className={classes.button__prev} link onClick={goToPreviousStep}>
            Prev
          </Button>
        </div>
      </Card>
    );
  }
}
