import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { graphql } from '@apollo/client/react/hoc';
import { Flyup, DataTableNaked, compose } from '@spoiler-alert/ui-library';
import { StagedListingSummaryWithSiteQuery } from '../../graphql/queries';
import routePaths from '../../route-paths';
import { getColumnsFromDataTableProfile } from '../../components/data-table';

const styles = {
  flyup: {
    display: 'initial',
  },
  inventory__flyup__space: {},
};

@injectSheet(styles)
@compose(graphql(StagedListingSummaryWithSiteQuery))
export default class StagedInventoryFlyup extends Component {
  state = {
    summaries: [],
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  static propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    unawardAllOfferListings: PropTypes.func,
    user: PropTypes.object,
  };

  content = React.createRef();

  static getDerivedStateFromProps(nextProps) {
    const { data } = nextProps;
    if (!data.loading && !data.error) {
      const summaries = data.currentUserQuery.stagedListingSummaryWithSite;
      return {
        summaries,
      };
    }
    return null;
  }

  getColumns() {
    return getColumnsFromDataTableProfile('Inventory Staged Flyup', this.props.user.site.dataTableProfiles);
  }

  handleRowClick = (row) => {
    this.context.router.history.push(`${routePaths.staging}/${row.catalogId}`);
  };

  render() {
    const { classes } = this.props;
    const { summaries } = this.state;
    return (
      <div className={classes.flyup}>
        <Flyup title={`Distribution List Summary (${summaries.length})`}>
          <DataTableNaked data={summaries} columns={this.getColumns()} onRowClick={this.handleRowClick} />
          <div className={classes.inventory__flyup__space}></div>
        </Flyup>
      </div>
    );
  }
}
