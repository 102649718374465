import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Tooltip, InformationIcon, Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  info__icon: {
    height: '1rem',
    width: '1rem',
    marginLeft: '4px',
    display: 'inline-block',
    cursor: 'pointer',
    fill: theme.tooltipColor,
    paddingBottom: '2px',
  },
  'revenue-summary__h3': {
    display: 'inline-block',
    fontSize: '1rem',
    margin: '0',
    fontWeight: '500,',
  },
  'revenue-summary-tooltip__text': {
    display: 'inline-block',
    width: 312,
  },
};

export const revenueColorMapping = [
  { destination: 'revenue', color: theme.addButtonColor },
  { destination: 'net cost recovery rate', color: theme.linkTextHoverColor },
  { destination: 'cost of inventory', color: theme.alertSuccessColor },
];

@injectSheet(styles)
export default class RevenueSummaryHeader extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    widgetName: PropTypes.string,
  };

  render() {
    const { widgetName, classes } = this.props;
    return (
      <span>
        <h3 className={classes['revenue-summary__h3']}>{widgetName}</h3>
        <Tooltip
          text={
            <span className={classes['revenue-summary-tooltip__text']}>
              The Sales performance chart shows the value of all the available inventory, compared to the revenue generated from accepted offers. The
              Gross Recovery Rate also describes that relationship in percentage form. The Net Recovery Rate shows the percentage of sold inventory
              cost compared to the generated revenue.
            </span>
          }
          small
        >
          <InformationIcon className={classes.info__icon} />
        </Tooltip>
      </span>
    );
  }
}
