import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Theme as theme, Checkbox, DryIcon, RefrigeratedIcon, FrozenIcon, DeliveryIcon, PickupIcon, Tooltip } from '@spoiler-alert/ui-library';
import accounting from 'accounting';
import { negativeCostHandler } from '../../utilities/negative-cost-handler';
import { costRecoveryHandler } from '../../utilities/cost-recovery-handler';

const useStyles = createUseStyles({
  row: {
    background: theme.white,
    borderBottom: `1px solid ${theme.grey30}`,
    '&:hover': {
      background: theme.menuItemHoverBg,
    },
  },
  column: {
    padding: [8, 12],
    color: theme.text,
    fontWeight: 'normal',
    fontSize: 14,
  },
  numberColumn: {
    extend: 'column',
    textAlign: 'right',
  },
  trucklaneColumn: {
    extend: 'column',
    paddingLeft: 72,
  },
  logisticsDetails: {
    color: theme.grey,
    fontSize: 12,
  },
  logisticsIcon: {
    width: 12,
    height: 12,
    fill: theme.grey,
  },
  logisticsDetailRow: {
    display: 'inline-block',
    marginRight: 8,
  },
  checkbox: {
    padding: [15, 12],
    textAlign: 'center',
    height: 48,
  },
  tooltip: {
    width: 270,
    fontSize: 12,
  },
  distanceMissing: {
    color: theme.red,
    fontWeight: 'bold',
  },
  distanceMissingRow: {
    backgroundColor: theme.red5,
  },
});

const logisticsIcons = {
  Pickup: PickupIcon,
  Delivery: DeliveryIcon,
};

const truckTypeIcons = {
  Dry: DryIcon,
  Refrigerated: RefrigeratedIcon,
  Frozen: FrozenIcon,
};

const AwardTableRow = ({ lane, includeLanes, excludeLanes, excludedLanes, smartAwardMode }) => {
  const classes = useStyles();

  const LogisticsIcon = logisticsIcons[lane.logisticsTerm];
  const TruckIcon = truckTypeIcons[lane.truckType];
  const distanceMissing = lane.logisticsTerm === 'Delivery' && !lane.trucklaneDistance;
  const netRevenueMode = smartAwardMode === 'NET_REVENUE';
  const handleCheck = (event) => {
    if (event.checked) {
      includeLanes([lane._id]);
    } else {
      excludeLanes([lane._id]);
    }
  };

  const checked = !excludedLanes.includes(lane._id);
  const NAClassName = netRevenueMode ? classes.distanceMissing : null;
  const formattedMoney = distanceMissing ? <span className={NAClassName}>N/A</span> : accounting.formatMoney(lane.laneTotals.totalEstDeliveryCost);
  const formattedDistance = distanceMissing ? 'mileage unknown' : `${accounting.formatNumber(lane.trucklaneDistance, 0)} mi.`;
  const rowClass = distanceMissing && netRevenueMode ? (classes.row, classes.distanceMissingRow) : classes.row;
  const formattedRevenue =
    distanceMissing && netRevenueMode ? 'N/A' : negativeCostHandler(lane.laneTotals.totalRevenue - lane.laneTotals.totalEstDeliveryCost);
  return (
    <tr className={rowClass}>
      <td>
        {distanceMissing && netRevenueMode ? (
          <div className={classes.checkbox}>
            <Tooltip
              className={classes.tooltip}
              small
              bottom
              text={<div className={classes.tooltip}>Excluded because net revenue can’t be determined</div>}
            >
              <Checkbox onChecked={handleCheck} checked={checked} disabled={distanceMissing && netRevenueMode} />
            </Tooltip>
          </div>
        ) : (
          <Checkbox className={classes.checkbox} onChecked={handleCheck} checked={checked} disabled={distanceMissing && netRevenueMode} />
        )}
      </td>
      <td className={classes.trucklaneColumn}>
        <div>{lane.buyerLocationName}</div>
        <div className={classes.logisticsDetails}>
          <div className={classes.logisticsDetailRow}>
            <LogisticsIcon className={classes.logisticsIcon} /> {lane.logisticsTerm}
          </div>
          <div className={classes.logisticsDetailRow}>
            <TruckIcon className={classes.logisticsIcon} /> {lane.truckType}
          </div>
        </div>
      </td>
      <td className={classes.numberColumn}>{accounting.formatNumber(lane.laneTotals.caseCount)}</td>
      <td className={classes.numberColumn}>{accounting.formatNumber(lane.laneTotals.palletCount, 2)}</td>
      <td className={classes.numberColumn}>{accounting.formatNumber(lane.laneTotals.totalWeight, 2)} lbs</td>
      <td className={classes.numberColumn}>{accounting.formatMoney(lane.laneTotals.totalRevenue)}</td>
      {lane.logisticsTerm === 'Delivery' ? (
        <td className={classes.numberColumn}>
          <div>{formattedMoney}</div>
          <div>{formattedDistance}</div>
        </td>
      ) : (
        <td className={classes.numberColumn}>-</td>
      )}
      <td className={classes.numberColumn}>{formattedRevenue}</td>
      <td className={classes.numberColumn}>{costRecoveryHandler(lane.laneTotals.totalPrice, lane.laneTotals.costOfAwardedInventory)}</td>
    </tr>
  );
};

AwardTableRow.propTypes = {
  lane: PropTypes.object,
  includeLanes: PropTypes.func,
  excludeLanes: PropTypes.func,
  excludedLanes: PropTypes.arrayOf(PropTypes.string),
  smartAwardMode: PropTypes.string,
};

export default AwardTableRow;
