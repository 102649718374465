const addTotals = (existing, toAdd) => {
  const newTotals = {};
  [...Object.keys(toAdd)].forEach((key) => {
    newTotals[key] = (existing[key] || 0) + toAdd[key];
  });
  return newTotals;
};

export const totalsReducer = (lanes) => {
  return lanes.reduce((acc, item) => {
    return addTotals(acc, item.laneTotals);
  }, {});
};

export const groupingFunction = (lanes, laneKeyProp) => {
  return lanes.reduce((groups, lane) => {
    const laneKey = lane[laneKeyProp];
    if (groups.has(laneKey)) {
      groups.get(laneKey).push(lane);
    } else {
      groups.set(laneKey, [lane]);
    }
    return groups;
  }, new Map());
};
