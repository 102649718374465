import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import { createUseStyles } from 'react-jss';
import { Theme as theme, EmptyStateIcon } from '@spoiler-alert/ui-library';
import AwardTableBuyer from './award-table-buyer';
import { groupingFunction, totalsReducer } from './award-table-grouping';
import { negativeCostHandler } from '../../utilities/negative-cost-handler';
import { costRecoveryHandler } from '../../utilities/cost-recovery-handler';

const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
    width: '100%',
    border: `1px solid ${theme.grey30}`,
    borderRadius: 2,
    borderCollapse: 'collapse',
    display: 'table',
  },
  headerRow: {
    background: theme.white,
  },
  headerColumn: {
    textAlign: 'left',
    padding: [20, 12],
    color: theme.grey80,
    fontWeight: 500,
    fontSize: 14,
    position: 'sticky',
    top: 0,
    background: theme.white,
    boxShadow: `inset 0 -1px 0px 0px ${theme.grey30}`,
    zIndex: 10,
  },
  headerNumberColumn: {
    extend: 'headerColumn',
    textAlign: 'right',
  },
  trucklaneHeaderColumn: {
    extend: 'headerColumn',
    paddingLeft: 42,
  },
  footerRow: {
    background: theme.white,
  },
  footerColumn: {
    padding: [18, 12],
    color: theme.blue,
    fontWeight: 500,
    fontSize: 16,
    position: 'sticky',
    bottom: 53,
    background: theme.white,
    boxShadow: `inset 0 1px 0px 0px ${theme.grey30}`,
  },
  numberColumn: {
    extend: 'footerColumn',
    textAlign: 'right',
  },
  checkboxColumn: {
    extend: 'headerColumn',
    width: 70,
  },
  zeroState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 24,
    marginBottom: 48,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '-0.18px',
  },
  noOfferText: {
    marginTop: 10,
  },
});

const AwardTable = ({ lanes, excludedLanes, setExcludedLanes, smartAwardMode, showZeroState }) => {
  const classes = useStyles();

  const laneGroups = useMemo(() => {
    return groupingFunction(lanes, 'buyerSiteName');
  }, [lanes]);

  const totals = useMemo(() => {
    return totalsReducer(lanes);
  }, [lanes]);

  const includeLanes = (laneIds) => {
    setExcludedLanes(excludedLanes.filter((laneId) => !laneIds.includes(laneId)));
  };

  const excludeLanes = (laneIds) => {
    const laneSet = new Set(excludedLanes);
    laneIds.forEach((laneId) => laneSet.add(laneId));
    setExcludedLanes([...laneSet.values()]);
  };

  const zeroState = (
    <tbody>
      <tr>
        <td colSpan="8">
          <div className={classes.zeroState}>
            <EmptyStateIcon />
            <div className={classes.noOfferText}> No offers have been recieved yet, check back later </div>
          </div>
        </td>
      </tr>
    </tbody>
  );

  return (
    <table className={classes.wrapper}>
      <thead>
        <tr className={classes.headerRow}>
          <th className={classes.checkboxColumn}>Include</th>
          <th className={classes.trucklaneHeaderColumn}>Trucklane</th>
          <th className={classes.headerNumberColumn}>Cases</th>
          <th className={classes.headerNumberColumn}>Pallets</th>
          <th className={classes.headerNumberColumn}>Weight</th>
          <th className={classes.headerNumberColumn}>Revenue</th>
          <th className={classes.headerNumberColumn}>Est. Delivery Cost</th>
          <th className={classes.headerNumberColumn}>Net Revenue</th>
          <th className={classes.headerNumberColumn}>Cost Recovery</th>
        </tr>
      </thead>
      {showZeroState
        ? zeroState
        : [...laneGroups.keys()]
            .sort()
            .map((buyerSiteName) => (
              <AwardTableBuyer
                includeLanes={includeLanes}
                excludeLanes={excludeLanes}
                excludedLanes={excludedLanes}
                key={buyerSiteName}
                groupKey={buyerSiteName}
                lanes={laneGroups.get(buyerSiteName)}
                smartAwardMode={smartAwardMode}
              />
            ))}
      {!showZeroState && (
        <tfoot>
          <tr className={classes.footerRow}>
            <td className={classes.footerColumn} colSpan="2">
              Total Staged for Awarding
            </td>
            <td className={classes.numberColumn}>{accounting.formatNumber(totals.caseCount)}</td>
            <td className={classes.numberColumn}>{accounting.formatNumber(totals.palletCount, 2)}</td>
            <td className={classes.numberColumn}>{accounting.formatNumber(totals.totalWeight, 2)} lbs</td>
            <td className={classes.numberColumn}>{accounting.formatMoney(totals.totalRevenue)}</td>
            <td className={classes.numberColumn}>({accounting.formatMoney(totals.totalEstDeliveryCost)})</td>
            <td className={classes.numberColumn}>{negativeCostHandler(totals.totalRevenue - totals.totalEstDeliveryCost)}</td>
            <td className={classes.numberColumn}>{costRecoveryHandler(totals.totalPrice, totals.costOfAwardedInventory)}</td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

AwardTable.propTypes = {
  lanes: PropTypes.arrayOf(PropTypes.object),
  excludedLanes: PropTypes.arrayOf(PropTypes.string),
  setExcludedLanes: PropTypes.func,
  smartAwardMode: PropTypes.string,
  showZeroState: PropTypes.bool,
};

export default AwardTable;
