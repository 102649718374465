import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  nomessages__container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '45vh',
    fontSize: '1rem',
    color: theme.primaryTextColor,
  },
  nomessages__content: {
    textAlign: 'center',
    maxWidth: '317px',
  },
};

@injectSheet(styles)
export default class NoVerifications extends Component {
  render() {
    const { style, classes } = this.props;
    return (
      <div className={classes.nomessages__container} style={style} data-element="no-messages">
        <div className={classes.nomessages__content}>
          <p>
            <strong>No unverified donations.</strong>
          </p>
          <p>When you receive donations that need to be verified, they will show up here.</p>
        </div>
      </div>
    );
  }
}

NoVerifications.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object,
};
