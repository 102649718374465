import React from 'react';
import { createUseStyles } from 'react-jss';
import SiteHeader from '../components/header/site-header';

const styles = {
  notfound__page: {
    width: '100%',
  },
  text__center: {
    textAlign: 'center',
  },
};
const useStyles = createUseStyles(styles, { name: 'NotFoundLayout' });
const NotFoundLayout = () => {
  const classes = useStyles();
  return (
    <div className={classes.notfound__page}>
      <SiteHeader />
      <div>
        <h2 className={classes.text__center}>404 Alert! Page not found!</h2>
        <p className={classes.text__center}>Sorry for spoiling it for you.</p>
      </div>
    </div>
  );
};

export default NotFoundLayout;
