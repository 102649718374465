import LocalizedStrings from 'react-localization';

const SplitInventoryStrings = new LocalizedStrings({
  en: {
    title: 'Split Inventory',
    splittingError: 'An error occured in splitting the inventory',
    totalQuantityAvailable: 'Total Quantity Available',
    uom: 'case',
    percentListed: 'Percent Listed',
    stageListingsButton: 'Stage Listings',
  },
});

export default SplitInventoryStrings;
