import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActionMenu, ActionMenuOption, Card, DataSource, StatusBadge, LoadingSmall, SmallCarousel } from '@spoiler-alert/ui-library';
import moment from 'moment';
import injectSheet from 'react-jss';
import { PostQuery } from '../../../graphql/queries';
import { sourceMap, destinationMap, vendorReturnMap, postActionMap } from '../../../enums';

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  'post-detail__container': {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  'post-detail__header': {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    flexShrink: 0,
  },
  header_h2: {
    fontSize: '1.5rem',
    margin: 0,
  },
  info__block: {
    display: 'block',
    marginBottom: '16px',
  },
  info__title: {
    fontWeight: 400,
    fontSize: '1rem',
    color: '#333333',
  },
  info__header: {
    color: '#4eaac2',
    fontSize: '0.875rem',
    margin: 0,
    padding: 0,
  },
  info__contents: {
    fontSize: '1rem',
    color: '#333333',
    margin: 0,
    padding: 0,
  },
  info__card: {
    margin: '12px 0',
  },
  loading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  content__container: {
    opacity: 1,
    transitionDuration: '200ms',
    transitionProperty: 'opacity',
    position: 'relative',
    zIndex: 1,
  },
  'content__container--hidden': {
    opacity: 0,
  },
  actioncard__container: {
    position: 'relative',
    display: 'block',
  },
  action__box: {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'scale(.6, .6)',
    opacity: 0,
    display: 'block',
    transitionDuration: '200ms',
    transitionProperty: 'opacity,transform',
  },
  'action__box--visible': {
    transform: 'scale(1, 1)',
    opacity: 1,
    zIndex: 2,
  },
};

const noDate = '0001-01-01T00:00:00.000Z';

@injectSheet(styles)
export default class PostsActivityDetails extends Component {
  state = {
    data: { loading: true, error: true, post: {} },
    id: this.props.id,
    shouldUpdate: true,
    showAction: false,
    action: '',
    codes: undefined,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.id !== nextProps.id ||
      this.state.data !== nextState.data ||
      this.state.showAction !== nextState.showAction ||
      this.state.action !== nextState.action
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { shouldUpdate: prevState.id !== nextProps.id, id: nextProps.id };
  }

  dataReceived = (data) => {
    this.setState({ data, shouldUpdate: false });
  };

  get audience() {
    if (this.state.data.postByIdQuery.audience.length === 0) return 'Entire Community';
    return this.state.data.postByIdQuery.audience.map((audience, ix) => <p key={`.audience-${ix}`}>{audience}</p>);
  }

  get showOutlet() {
    const post = this.state.data.postByIdQuery;
    const statuses = ['CLAIMED', 'COMPLETED', 'UNVERIFIED'];
    return (
      (post.outletSite._id !== '' && (post.isGiveaway || post.source === 'MARKETPLACE') && statuses.includes(post.status)) ||
      post.isSelfReportedDonation
    );
  }

  get showAudience() {
    return this.state.data.postByIdQuery.source === 'MARKETPLACE';
  }

  get showTransactionNumber() {
    const statuses = ['AVAILABLE', 'ARCHIVED'];
    return !statuses.includes(this.state.data.postByIdQuery.status);
  }

  hideAction = () => {
    this.setState({ showAction: false, action: undefined });
  };

  get renderAction() {
    if (this.state.action)
      return <this.state.action onHide={this.hideAction} user={this.props.user} post={this.state.data.postByIdQuery} codes={this.state.codes} />;
    return null;
  }

  get hasImages() {
    return this.state.data.postByIdQuery.images && this.state.data.postByIdQuery.images.length > 0;
  }

  hasDate(date) {
    return date && date !== noDate;
  }

  render() {
    const showClass = this.state.action ? ` ${this.props.classes['content__container--hidden']}` : '';
    const contentClass = `${this.props.classes.content__container}${showClass}`;
    const actionShowClass = this.state.action ? ` ${this.props.classes['action__box--visible']}` : '';
    const actionClass = `${this.props.classes.action__box}${actionShowClass}`;
    return (
      <div className={this.props.classes.container}>
        <DataSource query={PostQuery} variables={{ id: this.state.id }} shouldUpdate={this.state.shouldUpdate} onFetch={this.dataReceived} />
        {!this.state.data.loading && !this.state.data.error && (
          <div className={this.props.classes['post-detail__container']}>
            <div className={this.props.classes['post-detail__header']}>
              <h2 className={this.props.classes.header_h2}>{destinationMap.get(this.state.data.postByIdQuery.destination)}</h2>
              <ActionMenu hovered={true} position="topright">
                {this.state.data.postByIdQuery.actions.map((action) => {
                  if (postActionMap.has(action)) {
                    const postAction = postActionMap.get(action);
                    const postActionRoute = postAction.route(this.state.id);
                    const actionClick = postAction.onClick
                      ? () => {
                          postAction.onClick(this);
                        }
                      : undefined;
                    return (
                      <ActionMenuOption key={action} onClick={actionClick} route={postActionRoute}>
                        {postActionMap.get(action).text}
                      </ActionMenuOption>
                    );
                  }
                  return null;
                })}
              </ActionMenu>
            </div>
            <p>
              <StatusBadge status={this.state.data.postByIdQuery.status} />
            </p>
            <div className={this.props.classes.actioncard__container}>
              <div className={actionClass}>{this.renderAction}</div>
            </div>
            <div className={contentClass}>
              <Card className={this.props.classes.info__card}>
                {this.state.data.postByIdQuery.itemName && (
                  <div className={this.props.classes.info__block}>
                    <p className={this.props.classes.info__header}>Item #</p>
                    <p className={this.props.classes.info__contents}>{this.state.data.postByIdQuery.itemName}</p>
                  </div>
                )}
                <div className={this.props.classes.info__block}>
                  <p className={this.props.classes.info__header}>Description</p>
                  <p className={this.props.classes.info__contents}>{this.state.data.postByIdQuery.description}</p>
                </div>
                <div className={this.props.classes.info__block}>
                  <p className={this.props.classes.info__header}>Category</p>
                  <p className={this.props.classes.info__contents}>{this.state.data.postByIdQuery.category}</p>
                </div>
                <div className={this.props.classes.info__block}>
                  <p className={this.props.classes.info__header}>Quantity</p>
                  <p className={this.props.classes.info__contents}>
                    {this.state.data.postByIdQuery.quantity} {this.state.data.postByIdQuery.unitOfMeasure}
                  </p>
                </div>
                <div className={this.props.classes.info__block}>
                  <p className={this.props.classes.info__header}>Quality</p>
                  <p className={this.props.classes.info__contents}>{this.state.data.postByIdQuery.quality}</p>
                </div>
                {this.showOutlet && (
                  <div className={this.props.classes.info__block}>
                    <p className={this.props.classes.info__header}>Outlet</p>
                    <p className={this.props.classes.info__contents}>{this.state.data.postByIdQuery.outletSite.siteName}</p>
                  </div>
                )}
                {this.showAudience && (
                  <div className={this.props.classes.info__block}>
                    <p className={this.props.classes.info__header}>Audience</p>
                    <p className={this.props.classes.info__contents}>{this.audience}</p>
                  </div>
                )}
                <div className={this.props.classes.info__block}>
                  <p className={this.props.classes.info__header}>Vendor Return</p>
                  <p className={this.props.classes.info__contents}>{vendorReturnMap.get(this.state.data.postByIdQuery.isVendorReturn)}</p>
                </div>
                <div className={this.props.classes.info__block}>
                  <p className={this.props.classes.info__header}>Source</p>
                  <p className={this.props.classes.info__contents}>{sourceMap.get(this.state.data.postByIdQuery.source)}</p>
                </div>
                {this.showTransactionNumber && (
                  <div className={this.props.classes.info__block}>
                    <p className={this.props.classes.info__header}>Transaction Number</p>
                    <p className={this.props.classes.info__contents}>{this.state.data.postByIdQuery.transactionId}</p>
                  </div>
                )}
              </Card>
              <Card className={this.props.classes.info__card}>
                <h4 className={this.props.classes.info__title}>History</h4>
                <div className={this.props.classes.info__block}>
                  <p className={this.props.classes.info__header}>Created</p>
                  <p className={this.props.classes.info__contents}>{moment(this.state.data.postByIdQuery.transactedAt).format('M/D/YYYY')}</p>
                </div>
                {this.hasDate(this.state.data.postByIdQuery.claimDate) && (
                  <div className={this.props.classes.info__block}>
                    <p className={this.props.classes.info__header}>Transacted</p>
                    <p className={this.props.classes.info__contents}>{moment(this.state.data.postByIdQuery.claimDate).format('M/D/YYYY')}</p>
                  </div>
                )}
                {this.hasDate(this.state.data.postByIdQuery.verifiedAtDate) && (
                  <div className={this.props.classes.info__block}>
                    <p className={this.props.classes.info__header}>Verified</p>
                    <p className={this.props.classes.info__contents}>{moment(this.state.data.postByIdQuery.verifiedAtDate).format('M/D/YYYY')}</p>
                  </div>
                )}
              </Card>
              {this.hasImages && (
                <Card className={this.props.classes.info__card}>
                  <p className={this.props.classes.info__title}>Images ({this.state.data.postByIdQuery.images.length})</p>
                  <SmallCarousel images={this.state.data.postByIdQuery.images} />
                </Card>
              )}
            </div>
          </div>
        )}
        {this.state.data.loading && (
          <div className={this.props.classes.loading}>
            <LoadingSmall />
          </div>
        )}
      </div>
    );
  }
}

PostsActivityDetails.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object,
  classes: PropTypes.object,
};
