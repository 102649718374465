import { inject, observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { DateRangeProvider, Timing } from '@spoiler-alert/ui-library';
import ComponentBuilder from '../utilities/component-builder';
import { FilterRow } from '../components/filters';
import { Grid } from '../components/grid';
import { TitleService } from '../services';
import { initialTimeResolutionValue } from '../components/filters/time-resolution-filter';
import { Breadcrumbs } from '../store';
import featureFlags from '../enums/feature-flags';
import routePaths from '../route-paths';

const styles = {
  root: {
    flexGrow: 1,
  },
  filter__container: {
    flexGrow: 1,
    padding: '0 10px',
    maxWidth: '33.33%',
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  '@media (max-width: 1423px) and (min-width: 840px)': {
    filter__container: {
      minWidth: '33.33%',
      flexBasis: 0,
      flexGrow: 0,
      '&:last-child': {
        paddingRight: '10px',
      },
      '&:nth-child(3n+1)': {
        paddingLeft: 0,
      },
      '&:nth-child(3n+3)': {
        paddingRight: 0,
      },
    },
  },
  '@media (max-width: 840px)': {
    filter__container: {
      flexGrow: 1,
      midWidth: '100%',
      maxWidth: '100%',
      padding: 0,
    },
  },
};

class EnterpriseDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.gutterSize = 16;
    this.dateRangeProvider = new DateRangeProvider(props.user.site.fiscalYearStart, props.user.site.allTimeStart);

    TitleService.setTitles('Dashboard', 'Dashboard');
    Breadcrumbs.set([
      {
        url: '/',
        title: 'Dashboard',
      },
    ]);

    this.state = {
      startDate: this.dateRangeProvider.fiscalYear.start.toDate(),
      endDate: this.dateRangeProvider.fiscalYear.end.toDate(),
      sites: props.user.postFilterParameters.locations.map((l) => l.id),
      destinations: props.user.postFilterParameters.destinations.map((c) => c.id),
      vendorReturns: ['all'],
      categories: props.user.postFilterParameters.categories.map((c) => c.id),
      subcategories: props.user.postFilterParameters.subcategories.map((sc) => sc.id),
      qualities: props.user.postFilterParameters.qualities.map((q) => q.id),
      timeResolution: initialTimeResolutionValue,
    };
    const events = {
      dateRangeChange: this.dateRangeChange,
      sitesChange: this.sitesChange,
      destinationsChange: this.destinationsChange,
      returnsChange: this.returnsChange,
      categoryChange: this.categoryChange,
      subCategoryChange: this.subCategoryChange,
      qualityChange: this.qualityChange,
      timeResolutionChange: this.timeResolutionChange,
    };
    this.componentBuilder = new ComponentBuilder(props.user, events, this.dateRangeProvider);
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.updateDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  };

  updateDimensions = Timing.debounce(() => this.forceUpdate(), 2000);

  get topPartners() {
    return {
      title: 'Top Partners',
      query: 'topPartners',
    };
  }

  dateRangeChange = (startDate, endDate) => {
    this.setState({ startDate, endDate });
  };

  sitesChange = (sites) => {
    this.setState({ sites });
  };

  destinationsChange = (destinations) => {
    this.setState({ destinations });
  };

  returnsChange = (vendorReturns) => {
    this.setState({ vendorReturns });
  };

  categoryChange = (categories) => {
    this.setState({ categories });
  };

  subCategoryChange = (subcategories) => {
    this.setState({ subcategories });
  };

  qualityChange = (qualities) => {
    this.setState({ qualities });
  };

  timeResolutionChange = (timeResolution) => {
    this.setState({ timeResolution });
  };

  createOffset(widget) {
    if (!widget.offset) return null;
    const onlyShow = Object.keys(widget.offset).filter((offsetKey) => !widget.offset[offsetKey]);
    if (!onlyShow.length) return null;
    return (
      <Grid
        key={`${widget.widgetId}_offset`}
        hidden={{ only: onlyShow }}
        xs={widget.offset.xs}
        sm={widget.offset.sm}
        md={widget.offset.md}
        lg={widget.offset.lg}
        xl={widget.offset.xl}
      ></Grid>
    );
  }

  formatWidgetRow(widgetRow) {
    return widgetRow.reduce((widgetAccum, widget) => {
      const component = this.componentBuilder.getComponent(widget, this.state);
      if (!component) return widgetAccum;
      const offsetGrid = this.createOffset(widget);
      if (offsetGrid) widgetAccum.push(offsetGrid);
      widgetAccum.push(
        <Grid key={widget.widgetId} xs={widget.width.xs} sm={widget.width.sm} md={widget.width.md} lg={widget.width.lg} xl={widget.width.xl}>
          {component}
        </Grid>
      );
      return widgetAccum;
    }, []);
  }

  formatFilterRow(filterRow) {
    return filterRow.reduce((filterAccum, filter) => {
      const component = this.componentBuilder.getComponent(filter, this.state);
      if (!component) return filterAccum;
      filterAccum.push(
        <div className={this.props.classes.filter__container} key={filter.widgetId}>
          {component}
        </div>
      );
      return filterAccum;
    }, []);
  }

  isFilterRow(row) {
    return row.some((widget) => widget.componentType.indexOf('Filter') > -1);
  }

  renderFilterRow(row) {
    return <FilterRow key="dashboard-filter-row">{this.formatFilterRow(row)}</FilterRow>;
  }

  renderWidgetRow(row, i) {
    return (
      <Grid xs={12} key={`widget-row-${i}`}>
        <Grid container>{this.formatWidgetRow(row)}</Grid>
      </Grid>
    );
  }

  checkFeature(featureName) {
    const { featureMap } = this.props.rootStore;
    return !!featureMap.get(featureName) === true;
  }

  redirect = () => {
    this.props.history.push(routePaths.platformDashboard);
  };

  render() {
    const { classes } = this.props;
    const className = `sa-dashboard-container ${classes.root}`;
    const hasLegacyDashboardFeature = this.checkFeature(featureFlags.legacyDashboard);
    if (!hasLegacyDashboardFeature) this.redirect();
    return (
      <div id="enterprise-dashboard" className={className}>
        <Grid container className="grid--full-width" gutter={this.gutterSize}>
          {this.props.user.dashboard.layout.rows.map((widgetRow, i) =>
            this.isFilterRow(widgetRow) ? this.renderFilterRow(widgetRow) : this.renderWidgetRow(widgetRow, i)
          )}
        </Grid>
      </div>
    );
  }
}

EnterpriseDashboard.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  rootStore: PropTypes.object,
  history: PropTypes.object,
};

const ConnectedComponent = inject('rootStore')(observer(EnterpriseDashboard));
const StyledComponent = injectSheet(styles)(ConnectedComponent);
export default StyledComponent;
