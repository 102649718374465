import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { toggleImpersonateSite } from '../graphql/mutations';
import client from '../apollo/client';
import routePaths from '../route-paths';

const styles = {
  logout: {
    textAlign: 'center',
    paddingTop: '3rem',
  },
};

@graphql(toggleImpersonateSite)
@injectSheet(styles)
export default class Logout extends Component {
  logout = async () => {
    const regex = RegExp('coordinates');
    for (let i = 0; i < localStorage.length; i++) {
      if (regex.exec(localStorage.key(i))) localStorage.removeItem(localStorage.key(i));
    }
    localStorage.removeItem('token');
    await client.cache.reset();
    const signInPage = localStorage.getItem('isSSOUser') === 'yes' ? routePaths.ssoSignIn : routePaths.signIn;
    this.props.history.push(signInPage);
  };

  componentDidMount() {
    const { user, mutate } = this.props;
    if (user && user.isImpersonating) {
      mutate().then(this.logout);
    } else {
      this.logout();
    }
  }

  render() {
    return (
      <div className={this.props.classes.logout}>
        <h1>Logging out ... </h1>
      </div>
    );
  }
}

Logout.propTypes = {
  user: PropTypes.object,
  mutate: PropTypes.func,
  classes: PropTypes.object,
  history: PropTypes.object,
};
