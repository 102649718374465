import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { TextInput, Button, Snackbar, Timing, AlertService, Theme as theme } from '@spoiler-alert/ui-library';
import accounting from 'accounting';
import { useMutation } from '@apollo/client';
import truckType from '../../enums/truck-type';
import { updateTruckTypeCosts } from '../../graphql/mutations';

const useStyles = createUseStyles({
  wrapper: {
    padding: '24px',
  },
  title: {
    fontSize: 16,
    lineHeight: 1.5,
    marginBottom: 0,
  },
  subtitle: {
    fontSize: 12,
    marginBottom: '12px',
  },
  previewSubtitle: {
    fontSize: 12,
    color: theme.grey,
    margin: '4px 0px',
  },
  previewDescription: {
    fontSize: 14,
    color: theme.grey,
    margin: '4px 0px',
  },
  description: {
    fontSize: 14,
    marginBottom: '12px',
    marginTop: 0,
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
  },
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    margin: 'auto',
    '&:first-child': {
      marginRight: 'auto',
      marginLeft: 0,
    },
    '&:last-child': {
      marginLeft: 'auto',
      marginRight: 0,
    },
  },
  container: {
    display: 'flex',
    margin: '24px 0px',
  },
  previewContainer: {
    display: 'flex',
  },
  saveSettings: {
    display: 'flex',
    justifyContent: 'flex-end',
    boxShadow: 'inset 0px 1px 0 0 #d9dee1',
    padding: '24px',
    width: '100%',
    '& button': {
      marginLeft: 8,
    },
  },
});

const initializeStateFromDB = (truckTypeCosts) => {
  if (!truckTypeCosts) {
    return Object.values(truckType).map((truck) => ({ title: truck, fixedCost: 0, costPerMile: 0 }));
  }
  const { dry, refrigerated, frozen } = truckTypeCosts;
  return [
    {
      title: truckType.dry,
      fixedCost: dry?.fixedCost || 0,
      costPerMile: dry?.costPerMile || 0,
    },
    {
      title: truckType.refrigerated,
      fixedCost: refrigerated?.fixedCost || 0,
      costPerMile: refrigerated?.costPerMile || 0,
    },
    {
      title: truckType.frozen,
      fixedCost: frozen?.fixedCost || 0,
      costPerMile: frozen?.costPerMile || 0,
    },
  ];
};

const DeliveryCostSettings = ({ user, saveText }) => {
  const classes = useStyles();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [touched, setTouched] = useState(false);
  const [truckTypes, setTruckTypes] = useState(initializeStateFromDB(user.site.truckTypeCosts));
  const [backendData, setBackendData] = useState({});
  const [updateCosts, { loading }] = useMutation(updateTruckTypeCosts);

  const buildBackendData = (updatedCost) => {
    setBackendData({
      ...backendData,
      [updatedCost.title.toLowerCase()]: {
        fixedCost: updatedCost.fixedCost,
        costPerMile: updatedCost.costPerMile,
      },
    });
  };

  const updateTruckTypeCostsData = Timing.debounce((title, value, type) => {
    const updatedList = [...truckTypes];
    const foundType = updatedList.find((listItem) => title === listItem.title);
    foundType[type] = accounting.unformat(value);
    setTruckTypes(updatedList);
    buildBackendData(foundType);
  }, 10);

  const truckTypeCosts = () => {
    return truckTypes.map((type) => {
      return (
        <div key={type.title} className={classes.verticalContainer}>
          <h2 className={classes.subtitle}>{type.title}</h2>
          <TextInput
            type="text"
            value={accounting.formatMoney(type.fixedCost)}
            labelText="Fixed Delivery Cost"
            onChange={() => setTouched(true)}
            onBlur={(value) => updateTruckTypeCostsData(type.title, value, 'fixedCost')}
          />
          <TextInput
            type="text"
            value={accounting.formatMoney(type.costPerMile)}
            labelText="Cost Per Mile"
            onChange={() => setTouched(true)}
            onBlur={(value) => updateTruckTypeCostsData(type.title, value, 'costPerMile')}
          />
        </div>
      );
    });
  };

  const ratePreview = () => {
    const calculationAmounts = [50, 500, 1000, 1500, 3000];
    const tableInfo = (
      <div key="info-preview" className={classes.verticalContainer}>
        <span className={classes.previewSubtitle}>Distance</span>
        {calculationAmounts.map((amount) => (
          <span key={`${amount}-info`} className={classes.previewDescription}>
            {accounting.formatNumber(amount)} miles
          </span>
        ))}
      </div>
    );
    return [
      tableInfo,
      ...truckTypes.map((type) => {
        return (
          <div key={`${type.title}-preview`} className={classes.verticalContainer}>
            <span className={classes.previewSubtitle}>{type.title}</span>
            {calculationAmounts.map((amount) => (
              <span key={`${type.title}-${amount}`} className={classes.previewDescription}>
                {accounting.formatMoney(type.costPerMile * amount + type.fixedCost)}
              </span>
            ))}
          </div>
        );
      }),
    ];
  };

  const save = () => {
    updateCosts({ variables: { truckTypeCosts: backendData } })
      .then((res) => {
        if (res.data.updateTrucktypeCosts.errors && res.data.updateTrucktypeCosts.errors.length > 0) {
          throw new Error(res.data.updateTrucktypeCosts.errors[0].message);
        } else {
          setShowSuccessMessage(true);
          setTouched(false);
          setBackendData({});
        }
      })
      .catch((err) => {
        AlertService.alert({
          type: 'warning',
          message: err.message,
        });
      });
  };

  const hideSuccessMessage = () => setShowSuccessMessage(false);

  return (
    <>
      <div className={classes.wrapper}>
        <h1 className={classes.title}>Estimate Delivery Costs</h1>
        <p className={classes.description}>
          Use our calculator to get an idea of what your delivery costs will be for each truck type. These estimates will be displayed during awarding
          to help guide your decisions.
        </p>
        <div className={classes.container}>{truckTypeCosts()}</div>
        <h2 className={classes.description}>Rate Preview</h2>
        <div className={classes.previewContainer}>{ratePreview()}</div>
      </div>

      <div className={classes.saveSettings}>
        <Button onClick={save} primary disabled={!touched} loading={loading} loadingText="Saving">
          {saveText || 'Save'}
        </Button>
      </div>
      <Snackbar show={showSuccessMessage} callback={hideSuccessMessage} message="Delivery Costs saved successfully" />
    </>
  );
};

DeliveryCostSettings.propTypes = {
  user: PropTypes.object,
  saveText: PropTypes.string,
};

export default DeliveryCostSettings;
