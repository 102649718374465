import React, { useState } from 'react';
import { ModalContent, CheckboxWithLabel, Button, AlertWarningIcon, MinimalTextTable, SimpleTextArea } from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import archiveInventoryModalStyles from './archive-inventory-modal-styles';

const useStyles = createUseStyles(archiveInventoryModalStyles);

const ListedArchiveInventoryModalContent = ({
  customMessage,
  showCustomMessage,
  setShowCustomMessage,
  setCustomMessage,
  distributionLists,
  archiveLoading,
  cancel,
}) => {
  const [maxHeight, setMaxHeight] = useState(280);
  const modalTitle = 'Archive Inventory';
  const modalBody =
    'Some of the selected inventory has been staged or published to the distribution lists below and may have already received offers. Archived inventory that has been staged or published will be permanently removed from those lists, but can be restored to be relisted.';
  const modalInfo =
    'Any received offers on archived inventory will be removed and cannot be restored. Inventory with accepted offers cannot be archived. Contact support if you need to reconcile offers.';
  const placeholder = 'Add a message for your customers';
  const classes = useStyles();
  const onChecked = () => {
    setShowCustomMessage(!showCustomMessage);
    if (!showCustomMessage) setMaxHeight(159);
    if (showCustomMessage) setMaxHeight(280);
  };

  return (
    <div>
      <ModalContent>
        <div data-testid="modal-title" className={classes.modal__title}>
          {modalTitle}
        </div>
        <div data-testid="modal-body" className={classes.modal__body}>
          {modalBody}
        </div>
        <div data-testid="modal-info" className={classes.modalInfo}>
          <div className={classes.infoIconContainer}>
            <AlertWarningIcon className={classes.infoIcon} />
          </div>
          {modalInfo}
        </div>
        <div className={classes.tableContainer}>
          <MinimalTextTable data={[...new Set(distributionLists)]} keyHeader={'Distribution List'} maxHeight={maxHeight} />
        </div>
        <div className={classes.customMessage}>
          <div className={classes.checkboxContainer}>
            <CheckboxWithLabel
              testId="custom-message-button"
              onChecked={onChecked}
              value="showCustomMessage"
              checked={showCustomMessage}
              disabled={archiveLoading}
              label={'Message your customers (optional)'}
            />
          </div>
          Select this option to write a message to your customers letting them know of this change.
          {showCustomMessage && (
            <SimpleTextArea value={customMessage} onChange={(value) => setCustomMessage(value)} placeholder={placeholder} autoFocus={true} />
          )}
        </div>
      </ModalContent>
      <div className={classes.footer__container}>
        <div className={classes.modal__submit}>
          <Button testId="cancel" className={classes.modal__cancel} type="button" onClick={cancel} secondary disabled={archiveLoading}>
            Cancel
          </Button>
          <Button
            testId="submit"
            type="submit"
            primary
            loadingText="Archiving Inventory"
            loading={archiveLoading}
            disabled={showCustomMessage && !customMessage}
          >
            Archive Inventory
          </Button>
        </div>
      </div>
    </div>
  );
};

ListedArchiveInventoryModalContent.propTypes = {
  customMessage: PropTypes.string,
  showCustomMessage: PropTypes.bool,
  setCustomMessage: PropTypes.func,
  setShowCustomMessage: PropTypes.func,
  distributionLists: PropTypes.array,
  archiveLoading: PropTypes.bool,
  cancel: PropTypes.func,
};

export default ListedArchiveInventoryModalContent;
