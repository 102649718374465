export const totalCost = {
  query: 'totalCost',
  id: 'total-cost',
  numberClass: 'dollar-prefix',
  text: 'Cost of Transactions',
};

export const totalAmount = {
  query: 'totalAmount',
  id: 'total-amount',
  numberClass: 'dollar-prefix',
  text: 'Value of Transactions',
};

export const totalPounds = {
  query: 'totalPounds',
  id: 'total-pounds',
  numberClass: '',
  text: 'Pounds Transacted',
};

export const totalMeals = {
  query: 'totalMeals',
  id: 'total-meals',
  numberClass: '',
  text: 'Meals Donated to Charity',
};

export const productMix = [
  {
    text: 'Cost',
    value: 'cost',
    query: 'productMixCost',
    mask: '$0,0',
  },
  {
    text: 'Value',
    value: 'value',
    query: 'productMixAmount',
    mask: '$0,0',
  },
  {
    text: 'Pounds',
    value: 'pounds',
    query: 'productMixPounds',
    mask: '0,0',
  },
];

export const diversionOutlets = [
  {
    text: 'Cost',
    value: 'cost',
    query: 'diversionOutletsCost',
    mask: '$0,0',
  },
  {
    text: 'Value',
    value: 'value',
    query: 'diversionOutletsAmount',
    mask: '$0,0',
  },
  {
    text: 'Pounds',
    value: 'pounds',
    query: 'diversionOutletsPounds',
    mask: '0,0',
  },
];

export const taxDeductions = {
  query: 'totalTaxDeductible',
  id: 'tax-deductions',
  numberClass: 'dollar-prefix',
  text: 'Enhanced Tax Deductions',
};

export const areaChartMetrics = [
  {
    text: 'Cost',
    value: 'cost',
    query: 'costPerPeriod',
    mask: '$0,0',
  },
  {
    text: 'Value',
    value: 'value',
    query: 'amountPerPeriod',
    mask: '$0,0',
  },
  {
    text: 'Pounds',
    value: 'pounds',
    query: 'poundsPerPeriod',
    mask: '0,0',
  },
  {
    text: 'Meals',
    value: 'meals',
    query: 'mealsPerPeriod',
    mask: '0,0',
  },
];

export const stackedColumnChartMetrics = [
  {
    text: 'Cost',
    value: 'cost',
    query: 'groupedPostsPerPeriodCost',
    mask: '$0,0',
  },
  {
    text: 'Value',
    value: 'value',
    query: 'groupedPostsPerPeriodAmount',
    mask: '$0,0',
  },
  {
    text: 'Pounds',
    value: 'pounds',
    query: 'groupedPostsPerPeriodPounds',
    mask: '0,0',
  },
  {
    text: 'Meals',
    value: 'meals',
    query: 'groupedPostsPerPeriodMeals',
    mask: '0,0',
  },
];

export const topFiveMetrics = [
  {
    text: 'By Cost',
    value: 'cost',
    query: 'topSkuCostPerPeriod',
    mask: '$0,0',
  },
  {
    text: 'By Value',
    value: 'value',
    query: 'topSkuValuePerPeriod',
    mask: '$0,0',
  },
  {
    text: 'By Frequency',
    value: 'frequency',
    query: 'topSkuFrequencyPerPeriod',
    mask: '0,0',
  },
];

export const topPartners = [
  {
    text: 'Cost',
    value: 'cost',
    query: 'topPartnersCost',
    mask: '$0,0',
  },
  {
    text: 'Value',
    value: 'value',
    query: 'topPartnersAmount',
    mask: '$0,0',
  },
  {
    text: 'Pounds',
    value: 'pounds',
    query: 'topPartnersPounds',
    mask: '0,0',
  },
];
