import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { graphql } from '@apollo/client/react/hoc';
import { AlertService, Button, Stepper, Step, Steps, StepContent, Theme as theme } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { CreateSite } from '../../graphql/mutations';
import formStyles from '../activity/forms/form-styles';
import SiteType from './site-type';
import SiteInfo from './site-info';
import SiteAdditional from './site-additional';
import Webhooks from './webhooks';

const styles = {
  ...formStyles,
  'admin-container__wrapper': {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '80%',
    marginBottom: '150px',
  },
  address__container: {
    width: '80%',
    marginLeft: '5%',
  },
  checkbox__description: {
    marginRight: '10px',
    marginBottom: '10px',
  },
  webhooks__container: {
    display: 'flex',
    flexDirection: 'column',
  },
  button__container: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  sticky__footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.primaryColor,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 8,
    left: 0,
    zIndex: 4,
    maxHeight: '50px',
  },
  button__submit: {
    width: '80%',
    height: '50px',
    marginTop: '50px',
  },
  'step-container': {
    width: '35%',
  },
  'seller-info__container': {
    display: 'flex',
    flexDirection: 'column',
  },
  button__next: {
    width: '20%',
    alignSelf: 'flex-end',
    marginTop: '2%',
  },
  'seller-additional-info__container': {
    display: 'flex',
    flexDirection: 'column',
  },
  button__wrapper: {
    borderTop: '1px solid #D9DEE1',
    paddingTop: 24,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    marginTop: 28,
  },
  form__header: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    fontWeight: 'normal',
    marginBottom: 13,
  },
};

@graphql(CreateSite)
@injectSheet(styles)
export default class AddSite extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('Site Creation');
  }

  state = {
    siteType: { value: 'CDS', text: 'Create Outlet' },
    parentSite: '',
    rootSite: '',
    belongsTo: '',
    siteName: '',
    abbr: '',
    street: '',
    stateName: '',
    city: '',
    zip: '',
    itemImport: '',
    createItemImportWebhook: false,
    createInventoryImportWebhook: false,
    createTransactionImportWebhook: false,
    saving: false,
  };

  save = (e) => {
    e.preventDefault();
    const { mutate } = this.props;
    const {
      parentSite,
      rootSite,
      siteType,
      belongsTo,
      siteName,
      abbr,
      street,
      stateName,
      city,
      zip,
      itemImport,
      createItemImportWebhook,
      createInventoryImportWebhook,
      createTransactionImportWebhook,
    } = this.state;
    this.setState({ saving: true });
    if (!stateName) this.error({ message: 'State Name is undefined' });
    const site = {
      parentSite,
      siteType: siteType.value,
      rootSite: siteType.value === 'SRP' ? '' : rootSite,
      belongsTo,
      siteName,
      abbr,
      street,
      stateName: stateName.value,
      city,
      zip,
      itemImport,
      createItemImportWebhook,
      createInventoryImportWebhook,
      createTransactionImportWebhook,
    };
    mutate({
      variables: { site },
    })
      .then(this.success)
      .catch(this.error);
  };

  success = (result) => {
    AlertService.alert({
      type: 'success',
      autoDismiss: true,
      dismissDelay: 3000,
      message: (
        <span>
          {result.data.createSite.result.siteName} created. ID: {result.data.createSite.result._id}
        </span>
      ),
    });
  };

  error(error) {
    AlertService.alert({ type: 'warning', autoDismiss: true, dismissDelay: 3000, message: <span>{error.message}</span> });
  }

  handleReady = (isReady) => {
    this.setState({ isReady });
  };

  serializeFormData = (data) => {
    this.setState({ ...data });
  };

  render() {
    const { classes } = this.props;
    const { activeStep, siteType } = this.state;
    return (
      <div id="add-sites">
        <div className={classes['admin-container__wrapper']}>
          <form id="site-form" onSubmit={this.save}>
            <Stepper onReady={this.handleReady} activeStep={activeStep}>
              <Steps>
                <Step target="site-type" step="1" active>
                  Site Type
                </Step>
                <Step target="basic-infomation" step="2">
                  Basic Information
                </Step>
                <Step target="additional-infomation" step="3">
                  Additional Information
                </Step>
                <Step target="webhook-information" step="4">
                  Optional Webhook Information
                </Step>
              </Steps>
              <StepContent id="site-type" className={classes['step-container']} active>
                <SiteType serialize={this.serializeFormData} />
              </StepContent>
              <StepContent id="basic-infomation" className={classes['step-container']}>
                <SiteInfo serialize={this.serializeFormData} />
              </StepContent>
              <StepContent id="additional-infomation" className={classes['step-container']}>
                <SiteAdditional serialize={this.serializeFormData} siteType={siteType} />
              </StepContent>
              <StepContent id="webhook-information" className={classes['step-container']}>
                <Webhooks serialize={this.serializeFormData} />
              </StepContent>
            </Stepper>
            <div className={classes.sticky__footer}>
              <Button type="submit" secondary>
                Create Site
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AddSite.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object,
  mutate: PropTypes.func,
};
