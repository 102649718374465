import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Card, Select, SelectOption, Button } from '@spoiler-alert/ui-library';
import formStyles from '../activity/forms/form-styles';

const styles = {
  ...formStyles,
};

@injectSheet(styles)
export default class SiteType extends Component {
  static propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
    goToPreviousStep: PropTypes.func,
    goToNextStep: PropTypes.func,
    setReadyStep: PropTypes.func,
    serialize: PropTypes.func,
  };

  state = {
    siteType: undefined,
  };

  serialize() {
    this.props.serialize(this.state);
  }

  get ready() {
    const { siteType } = this.state;
    return siteType !== undefined;
  }

  setReady = () => {
    this.props.setReadyStep(this.ready);
    this.serialize();
  };

  setSiteType = (_, siteType) => {
    this.setState({ siteType }, this.setReady);
  };

  get disabled() {
    return !this.ready;
  }

  render() {
    const { classes, goToNextStep } = this.props;
    const { siteType } = this.state;
    return (
      <Card className={classes.form__container}>
        <h2 className={classes.form__header}>Type of Site</h2>
        <Select label="Type of site" onChange={this.setSiteType} selectedItem={siteType}>
          <SelectOption value="SRP">Create Organization</SelectOption>
          <SelectOption value="SRO">Create Business Unit</SelectOption>
          <SelectOption value="SCS">Create Facility With Business Unit</SelectOption>
          <SelectOption value="SPO">Create Facility Without Business Unit</SelectOption>
          <SelectOption value="CDS">Create Outlet</SelectOption>
        </Select>
        <div className={this.props.classes.button__container}>
          <Button type="button" link onClick={goToNextStep} disabled={this.disabled}>
            Next
          </Button>
        </div>
      </Card>
    );
  }
}
