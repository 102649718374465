import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from '@spoiler-alert/ui-library';
import { NavLink, Redirect, Route } from 'react-router-dom';
import CustomerParticipationSummaryButton from '../customer-participation-summary/customer-participation-summary-button';

const styles = {
  linkActive: {
    color: `${Theme.primaryColor} !important`,
    borderBottom: `2px solid ${Theme.primaryColor} !important`,
    paddingBottom: '5px !important',
    marginBottom: '-1px !important',
  },
  container: {
    width: '100%',
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  underline: {
    borderBottom: `1px solid ${Theme.grey30}`,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
    marginTop: '1px',
    position: 'sticky',
    top: 0,
    zIndex: 10,
    background: Theme.white,
  },
  link: {
    fontSize: '16px',
    color: Theme.textColorPrimary,
    marginRight: '8px',
    padding: '6px 16px',
    '&:hover': {
      extend: 'linkActive',
      color: Theme.textColorPrimary,
      borderBottom: `2px solid ${Theme.textColorPrimary}`,
    },
  },
  padding: {
    padding: '4px 0px',
  },
};
const useStyles = createUseStyles(styles);

const TabSwitcher = ({ user, history, match, tabs, ...rest }) => {
  const classes = useStyles();

  const goToInventory = () => {
    history.push('/inventory');
  };

  return (
    <div className={classes.container}>
      {history.location.pathname === rest.path && <Redirect from={rest.path} to={`${rest.path}${tabs[0].url}`} />}
      <div className={classes.underline}>
        <div className={classes.tabContainer}>
          {tabs.map((tab) => {
            return (
              <NavLink
                exact
                key={`link-${tab.title.replace(' ', '-')}`}
                activeClassName={classes.linkActive}
                className={classes.link}
                to={`${match.url}${tab.url}`}
              >
                {tab.title}
              </NavLink>
            );
          })}
        </div>
        <div className={`${classes.tabContainer} ${classes.padding}`}>
          <CustomerParticipationSummaryButton createListingsHandler={goToInventory} />
        </div>
      </div>
      {tabs.map((tab) => (
        <Route
          exact
          key={`nav-${tab.title.replace(' ', '-')}`}
          path={`${match.path}${tab.url}${tab.optionalPath ? `/${tab.optionalPath}` : ''}`}
          render={() => <tab.component user={user} history={history} {...rest} />}
        />
      ))}
    </div>
  );
};

TabSwitcher.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      component: PropTypes.any,
      optionalPath: PropTypes.string,
    })
  ),
};

export default TabSwitcher;
