import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import moment from 'moment';
import { Breadcrumb, Button, DataSource, PrintIcon, LoadingSmall } from '@spoiler-alert/ui-library';
import accounting from 'accounting';
import { TaxMissingSignature } from './tax-dashboard';
import { TaxReceiptQuery } from '../graphql/queries';
import { TitleService } from '../services';
import { Breadcrumbs } from '../store';

const styles = {
  loading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    fontSize: '16px',
    padding: '0 15px',
  },
  receipt__container: {
    padding: '18px 15px',
  },
  print__icon: {
    width: '15px',
    marginRight: '3px',
  },
  print__button: {
    alignSelf: 'flexEnd',
    width: '100px',
  },
  sa__logo: {
    width: '160px',
    height: '60px',
  },
  action__header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sa__header: {
    borderBottom: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '16px',
    alignItems: 'flex-end',
    marginTop: '30px',
  },
  'sa-address__block': {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '11px',
    lineHeight: '1.4em',
  },
  transaction__block: {
    marginBottom: '36px',
    marginTop: '4px',
  },
  'organizations-address__container': {
    display: 'flex',
  },
  'organizations-address__block': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '1.4em',
  },
  'receipt-details__table': {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid #ddd',
    borderTop: '1px solid #ddd',
    margin: '15px 0',
  },
  'receipt-details__row': {
    display: 'flex',
    borderBottom: '1px solid #ddd',
    '&:nth-child(odd)': {
      backgroundColor: '#f9f9f9',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  'receipt-details__cell': {
    flexGrow: 1,
    borderRight: '1px solid #ddd',
    padding: '8px',
    '&:first-child': {
      flexBasis: '40%',
      flexGrow: 0,
    },
  },
  'address__site-name': {
    fontWeight: 'bold',
  },
  'mission-statement__block': {
    margin: '0 15px 1em',
  },
  'receipt-text__block': {
    paddingBottom: '16px',
    borderBottom: '1px solid #ccc',
  },
  receipt__footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
    marginTop: '16px',
  },
  date__label: {
    display: 'inline-block',
    paddingBottom: '.5rem',
  },
  date__line: {
    marginLeft: '10px',
    borderBottom: '2px solid #666666',
  },
  signature__details: {
    fontSize: '11px',
  },
  signature__image: {
    marginLeft: '-2rem',
    height: 75,
    borderBottom: '1px solid #666666',
  },
  '@media print': {
    action__header: {
      display: 'none',
    },
  },
  '@media (min-width: 992px)': {
    receipt__container: {
      width: '775px',
      margin: '0 auto',
    },
  },
};

class TaxReceipt extends Component {
  constructor(props) {
    super(props);
    const data = { loading: true, error: true };

    TitleService.setTitles('Tax Receipt');
    Breadcrumbs.set([
      {
        url: '/tax',
        title: 'Tax',
      },
      {
        url: props.match.url,
        title: 'Receipt',
      },
    ]);

    this.state = {
      data,
      shouldUpdate: true,
    };
  }

  dataReceived = (data) => {
    this.setState({ data, shouldUpdate: false });
  };

  print() {
    window.print();
  }

  get hasSignature() {
    const { responsibleParty } = this.state.data.postByIdQuery.outletSite;
    return (
      responsibleParty &&
      responsibleParty.signatureImage !== undefined &&
      responsibleParty.signatureImage !== null &&
      responsibleParty.signatureImage.length > 0
    );
  }

  render() {
    const { classes } = this.props;
    const { data } = this.state;
    return (
      <div className={classes.container}>
        <DataSource
          query={TaxReceiptQuery}
          variables={{ id: this.props.match.params.id }}
          shouldUpdate={this.state.shouldUpdate}
          onFetch={this.dataReceived}
        />
        {!data.loading && this.hasSignature && (
          <div className={classes.receipt__container}>
            <div className={classes.action__header}>
              <Breadcrumb />
              <Button type="button" className={classes.print__button} secondary={true} onClick={this.print}>
                <PrintIcon className={classes.print__icon} />
                Print
              </Button>
            </div>
            <div className={classes.sa__header}>
              <img src="/assets/receipt-sa-logo.png" alt="Spoiler Alert" className={classes.sa__logo} />
              <div className={classes['sa-address__block']}>
                <div>356 Boylston Street, 2nd Floor</div>
                <div>Boston, MA 02116</div>
                <div>support@spoileralert.com</div>
              </div>
            </div>
            <div className={classes.receipt__body}>
              <div className={classes.transaction__block}>Transaction ID: {data.postByIdQuery.transactionId} </div>
              <div className={classes['organizations-address__container']}>
                <div className={classes['organizations-address__block']}>
                  <h4 className={classes.address__title}>DONOR ORGANIZATION</h4>
                  <div className={classes.address__siteName}>{data.postByIdQuery.supplierSite.siteName}</div>
                  <div className={classes['address__address-line']}>{data.postByIdQuery.supplierSite.physicalAddress.streetAddress}</div>
                  <div className={classes['address__address-line']}>
                    {data.postByIdQuery.supplierSite.physicalAddress.city}, {data.postByIdQuery.supplierSite.physicalAddress.stateCode}{' '}
                    {data.postByIdQuery.supplierSite.physicalAddress.zipCode}
                  </div>
                </div>
                <div className={classes['organizations-address__block']}>
                  <h4 className={classes.address__title}>CHARITABLE ORGANIZATION</h4>
                  <div className={classes['address__site-name']}>{data.postByIdQuery.outletSite.siteName}</div>
                  <div className={classes['address__address-line']}>{data.postByIdQuery.outletSite.physicalAddress.streetAddress}</div>
                  <div className={classes['address__address-line']}>
                    {data.postByIdQuery.outletSite.physicalAddress.city}, {data.postByIdQuery.outletSite.physicalAddress.stateCode}{' '}
                    {data.postByIdQuery.outletSite.physicalAddress.zipCode}
                  </div>
                </div>
              </div>
              <div className={classes['receipt-details__table']}>
                <div className={classes['receipt-details__row']}>
                  <div className={classes['receipt-details__cell']}>Donation Date</div>
                  <div className={classes['receipt-details__cell']}>{moment(data.postByIdQuery.completedAt).format('MM/DD/YYYY')}</div>
                </div>
                <div className={classes['receipt-details__row']}>
                  <div className={classes['receipt-details__cell']}>Food Category</div>
                  <div className={classes['receipt-details__cell']}>{data.postByIdQuery.category}</div>
                </div>
                <div className={classes['receipt-details__row']}>
                  <div className={classes['receipt-details__cell']}>Description</div>
                  <div className={classes['receipt-details__cell']}>{data.postByIdQuery.description}</div>
                </div>
                <div className={classes['receipt-details__row']}>
                  <div className={classes['receipt-details__cell']}>Quantity</div>
                  <div className={classes['receipt-details__cell']}>
                    {data.postByIdQuery.quantity} {data.postByIdQuery.unitOfMeasure}
                  </div>
                </div>
                <div className={classes['receipt-details__row']}>
                  <div className={classes['receipt-details__cell']}>Total Value</div>
                  <div className={classes['receipt-details__cell']}>${accounting.formatNumber(data.postByIdQuery.totalPrice)}</div>
                </div>
              </div>
              <div className={classes['receipt-text__block']}>
                <p>
                  Thank you for your donation, which Spoiler Alert received on {moment(data.postByIdQuery.createdAtDate).format('MM/DD/YYYY')}. Your
                  donation was verified by {data.postByIdQuery.outletSite.siteName} on{' '}
                  {moment(data.postByIdQuery.verifiedAtDate).format('MM/DD/YYYY')} and will support its mission:{' '}
                </p>
                <p className={classes['mission-statement__block']}>{data.postByIdQuery.outletSite.purpose}</p>
                <p>
                  {data.postByIdQuery.outletSite.siteName} has confirmed that it is a qualified nonprofit (Tax ID/EIN:{' '}
                  {data.postByIdQuery.outletSite.taxId}) that is eligible to receive tax-deductible contributions in accordance with IRS requiements
                  in I.R.C. 170(e)3, and that no goods or services were provided in return for the contribution.
                </p>
                <p>Please save this letter for your records. You should consult your tax advisor for support in filing tax deductions.</p>
              </div>
            </div>
            <div className={classes.receipt__footer}>
              <div>
                <strong>Receipt Signature</strong>
              </div>
              <div className={classes.signature__block}>
                <img
                  className={classes.signature__image}
                  src={data.postByIdQuery.outletSite.responsibleParty.signatureImage}
                  alt={data.postByIdQuery.outletSite.responsibleParty.name}
                />
                <div className={classes.signature__details}>
                  {data.postByIdQuery.outletSite.responsibleParty.title}, {data.postByIdQuery.outletSite.siteName}
                </div>
              </div>
              <div className={classes.date__block}>
                <span className={classes.date__label}>Date</span>
                <span className={classes.date__line}>
                  {data.postByIdQuery.verifiedAtDate ? moment(data.postByIdQuery.verifiedAtDate).format('MMMM D, YYYY') : 'N/A'}
                </span>
              </div>
            </div>
          </div>
        )}
        {!data.loading && !this.hasSignature && <TaxMissingSignature outletSite={data.postByIdQuery.outletSite} />}
        {data.loading && (
          <div className={this.props.classes.loading}>
            <LoadingSmall />
          </div>
        )}
      </div>
    );
  }
}

TaxReceipt.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object,
  data: PropTypes.object,
};

const StyledComponent = injectSheet(styles)(TaxReceipt);
export default StyledComponent;
