import LocalizedStrings from 'react-localization';

const FilterStrings = new LocalizedStrings({
  en: {
    week: 'Week',
    month: 'Month',
    quarter: 'Quarter',
    year: 'Year',
  },
});

export default FilterStrings;
