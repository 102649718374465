import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import { createUseStyles } from 'react-jss';
import { DataTableNaked, Flyup } from '@spoiler-alert/ui-library';
import { useQuery } from '@apollo/client';
import { AwardSummaryQuery } from '../../graphql/queries';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import routePaths from '../../route-paths';
import { weightWithUnit } from '../../utilities/unit-helper';

const useStyles = createUseStyles({
  buyer__flyup__space: {},
});

const BuyersFlyup = ({ user, selectedInventoryId, history }) => {
  const classes = useStyles();
  const [buyerList, setBuyerList] = useState([]);
  const [totals, setTotals] = useState([]);

  const { data, loading, error } = useQuery(AwardSummaryQuery, { variables: { inventoryId: selectedInventoryId } });
  useEffect(() => {
    if (!loading && !error) {
      const newBuyerList = data.currentUserQuery.getAwardSummary.results.map((buyer) => ({
        ...buyer,
        _id: `${buyer.transactionLocationName}-${buyer.siteName}-${buyer.logisticsTerm}`,
      }));
      setBuyerList(newBuyerList);
      setTotals(data.currentUserQuery.getAwardSummary.totals);
    }
  }, [data, loading, error]);

  const getColumnOverrides = () => {
    return {
      transactionLocationName: {
        groupHeader: (groupKey) => groupKey,
      },
      poundsAwarded: {
        groupSummary: (rows) => {
          const value = rows.reduce((sum, row) => sum + row.poundsAwarded, 0);
          return weightWithUnit(value, user);
        },
      },
      acceptedOffer: {
        groupSummary: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.acceptedOffer, 0)),
      },
      palletsAwarded: {
        groupSummary: (rows) =>
          accounting.formatNumber(
            rows.reduce((sum, row) => sum + row.palletsAwarded, 0),
            2
          ),
      },
      costRecoveryRate: {
        groupSummary: (rows) => {
          const totalCost = rows.reduce((sum, row) => sum + row.totalCost, 0);
          const acceptedOffer = rows.reduce((sum, row) => sum + row.acceptedOffer, 0);
          return `${((acceptedOffer / totalCost) * 100).toFixed(2)}%`;
        },
      },
      priceOfInventoryAwarded: {
        groupSummary: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.priceOfInventoryAwarded, 0)),
      },
      totalPrice: {
        groupSummary: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.totalPrice, 0)),
      },
      casesAwarded: {
        groupSummary: (rows) =>
          accounting.formatNumber(
            rows.reduce((sum, row) => sum + row.casesAwarded, 0),
            0
          ),
      },
      discountPercent: {
        groupSummary: (rows) => {
          const totalPrice = rows.reduce((sum, row) => sum + row.totalPrice, 0);
          const acceptedOffer = rows.reduce((sum, row) => sum + row.acceptedOffer, 0);
          const result = acceptedOffer && totalPrice ? ((1 - acceptedOffer / totalPrice) * 100).toFixed(2) : undefined;
          return isNaN(result) ? undefined : `${result}%`;
        },
      },
      writeOffDiscountPercent: {
        groupSummary: (rows) => {
          const acceptedOffer = rows.reduce((sum, row) => sum + row.acceptedOffer, 0);
          const writeOffPriceOfInventoryAwarded = rows.reduce((sum, row) => sum + row.writeOffPriceOfInventoryAwarded, 0);
          const result =
            acceptedOffer && writeOffPriceOfInventoryAwarded ? ((1 - acceptedOffer / writeOffPriceOfInventoryAwarded) * 100).toFixed(2) : undefined;
          return isNaN(result) ? undefined : `${result}%`;
        },
      },
      unitPriceRecoveryRate: {
        groupSummary: (rows) => {
          const acceptedOffer = rows.reduce((sum, row) => sum + row.acceptedOffer, 0);
          const unitPriceRecoveryRate = rows.reduce((sum, row) => sum + row.unitPriceRecoveryRate, 0);
          const result = acceptedOffer && unitPriceRecoveryRate ? ((acceptedOffer / unitPriceRecoveryRate) * 100).toFixed(2) : undefined;
          return isNaN(result) ? undefined : `${result}%`;
        },
      },
    };
  };

  const getColumns = () => [
    ...getColumnsFromDataTableProfile('Offer Comp Buyer Flyup and Offer Review', user.site.dataTableProfiles, getColumnOverrides()),
  ];

  const handleRowClick = (row) => {
    history.push(`${routePaths.offerReview}/${row.siteId}/${encodeURIComponent(row.siteName)}`);
  };

  const uniqueBuyersSize = () =>
    buyerList.reduce((set, row) => {
      set.add(row.siteName);
      return set;
    }, new Set()).size;

  return (
    <Fragment>
      <Flyup title={`Buyers (${uniqueBuyersSize()})`} totals={totals} closeOnNavigate={true}>
        <DataTableNaked data={buyerList} columns={getColumns()} onRowClick={(row) => handleRowClick(row)} groupBy={(row) => row.siteName} />
        <div className={classes.buyer__flyup__space}></div>
      </Flyup>
    </Fragment>
  );
};

BuyersFlyup.propTypes = {
  data: PropTypes.object,
  selectedInventoryId: PropTypes.string,
  user: PropTypes.object,
  history: PropTypes.object,
};

export default BuyersFlyup;
