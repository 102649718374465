import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import { GraphQLDataTable, Filter, AlertService, Button, ExportIcon, MultiButton, RowAction, UnawardIcon } from '@spoiler-alert/ui-library';
import { useMutation } from '@apollo/client';
import accounting from 'accounting';
import { TitleService } from '../../services';
import { UserTransactionHistoryQuery } from '../../graphql/queries';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import dataExportService from '../../services/data-export-service';
import { Breadcrumbs, UserFilters } from '../../store';
import { stripEmptyFilters } from '../../utilities/clean-filters';
import { RemoveOrReverseTransactionsModal, ReconcileQuantityModal } from '../../components/modals';
import { ReverseTransactions, RemoveTransactions, ReconcileFinalQuantities } from '../../graphql/mutations';
import deleteCachedFieldsOnUserQuery from '../../apollo/cache-helpers/delete-cached-fields-on-user-query';

const useStyles = createUseStyles({
  'transaction-history__wrap': {
    marginBottom: 20,
  },
  action: {
    width: 242,
  },
});

const Transactions = ({ user }) => {
  const classes = useStyles();

  const [variables, setVariables] = useState({
    paginate: {
      pageNumber: 1,
      limit: 30,
      filter: {
        sortBy: { asc: true, sortProperty: 'siteName' },
      },
    },
  });
  const [open, setOpen] = useState(false);
  const [openRowAction, setOpenRowAction] = useState(false);
  const [rowData, setRowData] = useState({});
  const [action, setAction] = useState('');
  const [selected, setSelected] = useState([]);
  const [deselect, setDeselect] = useState(false);
  const [total, setTotal] = useState(0);
  const [allSelected, setAllSelected] = useState(false);

  const [reverseTransactions, { loading: loadingReverse }] = useMutation(ReverseTransactions);
  const [removeTransactions, { loading: loadingRemove }] = useMutation(RemoveTransactions);
  const [reconcileQuantity, { loading: loadingReconcileQuantity }] = useMutation(ReconcileFinalQuantities);

  useEffect(() => {
    TitleService.setTitles('Transactions');
    Breadcrumbs.set([
      {
        url: '/transactions',
        title: 'Transactions',
      },
    ]);
  }, []);

  const handleQueryChange = (vars) => {
    setVariables({ ...vars });
  };

  const createInitialFilters = () => {
    const locationFilter = { displayName: 'Location', field: 'sites', queryField: 'sites', selectAll: true, filterType: 'search' };
    const defaultFilters = [
      { displayName: 'Accepted Date Range', filterType: 'daterange', options: { startParam: 'acceptedAtStartDate', endParam: 'acceptedAtEndDate' } },
      { displayName: 'Brand', field: 'brands', filterType: 'search', selectAll: true },
      { displayName: 'Sold to Customer', field: 'recipientSiteIds', filterType: 'search', selectAll: true },
      { displayName: 'Cycle Date', field: 'cycleDate', filterType: 'search', selectAll: true },
    ];
    if (user.privileges.canUserManageMultipleSites) defaultFilters.splice(1, 0, locationFilter);
    const storeFilters = UserFilters.filters.transactions || [];
    const inventoryFilters = defaultFilters.map((df) => new Filter({ ...df, ...storeFilters.find((sf) => sf?.displayName === df.displayName) }));
    return inventoryFilters;
  };

  const updateFilters = () => {
    const newFilters = createInitialFilters();
    const filterState = newFilters.reduce((filtersObj, filter) => {
      if (filter.filterType === 'daterange') {
        filtersObj[filter.options?.startParam || 'startDate'] = filter.range?.start ? moment(filter.range?.start) : undefined;
        filtersObj[filter.options?.endParam || 'endDate'] = filter.range?.end ? moment(filter.range?.end) : undefined;
      }

      if (filter.queryField && filter.values.length > 0) {
        filtersObj[filter.queryField] = filter.values.map((v) => v.value);
      }

      return filtersObj;
    }, {});
    const paginateFilter = { ...variables.paginate.filter, ...filterState };
    handleQueryChange({ paginate: { ...variables.paginate, filter: stripEmptyFilters(paginateFilter) } });
  };

  useEffect(() => {
    updateFilters();
  }, []);

  const handleSelectChange = (newSelected, newTotal, newAllSelected) => {
    setSelected(newSelected);
    setTotal(newTotal);
    setAllSelected(newAllSelected);
    setDeselect(false);
  };

  const exportErrored = () => {
    const message =
      'Sorry we are unable to export your sales history. If this problem persists, please contact a Spoiler Alert Administrator to help you.';
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  };

  const exportCompleted = (response) => {
    if (response.ok || response.data) {
      const message = 'Your export is now processing and we will email you when it is completed.';
      AlertService.alert({ type: 'info', message: <span>{message}</span>, autoDismiss: true, dismissDelay: 5000 });
    } else {
      exportErrored();
    }
  };

  const exportHistory = () => {
    const filters = variables.paginate.filter;
    dataExportService.export('sales-history', { historyFilters: filters }).then(exportCompleted).catch(exportErrored);
  };

  const reverseOrRemove = (actionClicked) => {
    setAction(actionClicked);
    setOpen(true);
  };

  const getBulkActionButtons = () => {
    const multiActions = [
      {
        name: 'Remove Transactions',
        description: 'Selected bids will be unaccepted and completely removed from Spoiler Alert.',
        onClick: () => reverseOrRemove('remove'),
      },
      {
        name: 'Reverse Transactions',
        description: 'Selected bids will be unaccepted & unawarded but the original bid will still remain.',
        onClick: () => reverseOrRemove('reverse'),
      },
    ];

    return [
      <MultiButton
        key="createlistings"
        primaryAction={{
          name: 'Bulk Actions',
          disabled: false,
        }}
        actions={multiActions}
        customActionClass={classes.action}
      ></MultiButton>,
    ];
  };

  const showSuccess = (message) => {
    AlertService.alert({
      type: 'success',
      message: <span>{message}</span>,
      autoDismiss: true,
      dismissDelay: 3000,
    });
  };

  const showError = (message) => {
    AlertService.alert({
      type: 'warning',
      message: <span>{message}</span>,
      autoDismiss: true,
      dismissDelay: 3000,
    });
  };

  const handleSubmit = () => {
    if (action === 'reverse') {
      reverseTransactions({
        variables: {
          offerListingIds: selected.map((inventory) => inventory.transaction.offerListingId),
          allSelected,
          filter: variables.paginate.filter,
        },
        update: (cache) => deleteCachedFieldsOnUserQuery(cache, ['transactionHistory']),
      })
        .then((res) => {
          const errors = res.data?.reverseTransactions?.errors;
          if (errors?.length > 0) showError(errors[0]);
          else {
            setOpen(false);
            showSuccess(`${res.data.reverseTransactions.modifiedCount} transactions successfully reversed.`);
            setDeselect(true);
          }
        })
        .catch(() => {
          setOpen(false);
          showError(
            'We were unable to reverse the transactions due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.'
          );
        });
    }

    if (action === 'remove') {
      removeTransactions({
        variables: {
          offerListingIds: selected.map((inventory) => inventory.transaction.offerListingId),
          allSelected,
          filter: variables.paginate.filter,
        },
        update: (cache) => deleteCachedFieldsOnUserQuery(cache, ['transactionHistory']),
      })
        .then((res) => {
          const errors = res.data?.removeTransactions?.errors;
          if (errors?.length > 0) showError(errors[0]);
          else {
            setOpen(false);
            showSuccess(`${res.data.removeTransactions.modifiedCount} transactions successfully removed.`);
            setDeselect(true);
          }
        })
        .catch(() => {
          setOpen(false);
          showError(
            'We were unable to remove the transactions due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.'
          );
        });
    }
  };

  const openQuantityModal = (row) => {
    setRowData(row);
    setOpenRowAction(true);
  };

  const rowActions = [
    <RowAction
      key={'reconcile-qty'}
      tooltipText="Reconcile Quantity"
      icon={UnawardIcon}
      onClick={(row) => openQuantityModal.bind(this, row)}
      secondary
      disabledRows={[]}
      disabled={false}
    />,
  ];

  const onQuantitySubmit = (quantity) => {
    reconcileQuantity({
      variables: {
        quantityUpdate: Number(quantity),
        offerListingIds: [rowData.transaction.offerListingId],
      },
    })
      .then((res) => {
        const errors = res.data?.reconcileFinalQuantities?.errors;
        if (errors?.length > 0) showError(errors[0].message);
        else {
          setOpenRowAction(false);
          showSuccess(`Successfully reconciled final quantities for this transaction`);
        }
      })
      .catch(() => {
        setOpenRowAction(false);
        showError('An error occured trying to reconcile final quantities, fatal error that couldnt be caught.');
      });
  };

  return (
    <div className={classes['transaction-history__wrap']}>
      <GraphQLDataTable
        query={UserTransactionHistoryQuery}
        queryName="currentUserQuery"
        queryField="transactionHistory"
        search
        transition
        columns={getColumnsFromDataTableProfile('Transactions', user.site.dataTableProfiles)}
        filterParameterField="transactionFilterParameters"
        filters={createInitialFilters()}
        rowActions={rowActions}
        updateStoredFilters={(filters) => {
          UserFilters.updateFilters('transactions', filters);
          updateFilters();
        }}
        pagination
        selectable
        perPage={30}
        infinite
        deselect={deselect}
        onSelectChange={handleSelectChange}
        pageActionButtons={[
          <Button key="export" secondary onClick={exportHistory} icon={ExportIcon}>
            Export
          </Button>,
        ]}
        bulkActionButtons={getBulkActionButtons()}
        handleQueryChange={handleQueryChange}
      />
      <RemoveOrReverseTransactionsModal
        open={open}
        onHide={() => setOpen(false)}
        transactionCount={!allSelected ? selected.length : total}
        transactionAmount={(selected.length && accounting.formatMoney(selected.reduce((a, b) => a + b.transaction.totalSalePrice, 0))) || ''}
        action={action}
        handleSubmit={handleSubmit}
        loading={loadingRemove || loadingReverse}
      />
      <ReconcileQuantityModal
        open={openRowAction}
        onHide={() => {
          setOpenRowAction(false);
        }}
        rowData={rowData}
        onSubmit={onQuantitySubmit}
        loading={loadingReconcileQuantity}
      />
    </div>
  );
};

Transactions.propTypes = {
  user: PropTypes.object,
};

export default Transactions;
