import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import pluralize from 'pluralize';
import InlineOfferListing from './inline-offer-listing';

const useStyles = createUseStyles({
  list: {
    textAlign: 'left',
    padding: '0 10px',
  },
});

const OfferListingList = ({ offerListings }) => {
  const classes = useStyles();
  let filtered = offerListings;
  let showMore = 0;
  if (offerListings.length > 3) {
    filtered = offerListings.slice(0, 3);
    showMore = offerListings.length - 3;
  }
  return (
    <div className={classes.list} data-testid="offer-listing">
      {filtered.map((ol) => (
        <InlineOfferListing key={ol._id} offerListing={ol} />
      ))}
      {showMore > 0 && `+ ${showMore} more ${pluralize('offer', showMore)}`}
    </div>
  );
};

OfferListingList.propTypes = {
  offerListings: PropTypes.arrayOf(
    PropTypes.shape({
      buyerSiteName: PropTypes.string,
      totalPrice: PropTypes.number,
      quantity: PropTypes.number,
    })
  ),
};

export default OfferListingList;
