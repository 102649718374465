import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Search, Button, Theme as theme, ClickAwayListener, SelectOption } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import { event } from 'react-fullstory';

const styles = {
  container: {
    width: '382px',
    maxHeight: '450px',
    position: 'absolute',
    top: 40,
    right: 0,
    zIndex: 2,
    borderRadius: '2px',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.11)',
    border: 'solid 1px #dadbdc',
    backgroundColor: theme.white,
  },
  header: {
    padding: '16px',
    width: '100%',
  },
  selectAll: {
    display: 'flex',
    padding: '6px 16px',
    height: '36px',
    gap: '10px',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.menuItemHoverBg,
    },
  },
  line: {
    backgroundColor: '#dadbdc',
    height: '1px',
    margin: '8px 0',
  },
  options: {
    height: '275px',
    overflow: 'scroll',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'inset 0px 1px 0 0 #d9dee1',
    width: '100%',
    padding: '8px',
    '& button': {
      width: '384px',
    },
  },
};

const MultiSelectWithButton = ({ customers, setShowSelectAll, setAddedCustomers, addedCustomers, getContacts, setTouched }) => {
  const useStyles = createUseStyles(styles, { name: 'MultiSelectWithButton' });
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  // all customers that are not listed on the page
  const notAddedCustomers = customers.filter((customer) => {
    return !addedCustomers
      .map((c) => {
        return c.buyerSiteId;
      })
      .includes(customer._id);
  });

  const filteredCustomers = notAddedCustomers
    .filter((customer) => customer?.buyerName?.toLowerCase().includes(searchText?.toLowerCase()))
    .map((customer) => {
      customer.contacts = getContacts(customer._id);
      customer.buyerSiteId = customer._id;
      customer.sellerSiteId = getContacts(customer._id)?.[0].sellerSiteId;
      return customer;
    })
    .sort((a, b) => {
      const nameA = a?.buyerName?.toUpperCase(); // ignore upper and lowercase
      const nameB = b?.buyerName?.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedCustomers([]);
      setSelectAll(false);
    } else if (!selectAll && selectedCustomers.length > 0) {
      setSelectedCustomers([]);
      setSelectAll(false);
    } else {
      setSelectedCustomers(Array.from(new Set(filteredCustomers)));
      setSelectAll(true);
    }
  };

  const toggleSelectCustomers = (id) => {
    const selected = selectedCustomers.find((customer) => customer._id === id);
    if (selected) {
      setSelectedCustomers(selectedCustomers.filter((c) => c._id !== id));
      setSelectAll(false);
    }
    if (!selected) {
      const addToCustomer = filteredCustomers.find((customer) => customer._id === id);
      setSelectedCustomers([...Array.from(new Set(selectedCustomers)), addToCustomer]);
    }
  };

  const handleAddCustomers = () => {
    const hydratedSelected = selectedCustomers.map((customer) => {
      customer.contacts = getContacts(customer._id);
      return customer;
    });
    const allSelectedCustomers = [...hydratedSelected, ...addedCustomers].sort((a, b) => {
      const nameA = a?.buyerName?.toUpperCase(); // ignore upper and lowercase
      const nameB = b?.buyerName?.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    setShowSelectAll(false);
    setAddedCustomers(allSelectedCustomers);
    setTouched(true);
    event('Added Buyer to Existing List');
  };

  return (
    <ClickAwayListener onClickAway={() => setShowSelectAll(false)}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Search placeholder="Search" value={searchText} onChange={setSearchText}></Search>
        </div>
        <div>
          <SelectOption
            value="all"
            checkbox
            show={true}
            onSelect={toggleSelectAll}
            selected={selectAll || selectedCustomers.length === filteredCustomers.length}
            partial={selectedCustomers.length > 0 && selectedCustomers.length < filteredCustomers.length}
          >
            All
          </SelectOption>
          <div className={classes.line}></div>
          <div data-testid="modal-list-area" className={classes.options}>
            {filteredCustomers.map((customer, i) => (
              <SelectOption
                key={i}
                value={customer.buyerName}
                checkbox
                show={true}
                onSelect={() => toggleSelectCustomers(customer._id)}
                selected={!!selectedCustomers.find((c) => customer._id === c._id)}
              >
                {customer.buyerName}
              </SelectOption>
            ))}
          </div>
        </div>
        <div className={classes.footer}>
          <Button disabled={!selectedCustomers.length} onClick={handleAddCustomers}>
            {`Add ${selectedCustomers.length > 0 ? selectedCustomers.length : ''} Selected`}
          </Button>
        </div>
      </div>
    </ClickAwayListener>
  );
};

MultiSelectWithButton.propTypes = {
  customers: PropTypes.any,
  setShowSelectAll: PropTypes.func,
  setAddedCustomers: PropTypes.func,
  addedCustomers: PropTypes.array,
  getContacts: PropTypes.func,
  setTouched: PropTypes.func,
};

export default MultiSelectWithButton;
