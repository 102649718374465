import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useMutation, useQuery } from '@apollo/client';
import accounting from 'accounting';
import {
  TruckSimpleIcon,
  DataTableNaked,
  TableWithDrawer,
  RowAction,
  UnawardIcon,
  AlertService,
  Column,
  ColumnFormatters,
  StickyTableTitle,
  NakedButton,
  TotalMetrics,
  WeightIcon,
  PalletJackIcon,
  RevenueIcon,
  EmptyStateIcon,
} from '@spoiler-alert/ui-library';
import { weightWithUnit } from '../../utilities/unit-helper';
import { OfferTrucklaneQuery } from '../../graphql/queries';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';
import styles from './offer-review-details-trucklane-styles';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import OfferListings from '../offer-comparison/offer-listings';
import {
  UnawardOfferListingsByTruckLane as UnawardOfferListingsByTruckLaneMutation,
  UnawardOfferListing as UnawardOfferListingMutation,
} from '../../graphql/mutations';
import { OfferComparisonStrings } from '../../string-resources';
import deleteCachedFieldsOnUserQuery from '../../apollo/cache-helpers/delete-cached-fields-on-user-query';
import deleteCachedObject from '../../apollo/cache-helpers/delete-cached-object';
import { negativeCostHandler } from '../../utilities/negative-cost-handler';

const useStyles = createUseStyles(styles);

const OfferReviewDetailsTrucklane = ({ user, match }) => {
  const classes = useStyles();
  const { transactionLocationId, buyerSiteId, sellerLocationId, buyerDestinationId } = match.params;
  const buyerSiteName = decodeURIComponent(match.params.buyerSiteName);
  const truckType = decodeURIComponent(match.params.truckType);
  const logisticsTerm = decodeURIComponent(match.params.logisticsTerm);
  const sellerSiteName = decodeURIComponent(match.params.sellerSiteName);
  const transactionLocationName = decodeURIComponent(match.params.transactionLocationName);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [fullstoryTag, setFullstoryTag] = useState('');
  const [logisticsInfo] = useState([
    {
      title: 'Origin',
      value: sellerSiteName,
    },
    {
      title: 'Truck Type',
      value: truckType,
    },
    {
      title: 'Destination',
      value: transactionLocationName,
    },
    {
      title: 'Shipping',
      value: logisticsTerm,
    },
  ]);
  const [metricsInfo, setMetricsInfo] = useState([
    {
      title: 'Net Revenue',
      currentValue: '$0.00',
      totalValue: '0 Est. Delivery Cost',
      icon: <RevenueIcon className={classes.iconMetric} />,
    },
    {
      title: 'Total Weight',
      currentValue: '0 LBS',
      totalValue: '0 total offered',
      icon: <WeightIcon className={classes.iconMetric} />,
    },
    {
      title: 'Total Pallets',
      currentValue: '0.00',
      totalValue: '0 total offered',
      icon: <PalletJackIcon className={classes.iconMetric} />,
    },
  ]);
  const { data, loading } = useQuery(OfferTrucklaneQuery, {
    variables: { buyerSiteId, truckType, logisticsTerm, transactionLocationId, sellerLocationId, buyerDestinationId },
  });
  const [unawardOfferListingsByTruckLane, { loading: unawardOfferListingsByTruckLaneLoading }] = useMutation(UnawardOfferListingsByTruckLaneMutation);
  const [unawardOfferListingById, { loading: unawardOfferListingByIdLoading }] = useMutation(UnawardOfferListingMutation);
  const columnOverrides = {
    itemName: {
      tableFooter: () => '',
    },
    offerListing_quantity: {
      tableFooter: (rows) => rows.reduce((sum, row) => sum + row.offerListing.quantity, 0),
    },
    unitCost: {
      tableFooter: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.unitCost, 0)),
    },
    weight_quantity: {
      tableFooter: (rows) =>
        weightWithUnit(
          rows.reduce((sum, row) => sum + row.unitGrossWeight * row.offerListing.quantity, 0),
          user
        ),
    },
    netWeight_quantity: {
      tableFooter: (rows) =>
        weightWithUnit(
          rows.reduce((sum, row) => sum + row.unitNetWeight * row.offerListing.quantity, 0),
          user
        ),
    },
    cubeAdjustedWeight: {
      tableFooter: (rows) =>
        weightWithUnit(
          rows.reduce((sum, row) => sum + row.cubeAdjustedWeight, 0),
          user
        ),
    },
    offerListing_totalPrice: {
      tableFooter: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.offerListing.totalPrice, 0)),
    },
    unitPrice: {
      tableFooter: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.unitPrice, 0)),
    },
    activeReservePrice: {
      tableFooter: (rows) =>
        accounting.formatMoney(rows.reduce((sum, row) => sum + ColumnFormatters.generic.access.activeReservePrice(null, row), 0)),
    },
    totalPallets: {
      tableFooter: (rows) => rows.reduce((sum, row) => sum + row.totalPallets, 0),
    },
    offerListing_discountRate: {
      tableFooter: (rows) => `${accounting.formatNumber(rows.reduce((sum, row) => sum + row.offerListing.discountRate, 0) / rows.length || 0, 2)}%`,
    },
    offerListing_unitPriceRecoveryRate: {
      tableFooter: (rows) =>
        `${accounting.formatNumber(rows.reduce((sum, row) => sum + row.offerListing.unitPriceRecoveryRate, 0) / rows.length || 0, 2)}%`,
    },
    offerListing_costRecoveryRate: {
      tableFooter: (rows) =>
        `${accounting.formatNumber(rows.reduce((sum, row) => sum + row.offerListing.costRecoveryRate, 0) / rows.length || 0, 2)}%`,
    },
    available_cases: {
      tableFooter: (rows) =>
        rows.reduce((sum, row) => {
          const total = sum.split('/');
          const rowCase = ColumnFormatters.generic.access.casesRemaining(null, row).split('/');
          return `${Number(total[0]) + Number(rowCase[0])} / ${Number(total[1]) + Number(rowCase[1])}`;
        }, '0/0'),
    },
  };
  const generateMetrics = (awardedInv, fillupInv) => {
    const metrics = {
      deliveryCost: 0,
      currentRev: 0,
      totalWeight: 0,
      currentWeight: 0,
      totalPallets: 0,
      currentPallets: 0,
    };

    awardedInv.forEach((row) => {
      metrics.deliveryCost = row.offerListing.trucklaneCost;
      metrics.currentRev = row.offerListing.totalPrice + metrics.currentRev;
      metrics.totalWeight = row.offerListing.quantity * row.unitGrossWeight + metrics.totalWeight;
      metrics.totalPallets = row.offerListing.quantity / row.casesPerPallet + metrics.totalPallets;
      metrics.currentWeight = row.offerListing.quantity * row.unitGrossWeight + metrics.currentWeight;
      metrics.currentPallets = row.offerListing.quantity / row.casesPerPallet + metrics.currentPallets;
    });
    fillupInv
      .filter((inv) => !awardedInv.map((awardInv) => awardInv.id).includes(inv.id))
      .forEach((row) => {
        metrics.totalWeight = row.offerListing.quantity * row.unitGrossWeight + metrics.totalWeight;
        metrics.totalPallets = row.offerListing.quantity / row.casesPerPallet + metrics.totalPallets;
      });

    setMetricsInfo([
      {
        title: 'Net Revenue',
        currentValue: negativeCostHandler(metrics.currentRev - metrics.deliveryCost),
        totalValue: `(${accounting.formatMoney(metrics.deliveryCost)}) Est. Delivery Cost`,
        icon: <RevenueIcon className={classes.iconMetric} />,
        className: metrics.deliveryCost > 0 ? { totalValue: classes.negativeTotalValue } : null,
      },
      {
        title: 'Total Weight',
        currentValue: weightWithUnit(metrics.currentWeight, user),
        totalValue: `${weightWithUnit(metrics.totalWeight, user)} total offered`,
        icon: <WeightIcon className={classes.iconMetric} />,
      },
      {
        title: 'Total Pallets',
        currentValue: `${accounting.formatNumber(metrics.currentPallets, 2)}`,
        totalValue: `${accounting.formatNumber(metrics.totalPallets, 2)} total offered`,
        icon: <PalletJackIcon className={classes.iconMetric} />,
      },
    ]);
  };
  useEffect(() => {
    TitleService.setTitles('Truck Lane Management');
    const breadCrumbData = [
      {
        url: routePaths.review,
        title: 'Offer Review',
      },
      {
        url: `${routePaths.offerReview}/${buyerSiteId}/${encodeURIComponent(buyerSiteName)}`,
        title: buyerSiteName,
      },
    ];
    breadCrumbData.push({ url: match.url, title: 'Truck Lane Management' });
    Breadcrumbs.set(breadCrumbData);
  }, []);

  const { awardedInventory, fillupInventory } = useMemo(() => {
    const awardedInv = [];
    const fillupInv = [];
    const reconfigedData = (data?.offerListingsByTrucklaneQuery?.data || []).reduce((newSet, row) => {
      row.offerListings.forEach((ol) => {
        if (ol.buyerSiteId === buyerSiteId)
          newSet.push({
            ...row.inventory,
            offerListing: ol,
            competingOffers: row.competingOffers - 1 - row.offerListings.filter((offer) => offer.status === 'IGNORED').length,
            id: row.inventory._id,
            _id: `${row.inventory._id}_${ol._id}`,
          });
      });
      return newSet;
    }, []);
    reconfigedData.forEach((row) => {
      if (row.offerListing.status === 'IGNORED') return;
      if (row.offerListing.status === 'AWARDED') {
        awardedInv.push(row);
      } else {
        fillupInv.push(row);
      }
    });

    generateMetrics(awardedInv, fillupInv);
    return { awardedInventory: awardedInv, fillupInventory: fillupInv.filter((inv) => !awardedInv.map((awardInv) => awardInv.id).includes(inv.id)) };
  }, [data]);

  const logisticDetails = useMemo(() => {
    return (
      <div className={classes.container}>
        <div>
          <div className={classes.title}>
            <TruckSimpleIcon className={classes.icon} />
            <p className={classes.logisticDetailsHeader}>Logistics Details</p>
          </div>
          <div className={classes.grid}>
            {logisticsInfo.map((item) => (
              <div className={classes.item} key={item.title}>
                <p className={classes.logisticDetailsTitle}>{item.title}: </p>
                <p className={classes.logisticsDetailsContent}>
                  {item.value}
                  {data && data.offerListingsByTrucklaneQuery?.data[0]?.offerListings[0]?.trucklaneDistance && item.title === 'Shipping'
                    ? ` - ${accounting.formatNumber(data.offerListingsByTrucklaneQuery.data[0].offerListings[0].trucklaneDistance)} miles`
                    : ''}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.floatRight}>
          {metricsInfo.map((metric, index) => (
            <TotalMetrics
              key={metric.title}
              icon={metric.icon}
              title={metric.title}
              totalValue={metric.totalValue}
              currentValue={metric.currentValue}
              borderRight={index < metricsInfo.length - 1}
              className={metric.className}
            />
          ))}
        </div>
      </div>
    );
  }, [logisticsInfo, data, metricsInfo]);

  const { awardedColumns, fillupColumns } = useMemo(() => {
    const defaultColumns = (dataTable) => [
      ...getColumnsFromDataTableProfile(dataTable, user.site.dataTableProfiles, columnOverrides, true),
      new Column({
        field: 'competingOffers',
        displayName: '# of Competing Offers',
        defaultSort: false,
        sortable: true,
        formatter: (_, row) => row.competingOffers,
      }),
    ];
    const columnData = {
      awardedColumns: defaultColumns('Offer Review Buyer Trucklane V2'),
      fillupColumns: defaultColumns('Offer Review Buyer Trucklane truckfillup'),
    };
    if (!fillupInventory?.length) {
      columnData.fillupColumns = [
        ...getColumnsFromDataTableProfile('Offer Review Buyer Trucklane truckfillup', user.site.dataTableProfiles, {}, true),
        new Column({
          field: 'competingOffers',
          displayName: '# of Competing Offers',
          defaultSort: false,
          sortable: true,
          formatter: (_, row) => row.competingOffers,
        }),
      ];
    }
    return columnData;
  }, [user.site.dataTableProfiles, columnOverrides]);

  const handleUnawardAllClick = () => {
    if (!unawardOfferListingsByTruckLaneLoading) {
      unawardOfferListingsByTruckLane({
        variables: {
          buyerSiteId,
          sellerLocationId,
          transactionLocationId,
          truckType,
          logisticsTerm,
        },
        update: (cache) => {
          deleteCachedObject(
            cache,
            'OfferListingSummaryType',
            `${buyerSiteId}_${logisticsTerm}_${truckType}_${transactionLocationId}_${sellerLocationId}`
          );
          deleteCachedFieldsOnUserQuery(cache, ['awardedOfferListings', 'awardedInventoryFilterParameters', 'getAwardSummary']);
        },
      })
        .then(() => {
          AlertService.alert({ type: 'success', autoDismiss: true, message: <span>All offers from the trucklane have been unawarded.</span> });
        })
        .catch(() => AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{OfferComparisonStrings.unawardingError}</span> }));
    }
  };

  const handleUnawardOfferClick = (row, e) => {
    if (!unawardOfferListingByIdLoading) {
      e.stopPropagation();

      unawardOfferListingById({
        variables: {
          id: row.offerListing._id,
        },
        update: (cache) => {
          const cachedObjectId = `${buyerSiteId}_${logisticsTerm}_${truckType}_${transactionLocationId}_${sellerLocationId}`;
          const cachedObjectTypename = 'OfferListingSummaryType';
          const normalizedCacheId = cache.identify({ id: cachedObjectId, __typename: cachedObjectTypename });
          cache.modify({
            id: normalizedCacheId,
            fields: {
              offerListingIds(cachedOfferListingIds) {
                return cachedOfferListingIds.filter((cachedOfferListingId) => cachedOfferListingId !== row.offerListing._id);
              },
            },
          });
          cache.gc();
          deleteCachedFieldsOnUserQuery(cache, ['awardedOfferListings', 'awardedInventoryFilterParameters', 'getAwardSummary']);
        },
      })
        .then(() => {
          setSelectedInventory(null);
          AlertService.alert({ type: 'success', autoDismiss: true, message: <span>The offer has been unawarded.</span> });
        })
        .catch(() => AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{OfferComparisonStrings.unawardingError}</span> }));
    }
  };

  const handleRowClick = (inventory) => {
    setFullstoryTag(inventory.offerListing.status === 'AWARDED' ? 'from inventory redistribution' : 'from truckfillup');
    setSelectedInventory(inventory);
  };

  const rowActions = () => {
    if (awardedInventory?.length)
      return [
        <RowAction
          key={1}
          tooltipText="Unaward Offer"
          loadingTooltipText="Unawarding Offer"
          icon={UnawardIcon}
          onClick={(row) => handleUnawardOfferClick.bind(this, row)}
          warning
        />,
      ];
    return [];
  };

  const emptyState = () => {
    return (
      <div className={classes.emptyState}>
        <EmptyStateIcon />
        <h1>There are no additional offers to add to this truck lane</h1>
      </div>
    );
  };

  return (
    <div>
      {logisticDetails}
      <TableWithDrawer
        className={{ container: classes.border, table: classes.removePadding }}
        table={
          <div>
            <div className={classes.tableBorder}>
              <StickyTableTitle title={'Currently Awarded'}>
                <NakedButton
                  onClick={handleUnawardAllClick}
                  className={classes.unawardAll}
                  icon={UnawardIcon}
                  loading={unawardOfferListingsByTruckLaneLoading}
                  disabled={loading || unawardOfferListingsByTruckLaneLoading || !awardedInventory.length}
                >
                  Unaward All
                </NakedButton>
              </StickyTableTitle>
              <DataTableNaked
                data={awardedInventory}
                columns={awardedColumns}
                loading={loading}
                sticky
                onRowClick={handleRowClick}
                rowActions={rowActions()}
                className={{ tableFooter: classes.tableFooter }}
                stickyOffset={62}
                stickyFooter={false}
                highlightRowId={selectedInventory?._id}
                noDataMessage=""
                cypressTagTable="offer-review-trucklane-table"
              />
            </div>
            <div className={classes.tableBorder}>
              <StickyTableTitle title="Other offers for this truck lane" />
              <DataTableNaked
                data={fillupInventory}
                columns={fillupColumns}
                loading={loading}
                sticky
                onRowClick={handleRowClick}
                className={{ tableFooter: classes.tableFooter }}
                stickyOffset={62}
                stickyFooter={false}
                highlightRowId={selectedInventory?._id}
                noDataMessage={emptyState()}
                cypressTagTable="offer-review-trucklane-other-offers-table"
              />
            </div>
          </div>
        }
        cardList={
          <div className={classes.offerCardBorder}>
            <OfferListings user={user} inventoryId={selectedInventory?.id} description={selectedInventory?.description} fullstoryTag={fullstoryTag} />
          </div>
        }
      />
    </div>
  );
};

OfferReviewDetailsTrucklane.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default OfferReviewDetailsTrucklane;
