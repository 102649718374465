import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Pill, DragDrop, StickyFooter, Theme as theme, FileXlsxIcon, GraphQLDataTable, AlertService } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import downloadTemplateButtonStyle from '../download-template-style';
import { ActivityImportQuery } from '../../graphql/queries';
import { ActivityImportService, TitleService } from '../../services';
import { getColumns } from './bulk-import-columns';

const styles = {
  bulk__import: {
    marginBottom: 68,
  },
  import__info: {
    fontSize: '0.875rem',
    marginBottom: 24,
  },
  import__steps: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  import__card: {
    width: 'calc(1/3*100% - (1 - 1/3) * 16px)',
    height: 181,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  'import__card-container': {
    padding: 17,
  },
  card__title: {
    margin: 0,
    padding: 0,
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: '13px',
    textAlign: 'center',
  },
  card__text: {
    fontSize: '0.875rem',
    lineHeight: '1.1875rem',
    marginBottom: '1.25rem',
  },
  button__container: {
    textAlign: 'center',
  },
  card__button: {
    display: 'inline-flex !important',
  },
  drop__zone: {
    borderRadius: theme.borderRadius,
    border: theme.dragDropBorder,
    margin: 5,
    padding: 10,
    height: 'calc(100% - 10px)',
  },
  drop__container: {
    display: 'block',
    height: '100%',
  },
  drop__info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: 4,
  },
  drop__icon: {
    width: 40,
    height: 44,
    marginTop: 12,
    marginRight: 29,
  },
  'drop__text--container': {
    textAlign: 'left',
  },
  drop__text: {
    color: theme.textColorPrimary,
    fontSize: '1.25rem',
    margin: 0,
  },
  'drop__text--suffix': {
    fontSize: '0.875rem',
    color: '#9e9e9e',
    margin: 0,
  },
  drop__button: {
    marginTop: 8,
  },
  input__hidden: {
    display: 'none',
  },
  drop__pills: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100% - 40px)',
  },
  import__data: {
    flexGrow: 1,
    margin: '41px 0 16px',
  },
  download__template__button: downloadTemplateButtonStyle,
  '@media (max-width: 1279px)': {
    import__steps: {
      flexWrap: 'wrap',
    },
    import__card: {
      width: '100%',
      height: 'auto',
      marginBottom: 16,
    },
  },
};

@injectSheet(styles)
export default class BulkActivityImport extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('Bulk Data Import', 'Activity Import');
    this.state = {
      files: [],
      loading: false,
    };

    this.setFileInput = this.setFileInput.bind(this);
    this.browseFiles = this.browseFiles.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.uploadImport = this.uploadImport.bind(this);
    this.finishUpload = this.finishUpload.bind(this);
    this.uploadErrored = this.uploadErrored.bind(this);
  }

  get accepts() {
    return ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  }

  get isValid() {
    return this.state.files.length === 1;
  }

  browseFiles(ev) {
    ev.preventDefault();
    this.fileInput.click();
  }

  setFileInput(el) {
    this.fileInput = el;
  }

  handleDrop(ev) {
    this.processFiles(ev.dataTransfer.files);
  }

  handleFileChange(ev) {
    this.processFiles(ev.target.files);
  }

  processFiles(fileList) {
    const files = Array.from(fileList);
    this.setState({ files });
  }

  removeFile(fileName) {
    const { files } = this.state;
    files.splice(
      this.state.files.findIndex((file) => file.name === fileName),
      1
    );
    this.setState({ files });
  }

  uploadImport() {
    this.setState({ loading: true });
    ActivityImportService.import(this.state.files[0]).then(this.finishUpload).catch(this.uploadErrored);
  }

  finishUpload(response) {
    if (response.ok || response.data) {
      const message = 'Your import is uploaded and is now processing.';
      AlertService.alert({ type: 'info', message: <span>{message}</span>, autoDismiss: true, dismissDelay: 5000 });
      this.setState({ files: [], loading: false });
    } else {
      this.uploadErrored();
    }
  }

  uploadErrored() {
    const message =
      'Sorry we are unable to import your donations. If this problem persists, please contact a Spoiler Alert Administrator to help you.';
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
    this.setState({ files: [], loading: false });
  }

  render() {
    const { classes, user } = this.props;
    return (
      <div className={classes.bulk__import}>
        <p className={classes.import__info}>
          You can import nonprofit donation and waste record data in your Spoiler Alert account using an Excel file.
        </p>
        <div className={classes.import__steps}>
          <Card noPadding className={classes.import__card}>
            <div className={classes['import__card-container']}>
              <h3 className={classes.card__title}>1. Download Excel Template</h3>
              <p className={classes.card__text}>The Excel file specifies the data fields you will need to provide for each transaction.</p>
              <div className={classes.button__container}>
                <a href={user.site.transactionImportFileUrl} className={classes.download__template__button}>
                  Download Template
                </a>
              </div>
            </div>
          </Card>
          <Card noPadding className={classes.import__card}>
            <div className={classes['import__card-container']}>
              <h3 className={classes.card__title}>2. Add Your Data</h3>
              <p className={classes.card__text}>
                To make this go as smoothly as possible, add your data to the Excel template provided, or if you are using your own file, make sure
                the headers map to the column headers in the template.
              </p>
            </div>
          </Card>
          <Card noPadding className={classes.import__card}>
            <DragDrop className={classes.drop__zone} accepts={this.accepts} onDrop={this.handleDrop}>
              <div className={classes.drop__container}>
                <h3 className={classes.card__title}>3. Upload Your File</h3>
                {this.state.files.length === 0 && (
                  <div className={classes.drop__info}>
                    <FileXlsxIcon className={classes.drop__icon} />
                    <div className={classes['drop__text--container']}>
                      <p className={classes.drop__text}>Drag and Drop</p>
                      <p className={classes['drop__text--suffix']}>your excel file, or</p>
                      <Button type="button" secondary className={classes.drop__button} onClick={this.browseFiles}>
                        Browse Files
                      </Button>
                      <span className={classes.input__hidden}>
                        <input type="file" ref={this.setFileInput} onChange={this.handleFileChange} accept={this.accepts.join(',')} />
                      </span>
                    </div>
                  </div>
                )}
                {this.state.files.length > 0 && (
                  <div className={classes.drop__pills}>
                    {this.state.files.map((file, i) => (
                      <Pill key={`.pill-${i}`} fileName={file.name} file={file} onCancel={this.removeFile} />
                    ))}
                  </div>
                )}
              </div>
            </DragDrop>
          </Card>
        </div>
        <div className={classes.import__data}>
          <GraphQLDataTable
            query={ActivityImportQuery}
            queryName="currentUserQuery"
            queryField="activityImports"
            transition
            columns={getColumns(this.props.user)}
            filters={[]}
            pagination
            perPage={15}
            pollInterval={5000}
            perPageIncrements={[15, 30, 50, 100]}
          />
        </div>
        <StickyFooter>
          <Button
            type="submit"
            secondary
            onClick={this.uploadImport}
            disabled={!this.isValid}
            loading={this.state.loading}
            loadingText="Processing..."
          >
            Import Transactions
          </Button>
        </StickyFooter>
      </div>
    );
  }
}

BulkActivityImport.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
};
