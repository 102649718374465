import accounting from 'accounting';
import React, { useState, useEffect } from 'react';
import {
  AlertService,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Timing,
  NegotiateIcon,
  AwardIcon,
  UnawardIcon,
  MiniTooltip,
  QuickEntry,
  icons,
  PriceIndicator,
  MultiButton,
  TextBoxWithLabel,
  ChevronIcon,
  WandIcon,
  Theme,
} from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { event } from 'react-fullstory';
import { negotiationOrigin } from '../../enums';
import { AwardSummaryQuery, OfferListingsByInventoryQuery, StagedNegotiationSummaryQuery, trucklanePollingQuery } from '../../graphql/queries';
import {
  AwardOfferListing,
  UnawardOfferListing,
  UnawardAndNegotiateOfferListing,
  createNegotiationStagedListings,
  IgnoreOfferListings,
  RestoreOfferListings,
} from '../../graphql/mutations';
import deleteCachedFieldsOnUserQuery from '../../apollo/cache-helpers/delete-cached-fields-on-user-query';
import destinationType from '../../enums/staged-inventory-destination-type';
import { Field } from './helpers';
import styles from './offer-card-v2-styles';
import { weightWithUnit } from '../../utilities/unit-helper';
import { NegotiationStrings } from '../../string-resources';
import deleteCachedFieldsOnOfferReviewPollingQuery from '../../apollo/cache-helpers/delete-cached-fields-on-offer-review-polling-query';

const CYPRESS_TAGS = {
  OFFER_CARD_NEGOTIATE_BUTTON: 'offer-card-negotiate-button',
  OFFER_CARD_AWARD_BUTTON: 'offer-card-award-button',
  OFFER_CARD_NEGOTIATE_QUICK_ENTRY_INPUT: 'offer-card-negotiate-quick-entry-input',
  OFFER_CARD_NEGOTIATE_QUICK_ENTRY_SUBMIT: 'offer-card-negotiate-quick-entry-submit',
};

const useStyles = createUseStyles(styles, { name: 'OfferCard' });

const OfferCardV2 = ({ disabled, setOfferCardsEnabledState, offerInfo, user, fullstoryTag, showSuggestions = false }) => {
  const classes = useStyles();
  const [quantity, setQuantity] = useState(0);
  const [pricePerCase, setPricePerCase] = useState(0);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [ignoreInProgress, setIgnoreInProgress] = useState(false);
  const [negotiationInProgress, setNegotiationInProgress] = useState(false);
  const [chevronDirection, setChevronDirection] = useState('down');
  const initialPricePerCase = offerInfo.totalPrice / offerInfo.quantity;
  const [activeTruckLane, setActiveTruckLane] = useState(offerInfo.activeTruckLane);
  const refetchQueries = (negotiation = false) => {
    const queries = [
      {
        query: AwardSummaryQuery,
      },
      {
        query: OfferListingsByInventoryQuery,
        variables: { inventoryIds: [offerInfo.inventory._id] },
      },
    ];
    if (negotiation) {
      queries.push({
        query: StagedNegotiationSummaryQuery,
        variables: { buyerSiteIds: [offerInfo.offer.buyerSite._id] },
      });
    }
    if (showSuggestions) {
      queries.push({
        query: trucklanePollingQuery,
        variables: { pollingDetails: { origin: offerInfo.inventory.siteId, truckType: offerInfo.inventory.truckType } },
      });
    }
    return queries;
  };

  const [unawardOfferListing] = useMutation(UnawardOfferListing);
  const [awardOfferListing] = useMutation(AwardOfferListing);
  const [ignoreOfferListing] = useMutation(IgnoreOfferListings);
  const [restoreOfferListing] = useMutation(RestoreOfferListings);
  const [negotiateOfferListing] = useMutation(createNegotiationStagedListings);
  const [unawardAndNegotiateOfferListing] = useMutation(UnawardAndNegotiateOfferListing);
  const isAwarded = () => offerInfo.status === 'AWARDED';
  const initialQuantity = isAwarded() === true ? offerInfo.quantity : Math.min(offerInfo.quantity, offerInfo.inventory.availableQuantity);

  useEffect(() => {
    setQuantity(isAwarded() === true ? offerInfo.quantity : Math.min(offerInfo.quantity, offerInfo.inventory.availableQuantity));
    setPricePerCase(offerInfo.totalPrice / offerInfo.quantity);
    setActiveTruckLane(offerInfo.activeTruckLane);
    refetchQueries();
  }, [offerInfo.quantity, offerInfo.totalPrice, offerInfo.inventory.availableQuantity, offerInfo.activeTruckLane]);

  const isNegotiationStaged = () => offerInfo.negotiationStatus === 'STAGED';

  const isNegotiationPublished = () => offerInfo.negotiationStatus === 'PUBLISHED';

  const isNegotiationReceived = () => offerInfo.negotiationStatus === 'RECEIVED';

  const isIgnored = () => offerInfo.status === 'IGNORED';

  const isDonation = () => offerInfo?.destinationType === destinationType.donation;

  const isSuggested = () => showSuggestions && !isAwarded() && offerInfo?.suggestions?.award?.suggested;

  const canAward = () => {
    if (quantity > offerInfo.inventory.availableQuantity) return false;
    if (quantity === 0) return false;
    if (!(pricePerCase > 0) && !isDonation()) return false;
    if (typeof pricePerCase !== 'number') return false;
    return true;
  };

  const error = (message) => {
    AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{message}</span> });
  };

  const handleAward = Timing.throttle(() => {
    if (actionInProgress || !canAward()) return;
    setActionInProgress(true);
    setOfferCardsEnabledState(true);
    awardOfferListing({
      variables: { id: offerInfo._id, quantity, costPerCase: pricePerCase.toString() },
      update: (cache) => {
        deleteCachedFieldsOnUserQuery(cache, ['awardedOfferListings', 'awardedInventoryFilterParameters']);
        deleteCachedFieldsOnOfferReviewPollingQuery(cache);
      },
      refetchQueries: refetchQueries(),
    })
      .then((res) => {
        if (res.data.awardOfferListing.errors && res.data.awardOfferListing.errors.length > 0) {
          error(res.data.awardOfferListing.errors[0].message);
        }
        event(`Offer was awarded ${fullstoryTag || ''}`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      })
      .catch(() => {
        error(
          'We were unable to award this offer due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.'
        );
      })
      .finally(() => {
        setOfferCardsEnabledState(false);
        setShowWarningModal(false);
        setActionInProgress(false);
      });
  }, 1000);

  const handleUnaward = Timing.throttle(() => {
    if (actionInProgress) return;
    setQuantity(offerInfo.originalQuantity);
    setActionInProgress(true);
    unawardOfferListing({
      variables: { id: offerInfo._id },
      update: (cache) => deleteCachedFieldsOnUserQuery(cache, ['awardedOfferListings', 'awardedInventoryFilterParameters']),
      refetchQueries: refetchQueries(),
    })
      .then(() => {
        event(`Offer was unawarded ${fullstoryTag || ''}`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      })
      .catch(() => {
        error('Sorry there was an error unawarding your offer. If this problem persists, please contact a Spoiler Alert Administrator to help you.');
      })
      .finally(() => {
        setActionInProgress(false);
      });
  }, 1000);

  const handleIgnore = Timing.throttle(() => {
    if (actionInProgress || ignoreInProgress) return;
    setIgnoreInProgress(true);
    ignoreOfferListing({
      variables: { offerListingIds: [offerInfo._id] },
      refetchQueries: refetchQueries(),
    })
      .then((ignoreResp) => {
        if (ignoreResp.data?.ignoreOfferListingsById?.errors?.length) return error(ignoreResp.data?.ignoreOfferListingsById?.errors[0].message);
        return event(`Offer was ignored`, {
          userId: user._id,
          offerId: offerInfo._id,
          offerStatus: offerInfo.status,
          negotiationStatus: offerInfo.negotiationStatus,
        });
      })
      .catch(() =>
        error('Sorry there was an error ignoring your offer. If this problem persists, please contact a Spoiler Alert Administrator to help you.')
      )
      .finally(() => {
        setIgnoreInProgress(false);
      });
  }, 1000);

  const handleRestore = Timing.throttle(() => {
    if (actionInProgress || ignoreInProgress) return;
    setIgnoreInProgress(true);
    restoreOfferListing({
      variables: { offerListingIds: [offerInfo._id] },
      refetchQueries: refetchQueries(),
    })
      .then((restoreResp) => {
        if (restoreResp.data?.restoreOfferListingsById?.errors?.length) return error(restoreResp.data?.restoreOfferListingsById?.errors[0].message);
        return event(`Offer was restored`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      })
      .catch(() =>
        error('Sorry there was an error restoring your offer. If this problem persists, please contact a Spoiler Alert Administrator to help you.')
      )
      .finally(() => {
        setIgnoreInProgress(false);
      });
  }, 1000);

  const handleNegotiation = Timing.throttle(() => {
    if (actionInProgress || negotiationInProgress) return;
    setNegotiationInProgress(true);
    negotiateOfferListing({
      variables: { counterOffers: [{ offerListingId: offerInfo._id }], negotiationOrigin: negotiationOrigin.OFFER_CARD_PRIMARY },
      refetchQueries,
    })
      .then((response) => {
        if (response.data?.createNegotiationStagedListings?.errors?.length)
          return error(response.data?.createNegotiationStagedListings?.errors[0].message);
        return event(`OC - Negotiation Staged`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      })
      .catch(() =>
        error('Sorry there was an error negotiating your offer. If this problem persists, please contact a Spoiler Alert Administrator to help you.')
      )
      .finally(() => {
        setNegotiationInProgress(false);
      });
  }, 1000);

  const handleUnawardAndNegotitate = Timing.throttle(() => {
    if (actionInProgress || negotiationInProgress) return;
    setNegotiationInProgress(true);
    unawardAndNegotiateOfferListing({
      variables: { id: offerInfo._id, negotiationOrigin: negotiationOrigin.OFFER_CARD_PRIMARY },
      refetchQueries,
    })
      .then((response) => {
        if (response.data?.unawardAndNegotiateOfferListing?.errors?.length)
          return error(response.data?.unawardAndNegotiateOfferListing?.errors[0].message);
        return event(`Offer was unawarded and negotiatiated`, {
          userId: user._id,
          offerId: offerInfo._id,
          negotiationOrigin: negotiationOrigin.OFFER_CARD_PRIMARY,
        });
      })
      .catch(() =>
        error(
          'Sorry there was an error unawarding and negotiating your offer. If this problem persists, please contact a Spoiler Alert Administrator to help you.'
        )
      )
      .finally(() => {
        setNegotiationInProgress(false);
      });
  }, 1000);

  const checkAwardQuantity = () => {
    if (quantity === 0) return;
    if (quantity > offerInfo.listing.originalQuantity) {
      setShowWarningModal(true);
    } else {
      handleAward();
    }
  };

  const adjustQuantity = (newQuantity) => {
    setQuantity(accounting.unformat(newQuantity));
  };

  const adjustPricePerCase = (newPricePerCase) => {
    setPricePerCase(accounting.unformat(newPricePerCase));
  };

  const inputsDisabled = () => {
    return (
      disabled ||
      actionInProgress ||
      ignoreInProgress ||
      negotiationInProgress ||
      isAwarded() ||
      isIgnored() ||
      quantity > offerInfo.inventory.availableQuantity ||
      offerInfo.inventory.availableQuantity <= 0
    );
  };

  const getLogisticsRelationshipName = () => {
    if (!offerInfo.logisticsRelationshipName) return '';
    return offerInfo.logisticsRelationshipName
      .replace('Delivery to ', '')
      .replace('Pickup for ', '')
      .replace(/^Default$/, '');
  };

  const isDisabled = () => disabled || (offerInfo.inventory.availableQuantity === 0 && !isAwarded());

  const onQuickEntryError = () => {
    error(NegotiationStrings.genericError);
  };

  const onQuickEntryResponse = (result) => {
    if (result?.createNegotiationStagedListings?.errors?.length > 0) {
      onQuickEntryError();
    } else {
      const negotiationQuantityChanged = quantity !== result?.createNegotiationStagedListings?.negotiationStagedListings[0].suggestedQuantity;
      const negotiationPriceChanged = pricePerCase !== result?.createNegotiationStagedListings?.negotiationStagedListings[0].suggestedUnitPrice;
      if (!negotiationQuantityChanged && !negotiationPriceChanged)
        event(`OC - Negotiation Staged Secondary - No Adjustment`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      else if (negotiationQuantityChanged && negotiationPriceChanged)
        event(`OC - Negotiation Staged - Price & Qty Adjusted`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      else if (negotiationQuantityChanged)
        event(`OC - Negotiation Staged - Qty Adjusted`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      else if (negotiationPriceChanged)
        event(`OC - Negotiation Staged - Price Adjusted`, {
          userId: user._id,
          offerId: offerInfo._id,
        });

      const message = `Negotiation started with ${offerInfo.offer.buyerSite.siteName}`;
      AlertService.alert({ type: 'success', message: <span>{message}</span>, autoDismiss: true });
    }
  };

  const renderNegotiateButton = (renderUnawardAndNegotiate) => {
    if (renderUnawardAndNegotiate) {
      return (
        <>
          <MultiButton
            primaryAction={{ onClick: handleUnawardAndNegotitate, tooltipText: 'Unaward & Negotiate' }}
            secondaryTooltipText={'Unaward & Adjust Counter Offer'}
            disabled={
              isIgnored() ||
              actionInProgress ||
              ignoreInProgress ||
              negotiationInProgress ||
              quantity > offerInfo.inventory.availableQuantity ||
              offerInfo.inventory.availableQuantity <= 0
            }
            loading={negotiationInProgress}
            secondary
            primaryIcon={NegotiateIcon}
            cypressTag={CYPRESS_TAGS.OFFER_CARD_NEGOTIATE_BUTTON}
            child={
              <QuickEntry
                buttonText="Negotiate"
                mutation={UnawardAndNegotiateOfferListing}
                mutationArgs={{ id: offerInfo._id, negotiationOrigin: negotiationOrigin.OFFER_CARD_SECONDARY }}
                quickEntryArgs={[
                  { name: 'counterOfferQuantity', value: quantity, labelText: 'COUNTER QTY', max: offerInfo.listing.originalQuantity },
                  { name: 'counterOfferPrice', value: pricePerCase, labelText: 'COUNTER PRICE', type: 'money' },
                ]}
                onResponse={onQuickEntryResponse}
                onError={onQuickEntryError}
                className={`${classes.quickEntry} awarded`}
                icon={NegotiateIcon}
                refetchQueries={refetchQueries(true)}
                cypressTags={{
                  input: CYPRESS_TAGS.OFFER_CARD_NEGOTIATE_QUICK_ENTRY_INPUT,
                  submit: CYPRESS_TAGS.OFFER_CARD_NEGOTIATE_QUICK_ENTRY_SUBMIT,
                }}
              />
            }
          />
        </>
      );
    }
    return (
      <>
        <MultiButton
          primaryAction={{ onClick: handleNegotiation, tooltipText: 'Negotiate' }}
          secondaryTooltipText={'Adjust Counter Offer'}
          disabled={
            isIgnored() ||
            actionInProgress ||
            ignoreInProgress ||
            negotiationInProgress ||
            quantity > offerInfo.inventory.availableQuantity ||
            offerInfo.inventory.availableQuantity <= 0
          }
          loading={negotiationInProgress}
          secondary
          icon={NegotiateIcon}
          cypressTag={CYPRESS_TAGS.OFFER_CARD_NEGOTIATE_BUTTON}
          child={
            <QuickEntry
              buttonText="Negotiate"
              mutation={createNegotiationStagedListings}
              mutationArgs={{
                counterOffers: [{ offerListingId: offerInfo._id, counterOfferQuantity: quantity, counterOfferPrice: pricePerCase }],
                negotiationOrigin: negotiationOrigin.OFFER_CARD_SECONDARY,
              }}
              quickEntryArgs={[
                { name: 'counterOffers.0.counterOfferQuantity', value: quantity, labelText: 'COUNTER QTY', max: offerInfo.listing.originalQuantity },
                { name: 'counterOffers.0.counterOfferPrice', value: pricePerCase, labelText: 'COUNTER PRICE', type: 'money' },
              ]}
              onResponse={onQuickEntryResponse}
              onError={onQuickEntryError}
              className={classes.quickEntry}
              icon={NegotiateIcon}
              refetchQueries={refetchQueries(true)}
              defaultValue={pricePerCase}
              cypressTags={{
                input: CYPRESS_TAGS.OFFER_CARD_NEGOTIATE_QUICK_ENTRY_INPUT,
                submit: CYPRESS_TAGS.OFFER_CARD_NEGOTIATE_QUICK_ENTRY_SUBMIT,
              }}
            />
          }
        ></MultiButton>
      </>
    );
  };

  const getIgnoreTooltipText = () => {
    if (!isAwarded() && offerInfo.negotiationStatus === 'STAGED') return 'Unstage & Ignore';
    return 'Ignore';
  };

  const renderIgnoreOrRestore = () => {
    return offerInfo.status === 'IGNORED' ? (
      <MiniTooltip text="Restore">
        <Button
          className={classes.ignoreRestore__button}
          onClick={handleRestore}
          disabled={ignoreInProgress || actionInProgress || negotiationInProgress}
          loading={ignoreInProgress}
          icon={icons.restore}
          readOnly={inputsDisabled()}
          secondary
        />
      </MiniTooltip>
    ) : (
      <MiniTooltip text={getIgnoreTooltipText()}>
        <Button
          className={classes.ignoreRestore__button}
          onClick={handleIgnore}
          disabled={ignoreInProgress || actionInProgress || negotiationInProgress || isAwarded()}
          loading={ignoreInProgress}
          icon={icons.ignore}
          readOnly={inputsDisabled()}
          secondary
        />
      </MiniTooltip>
    );
  };

  const renderButtons = () => (
    <div className={classes.offerCardButtons}>
      {isAwarded() ? (
        <>
          {renderIgnoreOrRestore()}
          {isDonation() ? false : renderNegotiateButton(true)}
          <MiniTooltip text="Unaward">
            <Button
              inline
              warning
              onClick={handleUnaward}
              className={classes.unawardButton}
              icon={UnawardIcon}
              loading={actionInProgress}
              cypressTag={CYPRESS_TAGS.OFFER_CARD_UNAWARD_BUTTON}
              disabled={actionInProgress || ignoreInProgress || negotiationInProgress}
            />
          </MiniTooltip>
        </>
      ) : (
        <>
          {renderIgnoreOrRestore()}
          {isDonation() ? false : renderNegotiateButton(false)}
          <MiniTooltip text="Award" disabled={inputsDisabled()}>
            <Button
              onClick={checkAwardQuantity}
              disabled={inputsDisabled() || !canAward()}
              loading={actionInProgress}
              icon={AwardIcon}
              readOnly={inputsDisabled()}
              cypressTag={CYPRESS_TAGS.OFFER_CARD_AWARD_BUTTON}
              className={classes.unawardButton}
            />
          </MiniTooltip>
        </>
      )}
    </div>
  );

  const offerAwarded = offerInfo.status === 'AWARDED';
  const summedRevenue = (activeTruckLane?.totalRevenue || 0) + (offerAwarded ? 0 : quantity * pricePerCase);
  const summedWeight = (activeTruckLane?.totalWeight || 0) + (offerAwarded ? 0 : quantity * offerInfo.inventory.unitGrossWeight);

  const getTotalPrice = () => quantity * pricePerCase;

  const estimatedNetOffer = () => getTotalPrice() - (offerInfo.logisticsCost / offerInfo.quantity) * quantity;

  const wrapperClass = () => {
    let classString = `${classes.offerCardWrapper}`;
    if (isAwarded()) classString += ' awarded';
    else if (isNegotiationPublished()) classString += ' negotiated';
    else if (isNegotiationStaged()) classString += ' negotiationStaged';
    else if (isNegotiationReceived()) classString += ' negotiationReceived';
    else if (isDisabled()) classString += ' disabled';
    if (isIgnored()) classString = `${classes.offerCardWrapper} ignored`;
    return classString;
  };

  const onTrucklaneClick = () => {
    if (chevronDirection === 'down') {
      event(`OC - Trucklane Info Expanded`, {
        userId: user._id,
        offerId: offerInfo._id,
      });
      setChevronDirection('up');
    }
    if (chevronDirection === 'up') setChevronDirection('down');
  };

  const getTotalPallets = () => {
    if (isAwarded()) return activeTruckLane?.totalPallets;
    return (activeTruckLane?.totalPallets || 0) + quantity / offerInfo.inventory.casesPerPallet;
  };

  const getLaneCostCell = (value) => {
    if (value > 0) {
      return <span className={classes.trucklaneCellNegative}>({accounting.formatMoney(value)})</span>;
    }
    return <span className={classes.trucklaneCell}>{accounting.formatMoney(value)}</span>;
  };

  const getNetRevenueCell = (value) => {
    if (value < 0) {
      return <span className={classes.trucklaneCellNegative}>({accounting.formatMoney(-1 * value)})</span>;
    }
    return <span className={classes.trucklaneCell}>{accounting.formatMoney(value)}</span>;
  };

  const renderExpandedTrucklane = () => (
    <div className={classes.expandedTrucklaneContainer}>
      <div className={classes.firstTrucklaneRow}>
        <span className={classes.trucklaneCell}></span>
        <span className={classes.header}>CURRENTLY</span>
        <span className={classes.header}>THIS OFFER</span>
        <span className={classes.header}>TOTAL</span>
      </div>
      <div className={classes.trucklaneRowWithBorder}>
        <span className={classes.header}>WEIGHT</span>
        <span className={classes.trucklaneCell}>{weightWithUnit(activeTruckLane?.totalWeight, user, true)}</span>
        <span className={classes.trucklaneCell}>{weightWithUnit(offerInfo.inventory.unitGrossWeight * quantity, user, true)}</span>
        <span className={classes.trucklaneCell}>{weightWithUnit(summedWeight, user, true)}</span>
      </div>
      <div className={classes.trucklaneRowWithBorder}>
        <span className={classes.header}>PALLETS</span>
        <span className={classes.trucklaneCell}>{accounting.formatNumber(activeTruckLane?.totalPallets)}</span>
        <span className={classes.trucklaneCell}>{accounting.formatNumber(quantity / offerInfo.inventory.casesPerPallet)}</span>
        <span className={classes.trucklaneCell}>{accounting.formatNumber(getTotalPallets())}</span>
      </div>
      <div className={classes.trucklaneRowWithBorder}>
        <span className={classes.header}>REVENUE</span>
        <span className={classes.trucklaneCell}>{accounting.formatMoney(activeTruckLane?.totalRevenue)}</span>
        <span className={classes.trucklaneCell}>{accounting.formatMoney(quantity * pricePerCase)}</span>
        <span className={classes.trucklaneCell}>{accounting.formatMoney(summedRevenue)}</span>
      </div>
      <div className={classes.trucklaneRowWithBorder}>
        <span className={classes.header}>LANE COST</span>
        {getLaneCostCell(activeTruckLane ? offerInfo.trucklaneCost || 0 : 0)}
        {getLaneCostCell(activeTruckLane ? 0 : offerInfo.trucklaneCost || 0)}
        {getLaneCostCell(offerInfo.trucklaneCost || 0)}
      </div>
      <div className={classes.trucklaneRowNetRevenue}>
        <span className={classes.headerNetRevenue}>NET REVENUE</span>
        {getNetRevenueCell(activeTruckLane?.totalRevenue - (activeTruckLane ? offerInfo.trucklaneCost || 0 : 0))}
        {getNetRevenueCell(quantity * pricePerCase - (activeTruckLane ? 0 : offerInfo.trucklaneCost || 0))}
        {getNetRevenueCell(summedRevenue - (offerInfo.trucklaneCost || 0))}
      </div>
    </div>
  );

  const getAdditionalQuanityInfo = () => {
    if (isAwarded() || isNegotiationReceived()) return `ORIGINAL QTY: ${accounting.formatNumber(offerInfo.originalQuantity)}`;
    if (isNegotiationStaged() || isNegotiationPublished()) {
      if (
        !offerInfo.negotiations ||
        !offerInfo.negotiations[offerInfo.negotiations?.length - 1]?.negotiationHistory[
          offerInfo.negotiations[offerInfo.negotiations?.length - 1].negotiationHistory?.length - 1
        ].suggestedQuantity
      )
        return null;
      return `COUNTER QTY: ${accounting.formatNumber(
        offerInfo.negotiations[offerInfo.negotiations?.length - 1]?.negotiationHistory[
          offerInfo.negotiations[offerInfo.negotiations?.length - 1].negotiationHistory?.length - 1
        ].suggestedQuantity
      )}`;
    }
    return null;
  };

  const getAdditionalSuggestedInfo = () => {
    if (isSuggested()) return `SUGGESTED QTY: ${accounting.formatNumber(offerInfo.suggestions.award.quantity)}`;
    return null;
  };

  const getAdditionalPriceInfo = () => {
    if (isAwarded() || isNegotiationReceived())
      return `ORIGINAL PRICE: ${accounting.formatMoney(offerInfo.originalTotalPrice / offerInfo.originalQuantity)}`;
    if (isNegotiationStaged() || isNegotiationPublished()) {
      if (
        !offerInfo.negotiations ||
        !offerInfo.negotiations[offerInfo.negotiations?.length - 1]?.negotiationHistory[
          offerInfo.negotiations[offerInfo.negotiations?.length - 1].negotiationHistory?.length - 1
        ].suggestedUnitPrice
      )
        return null;
      return `COUNTER PRICE: ${accounting.formatMoney(
        offerInfo.negotiations[offerInfo.negotiations?.length - 1]?.negotiationHistory[
          offerInfo.negotiations[offerInfo.negotiations?.length - 1].negotiationHistory?.length - 1
        ].suggestedUnitPrice
      )}`;
    }
    return null;
  };

  const getQuantity = () => {
    if ((!offerInfo.inventory.availableQuantity && isAwarded()) || !quantity) return accounting.formatNumber(initialQuantity);
    if (!offerInfo.inventory.availableQuantity) return '0';
    return accounting.formatNumber(quantity);
  };

  const renderInputs = () => (
    <div className={`${classes.offerCardEditable} ${isIgnored() ? 'ignored' : ''}`}>
      <TextBoxWithLabel
        isValid={(value) => {
          const number = accounting.unformat(value);
          return number <= offerInfo.inventory.availableQuantity && number > 0;
        }}
        onChange={(newQuantity) => adjustQuantity(newQuantity)}
        disabled={isAwarded() || isDisabled() || isIgnored()}
        type="text"
        label="QTY"
        value={getQuantity()}
        denominator={accounting.formatNumber(offerInfo.listing.originalQuantity)}
        className={isDonation() ? classes.donationTextboxWithDenominator : classes.textboxWithDenominator}
        additionalInfoTop={getAdditionalSuggestedInfo()}
        additionalInfoTopClassName={classes.textboxWithNumerator}
        additionalInfo={getAdditionalQuanityInfo()}
        additionalInfoClassName={classes.textboxWithDenominator}
        id={'QuantityTextBox'}
      />
      {!isDonation() && (
        <TextBoxWithLabel
          isValid={(value) => accounting.unformat(value) > 0}
          onChange={(newPricePerCase) => adjustPricePerCase(newPricePerCase)}
          disabled={isAwarded() || isDisabled() || isIgnored()}
          type="text"
          label="PRICE"
          value={pricePerCase !== 0 ? accounting.formatMoney(pricePerCase) : accounting.formatMoney(initialPricePerCase)}
          additionalInfo={getAdditionalPriceInfo()}
          additionalInfoClassName={classes.additionalInfoPrice}
          id={'PriceTextBox'}
        />
      )}
    </div>
  );

  return (
    <div className={wrapperClass()} data-cy="offer-card-wrapper" data-testid="offer-card">
      {!isAwarded() && isNegotiationStaged() && !isDonation() && !isIgnored() && (
        <div className={`${classes.offerCardNegotiationTag} ${classes.offerCardNegotiationTagDark}`}>Negotiation Staged</div>
      )}
      {!isAwarded() && isNegotiationPublished() && !isIgnored() && <div className={classes.offerCardNegotiationTag}>Negotiation Published</div>}
      {!isAwarded() && isNegotiationReceived() && !isIgnored() && (
        <div className={`${classes.offerCardNegotiationTag} ${classes.offerCardNegotiationTagBlue}`}>Negotiation Received</div>
      )}
      {isAwarded() && <div className={classes.offerCardAwardedTag}>Awarded</div>}
      {isIgnored() && <div className={classes.offerCardIgnoredTag}>Ignored</div>}
      <div className={`${classes.offerCardHeader} ${isIgnored() ? 'ignored' : ''}`}>
        <div className={classes.offerCardTitles}>
          <h4>{`${isDonation() ? 'Donation: ' : ''}${offerInfo.offer.buyerSite.siteName}`}</h4>
          {getLogisticsRelationshipName() !== '' && <h5>{getLogisticsRelationshipName()}</h5>}
        </div>
        {isSuggested() && <WandIcon className={classes.wandIcon} />}
      </div>
      {renderInputs()}
      <div style={{ padding: '0px 16px 19px' }}>
        {!isDonation() && (
          <PriceIndicator
            suggestedPrice={offerInfo.listing.suggestedUnitPrice}
            reservePrice={offerInfo.activeReservePrice}
            pricePerCase={pricePerCase || initialPricePerCase}
            tooltipText={
              <div>
                <div>{`${accounting.formatNumber((pricePerCase / offerInfo.listing.suggestedUnitPrice) * 100, 0)}% of suggested price`}</div>
                {offerInfo.activeReservePrice > 0 && (
                  <div>{`${accounting.formatNumber((pricePerCase / offerInfo.activeReservePrice) * 100, 0)}% of reserve price`}</div>
                )}
              </div>
            }
          />
        )}
      </div>
      <div className={activeTruckLane ? classes.trucklane : classes.inactiveTrucklane} onClick={onTrucklaneClick} data-testid="trucklane-info">
        <span className={classes.trucklaneText}>{`${offerInfo.logisticsTerm?.toUpperCase()} TRUCK LANE`}</span>
        <div className={classes.statusContainer}>
          {offerInfo.trucklaneDistance && (
            <span className={classes.trucklaneDistance} id="trucklaneDistance">
              {accounting.formatNumber(offerInfo.trucklaneDistance)} mi.
            </span>
          )}
          <span className={activeTruckLane ? classes.statusText : classes.inactiveStatusText} id="StatusText">
            {activeTruckLane ? 'ACTIVE' : 'INACTIVE'}
          </span>
          <span className={activeTruckLane ? classes.chevronContainer : classes.inactiveChevronContainer} id="ChevronContainer">
            <ChevronIcon className={classes.chevron} direction={chevronDirection} id="Chevron" />
          </span>
        </div>
      </div>
      {chevronDirection === 'up' && renderExpandedTrucklane()}
      <div className={`${classes.offerCardFooter} ${isIgnored() ? 'ignored' : ''}`}>
        <Field className={`${classes.offerCardTotalField} ${isIgnored() ? 'ignored' : ''}`} label="Total Offer" color={Theme.greyDark}>
          <br />
          <span className={classes.offerCardTotal}>{accounting.formatMoney(getTotalPrice())}</span>
        </Field>
        {offerInfo.logisticsCost > 0 && (
          <Field className={`${classes.offerCardTotalField} ${isIgnored() ? 'ignored' : ''}`} label="Estimated Net Offer">
            <span className={classes.offerCardTotal}>{accounting.formatMoney(estimatedNetOffer())}</span>
          </Field>
        )}
        {renderButtons()}
      </div>
      <Modal onHide={() => setShowWarningModal(false)} open={showWarningModal} closeOnEsc closeOnOutsideClick modalWidth={500}>
        <ModalContent>
          <h2 className={classes.offerCardModalTitle}>Warning</h2>
          <p>The amount you are trying to award is greater than the amount listed. Do you want to award anyway?</p>
        </ModalContent>
        <ModalFooter>
          <Button type="button" onClick={handleAward} primary>
            Award
          </Button>
          <Button type="button" onClick={() => setShowWarningModal(false)} link>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

OfferCardV2.propTypes = {
  disabled: PropTypes.bool,
  setOfferCardsEnabledState: PropTypes.func,
  inventoryId: PropTypes.string,
  offerInfo: PropTypes.object,
  user: PropTypes.object,
  fullstoryTag: PropTypes.string,
  showSuggestions: PropTypes.bool,
};

export default OfferCardV2;
