import LocalizedStrings from 'react-localization';

const DashboardStrings = new LocalizedStrings({
  en: {
    totalMealsTooltip:
      'Meals are calculated by using the finding from USDA "What We Eat in America" stating that an average meal is 1.2 lbs. of food.',
    sellThroughRateTooltip: 'Sell Through Rate is the percentage of available inventory that was sold',
    totalRevenueTooltip: 'Total Revenue from inventory sold through the Spoiler Alert platform',
    bidRateTooltip: 'Bid Rate is the percentage of available inventory that was bid on',
  },
});

export default DashboardStrings;
