import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SignaturePad from 'signature_pad';
import injectSheet from 'react-jss';
import { Card, CardActions, TextInput, Button, Tooltip, AlertService, InformationIcon, Theme as theme, compose } from '@spoiler-alert/ui-library';
import { graphql } from '@apollo/client/react/hoc';
import { SiteSettingsQuery } from '../../graphql/queries';
import { UpdateTaxSettings } from '../../graphql/mutations';
import { GeneralStrings, TaxInfoStrings } from '../../string-resources';
import settingsStyles from './settings-styles';
import formStyles from '../activity/forms/form-styles';

const styles = {
  ...settingsStyles,
  ...formStyles,
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    fontSize: '16px',
    padding: '0 15px',
  },
  settings__index: {
    display: 'flex',
    width: '80%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: 20,
  },
  'tool-tip__text': {
    width: '360px',
    textAlign: 'left',
  },
  signature__buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    margin: '10px 0',
  },
  signature: {
    position: 'relative',
  },
  signature__canvas: {
    borderRadius: theme.borderRadius,
    border: `1px solid ${theme.borderColor}`,
  },
  signature__footer: {
    textAlign: 'center',
    color: theme.borderColor,
    borderTop: '1px solid #000000',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    padding: 4,
    margin: '-30px 30px 0',
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none',
  },
};

@compose(graphql(SiteSettingsQuery), graphql(UpdateTaxSettings, { name: 'updateTaxSettings' }))
@injectSheet(styles)
export default class TaxInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      loading: true,
      saving: false,
      inputError: {
        taxId: false,
        purpose: false,
      },
      taxId: '',
      purpose: '',
      name: '',
      title: '',
      dataChanged: false,
      signature: undefined,
    };

    this.lastCalled = 0;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const data = nextProps.data ? nextProps.data.currentUserQuery : null;
    if (prevState.id !== data._id) {
      return {
        id: data._id,
        loading: nextProps.data.loading,
        taxId: data.site.taxId,
        purpose: data.site.purpose,
        name: data.site.responsibleParty && data.site.responsibleParty.name ? data.site.responsibleParty.name : '',
        title: data.site.responsibleParty && data.site.responsibleParty.title ? data.site.responsibleParty.title : '',
        signature: data.site.responsibleParty ? data.site.responsibleParty.signatureImage : undefined,
      };
    }
    return null;
  }

  componentDidUpdate() {
    this.setSignature();
  }

  get valid() {
    return !(this.signaturePad && this.signaturePad.isEmpty()) && this.state.taxId && this.state.purpose && this.state.name && this.state.title;
  }

  isValid = (key, _, event) => {
    const isValid = event.target.checkValidity();
    const updatedError = { ...this.state.inputError };

    updatedError[key] = !isValid;
    this.setState({
      inputError: updatedError,
    });
  };

  updateForm(key, value) {
    this.setState({
      [key]: value,
      dataChanged: true,
    });
  }

  success = () => {
    this.setState({ saving: false, dataChanged: false });
    AlertService.alert({ type: 'success', message: <span>{TaxInfoStrings.successAlertText}</span>, autoDismiss: true, dismissDelay: 3000 });
  };

  error = () => {
    this.setState({ saving: false });
    AlertService.alert({ type: 'warning', message: <span>{TaxInfoStrings.errorAlertText}</span>, autoDismiss: true, dismissDelay: 3000 });
    this.setState({ inviting: false });
  };

  saveTaxInfo = (event) => {
    event.preventDefault();
    this.setState({ saving: true });
    const { purpose, taxId, name, title } = this.state;
    const { refetchQueries } = this.props;
    const taxInfoUpdates = {
      purpose,
      taxId,
      name,
      title,
      signature: this.signaturePad.toDataURL(),
    };
    this.props.updateTaxSettings({ variables: taxInfoUpdates, refetchQueries }).then(this.success).catch(this.error);
  };

  setCanvas = (canvas) => {
    this.canvas = canvas;
    if (!this.signaturePad) {
      this.signaturePad = new SignaturePad(canvas, {
        onBegin: this.signatureChanged,
      });
    }
  };

  signatureChanged = () => {
    this.setState({ dataChanged: true });
  };

  setSignature = () => {
    if (this.state.signature) {
      this.signaturePad.fromDataURL(this.state.signature);
    }
  };

  clear = () => {
    this.signaturePad.clear();
    this.setState({ dataChanged: true, signature: null });
  };

  get disabled() {
    const { loading, dataChanged } = this.state;
    return loading || !this.valid || (this.valid && !dataChanged);
  }

  render() {
    const { classes } = this.props;
    const { loading, inputError, taxId, purpose, name, title } = this.state;
    return (
      <Card className={classes.card}>
        <h3 className={classes.section__title}>{TaxInfoStrings.pageTitle}</h3>
        {!loading && (
          <form onSubmit={this.saveTaxInfo}>
            <div className={classes.form__wrapper}>
              <div className={classes.form__row}>
                <div className={classes.form__item} data-element="tax-id">
                  <TextInput
                    onChange={this.updateForm.bind(this, 'taxId')}
                    required
                    id="tax-id"
                    type="text"
                    labelText={
                      <span>
                        Federal Tax ID
                        <Tooltip
                          text={
                            <div className={classes['tool-tip__text']}>
                              <p>{TaxInfoStrings.taxIdToolTip1}</p>
                              <ul>
                                {' '}
                                <li> {TaxInfoStrings.taxIdToolTip2} </li>
                                <li>{TaxInfoStrings.taxIdToolTip3}</li>
                                <li>{TaxInfoStrings.taxIdToolTip4}</li>
                              </ul>
                            </div>
                          }
                        >
                          <InformationIcon className={classes.info__icon} />
                        </Tooltip>
                      </span>
                    }
                    value={taxId}
                    tabIndex={7}
                    maxLength={10}
                    pattern="[0-9]{2}-[0-9]{7}"
                    title="Federal Tax ID format is ##-#######"
                    onBlur={this.isValid.bind(this, 'taxId')}
                    error={inputError.taxId}
                  />
                </div>
              </div>
              <div className={classes.form__row} data-element="purpose">
                <TextInput
                  onChange={this.updateForm.bind(this, 'purpose')}
                  required
                  id="purpose"
                  type="text"
                  labelText={
                    <span>
                      Nonprofit mission / purpose
                      <Tooltip text={<div className={classes['tool-tip__text']}>{TaxInfoStrings.purposeTooltip}</div>}>
                        <InformationIcon className={classes.info__icon} />
                      </Tooltip>
                    </span>
                  }
                  value={purpose}
                  tabIndex={8}
                  onBlur={this.isValid.bind(this, 'purpose')}
                  error={inputError.purpose}
                />
              </div>
              <div data-element="signature">
                <h4 className={classes.section__subtitle}>{TaxInfoStrings.signatureTitle}</h4>
                <p className={classes.section__description}>{TaxInfoStrings.signatureDescription}</p>
                <div className={classes.signature}>
                  <canvas ref={this.setCanvas} height="122" width="510" className={classes.signature__canvas}></canvas>
                  <div className={classes.signature__footer}>{TaxInfoStrings.signHere}</div>
                </div>
                <div className={classes.signature__buttons}>
                  <Button type="button" secondary onClick={this.clear} className={classes.clear}>
                    {TaxInfoStrings.clearSignature}
                  </Button>
                </div>
              </div>
              <div className={classes.form__row}>
                <div className={classes.form__item} data-element="tax-id">
                  <TextInput
                    onChange={this.updateForm.bind(this, 'name')}
                    required
                    id="responsible-party-name"
                    type="text"
                    labelText="Name"
                    value={name}
                    tabIndex={9}
                    onBlur={this.isValid.bind(this, 'name')}
                    error={inputError.name}
                  />
                </div>
                <div className={classes.form__item} data-element="tax-id">
                  <TextInput
                    onChange={this.updateForm.bind(this, 'title')}
                    required
                    id="responsible-party-title"
                    type="text"
                    labelText="Title"
                    value={title}
                    tabIndex={10}
                    onBlur={this.isValid.bind(this, 'title')}
                    error={inputError.title}
                  />
                </div>
              </div>
            </div>
            <CardActions>
              <Button disabled={this.disabled} type="submit" loading={loading} loadingText={GeneralStrings.loading} link>
                {GeneralStrings.save}
              </Button>
            </CardActions>
          </form>
        )}
      </Card>
    );
  }
}

TaxInfo.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  refetchQueries: PropTypes.array,
  updateTaxSettings: PropTypes.func,
};
