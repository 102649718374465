const contactTypes = [
  {
    label: 'Internal',
    value: 'INTERNAL',
    description: 'Internal contacts are contacts from within your organization. These people will not be able to submit offer sheets.',
  },
  {
    label: 'Customer',
    value: 'CUSTOMER',
    description: 'Customer contacts are the contacts outside your organization that will be able to submit offer sheets.',
  },
];

export default contactTypes;
