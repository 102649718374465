import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme as theme } from '@spoiler-alert/ui-library';
import { graphql } from '@apollo/client/react/hoc';
import accounting from 'accounting';
import capitalize from 'capitalize';
import injectSheet from 'react-jss';
import { TooltipIcon } from '../../pages/offer-comparison/helpers';
import { ProgressBarsQuery } from '../../graphql/queries';
import styleSheet from './progress-bars-styles';

@injectSheet(styleSheet)
@graphql(ProgressBarsQuery)
export class ProgressBars extends Component {
  tooltipContent(previousStage, currentStage, ofText, numberColor, stageText, completed) {
    const { classes } = this.props;
    return (
      <div className={classes.stagedTooltipBox}>
        <div className={classes.stagedTooltipAmount} style={{ color: theme[numberColor] }}>
          {accounting.formatNumber(currentStage)}
        </div>
        {this.checkCompleted(completed, stageText, ofText, previousStage)}
      </div>
    );
  }

  customStageText(stageText) {
    return stageText === 'Received' ? 'Received Offers' : stageText;
  }

  checkCompleted(completed, stageText, ofText, previousStage) {
    const { classes } = this.props;
    let render;
    let wording;
    if (stageText.toLowerCase() === 'staged' || stageText.toLowerCase() === 'listed') {
      wording = 'items';
    } else {
      wording = 'offers';
    }
    if (completed <= 0) {
      render = (
        <div className={classes.stagedTooltipText}>
          <div>No {wording} have</div>
          <div>been {stageText.toLowerCase()}</div>
        </div>
      );
    } else {
      render = (
        <div>
          <div className={classes.stagedTooltipText}> Items {this.customStageText(stageText)}</div>
          <div className={classes.stagedTooltipSubtitle}>
            OF {accounting.formatNumber(previousStage)} {ofText}
          </div>
        </div>
      );
    }
    return render;
  }

  formatCompletedPercent(completed) {
    let completedPercent;
    if (completed > 0 && completed < 100) {
      completedPercent = `${accounting.formatNumber(completed, 1)}%`;
    } else if (completed === 100) {
      completedPercent = '100%';
    } else {
      completedPercent = '0%';
    }
    return completedPercent;
  }

  tooltipContainer(previousStage, currentStage, ofText, numberColor, headerBackgroundColor, headerTextColor, stageText) {
    const { classes } = this.props;
    const completed = (currentStage / previousStage) * 100;
    const completedPercent = this.formatCompletedPercent(completed);
    return (
      <div className={classes[`progressBars${capitalize(stageText)}`]}>
        <TooltipIcon
          className={classes.toolTipWrapper}
          headerBackgroundColor={headerBackgroundColor}
          headerTextColor={headerTextColor}
          header={`${completedPercent} ${stageText}`}
          text={this.tooltipContent(previousStage, currentStage, ofText, numberColor, stageText, completed)}
        >
          <div className={classes.progressHeaderText}>{stageText.toUpperCase()}</div>
          <div className={classes[`progressBars${capitalize(stageText)}Bar`]}>
            <div className={classes[`progressBars${capitalize(stageText)}BarInternal`]} style={{ width: completedPercent }}></div>
          </div>
        </TooltipIcon>
      </div>
    );
  }

  render() {
    const { classes, data } = this.props;
    const { progressBarsQuery } = data;
    const { inventory = 0, staged = 0, listed = 0, received = 0, awarded = 0, accepted = 0 } = progressBarsQuery || {};
    return (
      <div className={classes.progressBarsWrapper}>
        <div className={classes.progressBarsItems}>
          <span className={classes.progressBarsItemsAmount}>{inventory} </span>
          <span>ITEMS</span>
        </div>
        {this.tooltipContainer(inventory, staged, 'ITEMS', 'grey', theme.grey5, theme.grey, 'Staged')}
        {this.tooltipContainer(staged, listed, 'STAGED', 'orange', theme.orange5, theme.orange, 'Listed')}
        {this.tooltipContainer(listed, received, 'LISTED', 'purple', theme.purple5, theme.purple, 'Received')}
        {this.tooltipContainer(received, awarded, 'RECEIVED', 'teal', theme.teal5, theme.teal, 'Awarded')}
        {this.tooltipContainer(awarded, accepted, 'AWARDED', 'green', theme.green5, theme.green, 'Accepted')}
      </div>
    );
  }
}

ProgressBars.propTypes = {
  classes: PropTypes.object,
  progressInventory: PropTypes.number,
  progressItems: PropTypes.number,
  user: PropTypes.object,
  data: PropTypes.object,
};
export default ProgressBars;
