import React from 'react';
import PropTypes from 'prop-types';
import { NavigationItem } from '@spoiler-alert/ui-library';
import { graphql } from '@apollo/client/react/hoc';
import { verificationCountQuery } from '../../graphql/queries';

@graphql(verificationCountQuery)
export default class VerificationsItem extends React.Component {
  state = {
    verifications: undefined,
  };

  static getDerivedStateFromProps(nextProps) {
    const { data } = nextProps;
    if (!data.loading && !data.error && data.currentUserQuery) {
      return { verifications: data.currentUserQuery.pendingVerificationsCount || undefined };
    }
    return null;
  }

  render() {
    return <NavigationItem badgeCount={this.state.verifications} {...this.props} />;
  }
}

VerificationsItem.propTypes = {
  data: PropTypes.object,
};
