import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import { GraphQLDataTable, Filter, AlertService, Button, ExportIcon } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { UserTransactionHistoryQuery } from '../../graphql/queries';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import dataExportService from '../../services/data-export-service';
import { Breadcrumbs, UserFilters } from '../../store';
import { stripEmptyFilters } from '../../utilities/clean-filters';

const useStyles = createUseStyles({
  'transaction-history__wrap': {
    marginBottom: 20,
  },
});

const TransactionHistory = ({ user }) => {
  const classes = useStyles();

  const [variables, setVariables] = useState({
    paginate: {
      pageNumber: 1,
      limit: 30,
      filter: {
        sortBy: { asc: true, sortProperty: 'siteName' },
      },
    },
  });

  useEffect(() => {
    TitleService.setTitles('History');
    Breadcrumbs.set([
      {
        url: '/history',
        title: 'History',
      },
    ]);
  }, []);

  const handleQueryChange = (vars) => {
    setVariables({ ...vars });
  };

  const createInitialFilters = () => {
    const locationFilter = { displayName: 'Location', field: 'sites', queryField: 'sites', selectAll: true, filterType: 'search' };
    const defaultFilters = [
      { displayName: 'Accepted Date Range', filterType: 'daterange', options: { startParam: 'acceptedAtStartDate', endParam: 'acceptedAtEndDate' } },
      { displayName: 'Brand', field: 'brands', filterType: 'search', selectAll: true },
      { displayName: 'Sold to Customer', field: 'recipientSiteIds', filterType: 'search', selectAll: true },
    ];
    if (user.privileges.canUserManageMultipleSites) defaultFilters.splice(1, 0, locationFilter);
    const storeFilters = UserFilters.filters.transactionHistory || [];
    const inventoryFilters = defaultFilters.map((df) => new Filter({ ...df, ...storeFilters.find((sf) => sf?.displayName === df.displayName) }));
    return inventoryFilters;
  };

  useEffect(() => {
    const filters = createInitialFilters();
    const filterState = filters.reduce((filtersObj, filter) => {
      if (filter.filterType === 'daterange') {
        filtersObj[filter.options?.startParam || 'startDate'] = filter.range?.start ? moment(filter.range?.start) : undefined;
        filtersObj[filter.options?.endParam || 'endDate'] = filter.range?.end ? moment(filter.range?.end) : undefined;
      }

      if (filter.queryField && filter.values.length > 0) {
        filtersObj[filter.queryField] = filter.values.map((v) => v.value);
      }

      return filtersObj;
    }, {});
    const paginateFilter = { ...variables.paginate.filter, ...filterState };
    handleQueryChange({ paginate: { ...variables.paginate, filter: stripEmptyFilters(paginateFilter) } });
  }, []);

  const exportErrored = () => {
    const message =
      'Sorry we are unable to export your sales history. If this problem persists, please contact a Spoiler Alert Administrator to help you.';
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  };

  const exportCompleted = (response) => {
    if (response.ok || response.data) {
      const message = 'Your export is now processing and we will email you when it is completed.';
      AlertService.alert({ type: 'info', message: <span>{message}</span>, autoDismiss: true, dismissDelay: 5000 });
    } else {
      exportErrored();
    }
  };

  const exportHistory = () => {
    const filters = variables.paginate.filter;
    dataExportService.export('sales-history', { historyFilters: filters }).then(exportCompleted).catch(exportErrored);
  };

  return (
    <div className={classes['transaction-history__wrap']}>
      <GraphQLDataTable
        query={UserTransactionHistoryQuery}
        queryName="currentUserQuery"
        queryField="transactionHistory"
        search
        columns={getColumnsFromDataTableProfile('History', user.site.dataTableProfiles)}
        filterParameterField="transactionFilterParameters"
        filters={createInitialFilters()}
        updateStoredFilters={(filters) => UserFilters.updateFilters('transactionHistory', filters)}
        perPage={30}
        onFilterChange={handleQueryChange}
        pageActionButtons={[
          <Button key="export" secondary onClick={exportHistory} icon={ExportIcon}>
            Export
          </Button>,
        ]}
      />
    </div>
  );
};

TransactionHistory.propTypes = {
  user: PropTypes.object,
};

export default TransactionHistory;
