import { makeAutoObservable } from 'mobx';
const smartAwardBuyersKey = 'smartAwardBuyersV2';
class RootStore {
    constructor() {
        this.sidebarStatus = JSON.parse(localStorage.getItem('sidebarStatus')) || {
            collapsed: false,
        };
        this.shouldRefreshOfferComparison = false;
        this.shouldRefreshQuickAward = true;
        this.featureMap = new Map();
        this.smartAwardBuyers = JSON.parse(localStorage.getItem(smartAwardBuyersKey)) || {
            selections: [],
            smartAwardType: { value: 'MAX_CASE_PRICE', text: 'Maximum Case Price' },
            sellerSiteIds: [],
        };
        this.isLoading = true;
        this.trucklaneChannels = new Map();
        this.invalidTrucklaneChannels = new Map();
        makeAutoObservable(this);
    }
    setSidebar(status) {
        localStorage.setItem('sidebarStatus', JSON.stringify(status));
        this.sidebarStatus = Object.assign(Object.assign({}, this.sidebarStatus), status);
    }
    refreshOfferComp(status) {
        this.shouldRefreshOfferComparison = status;
    }
    refreshQuickAward(status) {
        this.shouldRefreshQuickAward = status;
    }
    updateFeatures(featureArray) {
        featureArray.map((f) => this.featureMap.set(f.featureName, f.enabled));
    }
    setSmartAwardPreferences(buyers) {
        localStorage.setItem(smartAwardBuyersKey, JSON.stringify(buyers));
        this.smartAwardBuyers = buyers;
    }
    setLoading(loading) {
        this.isLoading = loading;
    }
    setTrucklaneChannels(channels) {
        channels.forEach((channel) => {
            this.trucklaneChannels.set(`${channel.truckType}-${channel.originDCId}`, channel);
        });
    }
    invalidateTrucklaneChannel(channel) {
        this.invalidTrucklaneChannels.set(`${channel.truckType}-${channel.originDCId}`, channel);
    }
    clearValidTrucklaneChannels() {
        this.trucklaneChannels = new Map();
    }
    clearTrucklaneChannels() {
        this.trucklaneChannels = new Map();
        this.invalidTrucklaneChannels = new Map();
    }
}
export default RootStore;
