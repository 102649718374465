import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card,
  Button,
  Pill,
  DragDrop,
  DatePicker,
  Theme as theme,
  FileXlsxIcon,
  AlertService,
  Select,
  SelectOption,
} from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import downloadTemplateButtonStyle from '../download-template-style';
import { ItemImportService, TitleService } from '../../services';
import ImportStrings from '../../string-resources/import-strings';
import { Breadcrumbs } from '../../store';

const styles = {
  import__info: {
    fontSize: '0.875rem',
    marginBottom: 24,
  },
  import__steps: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  import__card: {
    width: 'calc(1/4*100% - (1 - 1/4) * 16px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
  },
  'import__card-container': {
    padding: 17,
  },
  'import__card-drop': {
    padding: 0,
  },
  breadcrumb: {
    paddingBottom: 10,
  },
  card__title: {
    margin: 0,
    padding: 0,
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: '13px',
    textAlign: 'center',
  },
  card__text: {
    fontSize: '0.875rem',
    lineHeight: '1.1875rem',
    marginBottom: '1.25rem',
  },
  button__container: {
    margin: 8,
    textAlign: 'center',
  },
  'date-picker__container': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '0 25%',
  },
  'set-site__container': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  '@media (max-width: 1900px)': {
    'set-site__container': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  card__button: {
    display: 'inline-flex',
  },
  drop__zone: {
    borderRadius: theme.borderRadius,
    border: theme.dragDropBorder,
    margin: 5,
    padding: 10,
    height: 'calc(100% - 10px)',
  },
  drop__container: {
    display: 'block',
    height: '100%',
  },
  drop__info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: 4,
  },
  drop__icon: {
    width: 40,
    height: 44,
    marginTop: 12,
    marginRight: 29,
  },
  'drop__text--container': {
    textAlign: 'left',
  },
  drop__text: {
    color: theme.textColorPrimary,
    fontSize: '1.25rem',
    margin: 0,
  },
  'drop__text--suffix': {
    fontSize: '0.875rem',
    color: '#9e9e9e',
    margin: 0,
  },
  drop__button: {
    marginTop: 8,
  },
  input__hidden: {
    display: 'none',
  },
  drop__pills: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100% - 40px)',
  },
  import__data: {
    flexGrow: 1,
    margin: '41px 0 16px',
  },
  sticky__footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.primaryColor,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 8,
    left: 0,
    zIndex: 4,
  },
  download__template__button: downloadTemplateButtonStyle,
  '@media (max-width: 1279px)': {
    import__steps: {
      flexWrap: 'wrap',
    },
    import__card: {
      width: '100%',
      height: 'auto',
      marginBottom: 16,
    },
  },
};

class BulkItemImport extends Component {
  constructor(props) {
    super(props);
    TitleService.setTitles('Item Import');
    Breadcrumbs.set([
      {
        url: '/items',
        title: 'Items',
      },
      {
        url: '/items/import/file',
        title: 'Import',
      },
    ]);

    this.state = {
      site: this.initialSite,
      itemImportFileUrl: props.user.site.itemImportFileUrl,
      files: [],
      importDate: undefined,
      loading: false,
    };

    this.setFileInput = this.setFileInput.bind(this);
    this.browseFiles = this.browseFiles.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.uploadImport = this.uploadImport.bind(this);
    this.setImportDate = this.setImportDate.bind(this);
    this.finishUpload = this.finishUpload.bind(this);
    this.uploadErrored = this.uploadErrored.bind(this);
    this.setSite = this.setSite.bind(this);
  }

  get initialSite() {
    const { site } = this.props.user;
    return { value: site._id, text: site.siteName };
  }

  setSite(_, site) {
    const { itemImportFileUrl } = this.props.user.siteHierarchy.find((sh) => sh._id === site.value);
    this.setState({ site, itemImportFileUrl });
  }

  get siteOptions() {
    const { site, siteHierarchy, donationSuiteSiteHierarchy } = this.props.user;
    const createSelectOption = (s) => (
      <SelectOption value={s._id} key={s.siteName} selected={site._id === s._id}>
        {s.siteName}
      </SelectOption>
    );

    if (site.siteRole.includes('DONATION SELLER')) {
      return donationSuiteSiteHierarchy.map((s) => createSelectOption(s));
    }

    return siteHierarchy.map((s) => createSelectOption(s));
  }

  get accepts() {
    return ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  }

  get isValid() {
    return this.state.files.length === 1 && this.state.importDate;
  }

  browseFiles(ev) {
    ev.preventDefault();
    this.fileInput.click();
  }

  setFileInput(el) {
    this.fileInput = el;
  }

  handleDrop(ev) {
    this.processFiles(ev.dataTransfer.files);
  }

  handleFileChange(ev) {
    this.processFiles(ev.target.files);
  }

  processFiles(fileList) {
    const files = Array.from(fileList);
    this.setState({ files });
  }

  removeFile(fileName) {
    const { files } = this.state;
    files.splice(
      this.state.files.findIndex((file) => file.name === fileName),
      1
    );
    this.setState({ files });
  }

  setImportDate(importDate) {
    this.setState({ importDate: importDate || moment.utc(new Date()) });
  }

  uploadImport() {
    this.setState({ loading: true });
    ItemImportService.import(this.state.files[0], this.state.importDate, this.state.site.value).then(this.finishUpload).catch(this.uploadErrored);
  }

  finishUpload(response) {
    if (response.ok || response.data) {
      const message = ImportStrings.importUploadedMessage;
      AlertService.alert({ type: 'info', message: <span>{message}</span>, autoDismiss: true, dismissDelay: 5000 });
      this.setState({ files: [], loading: false });
    } else {
      this.uploadErrored();
    }
  }

  uploadErrored() {
    const message = ImportStrings.importErrorMessage;
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
    this.setState({ files: [], loading: false });
  }

  render() {
    const { classes } = this.props;
    const dropClass = `${classes.import__card} ${classes['import__card-drop']}`;
    return (
      <div className={classes.bulk__import}>
        <p className={classes.card__info}>{ImportStrings.itemImportDescription}</p>
        <div className={classes.import__steps}>
          <Card noPadding className={classes.import__card}>
            <div className={classes['import__card-container']}>
              <h3 className={classes.card__title}>1. {ImportStrings.downloadCSVTemplate}</h3>
              <p className={classes.card__text}>{ImportStrings.downloadCSVTemplateDescription}</p>
              <div className={classes['set-site__container']}>
                <div className={classes.form__item}>
                  <Select label="Site" onChange={this.setSite} required autoWidth selectedItem={this.state.site}>
                    {this.siteOptions}
                  </Select>
                </div>
                <div className={classes.button__container}>
                  <a href={this.state.itemImportFileUrl} className={classes.download__template__button}>
                    {ImportStrings.downloadTemplate}
                  </a>
                </div>
              </div>
            </div>
          </Card>
          <Card noPadding className={classes.import__card}>
            <div className={classes['import__card-container']}>
              <h3 className={classes.card__title}>2. {ImportStrings.addData}</h3>
              <p className={classes.card__text}>{ImportStrings.addDataDescription}</p>
            </div>
          </Card>
          <Card noPadding className={classes.import__card}>
            <div className={classes['import__card-container']}>
              <h3 className={classes.card__title}>3. {ImportStrings.selectDate}</h3>
              <div className={classes['date-picker__container']}>
                <DatePicker onChange={this.setImportDate} label={ImportStrings.itemImportDate} day={this.state.importDate} required />
              </div>
            </div>
          </Card>
          <Card noPadding className={dropClass}>
            <DragDrop className={classes.drop__zone} accepts={this.accepts} onDrop={this.handleDrop}>
              <div className={classes.drop__container}>
                <h3 className={classes.card__title}>4. {ImportStrings.uploadFile}</h3>
                {this.state.files.length === 0 && (
                  <div className={classes.drop__info}>
                    <FileXlsxIcon className={classes.drop__icon} />
                    <div className={classes['drop__text--container']}>
                      <p className={classes.drop__text}>{ImportStrings.dragAndDrop}</p>
                      <p className={classes['drop__text--suffix']}>{ImportStrings.yourCSVFile}</p>
                      <Button type="button" secondary className={classes.drop__button} onClick={this.browseFiles}>
                        {ImportStrings.browseFile}
                      </Button>
                      <span className={classes.input__hidden}>
                        <input type="file" ref={this.setFileInput} onChange={this.handleFileChange} accept={this.accepts.join(',')} />
                      </span>
                    </div>
                  </div>
                )}
                {this.state.files.length > 0 && (
                  <div className={classes.drop__pills}>
                    {this.state.files.map((file, i) => (
                      <Pill key={`.pill-${i}`} fileName={file.name} file={file} onCancel={this.removeFile} />
                    ))}
                  </div>
                )}
              </div>
            </DragDrop>
          </Card>
        </div>
        <div className={classes.sticky__footer}>
          <Button
            type="submit"
            secondary
            onClick={this.uploadImport}
            disabled={!this.isValid}
            loading={this.state.loading}
            loadingText={ImportStrings.processing}
          >
            {ImportStrings.itemImport}
          </Button>
        </div>
      </div>
    );
  }
}

BulkItemImport.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
};

const StyledComponent = injectSheet(styles)(BulkItemImport);
export default StyledComponent;
