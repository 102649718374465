import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Snackbar, ExportIcon, LoadingSmall, Theme, AlertService } from '@spoiler-alert/ui-library';
import { fetchWithToken } from '../../../services/fetch-with-token';
import AppSettings from '../../../app-settings';
import companyLogoStyles from './company-logo-styles';

const useStyles = createUseStyles(companyLogoStyles);

const CompanyLogo = ({ user }) => {
  const classes = useStyles();
  const [fileInput, setFileInput] = useState();
  const [logo, setLogo] = useState(user.site.logo);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();

  const uploadClick = () => {
    fileInput.click();
  };

  const validate = (file) => {
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      AlertService.alert({
        type: 'warning',
        message: 'The logo could not be uploaded because it is the incorrect file type. Please upload a JPG or PNG file.',
        autoDismiss: true,
        dismissDelay: 6000,
      });
      return false;
    }

    if (file.size > 800000) {
      AlertService.alert({
        type: 'warning',
        message: 'The logo could not be uploaded because the file size is too large. Please upload a file 800KB or less.',
        autoDismiss: true,
        dismissDelay: 6000,
      });
      return false;
    }

    return true;
  };

  const handleResponse = (response, snackbarText) => {
    if (response.errors?.length)
      AlertService.alert({
        type: 'warning',
        message: response.errors[0].message,
        autoDismiss: true,
        dismissDelay: 6000,
      });
    else {
      setLogo(response.logo);
      setSnackbarMessage(snackbarText);
      setShowSnackbar(true);
    }
  };

  const makeRequestWithFile = async (file, path) => {
    const body = new FormData();
    if (!file) {
      setActionInProgress(false);
      return;
    }
    const isValid = validate(file);
    if (isValid) {
      body.append('file', file);
      const response = await (
        await fetchWithToken(path, {
          method: 'POST',
          body,
        })
      ).json();
      handleResponse(response, 'Logo uploaded');
    }
    setActionInProgress(false);
  };

  const handleUpload = async (ev) => {
    setActionInProgress(true);
    const [file] = ev.target.files;
    await makeRequestWithFile(file, `${AppSettings.GRAPHQL_SERVER_URL}logo`);
  };

  const handleReplace = async (ev) => {
    setActionInProgress(true);
    const [file] = ev.target.files;
    await makeRequestWithFile(file, `${AppSettings.GRAPHQL_SERVER_URL}logo/replace`);
  };

  const removeLogo = async () => {
    setActionInProgress(true);
    const response = await (
      await fetchWithToken(`${AppSettings.GRAPHQL_SERVER_URL}logo`, {
        method: 'DELETE',
      })
    ).json();
    handleResponse(response, 'Logo removed');
    setActionInProgress(false);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.info}>
        <h1 className={classes.title}>Company Logo</h1>
        <span className={classes.subTitle}>We will use your logo in communications to your customers.</span>
        <div className={classes.instructions}>
          <span className={classes.header}>Instructions</span>
          <span className={classes.detail}>JPG or PNG. Max size of 800KB</span>
          <span className={classes.detail}>White or transparent background work best</span>
        </div>
      </div>
      <div className={classes.logoContainer}>
        <div className={!logo ? classes.uploadImage : classes.logo} onClick={!logo ? uploadClick : undefined}>
          {(actionInProgress && <LoadingSmall />) || (logo && <img className={classes.image} src={`${logo}`} />) || (
            <div className={classes.uploadContainer}>
              <div className={classes.uploadInput}>
                <input
                  style={{ display: 'none' }}
                  ref={(el) => setFileInput(el)}
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleUpload}
                  data-testid="upload-file-input"
                />
                <ExportIcon
                  style={{
                    stroke: Theme.white,
                  }}
                />
              </div>
              <div>
                <span className={classes.uploadText}>Upload logo</span>
              </div>
            </div>
          )}
        </div>
        {logo && (
          <div className={classes.logoActions}>
            <span className={classes.removeLogo} onClick={removeLogo}>
              Remove Logo
            </span>
            <span className={classes.replaceLogo} onClick={uploadClick}>
              Replace Logo
            </span>
            <input
              style={{ display: 'none' }}
              ref={(el) => setFileInput(el)}
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleReplace}
              data-testid="replace-file-input"
            />
          </div>
        )}
      </div>
      <Snackbar show={showSnackbar} callback={() => setShowSnackbar(false)} message={snackbarMessage} />
    </div>
  );
};

CompanyLogo.propTypes = {
  user: PropTypes.object,
};

export default CompanyLogo;
