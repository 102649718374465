import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Button, LinkButton, Timing, AlertService } from '@spoiler-alert/ui-library';
import emailService from '../../../services/email-service';
import TaxDashboardStrings from '../../../string-resources/tax-dashboard';

const styles = {
  no__signature: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '65vh',
    minHeight: '500px',
    backgroundColor: '#4eaac1',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '2px',
    padding: '0 24px',
  },
  circle: {
    height: '75px',
    width: '75px',
    borderRadius: '100%',
    border: '5px solid #fff',
    fontSize: '3.375rem',
    color: '#fff',
    fontWeight: 500,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signature__header: {
    fontSize: '2.0625rem',
  },
  text__center: {
    fontSize: '1.625rem',
    fontWeight: 300,
  },
  button: {
    color: '#4eaac1',
  },
  lineBreak: {
    display: 'block',
  },
  '@media (max-width: 840px)': {
    no__signature: {
      height: '88vh',
      padding: '24px',
      minHeight: 0,
    },
    circle: {
      width: '60px',
      height: '60px',
      fontSize: '2.25rem',
    },
    signature__header: {
      fontSize: '2.25rem',
      marginBottom: '38px',
    },
    text__center: {
      fontSize: '1.125rem',
      marginBottom: '38px',
    },
    lineBreak: {
      display: 'inline',
    },
  },
  '@media (max-width: 840px) and (orientation: landscape)': {
    no__signature: {
      height: '76vh',
      padding: '24px',
      minHeight: 0,
    },
    circle: {
      width: '45px',
      height: '45px',
      fontSize: '1.6rem',
      border: '4px solid #fff',
    },
    signature__header: {
      fontSize: '1.5rem',
      margin: '1rem 0 20px',
    },
    text__center: {
      fontSize: '1rem',
      marginBottom: '20px',
    },
  },
};

@injectSheet(styles)
export default class NoSignature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  get isAdmin() {
    const { user } = this.props;
    return user.privileges.isSystemAdmin || user.privileges.isSiteAdmin;
  }

  get signatureLink() {
    return '/site/settings';
  }

  get createText() {
    return this.isAdmin ? TaxDashboardStrings.createYourSiteSignature : TaxDashboardStrings.contactYourSiteAdmin;
  }

  handleSiteSignature = Timing.throttle(() => {
    this.setState({ loading: true });
    const { user } = this.props;
    emailService
      .SiteResponsibleParty(user.site._id)
      .then(() =>
        AlertService.alert({
          type: 'success',
          message: <span>{TaxDashboardStrings.formatString(TaxDashboardStrings.thanksWellLetYouKnow, user.firstName)}</span>,
          autoDismiss: true,
        })
      )
      .catch(() => AlertService.alert({ type: 'warning', message: <span>{TaxDashboardStrings.sorrySomethingWentWrong}</span>, autoDismiss: true }))
      .then(this.done);
  }, 20000);

  done = () => {
    this.setState({ loading: false });
  };

  render() {
    const { classes, style } = this.props;
    return (
      <div className={classes.no__signature} style={style}>
        <div className={classes.circle}>$</div>
        <h1 className={classes.signature__header}>{TaxDashboardStrings.firstStep}</h1>
        <p className={classes.text__center}>
          {TaxDashboardStrings.oneTimeStep}
          <span className={classes.lineBreak}> {TaxDashboardStrings.clickToStart} </span>
          <span className={classes.lineBreak}> {this.createText}.</span>
        </p>
        {this.isAdmin && (
          <LinkButton href={this.signatureLink} target="_blank" secondary className={classes.button}>
            {TaxDashboardStrings.createSiteSignature}
          </LinkButton>
        )}
        {!this.isAdmin && (
          <Button
            type="button"
            secondary
            className={classes.button}
            onClick={this.handleSiteSignature}
            loading={this.state.loading}
            loadingText={TaxDashboardStrings.loading}
          >
            {TaxDashboardStrings.contactSiteAdmin}
          </Button>
        )}
      </div>
    );
  }
}

NoSignature.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  style: PropTypes.object,
};
