export default new Map([
  [0, 'Accidental post'],
  [1, 'Post contains error'],
  [2, 'Product no longer available'],
  [3, 'Recipient rejected product'],
  [4, 'Recipient did not respond'],
  [5, 'Recipient did not show up'],
  [6, 'Other'],
  [7, 'Claimed by accident'],
  [8, 'No longer interested in product'],
  [9, 'Payment issue'],
  [10, 'Not able to arrange logistics'],
  [11, 'Supplier did not respond'],
  [12, 'Supplier did not show up'],
  [13, 'Product not as advertised (quality or quantity)'],
  [14, 'Can no longer store food'],
  [15, 'Food has expired'],
]);
