const keys = ['xs', 'sm', 'md', 'lg', 'xl'];
const BREAKPOINTS = {
  xs: 360,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const values = keys.map((key) => BREAKPOINTS[key]);

export default BREAKPOINTS;
export { keys, values };
