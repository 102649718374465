import { gql } from '@apollo/client';
import { InventoryDetailFragment, ItemDetailFragment, OfferListingFragment } from './fragments';

export const UserQuery = gql`
  query getUserQuery {
    currentUserQuery {
      _id
      authToken
      cellNumber
      companyName
      companyUrl
      companyZip
      email
      firstName
      lastName
      photo
      isImpersonating
      privileges {
        canUserManageMultipleSites
        canUserSiteUseItems
        canUserSiteUseTax
        canUserAddListings
        canUserAddTransactions
        canUserEditMarketplacePosts
        canUserEditSelfReportedDonations
        canUserSiteEditWasteRecords
        canUserAddBulkImport
        canViewTaxDashboard
        canViewItems
        canUserEditItems
        canUserViewMarketplace
        canViewClaims
        canViewPosts
        canViewActivity
        canViewEnterpriseDashboard
        canReconcile
        hideActivitySubMenu
        isSystemAdmin
        isSiteAdmin
        canViewDropdown
        canViewUserManagement
        canViewInventory
        canMakeOffers
        canReceiveOffers
      }
      inventoryFilterParameters {
        _id
        sites {
          id
          name
          category
        }
      }
      postFilterParameters {
        _id
        locations {
          id
          name
          category
        }
        sources {
          id
          name
        }
        categoriesForVerifications {
          id
          name
        }
        categories {
          id
          name
        }
        subcategories {
          id
          name
        }
        statuses {
          id
          name
        }
        suppliers {
          id
          name
        }
        destinations {
          id
          name
        }
        qualities {
          id
          name
        }
      }
      site {
        hasUnmappedHandlingValues
        reserveAnchor
        reserveNumber
        _id
        siteName
        businessWebsite
        isActive
        fiscalYearStart
        allTimeStart
        isNonProfit
        itemImportFileUrl
        foodQualityList
        transactionImportFileUrl
        transactionCycleId
        siteRole
        currencyType
        privClasses {
          name
          displayName
        }
        destinationColorMapping {
          destination
          color
        }
        charities {
          siteId
          siteName
          userId
        }
        destinations {
          name
          value
          enumValue
          outlets {
            siteId
            siteName
            userId
          }
        }
        physicalAddress {
          streetAddress
          city
          stateCode
          zipCode
          content
          loc {
            type
            coordinates
          }
        }
        children {
          _id
          siteName
          businessWebsite
          isActive
          fiscalYearStart
          isNonProfit
          physicalAddress {
            content
            loc {
              type
              coordinates
            }
          }
        }
        responsibleParty {
          name
          signatureImage
          title
        }
        dataTableProfiles {
          dataTableName
          siteName
          siteId
          columns {
            field
            displayName
            visible
            sortable
            defaultSort
            defaultSortDirection
            formatter
            editFormatter
          }
        }
        importProfiles {
          importType
          bypassItemValidation
        }
        weightUnit
        trucklaneMinimums {
          _id
          mode
          delivery {
            dry
            refrigerated
            frozen
          }
          pickup {
            dry
            refrigerated
            frozen
          }
        }
        truckTypeCosts {
          dry {
            fixedCost
            costPerMile
          }
          refrigerated {
            fixedCost
            costPerMile
          }
          frozen {
            fixedCost
            costPerMile
          }
        }
        logo
      }
      siteHierarchy {
        _id
        siteName
        itemImportFileUrl
      }
      donationSuiteSiteHierarchy {
        _id
        siteName
        itemImportFileUrl
      }
      dashboard {
        layout {
          numCols
          rows {
            widgetId
            widgetName
            offset {
              xs
              sm
              md
              lg
              xl
            }
            width {
              xs
              sm
              md
              lg
              xl
            }
            componentType
            title
            filters {
              query
              id
              numberClass
              text
              value
              mask
              aggregationType
              gql
            }
          }
        }
      }
      lookerDashboardSsoUrl
      programPerformanceDashboardSsoUrl
    }
  }
`;

export const ActiveUsersQuery = gql`
  query ActiveUsersQuery($paginate: PaginatedUsersInput) {
    currentUserQuery {
      _id
      site {
        _id
        siteName
        users(paginate: $paginate) {
          total
          pageContents {
            _id
            firstName
            lastName
            photo
            email
            siteName
            title
            privClass {
              name
              displayName
            }
          }
          limit
          pageNumber
          totalPages
        }
      }
    }
  }
`;

export const GetActiveUserQuery = gql`
  query GetActiveUserQuery($id: String!) {
    userByIdQuery(id: $id) {
      _id
      firstName
      lastName
      email
      siteName
      siteId
      siteHierarchy {
        _id
        siteName
      }
      privClass {
        displayName
        name
      }
      site {
        _id
        privClasses {
          name
          displayName
        }
      }
      title
      invitedBy
      createdAt
      lastLoginDate
    }
  }
`;

export const PendingUsersQuery = gql`
  query PendingUsersQuery($paginate: PaginatedInvitesInput) {
    currentUserQuery {
      _id
      site {
        _id
        siteName
        invites(paginate: $paginate) {
          total
          pageContents {
            _id
            firstName
            lastName
            email
            siteName
            expired
            privClass {
              name
              displayName
            }
          }
          limit
          pageNumber
          totalPages
        }
      }
    }
  }
`;

export const GetPendingUserQuery = gql`
  query GetPendingUserQuery($id: String!) {
    inviteByIdQuery(id: $id) {
      _id
      firstName
      lastName
      email
      siteName
      privClass {
        displayName
      }
      title
      expired
      createdByName
      expiresAt
    }
  }
`;

export const pendingClassificationsCountQuery = gql`
  query getUserQuery {
    currentUserQuery {
      _id
      pendingClassificationsCount
    }
  }
`;

export const verificationCountQuery = gql`
  query getUserQuery {
    currentUserQuery {
      _id
      pendingVerificationsCount
    }
  }
`;

export const TaxDeductionsAreaChartQuery = `
  query TaxDeductionsAreaChartQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      taxDeductionPerPeriod {
        year
        data
      }
    }
  }`;

export const TotalAmountQuery = gql`
  query TotalAmountQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      totalAmount
    }
  }
`;

export const TotalCostQuery = gql`
  query TotalCostQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      totalCost
    }
  }
`;

export const TotalPoundsQuery = gql`
  query TotalPoundsQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      totalPounds
    }
  }
`;

export const TotalMealsQuery = gql`
  query TotalMealsQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      totalMeals
    }
  }
`;

export const TotalTaxDeductibleQuery = gql`
  query TotalTaxDeductibleQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      totalTaxDeductible
    }
  }
`;

export const TotalPoundsOfVerificationsQuery = gql`
  query TotalPoundsOfVerificationsQuery($postFilter: PostFilterInput!) {
    siteMetricsQuery(postFilter: $postFilter) {
      totalPoundsOfVerifications
    }
  }
`;

export const SellThroughRateQuery = gql`
  query SellThroughRateQuery($postFilter: PostFilterInput!) {
    discountedSalesMetricsQuery(postFilter: $postFilter) {
      sellThroughRate {
        value
        changeRate
        positiveChange
      }
    }
  }
`;

export const TotalRevenueQuery = gql`
  query TotalRevenueQuery($postFilter: PostFilterInput!) {
    discountedSalesMetricsQuery(postFilter: $postFilter) {
      totalRevenue {
        value
        changeRate
        positiveChange
      }
    }
  }
`;

export const BidRateQuery = gql`
  query BidRateQuery($postFilter: PostFilterInput!) {
    discountedSalesMetricsQuery(postFilter: $postFilter) {
      bidRate {
        value
        changeRate
        positiveChange
      }
    }
  }
`;

export const ItemNameTypeAheadQuery = gql`
  query ItemNameTypeAheadQuery($typeAhead: TypeAhead) {
    itemNameTypeAheadQuery(typeAhead: $typeAhead) {
      displayText
      result {
        _id
        createdAt
        userName
        userId
        itemName
        siteName
        itemDesc
        foodType
        foodSubtype
        brand
        handling
        unitOfMeasure
        packQty
        packSize
        packUOM
        grossWeightPerUOM
        netWeightPerUOM
        unitPrice
        unitCost
      }
    }
  }
`;

export const UserItemsQuery = gql`
  ${ItemDetailFragment}
  query UserItemsQuery($paginate: PaginatedItemFilterInput) {
    currentUserQuery {
      _id
      itemFilterParameters {
        locations {
          id
          name
          category
          count
        }
      }
      items(paginate: $paginate) {
        total
        pageContents {
          ...ItemDetails
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const UserItemHistoriesQuery = gql`
  query UserItemHistoriesQuery($paginate: PaginatedItemHistoryFilterInput) {
    currentUserQuery {
      _id
      itemHistoryFilterParameters {
        locations {
          id
          name
          category
          count
        }
      }
      itemHistories(paginate: $paginate) {
        total
        pageContents {
          _id
          itemId
          changedAt
          siteName
          userName
          itemName
          description
          brand
          category
          subCategory
          unitOfMeasure
          unitPrice
          unitCost
          source
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const UserPostsQuery = gql`
  query UserPostsQuery($paginate: PaginatedPostFilterInput) {
    currentUserQuery {
      _id
      firstName
      postFilterParameters {
        _id
        locations {
          id
          name
          category
          count
        }
        sources {
          id
          name
          count
        }
        categories {
          id
          name
          count
        }
        subcategories {
          id
          name
          count
        }
        statuses {
          id
          name
          count
        }
        recipients {
          id
          name
          count
        }
        suppliers {
          id
          name
          count
        }
        destinations {
          id
          name
          count
        }
        vendorReturns {
          id
          name
          count
        }
      }
      posts(paginate: $paginate) {
        total
        pageContents {
          _id
          itemName
          source
          category
          quantity
          unitOfMeasure
          transactedAt
          destination
          status
          isVendorReturn
          actions
          description
          supplierSiteName
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const UserClaimsQuery = gql`
  query UserPostsQuery($paginate: PaginatedPostFilterInput) {
    currentUserQuery {
      _id
      firstName
      postFilterParameters {
        _id
        locations {
          id
          name
          category
        }
        sources {
          id
          name
        }
        categories {
          id
          name
        }
        statuses {
          id
          name
        }
        recipients {
          id
          name
        }
        suppliers {
          id
          name
        }
        destinations {
          id
          name
        }
        vendorReturns {
          id
          name
        }
      }
      claims(paginate: $paginate) {
        total
        pageContents {
          _id
          itemName
          source
          category
          supplierSiteName
          transactionId
          quantity
          unitOfMeasure
          transactedAt
          destination
          status
          isVendorReturn
          actions
          description
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const UserVerificationsQuery = gql`
  query UserVerificationsQuery($paginate: PaginatedPostFilterInput) {
    currentUserQuery {
      _id
      firstName
      postFilterParameters {
        _id
        locations {
          id
          name
          category
        }
        sources {
          id
          name
        }
        categories {
          id
          name
        }
        statuses {
          id
          name
        }
        recipients {
          id
          name
        }
        suppliers {
          id
          name
        }
        destinations {
          id
          name
        }
        vendorReturns {
          id
          name
        }
      }
      pendingVerifications(paginate: $paginate) {
        total
        pageContents {
          _id
          itemName
          source
          category
          pounds
          supplierSiteName
          transactionId
          quantity
          unitOfMeasure
          transactedAt
          destination
          status
          isVendorReturn
          actions
          description
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const TaxDeductiblePostsQuery = gql`
  query UserTaxDeducationQuery($paginate: PaginatedPostFilterInput) {
    currentUserQuery {
      _id
      firstName
      postFilterParameters {
        _id
        locations {
          id
          name
          category
        }
        sources {
          id
          name
        }
        categories {
          id
          name
        }
        statuses {
          id
          name
        }
        recipients {
          id
          name
        }
        suppliers {
          id
          name
        }
        destinations {
          id
          name
        }
        vendorReturns {
          id
          name
        }
      }
      taxDeductiblePosts(paginate: $paginate) {
        total
        pageContents {
          _id
          transactionId
          quantity
          unitOfMeasure
          transactedAt
          status
          actions
          description
          totalCost
          totalPrice
          enhancedTaxDeduction
          outletName
          supplierSiteName
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const UserInventoriesQuery = gql`
  ${InventoryDetailFragment}
  query UserInventoriesQuery($paginate: PaginatedInventoryFilterInput) {
    currentUserQuery {
      _id
      firstName
      inventoryFilterParameters {
        _id
        sites {
          id
          name
          category
          count
        }
        categories {
          id
          name
          count
        }
        publishStatuses {
          id
          name
          count
        }
        handlings {
          id
          name
          count
        }
        truckTypes {
          id
          name
          count
        }
        brands {
          id
          name
          count
        }
        customFilters {
          id
          name
          count
        }
        distributionLists {
          id
          name
          count
        }
        businessUnits {
          id
          name
          count
        }
        showArchived {
          id
          name
          count
        }
      }
      inventory(paginate: $paginate) {
        total
        pageContents {
          ...InventoryDetails
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const PostQuery = gql`
  query PostQuery($id: String!) {
    postByIdQuery(_id: $id) {
      _id
      transactedAt
      transactionId
      source
      category
      quantity
      unitOfMeasure
      destination
      description
      audience
      bestByDate
      mustGoByDate
      claimDate
      completedAtDate
      verifiedAtDate
      supplierSiteName
      status
      quality
      images
      minClaim
      maxDeliveryRadiusMiles
      supplierId
      recipientId
      isVendorReturn
      unitPrice
      isSelfReportedDonation
      isForSale
      isDonation
      isDiscountedSale
      isGiveaway
      distanceInMiles
      distanceInKm
      purchaseOrders {
        purchaseOrderNumber
        description
        amount
      }
      supplierSite {
        _id
        siteName
      }
      outletName
      outletSite {
        _id
        siteName
      }
      itemName
      item {
        _id
        itemName
        itemDesc
        unitOfMeasure
        packQty
        packSize
        packUOM
      }
      actions
      location {
        fullAddress
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const TaxReceiptQuery = gql`
  query TaxReceiptQuery($id: String!) {
    postByIdQuery(_id: $id) {
      _id
      transactedAt
      completedAtDate
      verifiedAtDate
      category
      quantity
      unitOfMeasure
      description
      unitPrice
      transactionId
      totalPrice
      supplierSite {
        _id
        siteName
        physicalAddress {
          city
          streetAddress
          stateCode
          zipCode
        }
      }
      outletSite {
        _id
        siteName
        physicalAddress {
          city
          streetAddress
          stateCode
          zipCode
        }
        taxId
        purpose
        responsibleParty {
          title
          name
          signatureImage
        }
      }
    }
  }
`;

export const ActivityImportQuery = gql`
  query activityImportQuery($paginate: PaginatedBulkImportRunsInput) {
    currentUserQuery {
      _id
      firstName
      activityImports(paginate: $paginate) {
        total
        pageContents {
          _id
          whenLoaded
          userId
          userName
          siteName
          importFile
          importType
          errorReportFile
          status
          totalRows
          totalImported
          totalErrors
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const getDestinationsQuery = gql`
  query getUserQuery {
    currentUserQuery {
      site {
        destinations {
          name
          outlets {
            siteId
            siteName
            userId
          }
        }
      }
    }
  }
`;

export const AllSitesQuery = gql`
  query AllSitesQuery($paginate: PaginatedSitesFilterInput) {
    currentUserQuery {
      _id
      getAllSites(paginate: $paginate) {
        total
        pageContents {
          _id
          siteName
          physicalAddress {
            stateCode
          }
          isEnterprise
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const DiscountedSalesDistributionListQuery = gql`
  query DiscountedSalesDistributionListQuery {
    currentUserQuery {
      _id
      site {
        _id
        distributionLists {
          _id
          name
          destinationType
          color
          buyers {
            siteId
            siteName
          }
        }
      }
    }
  }
`;

export const OfferReviewQuery = gql`
  query OfferReviewQuery($paginate: PaginatedOfferFilterInput) {
    currentUserQuery {
      _id
      offerFilterParameters {
        buyerSiteIds {
          id
          name
          count
        }
        statuses {
          id
          name
          count
        }
      }
      offersReceived(paginate: $paginate) {
        total
        pageContents {
          _id
          delivery
          totalPrice
          awardedOfferListingCount
          totalAwardedPrice
          totalAwardedWeight
          status
          createdAt
          buyerSite {
            _id
            siteName
          }
          itemCount
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const OfferTrucklaneQuery = gql`
  ${InventoryDetailFragment}
  ${OfferListingFragment}
  query OfferTrucklaneQuery(
    $buyerSiteId: String
    $truckType: String
    $logisticsTerm: String
    $transactionLocationId: String
    $sellerLocationId: String
    $buyerDestinationId: String
  ) {
    offerListingsByTrucklaneQuery(
      buyerSiteId: $buyerSiteId
      truckType: $truckType
      logisticsTerm: $logisticsTerm
      transactionLocationId: $transactionLocationId
      sellerLocationId: $sellerLocationId
      buyerDestinationId: $buyerDestinationId
    ) {
      data {
        inventory {
          ...InventoryDetails
        }
        offerListings {
          ...OfferListingDetail
        }
        competingOffers
      }
      errors
    }
  }
`;

export const OfferTrucklaneV2Query = gql`
  ${InventoryDetailFragment}
  ${OfferListingFragment}
  query OfferTrucklaneQuery(
    $buyerSiteId: String
    $truckType: String
    $logisticsTerm: String
    $transactionLocationId: String
    $sellerLocationId: String
    $buyerDestinationId: String
  ) {
    offerListingsByTrucklaneQuery(
      buyerSiteId: $buyerSiteId
      truckType: $truckType
      logisticsTerm: $logisticsTerm
      transactionLocationId: $transactionLocationId
      sellerLocationId: $sellerLocationId
      buyerDestinationId: $buyerDestinationId
    ) {
      data {
        inventory {
          ...InventoryDetails
        }
        offerListings {
          ...OfferListingDetail
        }
        competingOffers
        originLocation {
          latitude
          longitude
        }
        destinationLocation {
          latitude
          longitude
        }
      }
      errors
    }
  }
`;

export const SiteSettingsQuery = gql`
  query SiteSettingsQuery {
    currentUserQuery {
      _id
      privileges {
        canReceiveOffers
      }
      site {
        _id
        siteName
        taxId
        purpose
        isNonProfit
        industries
        responsibleParty {
          name
          signatureImage
          title
        }
        foodQualityList
        foodQualityPreferences
        phoneNumber
        isBuyer
        pacaLicenseNumber
        businessWebsite
        logo
        physicalAddress {
          content
        }
        longitude
        latitude
        responsibleParty {
          name
          signatureImage
          title
        }
        pickupPreferences {
          address
          latitude
          longitude
          radius
          enabled
        }
        dropoffPreferences {
          address
          latitude
          longitude
          radius
          enabled
        }
        foodHandlingList
        foodHandlingPreferences
      }
    }
  }
`;

export const CustomerParticipationSummaryQuery = gql`
  query CustomerParticipationSummaryQuery {
    customerParticipationSummaryQuery {
      buyerSiteId
      buyerSiteName
      status
    }
  }
`;

export const ActiveListingsQuery = gql`
  ${InventoryDetailFragment}
  query getListedInventories {
    currentUserQuery {
      _id
      getListedInventories {
        ...InventoryDetails
        buyerSiteNames
        site {
          _id
          itemImportSite
        }
        offerListingCount
        offerListings {
          _id
          activeTruckLane {
            _id
          }
          buyerDestinationName
          buyerDestinationId
          buyerProposedQuantity
          buyerProposedTotalPrice
          buyerSiteId
          buyerSiteName
          conversionRate
          createdAt
          currencyType
          destinationType
          exportRunId
          exportStatus
          ftpImportUsername
          importedCurrencyTotalPrice
          importedCurrencyType
          inventoryId
          listingId
          logisticsCost
          logisticsRelationshipName
          logisticsTerm
          logisticsTime
          negotiationListingId
          negotiationStatus
          offerId
          originalQuantity
          originalTotalPrice
          poNumber
          quantity
          requestedDeliveryDate
          reserveAnchor
          reserveNumber
          status
          sellerLocationId
          tags
          totalPrice
          transactionCycleId
          transactionLocationId
          weightUnit
          suggestedUnitPrice
          suggestions {
            award {
              suggested
            }
          }
        }
      }
      inventoryFilterParameters {
        _id
        categories {
          id
          name
          count
        }
        businessUnits {
          id
          name
          count
        }
        truckTypes {
          id
          name
          count
        }
        inventoryTypes {
          id
          name
          count
        }
        distributionLists {
          id
          name
          count
        }
      }
    }
  }
`;

export const OfferListingsByInventoryQuery = gql`
  ${InventoryDetailFragment}
  query OfferListingsByInventory($inventoryIds: [String]) {
    offerListingsByInventoryQuery(inventoryIds: $inventoryIds) {
      offerVisualizationUrl
      totalAwarded
      offerListings {
        listing {
          _id
          originalQuantity
          availableQuantity
          suggestedUnitPrice
        }
        _id
        negotiationListingId
        negotiationStatus
        quantity
        totalPrice
        originalQuantity
        originalTotalPrice
        logisticsTerm
        logisticsRelationshipName
        logisticsCost
        destinationType
        activeReservePrice
        trucklaneDistance
        trucklaneCost
        inventory {
          ...InventoryDetails
        }
        negotiations {
          negotiationHistory {
            suggestedUnitPrice
            suggestedQuantity
          }
        }
        offer {
          _id
          buyerSite {
            _id
            siteName
          }
        }
        activeTruckLane {
          _id
          totalRevenue
          totalWeight
          totalPallets
        }
        status
        tags
        suggestions {
          award {
            suggested
            quantity
          }
        }
      }
    }
  }
`;

export const AwardSummaryQuery = gql`
  query AwardSummaryQuery {
    currentUserQuery {
      _id
      getAwardSummary {
        results {
          acceptedOffer
          casesAwarded
          costRecoveryRate
          cubeAdjustedWeightAwarded
          discountPercent
          donatedCost
          exportStatus
          logisticsTerm
          netObsolescenceImpact
          originalQuantity
          palletsAwarded
          priceOfInventoryAwarded
          poundsAwarded
          siteId
          siteName
          totalCases
          totalCost
          totalPrice
          totalWeight
          transactionLocationName
          writeOffDiscountPercent
          writeOffPrice
          writeOffPriceOfInventoryAwarded
          unitPriceRecoveryRate
        }
        totals {
          name
          value
        }
        timestamp
      }
    }
  }
`;

export const OfferListingsByBuyerQuery = gql`
  query OfferListingsByBuyerQuery($buyerSiteIds: [String]) {
    offerListingsByBuyerQuery(buyerSiteIds: $buyerSiteIds) {
      _id
      cases
      costOfInventoryAwarded
      costRecoveryRate
      cubeAdjustedWeight
      discountPercent
      truckType
      importName
      items
      logisticsTerm
      netObsolescenceImpact
      offerListingIds
      offerPrice
      pallets
      priceOfInventoryAwarded
      sellerSiteId
      sellerSiteName
      buyerDestinationId
      buyerDestinationName
      totalRevenue
      transactionLocationId
      transactionLocationName
      unitPriceRecoveryRate
      weight
      writeOffDiscountPercent
      writeOffPriceOfInventoryAwarded
      trucklaneDistance
      trucklaneCost
    }
  }
`;

export const StagedListingsByInventoryQuery = gql`
  query StagedListingsByInventoryQuery($inventoryId: String!) {
    stagedListingsByInventoryQuery(inventoryId: $inventoryId) {
      stagedListings {
        distributionListId
        distributionListName
        availableQuantity
        percentageModifier
        pricingStrategy
      }
      onHandQuantity
      description
    }
  }
`;

export const GetSatisfiedListings = gql`
  query GetSatisfiedListings {
    currentUserQuery {
      _id
      getSatisfiedListings {
        _id
        availableQuantity
        unitGrossWeight
        offers {
          _id
          buyerSiteId
          totalPrice
          quantity
        }
      }
      sitesWithActiveOffers {
        _id
        siteName
        totalOfferValue
      }
    }
  }
`;

export const SiteNameTypeAheadQuery = gql`
  query SiteNameTypeAheadQuery($typeAhead: TypeAhead) {
    siteNameTypeAheadQuery(typeAhead: $typeAhead) {
      displayText
      result {
        siteName
        _id
      }
    }
  }
`;

export const StagedListingSummaryQuery = gql`
  query StagedListingSummaryQuery {
    currentUserQuery {
      _id
      firstName
      lastName
      stagedListingSummary {
        _id
        channel {
          name
          destinationType
        }
        listingId
        createdBy
        updatedAt
        catalogId
        distributionChannelId
        status
        totalWeight
        suggestedTotalPrice
        totalCost
        totalCases
        totalPallets
        totalPrice
        buyerSiteName
        archiveStatus
      }
    }
  }
`;

export const StagedListingSummaryByCatalogQuery = gql`
  query StagedListingSummaryQueryByCatalog($catalogIds: [String]) {
    currentUserQuery {
      _id
      stagedListingSummaryByCatalog(catalogIds: $catalogIds) {
        _id
        channel {
          name
          destinationType
        }
        listingId
        createdBy
        updatedAt
        catalogId
        distributionChannelId
        status
        totalWeight
        suggestedTotalPrice
        totalCost
        totalCases
        totalPallets
        totalPrice
        archiveStatus
      }
    }
  }
`;

export const StagedNegotiationSummaryQuery = gql`
  query StagedNegotiationSummaryQuery {
    currentUserQuery {
      _id
      stagedNegotiationSummary {
        _id
        buyerSiteName
        totalWeight
        suggestedTotalPrice
        totalCost
        totalCases
        totalPrice
        totalPallets
        status
        distributionChannelId
        catalogId
        updatedAt
        createdBy
        listingId
      }
    }
  }
`;

export const StagedListingSummaryWithSiteQuery = gql`
  query StagedListingSummaryWithSiteQuery {
    currentUserQuery {
      _id
      stagedListingSummaryWithSite {
        _id
        channel {
          name
          destinationType
          siteIds
          color
          listedAt
          listId
          published
          offerEmailTemplate
          archived
        }
        catalogId
        totalWeight
        suggestedTotalPrice
        totalCost
        totalCases
        siteName
        distributionChannelId
        totalPallets
        totalCubeAdjustedWeight
        parentSiteId
      }
    }
  }
`;

export const UserStagedListingsQuery = gql`
  ${InventoryDetailFragment}
  query UserStagedListingsQuery($paginate: PaginatedInventoryFilterInput) {
    currentUserQuery {
      _id
      firstName
      stagedInventoryFilterParameters(paginate: $paginate) {
        sites {
          id
          name
          category
          count
        }
        categories {
          id
          name
          count
        }
        statuses {
          id
          name
          count
        }
        handlings {
          id
          name
          count
        }
        truckTypes {
          id
          name
          count
        }
        brands {
          id
          name
          count
        }
        pricingStrategies {
          id
          name
          count
        }
      }
      stagedListingsForChannel(paginate: $paginate) {
        total
        pageContents {
          ...InventoryDetails
          site {
            _id
            allTimeStart
            businessWebsite
            currencyType
            defaultLogisticsTerm
            defaultPricingPercentage
            defaultPricingStrategy
            discountedSalesDistributionLists
            fiscalYearStart
            foodHandlingList
            foodHandlingPreferences
            foodQualityList
            foodQualityPreferences
            geographicRegion
            industries
            isActive
            isBuyer
            isEnterprise
            isNonProfit
            itemImportEmailRecipients
            itemImportFileUrl
            itemImportSite
            latitude
            logisticsTermOptions
            longitude
            parentSite
            pacaLicenseNumber
            phoneNumber
            purpose
            reserveAnchor
            reserveNumber
            rootSite
            siteName
            siteRole
            siteType
            taxId
            transactionCycleId
            transactionImportFileUrl
            weightUnit
          }
          stagedListing {
            _id
            pricingStrategy
            percentageModifier
            anchor
            availableQuantity
            suggestedUnitPrice
            suggestedTotalPrice
            destinationType
            originalQuantity
          }
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const NegotiationStagedListingsQuery = gql`
  ${InventoryDetailFragment}
  ${OfferListingFragment}
  query UserStagedListingsQuery($paginate: PaginatedInventoryFilterInput) {
    currentUserQuery {
      _id
      negotiationStagedInventoryFilterParameters(paginate: $paginate) {
        sites {
          id
          name
          category
          count
        }
        categories {
          id
          name
          count
        }
        statuses {
          id
          name
          count
        }
        handlings {
          id
          name
          count
        }
        truckTypes {
          id
          name
          count
        }
        brands {
          id
          name
          count
        }
        pricingStrategies {
          id
          name
          count
        }
      }
      negotiationStagedListings(paginate: $paginate) {
        total
        pageContents {
          negotiationListing {
            _id
            inventoryId
            transactionCycleId
            createdAt
            createdBy
            quantity
            suggestedUnitPrice
            suggestedTotalPrice
            suggestedQuantity
            pricingStrategy
            percentageModifier
            externalListingId
            reason
            offerDetails {
              quantity
            }
          }
          offerListing {
            ...OfferListingDetail
            listing {
              _id
              availableQuantity
            }
          }
          ...InventoryDetails
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const SmartAwardSummaryQuery = gql`
  query SmartAwardSummaryQuery($selections: [OfferSelectionType], $smartAwardType: String, $sellerSiteIds: [String]) {
    currentUserQuery {
      _id
      smartAwardInventoryFilterParameters {
        sites {
          id
          name
          category
        }
      }
      smartAwardTypes {
        name
        id
      }
      smartAwardSummary(selections: $selections, smartAwardType: $smartAwardType, sellerSiteIds: $sellerSiteIds) {
        transactionLocationSummary {
          _id
          buyerSiteName
          buyerSiteId
          transactionLocationId
          transactionLocationName
          logisticsTerm
          caseCount
          palletCount
          weight
          recoveryRate
          offerPrice
          offerRate
          awardRate
          totalRevenue
        }
        buyerMetrics {
          buyerSiteId
          recoveryRate
          offerPrice
          offerRate
          awardRate
        }
        totalMetrics {
          recoveryRate
          offerPrice
          offerRate
          awardRate
        }
      }
    }
  }
`;

export const SmartAwardSummaryV3Query = gql`
  ${OfferListingFragment}
  query SmartAwardSummaryV3Query($commitAwards: Boolean, $excludedTrucklanes: [String], $includedOrigins: [String]) {
    currentUserQuery {
      _id
      smartAwardSummaryV3(commitAwards: $commitAwards, excludedTrucklanes: $excludedTrucklanes, includedOrigins: $includedOrigins) {
        trucklanes {
          _id
          buyerSiteName
          buyerSiteId
          buyerLocationName
          buyerLocationId
          sellerLocationName
          sellerLocationId
          logisticsTerm
          truckType
          trucklaneDistance
          laneTotals {
            offerCount
            caseCount
            palletCount
            totalWeight
            costOfAwardedInventory
            totalRevenue
            totalEstDeliveryCost
            totalPrice
          }
          existingLane {
            offerCount
            caseCount
            palletCount
            totalWeight
            costOfAwardedInventory
            totalRevenue
            totalEstDeliveryCost
            totalPrice
          }
        }
        offersAwarded
        offerListings {
          ...OfferListingDetail
        }
        showZeroState
        errors {
          code
          message
        }
      }
    }
  }
`;

export const SmartAwardMaxRevenue = gql`
  query SmartAwardMaxRevenue {
    currentUserQuery {
      _id
      smartAwardMaxRevenue
    }
  }
`;

export const PossibleNegotiationsSummary = gql`
  query PossibleNegotiationsSummary {
    possibleNegotiationsSummaryQuery {
      offerListingsToNegotiate {
        id
        quantity
      }
      estimatedRevenueIncrease
      numberOfCases
      errors {
        code
        message
      }
    }
  }
`;

export const UserTransactionHistoryQuery = gql`
  ${InventoryDetailFragment}
  query UserTransactionHistoryQuery($paginate: PaginatedInventoryFilterInput) {
    currentUserQuery {
      _id
      transactionCycleHistory {
        id
        startedAt
        endedAt
        siteId
      }
      transactionFilterParameters {
        recipientSiteIds {
          id
          name
          count
        }
        sites {
          id
          name
          category
          count
        }
        brands {
          id
          name
          count
        }
        cycleDate {
          id
          name
          count
        }
      }
      transactionHistory(paginate: $paginate) {
        total
        pageContents {
          ...InventoryDetails
          transaction {
            id
            _id
            transactedAt
            supplierSiteName
            recipientSiteName
            quantity
            totalSalePrice
            buyerDestinationName
            inventoryId
            offerListingId
            transactionCycleSiteName
            metaData {
              externalTransactionId
            }
            cycleStartDate
            cycleEndDate
          }
        }
        limit
        pageNumber
        totalPages
      }
    }
  }
`;

export const ProgressBarsQuery = gql`
  query ProgressBarsQuery {
    progressBarsQuery {
      _id
      inventory
      staged
      listed
      received
      awarded
      accepted
    }
  }
`;

export const OfferReviewPollingQuery = gql`
  query OfferReviewPollingQuery {
    offerReviewPollingQuery {
      uiLockStatus
      exportStatuses {
        buyerSiteName
        exportStatus
      }
      timestamp
    }
  }
`;

export const OfferReviewDetailsPollingQuery = gql`
  query OfferReviewDetailsPollingQuery($buyerSiteId: String!) {
    offerReviewDetailsPollingQuery(buyerSiteId: $buyerSiteId) {
      exportStatus
    }
  }
`;

export const GetBuyerProfilesQuery = gql`
  query GetBuyerProfilesQuery {
    buyerProfiles {
      _id
      buyerName
    }
  }
`;

export const getHandlingMappingsBySiteIdQuery = gql`
  query getHandlingMappingsBySiteId($siteId: String!) {
    getHandlingMappingsBySiteId(siteId: $siteId) {
      name
      siteId
      _id
      truckType
    }
  }
`;

export const DistributionListsByBuyerAndSellerQuery = gql`
  query distributionListsByBuyerAndSeller($buyerSiteName: String!) {
    distributionListsByBuyerAndSeller(buyerSiteName: $buyerSiteName) {
      contacts {
        contactType
        email
      }
      distributionLists {
        _id
        name
        customerCount
        lastUsed
        offerEmailContacts
        awardEmailContacts
      }
    }
  }
`;

export const DistributionListsWithBuyersQuery = gql`
  query distributionListsWithBuyersQuery($siteId: String!) {
    distributionListsWithBuyers(siteId: $siteId) {
      _id
      name
      destinationType
      archived
      color
      buyers {
        siteId
        siteName
      }
    }
  }
`;

export const DistributionListContacts = gql`
  query distributionListContactsQuery($listId: String) {
    distributionListContactsQuery(listId: $listId) {
      seeds {
        type
        sellerSiteId
        buyerSiteId
        sellerUserEmails
        buyerUserEmails
      }
      customers {
        sellerSiteId
        buyerSiteId
        buyerName
        contacts {
          _id
          key
          value
          groupBy
        }
      }
      notInUse
    }
  }
`;

export const GetStatusOfSelectedInventoryForArchiveQuery = gql`
  query getStatusOfSelectedInventoryForArchive($inventoryFilters: InventoryFilterInput) {
    getStatusOfSelectedInventoryForArchive(inventoryFilters: $inventoryFilters) {
      status
      distributionLists
    }
  }
`;

export const trucklaneInitializePollingQuery = gql`
  query trucklaneInitializePollingQuery {
    trucklaneInitializePollingQuery {
      trucklaneChannels {
        _id
        originDCId
        truckType
        status
        updatedAt
      }
      errors
    }
  }
`;

export const trucklanePollingQuery = gql`
  query trucklanePollingQuery($pollingDetails: [pollingDetails]) {
    trucklanePollingQuery(pollingDetails: $pollingDetails) {
      trucklaneChannels {
        _id
        originDCId
        truckType
        status
        updatedAt
      }
      errors
    }
  }
`;

export const trucklaneViewQuery = gql`
  query trucklaneViewQuery($getOutOfSyncChannels: Boolean) {
    trucklaneViewQuery(getOutOfSyncChannels: $getOutOfSyncChannels) {
      trucklanes {
        id
        offers {
          _id
          buyerDestinationName
          buyerProposedQuantity
          buyerProposedTotalPrice
          buyerSiteId
          buyerSiteName
          buyerDestinationId
          conversionRate
          createdAt
          currencyType
          destinationType
          exportRunId
          exportStatus
          importedCurrencyTotalPrice
          importedCurrencyType
          inventoryId
          listingId
          logisticsCost
          logisticsRelationshipName
          logisticsTerm
          logisticsTime
          negotiationListingId
          negotiationStatus
          offerId
          originalQuantity
          originalTotalPrice
          poNumber
          quantity
          requestedDeliveryDate
          reserveAnchor
          reserveNumber
          status
          sellerLocationId
          tags
          totalPrice
          transactionCycleId
          transactionLocationId
          weightUnit
          suggestedUnitPrice
          trucklaneDistance
          trucklaneCost
          truckType
          suggestions {
            award {
              suggested
              quantity
            }
          }
        }
        origin
        truckType
        status
        channelLastUpdatedAt
        hasSuggestions
      }
      errors {
        code
        message
      }
    }
  }
`;

export const listedAndAcceptedInventoryQuery = gql`
  query listedAndAcceptedInventoryQuery {
    listedAndAcceptedInventoryQuery {
      _id
      casesPerPallet
      description
      itemName
      siteId
      siteName
      unitCost
      unitGrossWeight
    }
  }
`;

export const HolisticsDashboardsQuery = gql`
  query holisticsDashboardsQuery {
    holisticsDashboardsQuery {
      _id
      name
      type
      defaultForLocation
      embed {
        _id
        embedCode
        token
      }
    }
  }
`;

export const SuggestedOfferShiftsByBuyerQuery = gql`
  query suggestedOfferShiftsByBuyerQuery {
    suggestedOfferShiftsByBuyerQuery {
      buyers {
        _id
        originalOfferListings {
          _id
          itemName
          itemDescription
          bestByDate
          quantity
          unitPrice
          inventoryStatus
          sellerLocationName
          offerShifts {
            _id
            shiftedQuantity
            inventoryQuantity
            unitPrice
            status
            bestByDate
            sellerLocationSiteName
            shiftInventoryId
            costRecoveryRate
            lowRiskBBD
            aboveReservePrice
            sameDC
            inventoryStatus
            casesPerPallet
            unitGrossWeight
            buyerDestinationName
            trucklaneRevenue
            trucklaneWeight
            trucklanePallets
            truckType
            trucklaneDistance
            logisticsTerm
            logisticsOptions
          }
        }
      }
      errors {
        code
        message
      }
    }
  }
`;
