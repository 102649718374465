import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, FilterIcon, Theme as theme } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { Grid } from '../grid';

const styles = {
  filter__row: {
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    display: 'flex',
  },
  'dashboard__filter-row': {
    position: 'relative',
  },
  'dashboard__filter-toggle': {
    display: 'none',
  },
  filter__icon: {
    display: 'none',
  },
  '@media (max-width: 840px)': {
    filter__row: {
      position: 'absolute',
      backgroundColor: '#fff',
      zIndex: 9,
      margin: '0 -16px',
      padding: '0 8px',
      transform: 'scaleY(0)',
      transitionDuration: '300ms',
      transformOrigin: '50% 0',
      width: '100%',
    },
    'filter__row--show': {
      display: 'block',
      boxShadow: '0 7px 8px 0 rgba(0, 0, 0, 0.06)',
      borderBottom: 'solid 1px #d9dee1',
      transform: 'scaleY(1)',
    },
    'dashboard__filter-toggle': {
      display: 'block',
      fontSize: '1rem',
      color: theme.textColorPrimary,
      border: 'none',
      backgroundColor: 'transparent',
      padding: 0,
      margin: 0,
      cursor: 'pointer',
      outline: 'none',
      marginBottom: 10,
    },
    filter__icon: {
      height: 22,
      width: 16,
      fill: theme.menuIconFillColor,
      margin: '-2px 8px 0 5',
      display: 'block',
    },
  },
};

@injectSheet(styles)
export default class FilterRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  toggleFilter = () => {
    this.setState({ show: !this.state.show });
  };

  hide = () => {
    this.setState({ show: false });
  };

  render() {
    const { classes } = this.props;
    const filterClass = `${classes.filter__row}${this.state.show ? ` ${classes['filter__row--show']}` : ''}`;
    return (
      <ClickAwayListener onClickAway={this.hide}>
        <Grid xs={12} className={classes['dashboard__filter-row']}>
          <button type="button" onClick={this.toggleFilter} className={classes['dashboard__filter-toggle']}>
            <FilterIcon className={classes.filter__icon} /> Filter
          </button>
          <div className={filterClass}>{this.props.children}</div>
        </Grid>
      </ClickAwayListener>
    );
  }
}

FilterRow.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
};
