import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { inject, observer } from 'mobx-react';
import {
  GraphQLDataTable,
  Column,
  Filter,
  RowAction,
  SplitIcon,
  CreateListingsIcon,
  Button,
  AggregateIcon,
  CreateDonationsIcon,
  MultiButton,
} from '@spoiler-alert/ui-library';
import { UserInventoriesQuery } from '../../graphql/queries';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import FeatureFlag from '../../layouts/feature-flag';
import featureFlags from '../../enums/feature-flags';
import checkFeature from '../../helpers/check-feature-flag';
import InventoryStatus from './status/inventory-status';

const styles = {
  select__box: {
    order: 0,
    marginRight: '12px !important',
  },
  search: {
    order: 2,
    marginLeft: '15px !important',
  },
  search__row: {
    justifyContent: 'flex-start',
  },
  filter__button: {
    order: 3,
  },
  panelContent: {
    padding: '8px 0px 8px 0px !important',
    textAlign: 'left',
  },
  actionMenuOption: {
    padding: '7px 16px 7px 16px !important',
  },
};

class InventoryTable extends Component {
  static propTypes = {
    user: PropTypes.object,
    deselect: PropTypes.bool,
    handleSelectChange: PropTypes.func,
    classes: PropTypes.object,
    handleQueryChange: PropTypes.func,
    showFlyout: PropTypes.func,
    children: PropTypes.node,
    onSplitListingClick: PropTypes.func,
    openListInventoryMenu: PropTypes.func,
    openDonateInventoryMenu: PropTypes.func,
    donateActionOnRows: PropTypes.array,
    actionOnRows: PropTypes.array,
    rootStore: PropTypes.object,
    userFiltersStore: PropTypes.object,
    listMultipleInventories: PropTypes.func,
    donateMultipleInventories: PropTypes.func,
    archiveMultipleInventories: PropTypes.func,
    unarchiveMultipleInventories: PropTypes.func,
    customFilterActive: PropTypes.bool,
    showAggregateModal: PropTypes.func,
    archiveLoading: PropTypes.bool,
    archiveEnabled: PropTypes.bool,
    viewingArchived: PropTypes.bool,
  };
  static defaultProps = {
    handleQueryChange: () => {},
  };

  state = { closeOnPromise: null, showPanel: false };

  constructor(props) {
    super(props);
    this.filters = this.createInitialInventoryFilters(props.user);
  }

  componentDidMount() {
    const filterState = this.filters.reduce((filtersObj, filter) => {
      if (filter.filterType === 'daterange') {
        filtersObj[filter.options?.startParam || 'startDate'] = filter.range?.start?.toDate();
        filtersObj[filter.options?.endParam || 'endDate'] = filter.range?.end?.toDate();
      }

      if (filter.queryField && filter.values.length > 0) {
        filtersObj[filter.queryField] = filter.values.map((v) => v.value);
      }

      return filtersObj;
    }, {});

    const variables = { paginate: { pageNumber: 1, limit: 30, filter: { sortBy: { asc: true, sortProperty: 'bestByDate' } } } };
    const paginateFilter = { ...variables.paginate.filter, ...filterState };
    this.props.handleQueryChange({ paginate: { ...variables.paginate, filter: paginateFilter } });
  }

  createInitialInventoryFilters(user) {
    const { featureMap } = this.props.rootStore;
    const showUploadHistory = !!featureMap.get('UPLOAD HISTORY');

    const locationFilter = { displayName: 'Location', field: 'sites', queryField: 'sites', selectAll: true, filterType: 'search' };
    const defaultFilters = [
      { displayName: 'Upload History', field: 'importBatches', filterType: 'search', selectAll: true },
      { displayName: 'Code Date Range', filterType: 'daterange', options: { startParam: 'bestByStartDate', endParam: 'bestByEndDate' } },
      { displayName: 'Category', field: 'categories', selectAll: true },
      { displayName: 'Status', field: 'publishStatuses', selectAll: true },
      { displayName: 'Custom Filter', field: 'customFilters', selectAll: false, multiple: false },
      { displayName: 'Handling', field: 'handlings', selectAll: true },
      { displayName: 'Truck Type', field: 'truckTypes', selectAll: true },
      { displayName: 'Brand', field: 'brands', filterType: 'search', selectAll: true },
      { displayName: 'Distribution List', field: 'distributionLists', filterType: 'search', selectAll: true },
      { displayName: 'Business Unit', field: 'businessUnits', filterType: 'search', selectAll: true },
    ];
    const archivedFilter = {
      displayName: 'Archive Status',
      field: 'showArchived',
      selectAll: true,
      multiple: false,
    };
    if (!showUploadHistory) defaultFilters.unshift({ displayName: 'Created Date Range', filterType: 'daterange' });
    if (user.privileges.canUserManageMultipleSites) defaultFilters.splice(1, 0, locationFilter);
    if (this.props.archiveEnabled) defaultFilters.push(archivedFilter);

    const storeFilters = this.props.userFiltersStore?.filters?.inventoryTable || [];
    const filters = defaultFilters.map((df) => new Filter({ ...df, ...storeFilters.find((sf) => sf?.displayName === df.displayName) }));

    return filters;
  }

  getInventoryColumns = () => {
    const { user } = this.props;

    const additionalColumn = new Column({
      field: 'status',
      displayName: 'Status',
      sortable: false,
      style: { padding: '11px 0' },
      formatter: (value, row) => (
        <InventoryStatus
          status={value}
          inventoryId={row._id}
          show={!value.donationAccepted && !value.donationReceived && !value.donationAwarded && !value.donationListed && !value.donationStaged}
          showArchived={this.props.viewingArchived}
        />
      ),
    });

    const inventoryColumns = [...getColumnsFromDataTableProfile('Inventory', user.site.dataTableProfiles), additionalColumn];
    return inventoryColumns;
  };

  rowActions = (donationsEnabled) => {
    const { viewingArchived } = this.props;
    const rowActions = [
      <RowAction
        key={1}
        tooltipText="Split"
        icon={SplitIcon}
        onClick={(row) => this.props.onSplitListingClick.bind(this, row)}
        secondary
        disabledRows={this.props.actionOnRows}
        disabled={viewingArchived}
        cypressTag="split-inventory"
      />,
    ];

    if (donationsEnabled)
      rowActions.push(
        <RowAction
          key={2}
          tooltipText="List Donation To"
          loadingTooltipText="Creating Listing"
          icon={CreateDonationsIcon}
          onClick={(row) => this.props.openDonateInventoryMenu.bind(this, row)}
          loadingRows={this.props.donateActionOnRows}
          disabledRows={this.props.donateActionOnRows}
          disabled={viewingArchived}
        />
      );

    rowActions.push(
      <RowAction
        key={3}
        tooltipText="Stage Listing"
        loadingTooltipText="Creating Listing"
        icon={CreateListingsIcon}
        onClick={(row) => this.props.openListInventoryMenu.bind(this, row)}
        loadingRows={this.props.actionOnRows}
        disabledRows={this.props.actionOnRows}
        disabled={viewingArchived}
        cypressTag="list-to"
      />
    );

    return rowActions;
  };

  getButton = () => {
    return [
      <Button
        key="createlistings"
        type="button"
        icon={CreateListingsIcon}
        onClick={this.props.listMultipleInventories}
        getReference={this.setReference}
      >
        Create Listings
      </Button>,
    ];
  };

  getMulti = (donationsEnabled, archiveEnabled) => {
    const {
      listMultipleInventories,
      donateMultipleInventories,
      archiveMultipleInventories,
      unarchiveMultipleInventories,
      archiveLoading,
      viewingArchived,
    } = this.props;

    const multiActions = [];

    if (archiveEnabled) {
      multiActions.unshift(
        viewingArchived
          ? {
              name: 'Unarchive Inventory',
              onClick: (event) => {
                unarchiveMultipleInventories(event);
              },
            }
          : {
              name: 'Archive Inventory',
              onClick: (event) => {
                archiveMultipleInventories(event);
              },
            }
      );
    }
    if (donationsEnabled) {
      multiActions.unshift({
        name: 'Create Donation Listings',
        onClick: (event) => {
          donateMultipleInventories(event);
        },
        disabled: viewingArchived,
      });
    }

    return [
      <MultiButton
        key="createlistings"
        primaryAction={{
          name: 'Stage Listings',
          onClick: (event) => {
            listMultipleInventories(event);
          },
          disabled: viewingArchived,
        }}
        actions={multiActions}
        primaryIcon={CreateListingsIcon}
        loading={archiveLoading}
        loadingText={viewingArchived ? 'Unarchiving Inventory' : 'Archiving Inventory'}
      ></MultiButton>,
    ];
  };

  getBulkActionButtons = (donationsEnabled, archiveEnabled) => {
    if (archiveEnabled || donationsEnabled) return this.getMulti(donationsEnabled, archiveEnabled);
    return this.getButton();
  };

  render() {
    const { user, deselect, handleQueryChange, children, handleSelectChange, showFlyout, customFilterActive, showAggregateModal, archiveEnabled } =
      this.props;
    const pageActionButtons = [];
    const donationsEnabled = checkFeature(featureFlags.donationsv2);

    if (customFilterActive && user.site.transactionCycleId) {
      pageActionButtons.push(
        <FeatureFlag key="aggregate" featureName={'AGGREGATE BUTTON'} showChildrenWhenEnabled={true}>
          <Button type="button" secondary onClick={showAggregateModal} icon={AggregateIcon}>
            Aggregate All
          </Button>
        </FeatureFlag>
      );
    }
    return (
      <GraphQLDataTable
        query={UserInventoriesQuery}
        queryName="currentUserQuery"
        queryField="inventory"
        userId={user._id}
        search
        transition
        columns={this.getInventoryColumns(showFlyout)}
        filterable="internal"
        filterParameterField="inventoryFilterParameters"
        filters={this.filters}
        pagination
        selectable
        perPage={30}
        infinite
        deselect={deselect}
        customContent={children}
        onFilterChange={handleQueryChange}
        onSelectChange={handleSelectChange}
        updateStoredFilters={(filters) => this.props.userFiltersStore.updateFilters('inventoryTable', filters)}
        rowActions={this.rowActions(donationsEnabled)}
        pageActionButtons={pageActionButtons}
        bulkActionButtons={this.getBulkActionButtons(donationsEnabled, archiveEnabled)}
      />
    );
  }
}

const ConnectedComponent = inject((store) => store)(observer(InventoryTable));
const StyledComponent = injectSheet(styles)(ConnectedComponent);
export default StyledComponent;
