import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { TitleService } from '../../services';

const styles = {
  login__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: '0 auto',
    marginBottom: '40px',
    textAlign: 'center',
  },
  login__title: {
    fontSize: '1.3rem',
  },
  link__list: {
    display: 'inline-block',
  },
  unverified__paragraph: {
    textAlign: 'left',
  },
};

@injectSheet(styles)
export default class Unverified extends Component {
  constructor(props) {
    super(props);
    TitleService.setTitle('Unverified');
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.login__container}>
        <h1 className={classes.login__title}>Welcome to Spoiler Alert!</h1>
        <div className={classes.unverified__paragraph}>
          <header>
            <h4>If you are seeing this page, you likely need to clear your web browser&apos;s cache before entering the Spoiler Alert platform.</h4>
          </header>
          <p>
            For instructions on how to clear your cache on various web browsers, click <a href="https://kb.iu.edu/d/ahic">here</a>.
          </p>
          <p>
            Need help? Contact the Spoiler Alert support team at{' '}
            <a href="mailto:support@spoileralert.zendesk.com?Subject=Login%20issues" target="_top">
              support@spoileralert.zendesk.com
            </a>
          </p>
        </div>
        <h4 className={classes.login__title}>Having Trouble Logging In?</h4>

        <div className={classes.unverified__paragraph}>
          <h4>If you have recently logged in with a Temporary Password that we have sent to you in an email, please try clearing your cookies</h4>
          <ul className={classes.link__list}>
            <li>
              <a href="http://www.digitaltrends.com/computing/how-to-delete-cookies-in-chrome-firefox-safari-and-ie/#Chrome">Google Chrome</a>
            </li>
            <li>
              <a href="http://www.digitaltrends.com/computing/how-to-delete-cookies-in-chrome-firefox-safari-and-ie/#Firefox">Mozilla Firefox</a>
            </li>
            <li>
              <a href="http://www.digitaltrends.com/computing/how-to-delete-cookies-in-chrome-firefox-safari-and-ie/#Safari">Safari</a>
            </li>
            <li>
              <a href="http://www.digitaltrends.com/computing/how-to-delete-cookies-in-chrome-firefox-safari-and-ie/#IE">Internet Explorer</a>
            </li>
            <li>
              <a href="http://www.digitaltrends.com/computing/how-to-delete-cookies-in-chrome-firefox-safari-and-ie/#CCleaner">CCleaner</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

Unverified.propTypes = {
  classes: PropTypes.object,
};
