import React from 'react';
import moment from 'moment';
import accounting from 'accounting';
import { Column } from '@spoiler-alert/ui-library';
import statusMap from '../status-map';

const columns = [
  new Column({
    field: 'transactedAt',
    displayName: 'Transaction Date',
    sortable: true,
    defaultSort: true,
    formatter: (value) => moment(value).format('M/DD/YYYY h:mm a'),
  }),
  new Column({ field: 'supplierSiteName', displayName: 'Supplier', sortable: true }),
  new Column({ field: 'category', displayName: 'Category', sortable: true }),
  new Column({
    field: 'description',
    displayName: 'Description',
    sortable: true,
    formatter(value) {
      let displayText = value;
      if (value.length > 50) displayText = `${value.substr(0, 50).trim()}\u2026`;
      return <span title={value}>{displayText}</span>;
    },
  }),
  new Column({
    field: 'quantity',
    displayName: 'Quantity',
    sortable: true,
    formatter: (value, row) => `${accounting.formatNumber(value)} ${row.unitOfMeasure}`,
  }),
  new Column({
    field: 'pounds',
    displayName: 'Weight',
    sortable: false,
    formatter: (value) => `${accounting.formatNumber(value, { precision: 2 })} lbs`,
  }),
  new Column({ field: 'transactionId', displayName: 'Transaction #', sortable: true }),
  new Column({ field: 'status', displayName: 'Status', sortable: true, formatter: (value, row, col) => col.enumMap.get(value), enumMap: statusMap }),
];

export default columns;
