import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Button } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import passwordService from '../../services/password-service';
import { TitleService } from '../../services';

const styles = {
  login__container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: '1170px',
    margin: '0 auto',
  },
  login__title: {
    fontSize: '1.3rem',
    marginBottom: '40px',
    textAlign: 'center',
  },
  form__row: {
    width: '360px',
    marginBottom: '15px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  form__container: {
    marginBottom: '2.8rem',
  },
  form__button: {
    width: '100%',
    textTransform: 'uppercase',
  },
  message__container: {
    width: '360px',
    borer: '1px solid #d6e9c6',
    backgroundColor: '#dff0d8',
    color: '#3c763d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    height: '36px',
    marginBottom: '15px',
  },
  'message__container--error': {
    borer: '1px solid #ebccd1',
    backgroundColor: '#f2dede',
    color: '#a94442',
  },
  login__link: {
    color: '#5d9bd1',
    cursor: 'pointer',
  },
};

@injectSheet(styles)
export default class SetResetPassword extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('Reset Account');
    this.state = {
      password: '',
      confirmPassword: '',
      messageType: 'none',
      message: '',
    };

    this.submitResetPassword = this.submitResetPassword.bind(this);
  }

  updatePassword = (password) => {
    this.setState({ password });
  };

  updateConfirmPassword = (confirmPassword) => {
    this.setState({ confirmPassword });
  };

  validatePassword = () => {
    const { password, confirmPassword } = this.state;
    return password.trim().length > 0 && password.length >= 6 && password === confirmPassword;
  };

  handleSignInClick = () => {
    this.props.history.push('/sign-in');
  };

  success = () => {
    this.setState({
      messageType: 'success',
      loading: false,
    });
    window.setTimeout(this.handleSignInClick, 2000);
  };

  resetPasswordFailed = (error) => {
    const errorMsg = error === 'Reset token not found' || error === 'Password is too short' ? error : "Sorry, but we couldn't set your password.";
    this.setState({
      messageType: 'error',
      message: errorMsg,
      loading: false,
    });
  };

  resetPassword() {
    const { password } = this.state;
    passwordService
      .resetPassword(this.props.match.params.token, password)
      .then((res) => (res.ok ? this.success() : res.text().then((data) => this.resetPasswordFailed(data))))
      .catch(this.resetPasswordFailed);
  }

  async submitResetPassword(event) {
    event.preventDefault();
    if (this.validatePassword()) {
      this.setState({ loading: true });
      this.resetPassword();
    } else {
      this.resetPasswordFailed({ reason: 'Your passwords do not match.' });
    }
  }

  render() {
    const { classes, newUser } = this.props;
    const { messageType, loading, password, confirmPassword, message } = this.state;
    const messageClassName = `${classes.message__container} ${messageType === 'error' ? classes['message__container--error'] : ''}`;
    const titleText = newUser ? 'Set your Password' : 'Reset your Password';
    const buttonText = newUser ? 'Set Password' : 'Reset Password';
    const messageText =
      messageType === 'success' ? (
        <p>
          Your password was set successfully. You are logged in and will be redirected. If you are not redirected, click{' '}
          <span className={classes.login__link} onClick={this.handleSignInClick}>
            here
          </span>
          .
        </p>
      ) : (
        message
      );

    return (
      <div className={classes.login__container} data-element="reset-password">
        <h4 className={classes.login__title}>{titleText}</h4>
        {messageType !== 'none' && (
          <div className={messageClassName} data-element={`message-block-${messageType}`}>
            {messageText}
          </div>
        )}
        <form onSubmit={this.submitResetPassword} className={classes.form__container}>
          <div className={classes.form__row} data-element="password">
            <TextInput
              id="password"
              onChange={this.updatePassword}
              required
              type="password"
              labelText="New Password"
              value={password}
              pattern=".{6,}"
              title="Your Password must be at least 6 characters"
            />
          </div>

          <div className={classes.form__row} data-element="confirm-password">
            <TextInput
              id="confirm-password"
              onChange={this.updateConfirmPassword}
              required
              type="password"
              labelText="Confirm New Password"
              value={confirmPassword}
              pattern=".{6,}"
              title="Your Password must be at least 6 characters"
            />
          </div>

          <div className={classes.form__row}>
            <Button className={classes.form__button} id="set-password" type="submit" loading={loading} loadingText="Loading...">
              {buttonText}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

SetResetPassword.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  newUser: PropTypes.bool,
};
