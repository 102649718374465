import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Theme,
  Button,
  Checkbox,
  icons,
  SideDropArrowIcon,
  DryIcon,
  RefrigeratedIcon,
  FrozenIcon,
  PickupIcon,
  DeliveryIcon,
  ActiveLaneIcon,
  AlertService,
  Timing,
  ColorBadge,
} from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import accounting from 'accounting';
import { useMutation } from '@apollo/client';
import { event } from 'react-fullstory';
import TrucklaneCardSummary from './trucklane-card-summary';
import { offerlistingStatuses } from '../../enums';
import { IgnoreOfferListings, RestoreOfferListings } from '../../graphql/mutations';
import { OfferListingsByInventoryQuery, trucklanePollingQuery } from '../../graphql/queries';
import { Store } from '../../store';

const styles = {
  card: (selected) => ({
    border: `1px solid ${selected ? Theme.green : Theme.grey30}`,
    backgroundColor: selected ? Theme.green5 : Theme.white,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '12px 0px',
    transition: '500ms ease-in-out',
    transitionProperty: 'transform opacity',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: Theme.grey5,
      border: `1px solid ${Theme.greyDark}`,
    },
  }),
  checkboxWrapper: {
    height: '50px',
  },
  checkbox: {
    padding: '16px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardOverview: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px 24px 16px',
    '& h1': {
      fontSize: '14px',
      fontWeight: '500',
      margin: 0,
    },
    '& svg': {
      marginRight: '8px',
    },
    '& h2': {
      fontSize: '18px',
      fontWeight: 'normal',
      margin: 0,
    },
  },
  overviewLogistics: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      margin: 0,
      fontSize: '16px',
      color: Theme.grey,
      fontWeight: 'normal',
      marginRight: 24,
    },
  },
  overviewActions: {
    marginTop: 'auto',
    display: 'flex',
    '& button': {
      marginRight: 8,
    },
  },
  row: {
    display: 'flex',
  },
  rowBreak: {
    marginTop: '12px',
  },
  logisticsIcon: {
    height: 16,
    width: 16,
    fill: Theme.grey,
  },
  activeLane: {
    border: `1px solid ${Theme.primaryColor}`,
    backgroundColor: Theme.green5,
    borderRadius: '2px',
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
    '& p': {
      color: Theme.primaryColor,
      width: 'fit-content',
      padding: '2px 8px 2px 0px',
      marginBottom: 0,
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '0.75rem',
      whiteSpace: 'nowrap',
    },
    '& svg': {
      stroke: Theme.primaryColor,
      fill: 'transparent',
      width: 20,
      height: 20,
      marginLeft: 8,
    },
  },
};

const TrucklaneCard = ({ offers, suggested, recalculation, trucklaneId, selected, handleSelect, awarded, deSelect, user, openDrilldown }) => {
  const useStyles = createUseStyles(styles);
  const classes = useStyles(selected);
  const [awardSuggestions, setAwardSuggestions] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const aggregateOffer = offers[0];
  const activeTruckLane = offers.filter((offer) => [offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status));
  const ignoredOffers = offers.filter((offer) => [offerlistingStatuses.IGNORED].includes(offer.status));
  const [ignoreOffersMutation, { loading: ignoreOffersLoading }] = useMutation(IgnoreOfferListings);
  const [restoreOffersMutation, { loading: restoreOffersLoading }] = useMutation(RestoreOfferListings);

  const logisticsIcons = {
    Pickup: PickupIcon,
    Delivery: DeliveryIcon,
  };

  const truckTypeIcons = {
    Dry: DryIcon,
    Refrigerated: RefrigeratedIcon,
    Frozen: FrozenIcon,
  };

  const LogisticsIcon = logisticsIcons[aggregateOffer.logisticsTerm];
  const TruckIcon = truckTypeIcons[aggregateOffer.truckType];

  const displayLogisticsTerm = () => {
    return aggregateOffer.logisticsTerm === 'Pickup' ? (
      <div className={classes.overviewLogistics}>
        <LogisticsIcon className={classes.logisticsIcon} />
        <p>{aggregateOffer.logisticsTerm}</p>
      </div>
    ) : (
      <div className={classes.overviewLogistics}>
        <LogisticsIcon className={classes.logisticsIcon} />
        <p>
          {aggregateOffer.logisticsTerm} - {accounting.formatNumber(aggregateOffer.trucklaneDistance)} miles
        </p>
        <p>{accounting.formatMoney(aggregateOffer.trucklaneCost)} Est. Cost</p>
      </div>
    );
  };

  const ignoreOffers = Timing.throttle((e) => {
    e.stopPropagation();
    const matchedOffers = offers.filter((offer) => offer.status === offerlistingStatuses.ACTIVE);
    setActionLoading(true);
    if (suggested) deSelect(trucklaneId);
    ignoreOffersMutation({
      variables: { offerListingIds: matchedOffers.map((offer) => offer._id) },
      refetchQueries: [
        {
          query: trucklanePollingQuery,
          variables: {
            pollingDetails: [...Store.trucklaneChannels.values()].map((channel) => ({ origin: channel.originDCId, truckType: channel.truckType })),
          },
        },
        {
          query: OfferListingsByInventoryQuery,
          variables: { inventoryIds: matchedOffers.map((offer) => offer.inventory._id) },
        },
      ],
    }).then((ignoreResp) => {
      setActionLoading(false);
      if (ignoreResp.data?.ignoreOfferListingsById?.errors?.length)
        AlertService.alert({
          type: 'warning',
          autoDismiss: true,
          message: <span>{ignoreResp.data?.ignoreOfferListingsById?.errors[0].message}</span>,
        });
      event(`Offers were ignored in trucklane view`, {
        userId: user._id,
      });
    });
  }, 1000);

  const restoreOffers = Timing.throttle((e) => {
    e.stopPropagation();
    const matchedOffers = ignoredOffers;
    setActionLoading(true);
    if (suggested) deSelect(trucklaneId);
    restoreOffersMutation({
      variables: { offerListingIds: matchedOffers.map((offer) => offer._id) },
      refetchQueries: [
        {
          query: trucklanePollingQuery,
          variables: {
            pollingDetails: [...Store.trucklaneChannels.values()].map((channel) => ({ origin: channel.originDCId, truckType: channel.truckType })),
          },
        },
        {
          query: OfferListingsByInventoryQuery,
          variables: { inventoryIds: matchedOffers.map((offer) => offer.inventory._id) },
        },
      ],
    }).then((restoreResp) => {
      setActionLoading(false);
      if (restoreResp.data?.restoreOfferListingsById?.errors?.length)
        AlertService.alert({
          type: 'warning',
          autoDismiss: true,
          message: <span>{restoreResp.data?.restoreOfferListingsById?.errors[0].message}</span>,
        });
      event(`Offers were restored in trucklane view`, {
        userId: user._id,
      });
    });
  }, 1000);

  const ignoreOffersDisabler = () => {
    return (
      ignoredOffers.length === offers.length ||
      activeTruckLane.length === offers.length ||
      activeTruckLane.length + ignoredOffers.length === offers.length ||
      restoreOffersLoading ||
      actionLoading
    );
  };

  const restoreOffersEnabler = () => {
    return !ignoredOffers.length || activeTruckLane.length === offers.length || ignoreOffersLoading || actionLoading;
  };

  const slideCard = () => {
    setAwardSuggestions(true);
  };

  const checkboxDisabled = () => {
    if (suggested) {
      return recalculation;
    }
    return !offers?.filter((offer) => offer.status === offerlistingStatuses.AWARDED).length;
  };

  return (
    <div
      data-testid="trucklane-card"
      className={classes.card}
      style={awardSuggestions || awarded ? { opacity: '0%' } : {}}
      onClick={() => openDrilldown(trucklaneId)}
    >
      <div className={classes.row}>
        <div onClick={(e) => e.stopPropagation()} className={classes.checkboxWrapper}>
          <Checkbox className={classes.checkbox} checked={selected} onChecked={handleSelect} value={trucklaneId} disabled={checkboxDisabled()} />
        </div>
        <div className={classes.cardOverview}>
          <h1>{aggregateOffer.buyerSiteName}</h1>
          <h2>{aggregateOffer.inventory?.siteName}</h2>
          <div className={classes.row}>
            <SideDropArrowIcon />
            <h2>{aggregateOffer.buyerDestinationName}</h2>
          </div>
          <div className={`${classes.overviewLogistics} ${classes.rowBreak}`}>
            <TruckIcon className={classes.logisticsIcon} />
            <p data-testid={'card-truck-type'}>{aggregateOffer.truckType}</p>
          </div>
          {displayLogisticsTerm()}
          <div className={`${classes.row} ${classes.rowBreak}`}>
            {activeTruckLane.length > 0 && (
              <div className={classes.activeLane}>
                <ActiveLaneIcon /> <p>ACTIVE TRUCK LANE</p>
              </div>
            )}
            {ignoredOffers.length > 0 && <ColorBadge theme={{ color: Theme.greyDark, backgroundColor: Theme.grey10 }}>IGNORED OFFERS</ColorBadge>}
          </div>
          <div className={classes.overviewActions}>
            <Button
              disabled={ignoreOffersDisabler()}
              loadingText="Ignoring Offers"
              loading={ignoreOffersLoading}
              onClick={(e) => ignoreOffers(e)}
              icon={icons.ignore}
              secondary
            >
              Ignore Offers
            </Button>
            <Button
              disabled={restoreOffersEnabler()}
              loadingText="Restoring Offers"
              loading={restoreOffersLoading}
              onClick={(e) => restoreOffers(e)}
              icon={icons.restore}
              secondary
            >
              Restore Offers
            </Button>
          </div>
        </div>
      </div>
      <TrucklaneCardSummary
        trucklaneId={trucklaneId}
        offers={offers}
        suggested={suggested}
        recalculation={recalculation}
        awardSuggestion={slideCard}
        selected={selected}
        deSelect={deSelect}
        actionLoading={actionLoading}
        childAction={setActionLoading}
        user={user}
      />
    </div>
  );
};

TrucklaneCard.propTypes = {
  offers: PropTypes.any,
  suggested: PropTypes.bool,
  recalculation: PropTypes.bool,
  trucklaneId: PropTypes.string,
  selected: PropTypes.bool,
  handleSelect: PropTypes.func,
  deSelect: PropTypes.func,
  awarded: PropTypes.bool,
  user: PropTypes.object,
  openDrilldown: PropTypes.func,
};

export default TrucklaneCard;
