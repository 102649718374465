import React from 'react';
import moment from 'moment';
import { Column, VendorReturn } from '@spoiler-alert/ui-library';
import { destinationMap } from '../../../enums';
import statusMap from '../status-map';

export function getMyPostsColumns(user) {
  const supplierColumn = new Column({ field: 'supplierSiteName', displayName: 'Site', sortable: true });
  const myPostsColumns = [
    new Column({
      field: 'transactedAt',
      displayName: 'Transaction Date',
      sortable: true,
      defaultSort: true,
      formatter: (value) => moment(value).format('M/DD/YYYY h:mm a'),
    }),
    new Column({
      field: 'itemName',
      displayName: 'Item #',
      sortable: true,
      formatter(value, row) {
        return (
          <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {value} {row.isVendorReturn && <VendorReturn />}
          </span>
        );
      },
    }),
    new Column({ field: 'category', displayName: 'Category', sortable: true }),
    new Column({
      field: 'description',
      displayName: 'Description',
      sortable: true,
      formatter(value) {
        let displayText = value;
        if (value && value.length > 50) displayText = `${value.substr(0, 50).trim()}\u2026`;
        return <span title={value}>{displayText}</span>;
      },
    }),
    new Column({ field: 'quantity', displayName: 'Quantity', sortable: true, formatter: (value, row) => `${value} ${row.unitOfMeasure}` }),
    new Column({
      field: 'destination',
      displayName: 'Type',
      sortable: true,
      formatter: (value, row, col) => col.enumMap.get(value),
      enumMap: destinationMap,
    }),
    new Column({
      field: 'status',
      displayName: 'Status',
      sortable: true,
      formatter: (value, row, col) => col.enumMap.get(value),
      enumMap: statusMap,
    }),
  ];
  if (user.privileges.canUserManageMultipleSites) myPostsColumns.splice(1, 0, supplierColumn);
  return myPostsColumns;
}

export { statusMap };
