import { fetchWithToken } from './fetch-with-token';
import AppSettings from '../app-settings';

class SupportService {
  supportSSOUrl = () =>
    fetchWithToken(`${AppSettings.GRAPHQL_SERVER_URL}support`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
}

const supportService = new SupportService();
export default supportService;
