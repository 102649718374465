import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Navigation, NavigationItem, Sidebar, Logo, Theme as theme } from '@spoiler-alert/ui-library';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import VerificationsItem from './verifications-item';
import routePaths from '../../route-paths';
import Actions from './actions';
import featureFlags from '../../enums/feature-flags';
import AppSettings from '../../app-settings';

const styles = {
  sa__logo: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 152,
    height: 33,
    margin: '29px 0 35px 24px',
    padding: 0,
  },
  sa__img: {
    width: 152,
    height: 33,
  },
  '@media (max-width: 840px)': {
    sa__logo: {
      display: 'none',
    },
  },
};

class SidebarWrapper extends Component {
  mounted = true;

  componentWillUnmount() {
    this.mounted = false;
  }

  get privileges() {
    const { user } = this.props;
    return {
      canViewEnterpriseDashboard: user.privileges.canViewEnterpriseDashboard,
      canViewTaxDashboard: user.privileges.canViewTaxDashboard,
      canViewItems: user.privileges.canViewItems,
      canViewMarketplace: user.privileges.canUserViewMarketplace,
      canViewActivity: user.privileges.canViewActivity,
      canViewInventory: user.privileges.canViewInventory,
      canMakeOffers: user.privileges.canMakeOffers,
      canReceiveOffers: user.privileges.canReceiveOffers,
      canViewPosts: user.privileges.canViewPosts,
      canViewClaims: user.privileges.canViewClaims,
      canReconcile: user.privileges.canReconcile,
    };
  }

  get canViewMarketplace() {
    return this.privileges.canViewMarketplace && !this.privileges.canMakeOffers;
  }

  close = () => {};

  get canViewOffers() {
    return this.privileges.canMakeOffers || this.privileges.canReceiveOffers;
  }

  checkFeature(featureName) {
    const { featureMap } = this.props.rootStore;
    return !!featureMap.get(featureName) === true;
  }

  render() {
    const { classes, onClose, onOpen, rootStore, user } = this.props;
    const hasLegacyDashboardFeature = this.checkFeature(featureFlags.legacyDashboard);
    const hasCustomerProfilesFeature = this.checkFeature(featureFlags.customerProfiles);
    const isSeller = user.site.siteRole.includes('SELLER');
    const envSidebarEnabled = this.checkFeature(featureFlags.showNonProdSidebar);

    return (
      <Sidebar
        collapsed={rootStore.sidebarStatus.collapsed}
        onClose={onClose}
        onOpen={onOpen}
        backgroundColor={
          (envSidebarEnabled && AppSettings.ENVIRONMENT_NAME === 'staging' && theme.teal30) ||
          undefined ||
          (envSidebarEnabled && AppSettings.ENVIRONMENT_NAME === 'uat' && theme.yellow30) ||
          undefined
        }
      >
        <Logo route="/" className={classes.sa__logo} imageClassName={classes.sa__img} />
        <Navigation>
          {this.privileges.canViewEnterpriseDashboard && hasLegacyDashboardFeature && (
            <NavigationItem
              defaultText="Dashboard"
              id="dashboard"
              i18nKey="dashboard"
              route={routePaths.enterpriseDashboard}
              icon="dashboard"
              onClick={this.close}
            />
          )}
          {!hasLegacyDashboardFeature && isSeller && (
            <NavigationItem
              defaultText="Dashboard"
              id="platformDashboard"
              i18nKey="platformDashboard"
              route={routePaths.platformDashboard}
              icon="dashboard"
              onClick={window.location.reload}
            />
          )}
          {this.privileges.canViewItems && (
            <NavigationItem defaultText="Items" id="item-catalog" i18nKey="items" route={routePaths.items} icon="items" onClick={this.close} />
          )}
          {this.privileges.canViewInventory && (
            <NavigationItem defaultText="Inventory" id="inventory" i18nKey="box" route={routePaths.inventory} icon="box" onClick={this.close} />
          )}
          {this.privileges.canViewInventory && (
            <NavigationItem defaultText="Staging" id="staging" i18nKey="content" route={routePaths.staging} icon="content" onClick={this.close} />
          )}
          {this.privileges.canReceiveOffers && (
            <NavigationItem
              defaultText="Offer Comparison"
              id="offer-comp"
              i18nKey="offer-comp"
              route={routePaths.offerComparison}
              parentRoute={routePaths.offerComparison}
              icon="historydetail"
              onClick={this.close}
            />
          )}
          {this.privileges.canReceiveOffers && (
            <NavigationItem
              defaultText="Negotiations"
              id="negotiations"
              i18nKey="negotiations"
              route={routePaths.negotiations}
              icon="negotiate"
              onClick={this.close}
            />
          )}
          {this.canViewOffers && (
            <NavigationItem
              defaultText="Offer Review"
              id="offers"
              i18nKey="offers"
              route={routePaths.review}
              icon="marketplace"
              onClick={this.close}
            />
          )}
          {this.privileges.canViewInventory && (
            <NavigationItem defaultText="History" id="history" i18nKey="history" route={routePaths.history} icon="receipt" onClick={this.close} />
          )}
          {this.privileges.canReconcile && (
            <NavigationItem
              defaultText="Transactions"
              id="transactions"
              i18nKey="transactions"
              route={routePaths.transactions}
              icon="receipt"
              onClick={this.close}
            />
          )}
          {hasCustomerProfilesFeature && (
            <NavigationItem
              defaultText="Customer Profiles"
              id="customerProfiles"
              i18nKey="customerProfiles"
              route={routePaths.customers}
              icon="customerProfile"
              onClick={this.close}
              iconStyle={{
                stroke: theme.menuIconFillColor,
              }}
              activeIconStyle={{
                stroke: theme.green,
              }}
            />
          )}
          {this.privileges.canViewPosts && (
            <NavigationItem defaultText="Posts" id="my-posts" i18nKey="posts" route={routePaths.myPosts} icon="activity" onClick={this.close} />
          )}
          {this.privileges.canViewClaims && (
            <NavigationItem defaultText="Claims" id="my-claims" i18nKey="claims" route={routePaths.myClaims} icon="boxhandle" onClick={this.close} />
          )}
          {this.privileges.canViewClaims && (
            <VerificationsItem
              defaultText="Verifications"
              id="my-verifications"
              i18nKey="verifications"
              route={routePaths.myVerifications}
              icon="circlecheck"
              onClick={this.close}
            />
          )}
          {this.privileges.canViewTaxDashboard && (
            <NavigationItem defaultText="Tax" id="tax-dashboard" i18nKey="tax" route={routePaths.taxDashboard} icon="tax" onClick={this.close} />
          )}
          {!hasLegacyDashboardFeature && isSeller && (
            <NavigationItem
              defaultText="Reports"
              id="reportsDashboard"
              i18nKey="reportsDashboard"
              route={routePaths.reportsDashboard}
              icon="reporting"
              onClick={window.location.reload}
              iconStyle={{ margin: '0 -3px 0 3px' }}
            />
          )}
        </Navigation>
        <Actions>{this.props.children}</Actions>
      </Sidebar>
    );
  }
}

SidebarWrapper.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  history: PropTypes.object,
  rootStore: PropTypes.object,
  children: PropTypes.node,
};

const ConnectedComponent = inject('rootStore')(observer(SidebarWrapper));
const StyledComponent = injectSheet(styles)(ConnectedComponent);

export default withRouter(StyledComponent);
