import { Theme } from '@spoiler-alert/ui-library';

const removeOrReverseTransactionsStyles = {
  modal__title: {
    fontSize: '24px',
    padding: 0,
    marginTop: 0,
    height: '25px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.42,
    letterSpacing: '-0.5px',
    textAlign: 'left',
    marginBottom: '8px',
  },
  modal__body: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    height: '48px',
    alignSelf: 'stretch',
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
    marginBottom: '8px',
  },
  footer__container: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 24,
    borderTop: `1px solid ${Theme.modalFooterBorderColor}`,
    alignItems: 'center',
  },
  modal__submit: {
    display: 'flex',
  },
  form__container: {
    width: '100%',
  },
  actionButton: {
    marginRight: '8px',
    color: Theme.red,
    borderColor: Theme.red,
    '&:hover': {
      backgroundColor: `${Theme.red} !important`,
      color: `${Theme.white} !important`,
      borderColor: `${Theme.red} !important`,
    },
  },
  infoIcon: {
    fill: Theme.infoColor,
    width: 16,
    height: 16,
    marginTop: '3px',
  },
  infoIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: '16px',
  },
  modalInfo: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 4,
    padding: '13px 17px 13px 17px',
    backgroundColor: Theme.teal10,
    fontSize: 14,
  },
};

export default removeOrReverseTransactionsStyles;
