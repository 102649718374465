import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GraphQLDataTable, OverlayService } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import { UserItemHistoriesQuery } from '../../graphql/queries';
import { historyColumns, getFilters } from './history-columns';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';

const styles = {
  nav__buttons: {
    order: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  nav__button: {
    margin: '0 8px',
  },
};

class ItemHistory extends Component {
  constructor(props) {
    super(props);
    OverlayService.hide();

    const title = `Item History${props.match.params.id ? ` for ${props.match.params.itemName}` : ''}`;
    TitleService.setTitles(title);
    Breadcrumbs.set([
      {
        url: '/items',
        title: 'Items',
      },
      {
        url: props.match.url,
        title,
      },
    ]);

    const hasItemsModule = props.user.privileges.canUserSiteUseItems;
    if (!hasItemsModule) return this.props.history.push('/items/about');
  }

  get queryParameters() {
    return this.props.match.params ? { itemId: this.props.match.params.id } : undefined;
  }

  render() {
    const { user } = this.props;
    const filters = getFilters(user);
    return (
      <div id="history">
        <GraphQLDataTable
          query={UserItemHistoriesQuery}
          queryName="currentUserQuery"
          queryField="itemHistories"
          userId={user._id}
          search
          transition
          filterParameterField="itemHistoryFilterParameters"
          columns={historyColumns}
          filters={filters}
          queryParameters={this.queryParameters}
          pagination
          perPage={15}
          perPageIncrements={[15, 30, 50, 100]}
        />
      </div>
    );
  }
}

ItemHistory.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  classes: PropTypes.object,
};

const StyledComponent = injectSheet(styles)(ItemHistory);
export default StyledComponent;
