import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { DownloadIcon, Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  download__icon: {
    width: '16px',
    height: '16px',
    fill: theme.errorColor,
    cursor: 'pointer',
  },
};

@injectSheet(styles)
export default class ErrorDownload extends Component {
  render() {
    const { classes } = this.props;
    return <DownloadIcon className={classes.download__icon} />;
  }
}

ErrorDownload.propTypes = {
  classes: PropTypes.object,
};
