import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Timing, Snackbar } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import { useQuery } from '@apollo/client';
import routePaths from '../../../route-paths';
import { DistributionListsWithBuyersQuery } from '../../../graphql/queries';
import DistributionLists from './distribution-lists';
import DistributionList from './distribution-list';
import checkFeature from '../../../helpers/check-feature-flag';
import featureFlags from '../../../enums/feature-flags';
import { DistributionListTypes } from './distribution-list-enums';

const basePath = `${routePaths.orgSettings}/distribution-lists`;

const DistributionListsSettings = ({ user, history }) => {
  const useStyles = createUseStyles({
    cardContainer: {
      position: 'relative',
      width: '800px',
    },
  });
  const classes = useStyles();

  const [searchText, setSearchText] = useState('');
  const [listName, setListName] = useState();
  const [listType, setListType] = useState({ ...DistributionListTypes.LIQUIDATION });
  const [addedCustomers, setAddedCustomers] = useState([]);
  const [originalListName, setOriginalListName] = useState();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const { data } = useQuery(DistributionListsWithBuyersQuery, {
    variables: { siteId: user.site._id },
  });

  const getBuyers = (buyersData) => {
    return buyersData
      .map((buyer) => buyer.siteName)
      .sort((a, b) => {
        const nameA = a?.toUpperCase(); // ignore upper and lowercase
        const nameB = b?.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
  };

  const flashSnackbar = (message) => {
    setSnackbarMessage(message);
    setShowSnackbar(true);
  };

  const distributionListsForTable =
    useMemo(() => {
      return data?.distributionListsWithBuyers
        .filter((dl) => dl?.name?.length > 0 && dl?.name?.toLowerCase().includes(searchText?.toLowerCase()))
        .sort((a, b) => {
          const nameA = a?.name?.toUpperCase(); // ignore upper and lowercase
          const nameB = b?.name?.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        })
        .map((dl) => {
          return { _id: dl._id, key: dl.name, value: getBuyers(dl.buyers), destinationType: dl.destinationType, archived: dl.archived };
        });
    }, [searchText, data?.distributionListsWithBuyers]) || [];

  const handleRowClick = (e, obj) => {
    e.stopPropagation();
    if (obj.destinationType !== 'donation') {
      setListType({ ...DistributionListTypes.LIQUIDATION });
    } else {
      setListType({ ...DistributionListTypes.DONATION });
    }
    history.push(`${basePath}/edit/${obj._id}`);
  };

  const createNewList = () => {
    setListName('');
    setListType();
    setAddedCustomers([]);
    history.push(`${basePath}/new`);
  };

  const handleSearch = Timing.debounce((text) => setSearchText(text), 300);

  const donationsEnabled = checkFeature(featureFlags.donationsv2);

  return (
    <div className={classes.cardContainer}>
      <Switch>
        <Route exact path={`${basePath}`}>
          <DistributionLists
            handleSearch={handleSearch}
            createNewList={createNewList}
            handleRowClick={handleRowClick}
            distributionListsForTable={distributionListsForTable}
            searchText={searchText}
            setSearchText={setSearchText}
            basePath={basePath}
            history={history}
          />
        </Route>
        <Route path={`${basePath}/new`}>
          <DistributionList
            key={'new'}
            distributionListNames={distributionListsForTable.map((dlData) => dlData.key)}
            siteId={user.site._id}
            listName={listName}
            setListName={setListName}
            listType={listType}
            setListType={setListType}
            addedCustomers={addedCustomers}
            setAddedCustomers={setAddedCustomers}
            basePath={basePath}
            history={history}
            flashSnackbar={flashSnackbar}
            showDestinationType={donationsEnabled}
          />
        </Route>
        <Route path={`${basePath}/edit/:listId`}>
          {({ match }) => (
            <DistributionList
              key={match.params.listId}
              distributionLists={distributionListsForTable}
              distributionListNames={distributionListsForTable.map((dlData) => dlData.key)}
              siteId={user.site._id}
              listName={listName}
              setListName={setListName}
              originalListName={originalListName}
              setOriginalListName={setOriginalListName}
              listType={listType}
              setListType={setListType}
              addedCustomers={addedCustomers}
              setAddedCustomers={setAddedCustomers}
              basePath={basePath}
              match={match}
              history={history}
              flashSnackbar={flashSnackbar}
              edit
              showDestinationType={donationsEnabled}
            />
          )}
        </Route>
      </Switch>
      <Snackbar show={showSnackbar} callback={() => setShowSnackbar(false)} message={snackbarMessage} />
    </div>
  );
};

DistributionListsSettings.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
};

export default DistributionListsSettings;
