import accounting from 'accounting';
import { costRecoveryHandler } from '../../utilities/cost-recovery-handler';
import weightTypes from '../../enums/weight-types';
import { offerlistingStatuses } from '../../enums';

const calculateWeight = (tableColumn, offers, recalculation) => {
  let currentWeight = null;
  let suggestedWeight = null;
  let totalWeight = null;

  if (['CURRENTLY', 'TOTAL'].includes(tableColumn)) {
    currentWeight = offers.reduce((acc, current) => {
      if ([offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(current.status))
        return current.quantity * current.inventory.unitGrossWeight + acc;
      return acc;
    }, 0);
  }
  if (['SUGGESTED', 'TOTAL'].includes(tableColumn)) {
    suggestedWeight = offers.reduce((acc, current) => {
      if (
        current.suggestions.award.suggested &&
        ![offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED, offerlistingStatuses.IGNORED].includes(current.status)
      )
        return current.suggestions.award.quantity * current.inventory.unitGrossWeight + acc;
      return acc;
    }, 0);
  }
  totalWeight = currentWeight + suggestedWeight;
  switch (tableColumn) {
    case 'CURRENTLY':
      return `${accounting.formatNumber(currentWeight)}  ${weightTypes[offers[0].weightUnit] || weightTypes.LB}`;
    case 'SUGGESTED':
      return recalculation ? '—' : `${accounting.formatNumber(suggestedWeight)}  ${weightTypes[offers[0].weightUnit] || weightTypes.LB}`;
    case 'TOTAL':
      return recalculation ? '—' : `${accounting.formatNumber(totalWeight)}  ${weightTypes[offers[0].weightUnit] || weightTypes.LB}`;
    default:
      return '—';
  }
};

const calculatePallets = (tableColumn, offers, recalculation) => {
  let currentPallets = null;
  let suggestedPallets = null;
  let totalPallets = null;

  if (['CURRENTLY', 'TOTAL'].includes(tableColumn)) {
    currentPallets = offers.reduce((acc, current) => {
      if ([offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(current.status))
        return current.quantity / (current.inventory.casesPerPallet || 1) + acc;
      return acc;
    }, 0);
  }
  if (['SUGGESTED', 'TOTAL'].includes(tableColumn)) {
    suggestedPallets = offers.reduce((acc, current) => {
      if (
        current.suggestions.award.suggested &&
        ![offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED, offerlistingStatuses.IGNORED].includes(current.status)
      )
        return current.suggestions.award.quantity / (current.inventory.casesPerPallet || 1) + acc;
      return acc;
    }, 0);
  }
  totalPallets = currentPallets + suggestedPallets;
  switch (tableColumn) {
    case 'CURRENTLY':
      return `${accounting.formatNumber(currentPallets)}`;
    case 'SUGGESTED':
      return recalculation ? '—' : `${accounting.formatNumber(suggestedPallets)}`;
    case 'TOTAL':
      return recalculation ? '—' : `${accounting.formatNumber(totalPallets)}`;
    default:
      return '—';
  }
};

const calculateRevenue = (tableColumn, offers, recalculation) => {
  let currentRevenue = null;
  let suggestedRevenue = null;
  let totalRevenue = null;

  if (['CURRENTLY', 'TOTAL'].includes(tableColumn)) {
    currentRevenue = offers.reduce((acc, current) => {
      if ([offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(current.status)) return current.totalPrice + acc;
      return acc;
    }, 0);
  }
  if (['SUGGESTED', 'TOTAL'].includes(tableColumn)) {
    suggestedRevenue = offers.reduce((acc, current) => {
      if (current.suggestions.award.suggested && ![offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(current.status))
        return current.suggestions.award.quantity * (current.totalPrice / current.quantity) + acc;
      return acc;
    }, 0);
  }
  totalRevenue = currentRevenue + suggestedRevenue;
  switch (tableColumn) {
    case 'CURRENTLY':
      return `${accounting.formatMoney(currentRevenue)}`;
    case 'SUGGESTED':
      return recalculation ? '—' : `${accounting.formatMoney(suggestedRevenue)}`;
    case 'TOTAL':
      return recalculation ? '—' : `${accounting.formatMoney(totalRevenue)}`;
    default:
      return '—';
  }
};

const calculateNetRevenue = (tableColumn, offers, recalculation) => {
  let currentRevenue = 0;
  let suggestedRevenue = 0;

  offers.forEach((offer) => {
    if ([offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status)) currentRevenue += offer.totalPrice;
    if (offer.suggestions?.award?.suggested && ![offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status))
      suggestedRevenue += offer.suggestions.award.quantity * (offer.totalPrice / offer.quantity);
  });

  const totalRevenue = currentRevenue + suggestedRevenue;
  switch (tableColumn) {
    case 'CURRENTLY':
      return `${accounting.formatMoney(currentRevenue - (currentRevenue > 0 ? offers[0].trucklaneCost || 0 : 0))}`;
    case 'SUGGESTED':
      return recalculation ? '—' : `${accounting.formatMoney(suggestedRevenue - (currentRevenue === 0 ? offers[0].trucklaneCost || 0 : 0))}`;
    case 'TOTAL':
      return recalculation ? '—' : `${accounting.formatMoney(totalRevenue - (totalRevenue > 0 ? offers[0].trucklaneCost || 0 : 0))}`;
    default:
      return '—';
  }
};

const calculateRecovery = (tableColumn, offers, recalculation) => {
  let current = null;
  let suggestion = null;
  let total = null;
  let currentRecovery = { totalPrice: 0, totalCost: 0 };
  let suggestedRecovery = { totalPrice: 0, totalCost: 0 };

  if (['CURRENTLY', 'TOTAL'].includes(tableColumn)) {
    offers.forEach((offer) => {
      if ([offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status))
        currentRecovery = {
          totalPrice: offer.totalPrice + currentRecovery.totalPrice,
          totalCost: offer.quantity * offer.inventory.unitCost + currentRecovery.totalCost,
        };
    });
    current = costRecoveryHandler(currentRecovery.totalPrice, currentRecovery.totalCost);
  }
  if (['SUGGESTED', 'TOTAL'].includes(tableColumn)) {
    offers.forEach((offer) => {
      if (
        offer.suggestions.award.suggested &&
        ![offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED, offerlistingStatuses.IGNORED].includes(offer.status)
      )
        suggestedRecovery = {
          totalPrice: offer.suggestions.award.quantity * (offer.totalPrice / offer.quantity) + suggestedRecovery.totalPrice,
          totalCost: offer.suggestions.award.quantity * offer.inventory.unitCost + suggestedRecovery.totalCost,
        };
    });
    suggestion = costRecoveryHandler(suggestedRecovery.totalPrice, suggestedRecovery.totalCost);
  }
  total = costRecoveryHandler(currentRecovery.totalPrice + suggestedRecovery.totalPrice, currentRecovery.totalCost + suggestedRecovery.totalCost);
  switch (tableColumn) {
    case 'CURRENTLY':
      return current;
    case 'SUGGESTED':
      return recalculation ? '—' : suggestion;
    case 'TOTAL':
      return recalculation ? '—' : total;
    default:
      return '—';
  }
};

const calculateMetricSoldCostRecovery = (trucklanes) => {
  let currentRecovery = { totalPrice: 0, totalCost: 0 };
  let suggestedRecovery = { totalPrice: 0, totalCost: 0 };
  trucklanes.forEach((trucklane) => {
    trucklane.offers.forEach((offer) => {
      if (offer.suggestions.award.suggested && [offerlistingStatuses.ACTIVE].includes(offer.status))
        suggestedRecovery = {
          totalPrice: offer.suggestions.award.quantity * (offer.totalPrice / offer.quantity) + suggestedRecovery.totalPrice,
          totalCost: offer.suggestions.award.quantity * offer.inventory.unitCost + suggestedRecovery.totalCost,
        };
      if ([offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status))
        currentRecovery = {
          totalPrice: offer.totalPrice + currentRecovery.totalPrice,
          totalCost: offer.quantity * offer.inventory.unitCost + currentRecovery.totalCost,
        };
    });
  });
  return {
    current: costRecoveryHandler(currentRecovery.totalPrice, currentRecovery.totalCost),
    projected: costRecoveryHandler(
      currentRecovery.totalPrice + suggestedRecovery.totalPrice,
      currentRecovery.totalCost + suggestedRecovery.totalCost
    ),
  };
};

const calculateMetricTrucklanes = (trucklanes) => {
  const suggestedTrucklaneSet = new Set();
  const currentTrucklaneSet = new Set();
  trucklanes.forEach((trucklane) => {
    trucklane.offers.forEach((offer) => {
      if (offer.suggestions.award.suggested && [offerlistingStatuses.ACTIVE].includes(offer.status)) suggestedTrucklaneSet.add(trucklane.id);
      if ([offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status)) currentTrucklaneSet.add(trucklane.id);
    });
  });
  return {
    current: currentTrucklaneSet.size,
    projected: new Set([...suggestedTrucklaneSet, ...currentTrucklaneSet]).size,
  };
};

const calculateMetricRevenue = (trucklanes) => {
  let suggested = null;
  let current = null;
  trucklanes.forEach((trucklane) => {
    trucklane.offers.forEach((offer) => {
      if (offer.suggestions.award.suggested && [offerlistingStatuses.ACTIVE].includes(offer.status))
        suggested += offer.suggestions.award.quantity * (offer.totalPrice / offer.quantity);
      if ([offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status)) current += offer.totalPrice;
    });
  });
  return {
    current: accounting.formatMoney(current),
    projected: accounting.formatMoney(suggested + current),
  };
};

const calculateMetricDeliveryCost = (trucklanes) => {
  const suggested = new Map();
  const current = new Map();
  trucklanes.forEach((trucklane) => {
    trucklane.offers.forEach((offer) => {
      if (offer.suggestions.award.suggested && [offerlistingStatuses.ACTIVE].includes(offer.status)) suggested.set(trucklane.id, offer.trucklaneCost);
      if ([offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status)) current.set(trucklane.id, offer.trucklaneCost);
    });
  });
  return {
    current: accounting.formatMoney(
      [...current.values()].reduce((value, acc) => {
        return value + acc;
      }, 0)
    ),
    projected: accounting.formatMoney(
      [...new Map([...suggested, ...current]).values()].reduce((value, acc) => {
        return value + acc;
      }, 0)
    ),
  };
};

const calculateMetricNetRevenue = (trucklanes) => {
  const suggestedCost = new Map();
  const currentCost = new Map();
  let suggestedRev = null;
  let currentRev = null;
  trucklanes.forEach((trucklane) => {
    trucklane.offers.forEach((offer) => {
      if (offer.suggestions.award.suggested && [offerlistingStatuses.ACTIVE].includes(offer.status)) {
        suggestedCost.set(trucklane.id, offer.trucklaneCost);
        suggestedRev += offer.suggestions.award.quantity * (offer.totalPrice / offer.quantity);
      }
      if ([offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status)) {
        currentCost.set(trucklane.id, offer.trucklaneCost);
        currentRev += offer.totalPrice;
      }
    });
  });
  return {
    current: accounting.formatMoney(
      currentRev -
        [...currentCost.values()].reduce((value, acc) => {
          return value + acc;
        }, 0)
    ),
    projected: accounting.formatMoney(
      currentRev +
        suggestedRev -
        [...new Map([...suggestedCost, ...currentCost]).values()].reduce((value, acc) => {
          return value + acc;
        }, 0)
    ),
  };
};

const calculateMetricWeight = (trucklanes) => {
  let suggested = null;
  let current = null;
  trucklanes.forEach((trucklane) => {
    trucklane.offers.forEach((offer) => {
      if (offer.suggestions.award.suggested && [offerlistingStatuses.ACTIVE].includes(offer.status))
        suggested += offer.suggestions.award.quantity * offer.inventory.unitGrossWeight;
      if ([offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status))
        current += offer.quantity * offer.inventory.unitGrossWeight;
    });
  });
  return {
    current: `${accounting.formatNumber(current)} ${weightTypes[trucklanes[0].offers[0].weightUnit] || weightTypes.LB}`,
    projected: `${accounting.formatNumber(suggested + current)} ${weightTypes[trucklanes[0].offers[0].weightUnit] || weightTypes.LB}`,
  };
};

const calculateMetricPallet = (trucklanes) => {
  let suggested = null;
  let current = null;
  trucklanes.forEach((trucklane) => {
    trucklane.offers.forEach((offer) => {
      if (offer.suggestions.award.suggested && [offerlistingStatuses.ACTIVE].includes(offer.status))
        suggested += offer.suggestions.award.quantity / (offer.inventory.casesPerPallet || 1);
      if ([offerlistingStatuses.AWARDED, offerlistingStatuses.ACCEPTED].includes(offer.status))
        current += offer.quantity / (offer.inventory.casesPerPallet || 1);
    });
  });
  return {
    current: accounting.formatNumber(current),
    projected: accounting.formatNumber(suggested + current),
  };
};

const initialMetrics = [
  { title: 'Sold Cost Recovery', current: 'N/A', projected: '—' },
  { title: 'Truck Lanes', current: 'N/A', projected: '—' },
  { title: 'Revenue', current: 'N/A', projected: '-' },
  { title: 'Est Delivery Cost', current: 'N/A', projected: '-' },
  { title: 'Net Revenue', current: 'N/A', projected: '-' },
];

const initialDrilldownMetrics = [
  { title: 'Net Revenue', current: '—', projected: '—' },
  { title: 'Weight', current: '—', projected: '—' },
  { title: 'Pallets', current: '—', projected: '-' },
  { title: 'Cost Recovery', current: '—', projected: '-' },
];

const generateMetrics = (trucklanes, metricType, recalculation) => {
  const mappedMetrics = metricType.map((metric) => {
    let metricData;
    switch (metric.title) {
      case 'Sold Cost Recovery':
      case 'Cost Recovery':
        metricData = calculateMetricSoldCostRecovery(trucklanes);
        break;
      case 'Truck Lanes':
        metricData = calculateMetricTrucklanes(trucklanes);
        break;
      case 'Revenue':
        metricData = calculateMetricRevenue(trucklanes);
        break;
      case 'Est Delivery Cost':
        metricData = calculateMetricDeliveryCost(trucklanes);
        break;
      case 'Net Revenue':
        metricData = calculateMetricNetRevenue(trucklanes);
        break;
      case 'Weight':
        metricData = calculateMetricWeight(trucklanes);
        break;
      case 'Pallets':
        metricData = calculateMetricPallet(trucklanes);
        break;
      default:
        metricData = { current: '—', projected: '—' };
    }
    return { title: metric.title, current: `${metricData.current}`, projected: recalculation ? '—' : `${metricData.projected}` };
  });
  return mappedMetrics;
};

export {
  calculateWeight,
  calculatePallets,
  calculateRevenue,
  calculateNetRevenue,
  calculateRecovery,
  initialMetrics,
  initialDrilldownMetrics,
  generateMetrics,
};
