import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectOption } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import filterStyles from './filter-styles';

@injectSheet(filterStyles)
export default class SubcategoryFilter extends React.Component {
  constructor(props) {
    super(props);
    this.subcategories = this.getSubCategoryList();
    this.state = {
      values: this.subcategories.map((c) => ({ value: c.value, text: c.text })),
    };
  }

  getSubCategoryList() {
    const { subCategoryParam, user } = this.props;
    const subCategoriesToReturn = user.postFilterParameters[subCategoryParam];
    return subCategoriesToReturn.map((c) => ({ text: c.name, value: c.id }));
  }

  onChange = (values) => {
    this.setState({ values });
    this.props.onChange(values.map((val) => val.value));
  };

  render() {
    const subCatItems = this.subcategories.map((itm, idx) => (
      <SelectOption
        key={`sub-cat-item-${idx}`}
        checkbox
        selected={this.state.values.findIndex((item) => item.value === itm.value) > -1}
        value={itm.value}
      >
        {itm.text}
      </SelectOption>
    ));
    return (
      <div className={this.props.classes.dashboard__filter}>
        <Select label="Subcategories" selectedItems={this.state.values} multiple selectAll search autoWidth onChange={this.onChange}>
          {subCatItems}
        </Select>
      </div>
    );
  }
}

SubcategoryFilter.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  onChange: PropTypes.func,
  subCategoryParam: PropTypes.string,
};

SubcategoryFilter.defaultProps = {
  subCategoryParam: 'subcategories',
};
