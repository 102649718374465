import React from 'react';
import PropTypes from 'prop-types';
import { Select, SelectOption } from '@spoiler-alert/ui-library';
import injectSheet from 'react-jss';
import filterStyles from './filter-styles';

@injectSheet(filterStyles)
export default class VendorReturnsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.defaults = [
      { value: ['all'], text: 'All' },
      { value: ['false'], text: 'Non-Vendor Returns' },
      { value: ['true'], text: 'Vendor Returns' },
    ];

    this.state = {
      value: [this.defaults[0]],
    };
  }

  onChange = (value) => {
    this.setState({ value });
    const values = this.defaults.find((itm) => itm.value[0] === value[0].value).value;
    this.props.onChange(values);
  };

  render() {
    const items = this.defaults.map((itm, ix) => (
      <SelectOption key={`item-${ix}`} selected={this.state.value[0].value[0] === itm.value[0]} value={itm.value[0]}>
        {itm.text}
      </SelectOption>
    ));
    return (
      <div data-element="vendor-returns-filter" className={this.props.classes.dashboard__filter}>
        <Select label="Vendor Returns" selectedItems={this.state.value} autoWidth onChange={this.onChange}>
          {items}
        </Select>
      </div>
    );
  }
}

VendorReturnsFilter.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
};
