import { Theme } from '@spoiler-alert/ui-library';

const styles = {
  loginContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    maxWidth: '1170px',
    margin: '0 auto',
  },
  loginTitle: {
    fontSize: '1.3rem',
    marginBottom: 8,
    textAlign: 'left',
    marginTop: 0,
    fontWeight: 500,
    width: 360,
    color: Theme.greyDark,
  },
  formRow: {
    width: 360,
    marginBottom: '15px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  formContainer: {
    marginBottom: 9,
  },
  formButton: {
    width: '100%',
  },
  loginText: {
    color: '#969696',
    marginBottom: 24,
  },
  loginLink: {
    color: Theme.teal,
    cursor: 'pointer',
    fontSize: '16px',
    display: 'block',
    marginBottom: 24,
  },
  footerContainer: {
    textAlign: 'center',
  },
  errorContainer: {
    width: 360,
    backgroundColor: Theme.red10,
    color: Theme.redDark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    minHeight: 36,
    marginBottom: 16,
    padding: 5,
  },
  messageContainer: {
    width: 360,
    borer: '1px solid #d6e9c6',
    backgroundColor: '#dff0d8',
    color: '#3c763d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    height: 36,
    marginBottom: 15,
  },
  messageContainerError: {
    borer: '1px solid #ebccd1',
    backgroundColor: '#f2dede',
    color: '#a94442',
  },
};

export default styles;
