import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import injectSheet from 'react-jss';
import { Loading, AlertService, OverlayService } from '@spoiler-alert/ui-library';
import { PostQuery } from '../../../graphql/queries';
import LogTransaction from './log-transaction';
import { TitleService } from '../../../services';
import routePaths from '../../../route-paths';
import { Breadcrumbs } from '../../../store';

const styles = {
  root: {
    width: '100%',
  },
};

class EditTransaction extends Component {
  constructor(props) {
    super(props);

    const { data } = props;
    this.state = {
      data,
      id: props.match.params.id,
      post: data.postByIdQuery,
    };

    if (data.postByIdQuery && !data.postByIdQuery.item) EditTransaction.error(props.history);

    TitleService.setTitles('Edit Transaction');
    Breadcrumbs.set([
      {
        url: '/edit/transaction',
        title: 'Edit Transaction',
      },
    ]);
    OverlayService.hide();
  }

  static getDerivedStateFromProps(nextProps) {
    const { data } = nextProps;
    if (!data.loading) {
      const post = data.postByIdQuery;
      if (!post || (post && (data.error || !post.item))) {
        EditTransaction.error(nextProps.history);
      }
      return { data, post };
    }
    return null;
  }

  dataReceived = (data) => {
    this.setState({ data });
  };

  static error = (history) => {
    AlertService.alert({
      type: 'warning',
      message: (
        <span>
          There was a problem retrieving your transaction. Try again or contact <a href="mailto:support@spoileralert.com">support@spoileralert.com</a>{' '}
          for assistance.
        </span>
      ),
      autoDismiss: true,
    });
    history.push(routePaths.myPosts);
  };

  render() {
    const { user, classes } = this.props;
    const { post, data } = this.state;
    return (
      <Loading size="large" loading={!post && !data.error} error={data.error}>
        <div className={classes.root}>{post && !data.loading && !data.error && <LogTransaction user={user} post={post} />}</div>
      </Loading>
    );
  }
}

EditTransaction.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object,
  data: PropTypes.object,
  match: PropTypes.object,
};

const StyledComponent = injectSheet(styles)(EditTransaction);
const GqlComponent = graphql(PostQuery, { options: (props) => ({ variables: { id: props.match.params.id } }) })(StyledComponent);
export default GqlComponent;
