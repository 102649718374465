import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Button, ChartIncreaseIcon, CalculatorIcon, ShieldCheckIcon, ReceiptIcon } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';

const styles = {
  about__container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 32px 0 16px',
  },
  about__content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '58vh',
    minHeight: '400px',
    backgroundColor: '#4eaac1',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '2px',
    padding: '24px 48px',
  },
  circle: {
    height: '75px',
    width: '75px',
    borderRadius: '100%',
    border: '5px solid #fff',
    fontSize: '3.375rem',
    color: '#fff',
    fontWeight: 500,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  about__header: {
    fontSize: '1.675rem',
  },
  text__center: {
    fontSize: '1.2rem',
    fontWeight: 300,
  },
  button: {
    color: '#4eaac1',
  },
  line__break: {
    display: 'block',
  },
  content__icon: {
    height: 36,
    width: 36,
    fill: '#4eaac1',
    margin: 10,
  },
  content__boxes: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content__box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 20,
  },
  box__header: {
    margin: '10px 0 0',
  },
  box__paragraph: {
    margin: '10px 0 0',
  },
  '@media (max-width: 840px)': {
    about__content: {
      height: '88vh',
      padding: '24px',
      minHeight: 0,
    },
    circle: {
      width: '60px',
      height: '60px',
      fontSize: '2.25rem',
    },
    text__center: {
      fontSize: '1.125rem',
      marginBottom: '38px',
    },
    line__break: {
      display: 'inline',
      marginLeft: 4,
    },
    content__boxes: {
      flexDirection: 'column',
    },
  },
  '@media (max-width: 840px) and (orientation: landscape)': {
    about__content: {
      height: '76vh',
      padding: '24px',
      minHeight: 0,
    },
    circle: {
      width: '45px',
      height: '45px',
      fontSize: '1.6rem',
      border: '4px solid #fff',
    },
    text__center: {
      fontSize: '1rem',
      marginBottom: '20px',
    },
  },
};

class TaxesAbout extends Component {
  constructor(props) {
    super(props);

    TitleService.setTitles('Tax Portal');
    Breadcrumbs.set([
      {
        url: '/tax',
        title: 'Tax',
      },
      {
        url: '/tax/about',
        title: 'About',
      },
    ]);

    this.state = {
      loading: false,
    };
    this.handleSAASInquiryEmail = this.handleSAASInquiryEmail.bind(this);
  }

  handleSAASInquiryEmail() {
    // MeteorConnector.Meteor.call('sendSAASInquiryEmail', 'Tax Portal', (err, res) => {
    //   if (err) {
    //     AlertService.alert({ type: 'warning', message: <span>Our apologies, looks like something is wrong with our system. Please contact an Administrator to inquire about this feature.</span> });
    //   } else if (res) {
    //     AlertService.alert({ type: 'success', message: <span>Our team will be in touch to get you started with the Tax Portal.</span> });
    //   }
    // });
  }

  done = () => {
    this.setState({ loading: false });
  };

  render() {
    const { classes, style } = this.props;
    return (
      <div className={classes.about__container} style={style}>
        <div className={classes.about__content}>
          <div className={classes.circle}>$</div>
          <h1 className={classes.about__header}>Claiming tax deductions just got a lot easier</h1>
          <p className={classes.text__center}>
            Donating food is good for your community and your bottom line.
            <span className={classes.line__break}> Maximize your tax savings and streamline your accounting with</span>
            <span className={classes.line__break}> the Spoiler Alert Tax Portal..</span>
          </p>
          <Button
            type="button"
            secondary
            className={classes.button}
            onClick={this.handleSAASInquiryEmail}
            loading={this.state.loading}
            loadingText="Loading..."
          >
            Get Started
          </Button>

          <button
            type="button"
            onClick={() => {
              this.props.history.push('/');
            }}
          >
            Click Me!
          </button>
        </div>
        <div className={classes.content__boxes}>
          <div className={classes.content__box}>
            <ChartIncreaseIcon className={classes.content__icon} />
            <h5 className={classes.box__header}>Centralized Reporting</h5>
            <p className={classes.box__paragraph}>
              Manage all of your charitable
              <span className={classes.line__break}>donations easily in one place</span>
            </p>
          </div>
          <div className={classes.content__box}>
            <CalculatorIcon className={classes.content__icon} />
            <h5 className={classes.box__header}>Enhanced Deductions</h5>
            <p className={classes.box__paragraph}>
              Maximize savings with our
              <span className={classes.line__break}>integrated benefit calculator</span>
            </p>
          </div>
          <div className={classes.content__box}>
            <ShieldCheckIcon className={classes.content__icon} />
            <h5 className={classes.box__header}>Electronic Verification</h5>
            <p className={classes.box__paragraph}>
              Save time with automated
              <span className={classes.line__break}>verification from nonprofits</span>
            </p>
          </div>
          <div className={classes.content__box}>
            <ReceiptIcon className={classes.content__icon} />
            <h5 className={classes.box__header}>Official Receipts</h5>
            <p className={classes.box__paragraph}>
              Be prepared for an audit
              <span className={classes.line__break}>with IRS compliant receipts</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

TaxesAbout.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  style: PropTypes.object,
};

const StyledComponent = injectSheet(styles)(TaxesAbout);
export default StyledComponent;
