import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { createUseStyles } from 'react-jss';
import { LoadingLarge } from '@spoiler-alert/ui-library';
import { LoginService } from '../../services';
import client from '../../apollo/client';
import routePaths from '../../route-paths';

const useStyles = createUseStyles({
  wrap: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  heading: {
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

const SSOTokenExchange = ({ location, history }) => {
  const classes = useStyles();
  const accessGranted = async () => {
    await client.resetStore();
    localStorage.setItem('isSSOUser', 'yes');
    const redirect = localStorage.getItem('redirect');
    localStorage.removeItem('redirect');
    history.push(redirect || routePaths.home);
  };

  useEffect(() => {
    const values = queryString.parse(location.search);
    const { code } = values;
    LoginService.exchangeToken(code)
      .then(accessGranted)
      .catch((err) => {
        history.push(`${routePaths.ssoSignIn}?errorMessage=${err}`);
      });
  }, [location.search]);

  return (
    <div className={classes.wrap}>
      <LoadingLarge />
      <h4 className={classes.heading}>Signing you in...</h4>
    </div>
  );
};

SSOTokenExchange.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default SSOTokenExchange;
