import { Theme as theme } from '@spoiler-alert/ui-library';

const filterStyles = {
  dashboard__filter: {
    minWidth: '100%',
    display: 'block',
  },
  chevron__icon: {
    stroke: theme.primaryColor,
    width: 13,
    height: 9,
  },
};

export default filterStyles;
