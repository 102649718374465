import accounting from 'accounting';

export const topPartnersColumns = [
  {
    title: '',
    headerClassName: 'col-60',
    className: '',
    field: 'siteName',
    formatter(val, ix) {
      return `${ix + 1}. ${val}`;
    },
  },
  {
    title: '',
    headerClassName: 'text-right col-25',
    className: 'text-right',
    field: 'value',
    formatter(val, _, mask) {
      return mask.includes('$') ? accounting.formatMoney(val) : accounting.formatNumber(val);
    },
  },
  {
    title: '',
    headerClassName: 'text-right col-15',
    className: 'text-right',
    field: 'percentage',
    formatter(val) {
      return `${val}%`;
    },
  },
];

export const topSkuColumns = [
  {
    title: '',
    headerClassName: 'col-25',
    className: '',
    field: 'sku',
    formatter(val, ix) {
      return `${ix + 1}. ${val}`;
    },
  },
  {
    title: '',
    headerClassName: 'col-50',
    className: '',
    field: 'description',
  },
  {
    title: '',
    headerClassName: 'text-right col-25',
    className: 'text-right',
    field: 'value',
    formatter(val, _, mask) {
      return mask.includes('$') ? accounting.formatMoney(val) : accounting.formatNumber(val);
    },
  },
];
