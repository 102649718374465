export function stripEmptyFilters(dirtyFilter) {
  const cleanFilter = {};

  Object.keys(dirtyFilter).forEach((k) => {
    const filterValue = dirtyFilter[k];
    if (filterValue && (!Array.isArray(filterValue) || filterValue.length > 0)) {
      cleanFilter[k] = filterValue;
    }
  });

  return cleanFilter;
}
