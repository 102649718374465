import React, { Component } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import { graphql } from '@apollo/client/react/hoc';
import injectSheet from 'react-jss';
import { inject, observer } from 'mobx-react';
import pluralize from 'pluralize';
import {
  AlertService,
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  Theme as theme,
  DataTableNaked,
  Column,
  TextBox,
  compose,
} from '@spoiler-alert/ui-library';
import { StagedListingsByInventoryQuery } from '../../graphql/queries';
import { createStagedListingsFromInventory } from '../../graphql/mutations';
import { SplitInventoryStrings } from '../../string-resources';

export const styles = {
  formContainer: {
    width: '100%',
  },
  modalTitle: {
    margin: '0 0 0 0',
  },
  modalSubhead: {
    fontSize: '18px',
    margin: 0,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    '& td': {
      padding: 5,
    },
    '& tbody tr': {
      borderTop: `1px ${theme.borderColor} solid`,
    },
  },
  tableWrap: {
    borderTop: '1px #D1D6DA solid',
    borderRight: '1px #D1D6DA solid',
    borderLeft: '1px #D1D6DA solid',
    borderBottom: '1px #D1D6DA solid',
    maxHeight: 450,
    overflow: 'auto',
  },
  splitScreenHeader: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingBottom: 4,
  },
  splitScreenFooter: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: 4,
  },
  textbox__pre: {
    width: 13,
    fontSize: '0.875rem',
    color: theme.textColorPrimary,
    textAlign: 'right',
    paddingRight: 4,
    verticalAlign: 'middle',
  },
  priceField: {
    display: 'flex',
    width: 100,
    justifyContent: 'end',
  },
  priceInput: {
    width: '73px !important',
    textAlign: 'right !important',
  },
  submit: {
    marginRight: 0,
  },
  error: {
    borderRadius: 4,
    background: theme.red10,
    border: `1px ${theme.red} solid`,
    padding: '2px 5px',
    marginTop: '1em',
  },
};

class SplitInventoryModal extends Component {
  state = {
    stagedListingsForInventory: [],
    onHandQuantity: undefined,
    splitting: false,
    totalListedQuantity: 0,
    inventoryId: this.props.inventoryId,
    shouldRefreshQuantities: true,
    description: null,
    loading: true,
  };

  static propTypes = {
    classes: PropTypes.object,
    onHide: PropTypes.func,
    open: PropTypes.bool,
    refetchQueries: PropTypes.array,
    data: PropTypes.object,
    inventoryId: PropTypes.string,
    createStagedListingsFromInventory: PropTypes.func,
    filters: PropTypes.object,
    rootStore: PropTypes.object,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = { inventoryId: nextProps.inventoryId };
    if (prevState.shouldRefreshQuantities && nextProps.data && nextProps.data.stagedListingsByInventoryQuery) {
      state.shouldRefreshQuantities = false;
      const { stagedListingsByInventoryQuery } = nextProps.data;
      let totalListedQuantity = 0;
      state.stagedListingsForInventory = stagedListingsByInventoryQuery.stagedListings.map((sl) => {
        totalListedQuantity += sl.availableQuantity;
        return {
          _id: sl.distributionListId,
          distributionListId: sl.distributionListId,
          distributionListName: sl.distributionListName,
          listQuantityInvalid: false,
          availableQuantity: sl.availableQuantity,
          pricingStrategy: sl.pricingStrategy,
          percentageModifier: accounting.unformat(sl.percentageModifier),
        };
      });

      state.onHandQuantity = stagedListingsByInventoryQuery.onHandQuantity;
      state.description = stagedListingsByInventoryQuery.description;
      state.totalListedQuantity = totalListedQuantity;
      state.loading = false;
    }
    if (nextProps.data && nextProps.data.loading) {
      state.shouldRefreshQuantities = true;
      state.stagedListingsForInventory = [];
      state.onHandQuantity = 0;
      state.totalListedQuantity = 0;
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open && this.props.data && !this.props.data.loading) {
      this.props.data.refetch().then(() => this.setState({ shouldRefreshQuantities: true }));
    }
  }

  createStagedListings = (ev) => {
    const { stagedListingsForInventory } = this.state;
    const { inventoryId, refetchQueries } = this.props;
    const stagedListingsWithQuantity = stagedListingsForInventory.filter((sl) => sl.availableQuantity > 0);
    const inventoriesToStage = [];
    ev.preventDefault();
    this.setState({ splitting: true });
    stagedListingsWithQuantity.forEach((sl) => {
      inventoriesToStage.push({
        inventoryId,
        distributionListId: sl.distributionListId,
        quantity: sl.availableQuantity,
        pricingStrategy: sl.pricingStrategy || undefined,
        percentageModifier: sl.percentageModifier ? accounting.unformat(sl.percentageModifier) : undefined,
      });
    });
    const createPostVariables = {
      inventoriesToStage,
      allSelected: false,
      inventoryFilters: {},
    };
    this.props.createStagedListingsFromInventory({ variables: createPostVariables, refetchQueries }).then(this.hide).catch(this.hideAndError);
  };

  cancel = () => {
    this.props.onHide(false);
  };

  hide = (response) => {
    let actionTaken = false;
    let loggedCount = 0;
    let errorCount = 0;
    if (response.data) {
      const loggedResults = response.data.createStagedListingsFromInventory;
      errorCount = loggedResults.errors?.length;
      actionTaken = loggedResults.inventories?.length > 0;
      loggedCount = loggedResults.inventories?.length - errorCount;
    }
    if (errorCount > 0) {
      const { message } = response.data.createStagedListingsFromInventory.errors[0];
      AlertService.alert({ type: 'warning', message: <span>{message}</span> });
      this.props.onHide(false, 0, 0);
    } else {
      this.props.onHide(actionTaken, errorCount, loggedCount);
    }
    this.setState({ splitting: false });
    this.props.data.refetch();
  };

  hideAndError = () => {
    const message = `We were unable to create the listings due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.`;
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });

    this.setState({ splitting: false });
    this.props.data.refetch();
    this.props.onHide(false, 0, 0);
  };

  updateListedQuantity = (row, newValue) => {
    let value = newValue;
    let totalListedQuantity = 0;
    const { stagedListingsForInventory } = this.state;
    if (value.trim() === '') value = 0;
    stagedListingsForInventory.forEach((sl) => {
      const numericValue = parseInt(value, 10);
      if (sl.distributionListName === row.distributionListName) {
        if (isNaN(numericValue) || numericValue < 0 || numericValue > this.state.onHandQuantity) {
          sl.listQuantityInvalid = true;
          sl.availableQuantity = 0;
        } else {
          sl.listQuantityInvalid = false;
          sl.availableQuantity = numericValue;
        }
      }
      const availableQuantity = isNaN(parseInt(sl.availableQuantity, 10)) ? 0 : parseInt(sl.availableQuantity, 10);
      totalListedQuantity += parseInt(availableQuantity, 10);
    });
    this.setState({ totalListedQuantity, stagedListingsForInventory });
  };

  get percentListed() {
    const { totalListedQuantity, onHandQuantity } = this.state;
    return Math.round((totalListedQuantity / onHandQuantity) * 100);
  }

  get disableStageListing() {
    const { stagedListingsForInventory } = this.state;
    const stagedListingsWithInvalidQuantity = stagedListingsForInventory.find((sl) => sl.listQuantityInvalid === true);
    const stagedListingsWithQuantity = stagedListingsForInventory.find((sl) => sl.availableQuantity > 0);
    return stagedListingsWithInvalidQuantity !== undefined || stagedListingsWithQuantity === undefined;
  }

  getStagingColumns() {
    const { classes } = this.props;
    return [
      new Column({
        field: 'distributionListName',
        displayName: 'Distribution List Name',
        defaultSort: true,
        formatter: (_, row) => row.distributionListName,
      }),
      new Column({
        field: 'availableQuantity',
        displayName: 'Quantity to List',
        style: { width: '130px', paddingRight: '24px' },
        formatter: (availableQuantity, row) => {
          const currentQuantity = availableQuantity > 0 ? availableQuantity : '';
          return (
            <div className={classes.priceField}>
              <TextBox
                type="number"
                onChange={this.updateListedQuantity.bind(this, row)}
                inputClassName={classes.priceInput}
                labelSROnly
                small
                value={currentQuantity}
                min={0}
                max={this.state.onHandQuantity}
                error={row.listQuantityInvalid}
              />
            </div>
          );
        },
      }),
    ];
  }

  render() {
    const { open, classes } = this.props;
    const { splitting, stagedListingsForInventory, onHandQuantity, description, loading } = this.state;
    return (
      <Modal onHide={this.props.onHide} open={open} closeOnEsc closeOnOutsideClick>
        <form onSubmit={this.createStagedListings} className={classes.formContainer}>
          <ModalContent>
            <h2 className={classes.modalTitle}>{SplitInventoryStrings.title}</h2>
            <h3 className={classes.modalSubhead}>{description}</h3>
            <div className={classes.splitScreenHeader}>
              <div>
                {SplitInventoryStrings.totalQuantityAvailable}: {onHandQuantity} {pluralize(SplitInventoryStrings.uom, onHandQuantity)}
              </div>
            </div>
            <div id="inventory" className={classes.tableWrap}>
              <DataTableNaked
                data={stagedListingsForInventory}
                filterable="none"
                filterParameters={{}}
                filters={[]}
                transition
                onRowClick={() => {}}
                columns={this.getStagingColumns()}
                loading={loading}
              />
            </div>
            <div className={classes.splitScreenFooter}>
              <div>
                {SplitInventoryStrings.percentListed}: {this.percentListed}%
              </div>
            </div>
          </ModalContent>
          <ModalFooter>
            <Button type="button" onClick={this.cancel} link disabled={splitting}>
              Close
            </Button>
            <Button type="submit" className={classes.submit} disabled={this.disableStageListing} primary loading={splitting} loadingText="Wait...">
              {SplitInventoryStrings.stageListingsButton}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

const ConnectedComponent = inject('rootStore')(observer(SplitInventoryModal));
const StyledComponent = injectSheet(styles)(ConnectedComponent);
const GqlComponent = compose(
  graphql(StagedListingsByInventoryQuery, {
    options: (props) => ({ variables: { inventoryId: props.inventoryId }, fetchPolicy: 'network-only' }),
    skip: (props) => !props.inventoryId,
  }),
  graphql(createStagedListingsFromInventory, { name: 'createStagedListingsFromInventory' })
)(StyledComponent);
export default GqlComponent;
