import React from 'react';
import moment from 'moment';
import { Column } from '@spoiler-alert/ui-library';
import statusMap from '../status-map';

const destinationMap = new Map([
  ['DONATE', 'Nonprofit Donation'],
  ['SALE', 'Sale'],
  ['DISCOUNTED_SALES', 'Discounted Sales'],
  ['GIVE_AWAY', 'Give Away'],
  ['ANIMAL_FEED', 'Animal Feed'],
  ['CHARITY', 'Charity'],
  ['BIOMATERIAL_PROCESSING', 'Biomaterial Processing'],
  ['CO_ANAEROBIC_DIGESTION', 'Co-anaerobic digestion'],
  ['COMPOST', 'Compost'],
  ['CONTROLLED_COMBUSTION', 'Controlled Combustion'],
  ['LAND_APPLICATION', 'Land Application'],
  ['LANDFILL', 'Landfill'],
  ['NOT_HARVESTED', 'Not Harvested'],
  ['REFUSE_DISCARDS', 'Refuse / Discards'],
  ['SEWER', 'Sewer'],
]);

const myClaimsColumns = [
  new Column({ field: 'supplierSiteName', displayName: 'Supplier', sortable: true }),
  new Column({
    field: 'transactedAt',
    displayName: 'Transaction Date',
    sortable: true,
    defaultSort: true,
    formatter: (value) => moment(value).format('M/DD/YYYY h:mm a'),
  }),
  new Column({ field: 'category', displayName: 'Category', sortable: true }),
  new Column({
    field: 'description',
    displayName: 'Description',
    sortable: true,
    formatter(value) {
      let displayText = value;
      if (value && value.length > 50) displayText = `${value.substr(0, 50).trim()}\u2026`;
      return <span title={value}>{displayText}</span>;
    },
  }),
  new Column({ field: 'quantity', displayName: 'Quantity', sortable: true, formatter: (value, row) => `${value} ${row.unitOfMeasure}` }),
  new Column({
    field: 'destination',
    displayName: 'Type',
    sortable: true,
    formatter: (value, row, col) => col.enumMap.get(value),
    enumMap: destinationMap,
  }),
  new Column({ field: 'transactionId', displayName: 'Transaction #', sortable: true }),
  new Column({ field: 'status', displayName: 'Status', sortable: true, formatter: (value, row, col) => col.enumMap.get(value), enumMap: statusMap }),
];

export { myClaimsColumns, statusMap };
