import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import AppSettings from '../app-settings';
const fetcher = (input, init) => window.fetch(input, init);
const requestLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('token');
    operation.setContext({
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
    return forward(operation);
});
const client = new ApolloClient({
    connectToDevTools: AppSettings.ENVIRONMENT_NAME === 'local',
    link: ApolloLink.from([
        onError(({ networkError }) => {
            if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
                localStorage.removeItem('token');
            }
        }),
        requestLink,
        createHttpLink({
            uri: `${AppSettings.GRAPHQL_SERVER_URL}graphql`,
            fetch: fetcher,
        }),
    ]),
    cache: new InMemoryCache({
        typePolicies: {
            Inventory: {
                merge: true,
            },
            OfferListingType: {
                merge: true,
            },
            Query: {
                fields: {
                    trucklaneViewQuery: {
                        keyArgs: false,
                        merge(existing = { trucklanes: [] }, incoming) {
                            const array = [...existing.trucklanes, ...(incoming.trucklanes || [])];
                            const set = new Set();
                            const trucklanes = array.filter((item) => {
                                const id = item.__ref;
                                return set.has(id) ? false : set.add(id);
                            });
                            return Object.assign(Object.assign({}, incoming), { trucklanes });
                        },
                    },
                },
            },
        },
    }),
});
export default client;
