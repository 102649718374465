import { gql } from '@apollo/client';

export const InventoryDetailFragment = gql`
  fragment InventoryDetails on Inventory {
    _id
    availableQuantity
    batch
    bestByDate
    brand
    businessUnit
    casesPerPallet
    codeDate
    committedQuantity
    comments
    consumerCode
    costPerEach
    countryOfOrigin
    createdByName
    createdAt
    cubeAdjustedWeight
    currencyType
    description
    distributionLists {
      _id
      listId
      listedAt
      published
      name
    }
    division
    exportFlag
    firstReceivedDate
    foodSubtype
    foodType
    GTINCase
    handling
    truckType
    images
    inventoryType
    itemNote
    itemId
    itemName
    lineOfBusiness
    managementGroup
    materialType
    mustGoByDate
    offerListingCount
    onHandQuantity
    originalQuantity
    originalTotalCost
    packQuantity
    packSize
    packUom
    palletStack
    proteinBufferDate
    pullTier
    quality
    reason
    reasonDescription
    retailReady
    salvageFlag
    siteId
    siteName
    siteNumber
    skuRationalizationFlag
    source
    spoilsPercentage
    status {
      _id
      accepted
      awarded
      listed
      received
      staged
    }
    storageLocation
    uploadedSuggestedUnitPrice
    tags
    totalCost
    totalCubeAdjustedWeight
    totalGrossWeight
    totalNetWeight
    totalPallets
    totalPrice
    unitCost
    unitGrossWeight
    unitNetWeight
    unitOfMeasure
    unitPrice
    unitUPC
    unitUPCWithCheckId
    upc
    vendorReturns
    weightUnit
    writeOffPrice
    writeOffReason
    uploadedReservePrice
    activeReservePricing {
      reserveAnchor
      reserveNumber
    }
    importBatch
  }
`;
