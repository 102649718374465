import LocalizedStrings from 'react-localization';

const SuggestedPriceStrings = new LocalizedStrings({
  en: {
    title: 'Adjust Suggested Price',
    anchor: 'Anchor',
    percent: 'Percent',
    flatRate: 'Flat Rate',
    cancel: 'Cancel',
    submit: (num) => `Adjust ${num} Item${num > 1 ? 's' : ''}`,
  },
});

export default SuggestedPriceStrings;
