import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import injectSheet from 'react-jss';
import { PostDetails, Map, StatusBadge, Loading, OverlayService } from '@spoiler-alert/ui-library';
import AppSettings from '../app-settings';
import { PostQuery } from '../graphql/queries';
import ErrorModal from '../components/modals/error-modal';
import { TitleService } from '../services';
import { Breadcrumbs } from '../store';

const styles = {
  post__view: {
    flexGrow: 1,
    minHeight: '500px',
  },
  ask__button: {
    marginLeft: 'auto',
  },
  post__loading: {
    width: '100%',
    height: '100%',
  },
  'post-view__container': {
    display: 'flex',
    flexDirection: 'row',
    height: 432,
    marginLeft: -12,
    flexWrap: 'wrap',
  },
  'post-view__header': {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 0 auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 24,
    marginTop: 3,
  },
  'post-view__title': {
    fontSize: '1.5rem',
    marginRight: 24,
    marginBottom: 0,
  },
  '@media (max-width: 840px)': {
    'post-details__container': {
      flexDirection: 'column',
      height: 'auto',
    },
  },
};

class Post extends Component {
  constructor(props) {
    super(props);
    TitleService.setTitles('Post Details');
    OverlayService.hide();
    Breadcrumbs.set([
      {
        url: '/activity/my-posts',
        title: 'My Posts',
      },
      {
        url: props.match.url,
        title: 'Post',
      },
    ]);

    const { data } = props;

    this.state = {
      data,
      id: props.match.params.id,
      post: data ? data.postByIdQuery : undefined,
      showErrorModal: false,
      error: undefined,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return { data: nextProps.data, post: nextProps.data.postByIdQuery };
  }

  hideErrorModal = () => {
    this.setState({ showErrorModal: false });
  };

  get isSupplier() {
    return this.state.post && this.state.post.supplierSite._id === this.props.user.site._id;
  }

  render() {
    const { classes } = this.props;
    const { post, data, showErrorModal, error } = this.state;
    const coordinates = post && post.location && post.location.coordinates ? post.location.coordinates : { latitude: null, longitude: null };
    return (
      <div id="post" className={classes.post__view}>
        <Loading size="large" loading={!post && !data.error} error={data.error}>
          <div className={classes.post__loading}>
            {post && !data.loading && !data.error && (
              <div>
                <div className={classes['post-view__header']}>
                  <h1 className={classes['post-view__title']}>{post.description}</h1>
                  <StatusBadge status={post.status} />
                </div>
                <div className={classes['post-view__container']}>
                  <PostDetails post={post} />
                  <Map
                    latitude={coordinates.latitude}
                    longitude={coordinates.longitude}
                    location={post.location.fullAddress}
                    distanceFromYou={`${post.distanceInMiles} miles`}
                    radius={post.maxDeliveryRadiusMiles}
                    googleApiKey={AppSettings.GOOGLE_MAPS_KEY}
                    googleApiVersion={AppSettings.GOOGLE_MAPS_VERSION}
                  />
                </div>
                <ErrorModal open={showErrorModal} onHide={this.hideErrorModal} error={error} />
              </div>
            )}
          </div>
        </Loading>
      </div>
    );
  }
}

Post.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  classes: PropTypes.object,
  data: PropTypes.object,
};

const StyledComponent = injectSheet(styles)(Post);
const GqlComponent = graphql(PostQuery, { options: (props) => ({ variables: { id: props.match.params.id } }) })(StyledComponent);
export default GqlComponent;
