import { Column, StatusBadge, ArrowIcon, InformationIcon, MiniTooltip, ColorBadge, Theme, DotIcon, ExclamationIcon } from '@spoiler-alert/ui-library';
import accounting from 'accounting';
import moment from 'moment';
import React from 'react';

const statusMap = new Map([
  ['ACCEPTED', <StatusBadge status="AWARDED" key="status" />],
  ['PROPOSED', <StatusBadge status="UNAWARDED" key="status" />],
]);

const bestBuyFormatter = (currentRow, offer, classes) => {
  const lowRisk = currentRow.aboveReservePrice && currentRow.lowRiskBBD;
  const mainColor = lowRisk ? Theme.green : Theme.orange;
  const displayText = `${
    lowRisk
      ? 'Buyer approval is not needed before shifting this bid. Click for more detail.'
      : 'We recommend getting buyer approval before shifting this bid. Click for more detail.'
  }`;

  return (
    <div className={classes.row}>
      {moment(currentRow.bestByDate).format('MM/DD/YYYY')} ({moment(currentRow.bestByDate).startOf('day').diff(moment().startOf('day'), 'days')} days)
      <div style={{ color: mainColor }} className={classes.bestByDate}>
        <ArrowIcon style={{ fill: mainColor }} className={classes.arrowIcon} />
        {moment(offer.bestByDate).startOf('day').diff(moment(currentRow.bestByDate).startOf('day'), 'days')}
      </div>
      <MiniTooltip
        text={
          <div className={classes.tooltipText}>
            <span style={{ whiteSpace: 'pre-line', textTransform: 'none', lineHeight: 'normal' }}>{displayText}</span>
          </div>
        }
      >
        <div className={classes.row}>
          <ColorBadge
            theme={{
              color: mainColor,
              backgroundColor: currentRow.aboveReservePrice && currentRow.lowRiskBBD ? Theme.green5 : Theme.orange5,
            }}
          >
            <div className={classes.row}>
              {currentRow.aboveReservePrice && currentRow.lowRiskBBD ? (
                <DotIcon style={{ transform: 'translateY(-1px)' }} />
              ) : (
                <ExclamationIcon style={{ transform: 'translateY(-2px)' }} />
              )}

              <InformationIcon style={{ fill: mainColor }} className={classes.informationIcon} />
            </div>
          </ColorBadge>
        </div>
      </MiniTooltip>
    </div>
  );
};

export const shiftedOfferColumns = (classes, offer) => [
  new Column({
    displayName: 'SKU',
    field: 'sku',
    formatter: () => offer.itemName,
    visible: true,
    sortable: false,
    defaultSort: true,
  }),
  new Column({
    displayName: 'Description',
    field: 'description',
    formatter: () => offer.itemDescription,
    visible: true,
    sortable: false,
  }),
  new Column({
    displayName: 'Best By',
    field: 'bestBy',
    formatter: (_, currentRow) => bestBuyFormatter(currentRow, offer, classes),
    visible: true,
    sortable: false,
  }),
  new Column({
    displayName: 'Available Qty',
    field: 'availableQuantity',
    formatter: (_, currentRow) => currentRow.inventoryQuantity,
    visible: true,
    sortable: false,
  }),
  new Column({
    displayName: 'Qty to Shift',
    field: 'quantity',
    formatter: (_, currentRow) => currentRow.shiftedQuantity,
    visible: true,
    sortable: false,
  }),
  new Column({
    displayName: 'Cost Recovery Rate',
    field: 'SCR',
    formatter: (_, currentRow) => currentRow.costRecoveryRate,
    visible: true,
    sortable: false,
  }),
  new Column({
    displayName: 'Total Revenue',
    field: 'totalRev',
    formatter: (_, currentRow) => `${accounting.formatMoney(currentRow.shiftedQuantity * currentRow.unitPrice)}`,
    visible: true,
    sortable: false,
  }),
  new Column({
    displayName: 'Status',
    field: 'status',
    enumMap: statusMap,
    formatter: (value, row, col) => col.enumMap.get(value),
    visible: true,
    sortable: false,
  }),
];
