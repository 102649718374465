import LocalizedStrings from 'react-localization';

const SiteInfoStrings = new LocalizedStrings({
  en: {
    successAlertText: 'Your site info has been updated correctly.',
    errorAlertText: 'There was an error updating your site info. If this problem persists, please contact a Spoiler Alert Administrator to help you.',
    browseFiles: 'Browse Files',
    companyLogo: 'Company Logo',
    uploadLogoText: 'Upload Your Logo',
    siteNameTooltip: "Your company's name. This is a unique for your business/location that should be easily identifiable by Spoiler Alert users.",
    phoneNumberTooltip: "Your company's main phone number.",
    websiteTooltip: "Your company's public website URL.",
    pacaTooltip: "Your company's paca license number.",
    pageTitle: 'Your Site',
    address: 'Address',
    dragDrop: 'Drag and Drop',
    yourImageFile: 'your image file, or',
    noImage: 'No Image',
  },
});

export default SiteInfoStrings;
